import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import wysiwyg from 'static/wysiwyg.json';
import { Container, Col, Row } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { Layout } from 'containers';
import {
  Breadcrumb,
  FeatherIcon,
  TextEditor,
} from 'components';
import {
  NetworksProvider,
  PackagesProvider,
} from 'providers';
import Select from 'react-select';

const flteTypes = ['CAPPED', 'UNCAPPED'];

const EditPackage = () => {
  const { id } = useParams();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [contractDetails, setContractDetails] = useState(null);
  const [networkProviders, setNetworkProviders] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [packageDetails, setPackageDetails] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [showAirtime, setShowAirtime] = useState(false);
  const [showMinutes, setShowMinutes] = useState(false);
  const [packageTypes, setPackageTypes] = useState([]);
  const [packageInfo, setPackageInfo] = useState({});
  const [showData, setShowData] = useState(true);
  const [showSMS, setShowSMS] = useState(false);
  const [airtime, setAirtime] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [data, setData] = useState(null);
  const [sms, setSms] = useState(null);
  const breadcrumb = [
    { title: 'Packages', link: '/packages', active: false },
    { title: 'Edit Package', active: true },
  ];

  const handleSelectType = (item) => {
    setShowSMS(false);
    setShowData(false);
    setShowAirtime(false);
    setShowMinutes(false);
    setSelectedType(null);

    if (item && item.description) {
      setSelectedType(item);
      switch (item.description.toLowerCase()) {
        case 'data':
        case 'capped':
        case 'uncapped':
        case 'data only':
          setShowData(true);
          register('data', { required: true });
          break;

        case 'voice':
        case 'voice only':
          setShowMinutes(true);
          register('minutes', { required: true });
          break;

        case 'airtime':
        case 'prepaid':
        case 'pre-paid':
        case 'pre paid':
          setShowAirtime(true);
          register('airtime', { required: true });
          break;

        case 'topup':
        case 'top up':
          setShowAirtime(true);
          register('airtime', { required: true });
          break;

        case 'voice + data':
        case 'open contract':
          setShowData(true);
          setShowMinutes(true);
          setShowSMS(true);
          register('data', { required: true });
          register('minutes', { required: true });
          register('sms', { required: true });
          break;

        default: break;
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const data = await NetworksProvider.getAll();
        const types = await PackagesProvider.getTypes();
        const pckg = await PackagesProvider.get(id);
        if (data.results) {
          if (data.results.length > 0) {
            const providers = [];
            data.results.map((item) =>
              providers.push({ value: item.id, label: item.name })
            );
            setNetworkProviders(providers);
          }
        }
        if (types.results) {
          if (types.results.length > 0) {
            handleSelectType(pckg.package_type);
            setPackageTypes(types.results);
          }
        }
        let contractDetails = "";
        if (pckg.contract_details && pckg.contract_details.length > 0) {
          contractDetails = pckg.contract_details.split("\n").join("<br/>");
        }
        setPackageDetails(pckg);
        setContractDetails(contractDetails);
        setSelectedNetwork(pckg.network_provider);
        setPackageInfo(pckg);
        setAirtime(pckg.airtime);
        setMinutes(pckg.minutes);
        setData(pckg.data);
        setSms(pckg.sms);
        return setPageLoading(false);
      }
      return (window.location = "/packages");
    };
    fetchData();
  }, []);

  const onSubmit = (data) => {
    handleSavePackage(data);
  };

  const handleSavePackage = async (param) => {
    setSubmitting(true);
    const { name, code, description } = param;
    const post = {
      name,
      code,
      description,
      type_id: selectedType.id,
      provider_id: selectedNetwork.id,
      contract_details: contractDetails,
      airtime,
      minutes,
      data,
      sms,
    };
    const result = await PackagesProvider.update(packageInfo.id, post);
    setSubmitting(false);

    if (result.status === "success") {
      alert("Package information successfully updated!");
      window.location = "/packages";
    } else {
      alert(result.message);
    }
  };

  const handleNetworkSelect = (data) => {
    networkProviders.map((item) => {
      if (item.id === data.value) {
        setSelectedNetwork(item);
      }
      return true;
    });
  };

  return (
    <Layout pageLoading={submitting || pageLoading}>
      <div className="content content-fixed pd-b-0">
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <Breadcrumb heading="Edit Package" items={breadcrumb} />
        </Container>
      </div>
      <div className="contact-wrapper" style={{ top: 174 }}>
        <div className="contact-content fluid">
          <div className="contact-content-header r-f-0">
            <nav className="nav">
              <a role="button" href="/packages/add" className="nav-link active">
                Information
              </a>
            </nav>
          </div>
          {packageInfo.id && (
            <div className="contact-content-body ps r-f-0 overflow-y-auto">
              <div className="tab-content">
                <div className="tab-pane show active pd-20 pd-xl-25">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex align-items-center justify-content-between mg-b-25">
                      <h6 className="mg-b-0">Package Information</h6>
                      <div className="d-flex">
                        <button
                          type="submit"
                          className="btn btn-sm btn-outline-primary d-flex align-items-center mg-r-5"
                        >
                          <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                          <span className="d-none d-sm-inline mg-l-5">
                            {" "}
                            Save
                          </span>
                        </button>
                        <Link to="/packages">
                          <button
                            type="button"
                            className="btn btn-sm btn-white d-flex align-items-center"
                          >
                            <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                            <span className="d-none d-sm-inline mg-l-5">
                              {" "}
                              Cancel
                            </span>
                          </button>
                        </Link>
                      </div>
                    </div>
                    <Row style={{ maxWidth: "100%" }}>
                      <Col xs={12} sm={4}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Network Provider
                          </label>
                          <Select
                            options={networkProviders}
                            isLoading={networkProviders.length === 0}
                            isDisabled={networkProviders.length === 0}
                            defaultValue={{
                              value: selectedNetwork.id,
                              label: selectedNetwork.name,
                            }}
                            onChange={(value) => handleNetworkSelect(value)}
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={4}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Name
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.name ? "parsley-error" : ""
                            }`}
                            placeholder="Enter package name..."
                            {...register('name', { required: true })}
                            defaultValue={packageInfo.name}
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={4}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Code
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.code ? "parsley-error" : ""
                            }`}
                            placeholder="Enter package code..."
                            {...register('code', { required: true })}
                            defaultValue={packageInfo.code}
                          />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Package Type
                          </label>
                          <Row>
                            <Col xs={12} sm={7}>
                              <div className="btn-group" role="group">
                                {packageTypes.length > 0 &&
                                  packageTypes.map((item) => (
                                    <button
                                      key={item.id}
                                      type="button"
                                      className={`btn btn-${
                                        selectedType &&
                                        selectedType.id === item.id
                                          ? "primary"
                                          : "dark"
                                      }`}
                                      onClick={() => handleSelectType(item)}
                                    >
                                      {item.description}
                                    </button>
                                  ))}
                              </div>
                            </Col>
                            <Col xs={12} sm={5} className="package-types">
                              <div className="input-group">
                                {showAirtime && (
                                  <>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        Airtime
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        errors.airtime ? "parsley-error" : ""
                                      }`}
                                      placeholder="Value in Rands"
                                      {...register('airtime', { required: true })}
                                      onChange={(e) =>
                                        setAirtime(e.target.value)
                                      }
                                      defaultValue={airtime}
                                    />
                                  </>
                                )}

                                {showMinutes && (
                                  <>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        Minutes
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        errors.minutes ? "parsley-error" : ""
                                      }`}
                                      placeholder="Minute bundles"
                                      {...register('minutes', { required: true })}
                                      onChange={(e) =>
                                        setMinutes(e.target.value)
                                      }
                                      defaultValue={minutes}
                                    />
                                  </>
                                )}

                                {showData && (
                                  <>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        DATA
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        errors.data ? "parsley-error" : ""
                                      }`}
                                      placeholder="Data in MB"
                                      {...register('data', { required: true })}
                                      onChange={(e) => setData(e.target.value)}
                                      defaultValue={data}
                                    />
                                  </>
                                )}

                                {showSMS && (
                                  <>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        SMS
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        errors.sms ? "parsley-error" : ""
                                      }`}
                                      placeholder="SMS bundles"
                                      {...register('sms', { required: true })}
                                      onChange={(e) => setSms(e.target.value)}
                                      defaultValue={sms}
                                    />
                                  </>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      {selectedType && selectedType.description && flteTypes.includes(selectedType.description.toUpperCase()) &&
                        <Col xs={12}>
                          <div className="form-group">
                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                              FLTE Attributes
                            </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  Download Speed
                                </span>
                              </div>
                              <input
                                type="text"
                                className={`form-control ${errors.download_speed ? "parsley-error" : ""
                                  }`}
                                placeholder="Speed in Mbps"
                                {...register('download_speed', { required: false })}
                                defaultValue={packageDetails.download_speed || ''}
                              />

                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  Upload Speed
                                </span>
                              </div>
                              <input
                                type="text"
                                className={`form-control ${errors.upload_speed ? "parsley-error" : ""
                                  }`}
                                placeholder="Speed in Mbps"
                                {...register('upload_speed', { required: false })}
                                defaultValue={packageDetails.upload_speed || ''}
                              />

                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  Throttle
                                </span>
                              </div>
                              <input
                                type="text"
                                className={`form-control ${errors.throttle ? "parsley-error" : ""
                                  }`}
                                placeholder="Throttle"
                                {...register('throttle', { required: false })}
                                defaultValue={packageDetails.throttle || ''}
                              />

                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  Night Express Data
                                </span>
                              </div>
                              <input
                                type="text"
                                className={`form-control ${errors.night_express_data ? "parsley-error" : ""
                                  }`}
                                placeholder="Data in MB"
                                {...register('night_express_data', { required: false })}
                                defaultValue={packageDetails.night_express_data || ''}
                              />

                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  FUP
                                </span>
                              </div>
                              <input
                                type="text"
                                className={`form-control ${errors.fup ? "parsley-error" : ""
                                  }`}
                                placeholder="Fair Usage Policy"
                                {...register('fup', { required: false })}
                                defaultValue={packageDetails.fup || ''}
                              />
                            </div>
                          </div>
                        </Col>}
                      <Col xs={12}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Description (Optional)
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.description ? "parsley-error" : ""
                            }`}
                            placeholder="Enter package description (optional)..."
                            {...register('description', { required: false })}
                            defaultValue={packageInfo.description}
                          />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Contract Details (Optional)
                          </label>
                          <TextEditor.WYSIWYG
                            model={contractDetails}
                            setModel={setContractDetails}
                            placeholderText="Enter contract details..."
                            toolbarButtons={
                              wysiwyg.toolbarButtons.contractDetails
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EditPackage;
