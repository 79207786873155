import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-currency-format';
import CurrencyFormat from 'react-currency-format';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import utils from 'utils';

const {
    formatCurrency,
    getDealDescription,
    getDeviceDescription,
    getExtraData,
    getExtraValue,
    getFreeAccessory,
    getPackageCategory,
    getReducedSub,
    validRecordToImport,
} = utils;

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const DealsTable = props => {
    const {
        data,
        imported,
    } = props;

    // table headings definition
    const columns = [{
        name: 'Dealsheet No',
        selector: row => row['dealsheet_number'],
        sortable: true,
        allowOverflow: true,
        minWidth: '150px',
    },
    {
        name: 'Package Code',
        selector: row => row['package_code'],
        sortable: true,
        wrap: true,
    },
    {
        name: 'Device Description',
        selector: row => row['device_description'],
        sortable: true,
        allowOverflow: true,
        wrap: true,
    },
    {
        name: 'Contract Term',
        selector: row => row['contract_term'],
        sortable: true,
        wrap: true,
        cell: row => <span>{row.contract_term ? row.contract_term === 1 ? `${row.contract_term} month` : `${row.contract_term} months` : ''}</span>
    },
    {
        name: 'Valid From',
        selector: row => row['valid_from'],
        sortable: true,
        wrap: true,
    },
    {
        name: 'Valid To',
        selector: row => row['valid_to'],
        sortable: true,
        wrap: true,
    },
    {
        name: 'Price',
        selector: row => row['total_subscription'],
        sortable: true,
        wrap: true,
        cell: row => (
            <>
                {row.total_subscription ?
                    <CurrencyFormat
                        value={row.total_subscription}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'R'}
                    /> : '-'
                }
            </>
        )
    }];

    if (imported) {
        columns.push({
            name: 'To be Imported?',
            sortable: false,
            wrap: true,
            cell: row => (
                <span>
                    {imported && imported.includes(row.dealsheet_number) ? 'YES' : 'NO'}
                </span>
            )
        });
    }

    const conditionalRowStyles = [
        {
            when: () => !imported,
            style: { backgroundColor: 'transparent' },
        },
        {
            when: row => (imported && imported.includes(row.dealsheet_number)),
            style: () => ({
                color: '#085f2e',
                backgroundColor: 'green',
                backgroundColor: '#cff1de',
                borderColor: '#bcebd1',
            }),
        },
        {
            when: row => (imported && !imported.includes(row.dealsheet_number)),
            style: () => ({
                color: '#721c24',
                backgroundColor: '#f8d7da',
                borderColor: '#f5c6cb',
            }),
        },
    ];

    // render datatable component
    return (
        <DataTable
            data={data}
            columns={columns}
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pagination
            noHeader
        />
    );
};

export default function DealsToBeImportedModal(props) {
    const {
        show,
        fields,
        network,
        bundles,
        setShow,
        settings,
        fileData,
        dealType,
        networkName,
        setShowLoader,
        saleIndicator,
        uploadedCount,
        setImportedDeals,
        setImportedDealsTotal,
        setReferenceNumber,
        importedDealsTotal,
        importedDeals,
        validFromDate,
        validToDate,
    } = props;
    const [activeTab, setActiveTab] = useState('import');
    const [uploadedData, setUploadedData] = useState([]);
    const [importedDN, setImportedDN] = useState([]);

    const handleClose = () => setShow(false);

    const processData = () => {
        const referenceNumber = `DU${networkName.substring(0, 3).toUpperCase() + moment().format('YYMMDDHHmmss')}`;
        const priceFields = ['tariff_subscription', 'total_subscription', 'device_cost'];
        const fieldValues = Object.values(fields);
        const fieldKeys = Object.keys(fields);
        const dataKeys = fileData[0];
        const importedDN = [];
        const dataObject = [];
        const fileObject = [];

        setReferenceNumber(referenceNumber);

        fileData.map((row, index) => {
            // exclude [first] heading row
            if (index !== 0) {
                const obj = {};
                row.map((value, idx) => {
                    const i = fieldValues.indexOf(dataKeys[idx]);
                    if (i !== -1) {
                        const key = fieldKeys[i];
                        if (priceFields.indexOf(key) !== -1) {
                            return obj[key] = value.replace('R', '').replace(',', '').trim();
                        }
                        obj[key] = (
                            value &&
                            (value.trim().toLowerCase() === '(blank)' || value.trim().toLowerCase() === 'none')
                        ) ? null : value.trim();
                    }
                    return true;
                });
                obj['extra_data'] = getExtraData(obj, networkName);
                obj['extra_value'] = getExtraValue(obj, networkName, bundles);
                obj['device_description'] = getDeviceDescription(obj['device_description']);
                obj['free_accessory'] = getFreeAccessory(obj, networkName);
                obj['description'] = getDealDescription(obj, networkName);
                obj['package_title'] = getPackageCategory(obj['package_code'], networkName, obj['contract_term']);
                obj['online'] = true;
                obj['status'] = 'ACTIVE';
                obj['deal_type'] = dealType;
                obj['provider_id'] = network;
                obj['sale_indicator_id'] = saleIndicator.id || null;
                obj['valid_from'] = moment(validFromDate).format('YYYY-MM-DD');
                obj['valid_to'] = moment(validToDate).format('YYYY-MM-DD');
                obj['device_cost'] = (obj['device_cost']) ? formatCurrency(obj['device_cost']) : null;
                obj['tariff_subscription'] = (obj['tariff_subscription']) ? formatCurrency(obj['tariff_subscription']) : null;
                obj['total_subscription'] = (obj['total_subscription']) ? formatCurrency(obj['total_subscription']) : null;
                obj['reduced_subscription'] = getReducedSub(obj);
                obj['reference'] = referenceNumber;
                if (validRecordToImport(obj, settings)) {
                    dataObject.push(obj);
                    importedDN.push(obj.dealsheet_number);
                }
                fileObject.push(obj);
            }
        });

        setImportedDN(importedDN);
        setUploadedData(fileObject);
        setImportedDeals(dataObject);
        setImportedDealsTotal(dataObject.length);
        setShowLoader(false);
    };

    useEffect(() => {
        processData();
    }, []);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className="confirm-modal"
            size="xxl"
        >
            <Modal.Header closeButton>
                <h6 className="modal-title">
                    {networkName} {dealType} Dealsheet
                </h6>
            </Modal.Header>
            <Modal.Body>
                <p className="mg-b-10">
                    Below is a list of all {networkName} {dealType} deals uploaded, and deals to be imported.
                    Out of&nbsp;
                    <NumberFormat
                        value={uploadedCount}
                        thousandSeparator={' '}
                        displayType={'text'}
                    />
                    &nbsp;deals uploaded,{uploadedCount > importedDealsTotal ? ' only ' : ' all '}
                    <NumberFormat
                        value={importedDealsTotal}
                        thousandSeparator={' '}
                        displayType={'text'}
                    />
                    &nbsp;will be imported into DAMS...
                </p>
                <div>
                    <ul className="nav nav-line" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a
                                className={`nav-link ${activeTab === 'import' ? 'active' : ''}`}
                                id="import-tab"
                                data-toggle="tab"
                                href="#import"
                                role="tab"
                                aria-controls="import"
                                aria-selected={activeTab === 'import'}
                                onClick={e => {
                                    e.preventDefault();
                                    setActiveTab('import');
                                }}
                            >
                                Deals to be Imported
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${activeTab === 'upload' ? 'active' : ''}`}
                                id="upload-tab"
                                data-toggle="tab"
                                href="#upload"
                                role="tab"
                                aria-controls="upload"
                                aria-selected={activeTab === 'upload'}
                                onClick={e => {
                                    e.preventDefault();
                                    setActiveTab('upload');
                                }}
                            >
                                Uploaded Deals
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content bd bd-gray-300 bd-t-0 pd-0" id="myTabContent">
                        <div
                            className={`tab-pane fade ${activeTab === 'import' ? 'show active' : ''}`}
                            id="import"
                            role="tabpanel"
                            aria-labelledby="import-tab"
                        >
                            <div>
                                <DealsTable data={importedDeals} />
                            </div>
                        </div>
                        <div
                            className={`tab-pane fade ${activeTab === 'upload' ? 'show active' : ''}`}
                            id="upload"
                            role="tabpanel"
                            aria-labelledby="upload-tab"
                        >
                            <div>
                                <DealsTable data={uploadedData} imported={importedDN} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
