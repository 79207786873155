import axios from 'axios';
import config from '../config';
import Session from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
let headers = {};

if (Session.getToken()) {
    headers = {
        'Authorization': `Bearer ${Session.getToken()}`,
    }
}

class DataImportsProvider {
    static async post(section, uri, data) {
        if (uri === 'upload') {
            headers['ContentType'] = 'multipart/form-data';
        }
        return await axios({
            mode: 'no-cors',
            method: 'POST',
            url: `${baseurl}imports/${section}/${uri}`,
            crossdomain: true,
            headers,
            data,
        })
            .then((json) => json.data)
            .then((res) => res)
            .catch((err) => {
                if (err.response) return err.response.data;
                return err;
            });
    }

    static async getAll(offset = config.results.offset, limit = config.results.limit, filters = null) {
        return await axios({
            mode: 'no-cors',
            method: 'GET',
            url: `${baseurl}imports?offset=${offset}&limit=${limit + (filters ? `&${filters}` : '')}`,
            crossdomain: true,
            headers,
        })
            .then((json) => json.data)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response) return err.response.data;
                return err;
            });
    }

    static async get(id) {
        return await axios({
            mode: 'no-cors',
            method: 'GET',
            url: `${baseurl}imports/${id}`,
            crossdomain: true,
            headers,
        })
            .then((json) => json.data)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response) return err.response.data;
                return err;
            });
    }

    static async settings() {
        return await axios({
            mode: 'no-cors',
            method: 'GET',
            url: `${baseurl}imports/settings`,
            crossdomain: true,
            headers,
        })
            .then((json) => json.data)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response) return err.response.data;
                return err;
            });
    }

    static async report(id, description) {
        return await axios({
            mode: 'no-cors',
            method: 'GET',
            url: `${baseurl}imports/${id}/report?description=${description}`,
            crossdomain: true,
            headers,
        })
            .then((json) => json.data)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response) return err.response.data;
                return err;
            });
    }

    static async delete(id, log_trail = true) {
        return await axios({
            mode: 'no-cors',
            method: 'DELETE',
            url: `${baseurl}imports/${id}`,
            crossdomain: true,
            headers,
            data: { log_trail },
        })
            .then((json) => json.data)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response) return err.response.data;
                return err;
            });
    }
}

export default DataImportsProvider;
