import React from 'react';
import { Link } from 'react-router-dom';
import { FeatherIcon } from 'components';
import CurrencyFormat from 'react-currency-format';
import DataTable from 'react-data-table-component';

const RowActions = props => {
    const { id, handleDelete } = props;
    return (
        <div>
            <Link
                to={`/packages/edit/${id}`}
            >
                <FeatherIcon icon="edit" width="16" height="16" />
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link
                to={`/packages/delete/${id}`}
                className="tx-danger"
                onClick={e => {
                    e.preventDefault();
                    return handleDelete(props);
                }}
            >
                <FeatherIcon icon="trash-2" width="16" height="16" />
            </Link>
        </div>
    );
};

const PackageData = ({ name, value }) => {
    let data = null;
    let unit = '';

    if (!value) data = 0;
    else if (value === -1) data = (name === 'data') ? 'Uncapped' : 'Unlimited';
    else data = (value !== 0) ? value : 0;

    if (name === 'data' && (data !== 'Uncapped')) {
        unit = 'MB';
    }

    return <span>{data + unit}</span>
};

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

export default function Table(props) {
    const {
        handleRowSelected,
        packages,
        resultsLoading,
    } = props;

    // table headings definition
    const columns = [{
        name: 'Code',
        selector: row => row['code'],
        sortable: true,
    },
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: true,
        allowOverflow: true,
        minWidth: '200px',
        cell: row => <span>{row.name}</span>
    },
    {
        name: 'Type',
        selector: row => row['package_type'],
        sortable: true,
        cell: row => <span>{(row.package_type && row.package_type.description) ? row.package_type.description : ''}</span>
    },
    {
        name: 'Network',
        sortable: true,
        cell: row => <span>{row.network_provider.name}</span>
    },
    {
        name: 'Minutes',
        selector: row => row['minutes'],
        sortable: true,
        cell: row => <PackageData name="minutes" value={row.minutes} />
    },
    {
        name: 'SMS',
        selector: row => row['sms'],
        sortable: true,
        cell: row => <PackageData name="sms" value={row.sms} />
    },
    {
        name: 'Data',
        selector: row => row['data'],
        sortable: true,
        cell: row => <PackageData name="data" value={row.data} />
    },
    {
        name: 'Airtime',
        selector: row => row['airtime'],
        sortable: true,
        cell: row => <CurrencyFormat
            fixedDecimalScale
            value={row.airtime || 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'R'}
            decimalScale={2}
        />
    },
    {
        name: 'Contract Details',
        sortable: false,
        cell: row => <span>{(row.contract_details) ? 'Y' : 'N'}</span>
    },
    {
        name: 'FLTE',
        sortable: false,
        cell: row => <span>{(row.is_flte) ? 'Y' : 'N'}</span>
    },
    {
        name: 'Status',
        selector: row => row['status'],
        sortable: true,
        cell: row => <span className={row.status.toLowerCase() === 'active' ? 'tx-teal' : 'tx-danger'}>{row.status}</span>
    },
    {
        name: 'Actions',
        sortable: false,
        width: '90px',
        cell: row => <RowActions {...row} {...props} />
    }];

    // render datatable component
    return (
        <DataTable
            data={packages}
            columns={columns}
            customStyles={customStyles}
            paginationRowsPerPageOptions={[50, 100, 250, 500]}
            onSelectedRowsChange={handleRowSelected}
            progressPending={resultsLoading}
            paginationPerPage={50}
            selectableRows={false}
            highlightOnHover
            pagination
            noHeader
        />
    );
}
