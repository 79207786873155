import Content from './Content';
import ContentSideBar from './ContentSideBar';
import Export from './Export';
import List from './UserList';
import ListTable from './ListTable';
import NavLeft from './NavLeft';
import RecentlyAdded from './RecentlyAdded';
import Roles from './Roles';
import RoleList from './RoleList';
import Search from './Search';

export default {
    Content,
    ContentSideBar,
    Export,
    List,
    ListTable,
    NavLeft,
    RecentlyAdded,
    Roles,
    RoleList,
    Search,
};
