import React, { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Navigate,
	Routes,
	Route,
} from 'react-router-dom';

import { AuthContext } from 'context/auth';
import PrivateRoute from './PrivateRoute';

// auth pages/routes
import Login from 'pages/Auth/Login';
import ResetPassword from 'pages/Auth/ResetPassword';
import ConfirmResetPassword from 'pages/Auth/ConfirmResetPassword';

// page not found
import PageNotFound from 'pages/PageNotFound';

// private routes
import Deals from 'pages/Deals';
import DealsNew from 'pages/Deals/indexNew';
import DealAdd from 'pages/Deals/Add';
import DealEdit from 'pages/Deals/Edit';

import DealsheetImport from 'pages/Dealsheets/Import';
import DealsheetUploads from 'pages/Dealsheets/Uploads';
import DealsheetUploadDetails from 'pages/Dealsheets/UploadDetails';

import Settings from 'pages/Settings';
import Users from 'pages/Users';
import UsersNew from 'pages/Users/UsersNew';
import UserEdit from 'pages/Users/EditUser';
import UserRoles from 'pages/Users/Roles';
import Devices from 'pages/Devices';
import DeviceAdd from 'pages/Devices/Add';
import DeviceAddModel from 'pages/Devices/AddModel';
import DeviceAttributes from 'pages/Devices/Attributes';
import DeviceDuplicate from 'pages/Devices/Duplicate';
import DeviceEdit from 'pages/Devices/Edit';
import DeviceEditModel from 'pages/Devices/EditModel';
import DeviceManufacturers from 'pages/Devices/Manufacturers';
import DeviceModels from 'pages/Devices/Models';
import DeviceTypes from 'pages/Devices/Types';
import Packages from 'pages/Packages';
import PackageAdd from 'pages/Packages/Add';
import PackageTypes from 'pages/Packages/Types';
import PackageEdit from 'pages/Packages/Edit';

const App = () => {
	const [authTokens, setAuthTokens] = useState();

	const setTokens = (data) => {
		localStorage.setItem('tokens', JSON.stringify(data));
		setAuthTokens(data);
	}

	useEffect(() => {
		
	}, []);

	return (
		<AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
			<Router basename={process.env.PUBLIC_URL}>
				<Routes>
					{/* start: public routes */}

					<Route path="/" element={<Navigate replace to="/auth/login" />} />
					<Route path="auth/login" element={<Login />} />
					<Route path="auth/password/reset" element={<ResetPassword />} />
					<Route path="auth/password/reset/confirm/:token" element={<ConfirmResetPassword />} />

					{/* end: public routes */}

					{/* start: private routes */}

					<Route path="/deals">
						<Route index={true} element={<PrivateRoute component={Deals} />} />
						<Route index={false} path="new" element={<PrivateRoute component={DealsNew} />} />
						<Route index={false} path="add" element={<PrivateRoute component={DealAdd} />} />
						<Route index={false} path="edit/:dealsheet_number" element={<PrivateRoute component={DealEdit} />} />
					</Route>

					<Route path="/dealsheets">
						{/* <Route index={true} element={<PrivateRoute component={Dealsheets} />} /> */}
						<Route index={false} path="uploads" element={<PrivateRoute component={DealsheetUploads} />} />
						<Route index={false} path="import" element={<PrivateRoute component={DealsheetImport} />} />
						<Route index={false} path="uploads/:id" element={<PrivateRoute component={DealsheetUploadDetails} />} />
					</Route>

					<Route path="/devices">
						<Route index={true} element={<PrivateRoute component={Devices} />} />
						<Route index={false} path="add" element={<PrivateRoute component={DeviceAdd} />} />
						<Route index={false} path="attributes" element={<PrivateRoute component={DeviceAttributes} />} />
						<Route index={false} path="manufacturers" element={<PrivateRoute component={DeviceManufacturers} />} />
						<Route index={false} path="types" element={<PrivateRoute component={DeviceTypes} />} />
						<Route index={false} path="models" element={<PrivateRoute component={DeviceModels} />} />
						<Route index={false} path="models/add" element={<PrivateRoute component={DeviceAddModel} />} />
						<Route index={false} path="models/edit/:id" element={<PrivateRoute component={DeviceEditModel} />} />
						<Route index={false} path="duplicate/:id" element={<PrivateRoute component={DeviceDuplicate} />} />
						<Route index={false} path="edit/:id" element={<PrivateRoute component={DeviceEdit} />} />
					</Route>

					<Route path="/settings">
						<Route index={true} element={<PrivateRoute component={Settings} />} />
						<Route index={false} path="configurations" element={<PrivateRoute component={Settings} />} />
					</Route>

					<Route path="/users">
						<Route index={true} element={<PrivateRoute component={Users} />} />
						<Route index={false} path="new" element={<PrivateRoute component={UsersNew} />} />
						<Route index={false} path="roles" element={<PrivateRoute component={UserRoles} />} />
						<Route index={false} path="edit/:id" element={<PrivateRoute component={UserEdit} />} />
					</Route>

					<Route path="/packages">
						<Route index={true} element={<PrivateRoute component={Packages} />} />
						<Route index={false} path="add" element={<PrivateRoute component={PackageAdd} />} />
						<Route index={false} path="types" element={<PrivateRoute component={PackageTypes} />} />
						<Route index={false} path="edit/:id" element={<PrivateRoute component={PackageEdit} />} />
					</Route>

					{/* end: private routes */}

					{/* start: default fall back route (404) */}

					<Route path="*" element={<PageNotFound />} />
					
					{/* end: default fall back route (404) */}
				</Routes>
			</Router>
		</AuthContext.Provider>
	)
}

export default App;
