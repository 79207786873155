import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import UsersGQL from 'queries/users';
import ListCard from './ListCard';

export default function RecentlyAdded(props) {
    const { data, loading } = useQuery(UsersGQL.listUsers);
    const [active, setActive] = useState();
    return (
        <div className="pd-y-20 pd-x-10 contact-list">
            <label className="contact-list-divider">
                Recently Added
            </label>
            {loading && <div className="loading" />}
            {data && data.users && data && data.users.slice(0, 5).map((user) => (
                <ListCard
                    key={user.id}
                    active={active}
                    setActive={setActive}
                    {...props}
                    {...user}
                />
            ))}
        </div>
    );
}
