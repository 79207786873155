import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Layout } from 'containers';
import { Breadcrumb, FeatherIcon, Modals, Packages } from 'components';
import { PackagesProvider } from 'providers';

export default function PackageTypes() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [types, setTypes] = useState([]);
  const breadcrumb = [
    { title: 'Packages', link: '/packages', active: false },
    { title: 'Types', active: true },
  ];

  const actionButtons = [
    {
      type: 'button',
      title: 'Add package type',
      classes: 'btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5',
      icon: <FeatherIcon icon="plus" classes="wd-10 mg-r-5" />,
      action: () => setShowAddModal(true),
    },
  ];

  async function fetchData() {
    if (!pageLoading) setPageLoading(true);
    const types = await PackagesProvider.getTypes('all=true');
    setTypes(types.results || []);
    setPageLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout pageLoading={pageLoading}>
      <Modals.AddPackageType
        show={showAddModal}
        fetchData={fetchData}
        setShow={setShowAddModal}
      />
      <div className="content content-fixed pd-b-0">
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <Breadcrumb
            heading="Package Types"
            items={breadcrumb}
            actions={actionButtons}
          />
        </Container>
      </div>
      <Packages.Types
        data={types}
        fetchData={fetchData}
        setPageLoading={setPageLoading}
      />
    </Layout>
  );
}
