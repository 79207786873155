import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { BulkActionsProvider } from 'providers';
import { DeviceAutoCompleteInput, ResultsModal } from 'components';

const UpdateDevices = props => {
    const { selectedRows } = props;
    const [ device, setDevice ] = useState('');
    const [ submitting, setSubmitting ] = useState(false);
    const [ showResultsModal, setShowResultsModal ] = useState(false);

    async function handleBulkUpdate() {
        if (device.length > 0) {
            setSubmitting(true);
            const updateData = [];
            selectedRows.map(item => updateData.push({
                item_id: item.id,
                device_id: device,
                dealsheet_no: item.dealsheet_number,
            }));
            const result = await BulkActionsProvider.update('dealsheet', { data: updateData });
            setSubmitting(false);
            if (result.status === 'success') {
                setShowResultsModal(true);
            } else {
                alert(result.message);
            }
        }
    }

    return (
        <div className="form-group">
            <ResultsModal
                title="Deals Updated"
                body={<p><strong>{selectedRows.length}</strong> deal(s) have been linked to selected device.</p>}
                show={showResultsModal}
                setShow={setShowResultsModal}
                buttonText="Back to Manage Deals"
            />
            <Card>
                <CardBody>
                    <label>Bulk Update Devices</label>
                    <Row>
                        <Col xs={8}>
                            <DeviceAutoCompleteInput setDevice={setDevice} />
                        </Col>
                        <Col xs={4}>
                            <button
                                type="button"
                                className="btn btn-primary btn-block"
                                disabled={selectedRows.length === 0 || device.length === 0}
                                onClick={() => handleBulkUpdate()}
                            >
                                {submitting ? 'Processing...' : 'Update'}
                            </button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

export default UpdateDevices;
