import React from 'react';
import moment from 'moment';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';

const Footer = () => {
	return (
		<footer className="footer">
			<ReactNotifications  />
			<div>
				<span>Copyright &copy; {moment().format('YYYY')} Rewardsco. All rights reserved. </span>
			</div>
		</footer>
	)
};

export default Footer;
