import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { Breadcrumb, Dealsheets, FeatherIcon } from 'components';
import { DataImportsProvider } from 'providers';
import { Layout } from 'containers';
import Swal from 'sweetalert2';

const actionButtons = [
    {
		type: 'button-link',
		link: '/dealsheets/import',
        title: 'New Import',
        classes: 'btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5',
        icon: <FeatherIcon icon="plus" classes="wd-10 mg-r-5" />,
    }
];

export default function DealsheetUploads(props) {
    const breadcrumb = [{ title: 'Dealsheet Uploads', active: true }];
    const [resultsLoading, setResultsLoading] = useState(false);
    const [loaderText, setLoaderText] = useState('Loading');
    const [pageLoading, setPageLoading] = useState(true);
    const [filteredList, setFilteredList] = useState([]);
    const [uploads, setUploads] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const uploads = await DataImportsProvider.getAll();
            if (uploads.count && uploads.count > 0) {
                setFilteredList(uploads.results);
                setUploads(uploads.results);
            }
            setPageLoading(false);
            setResultsLoading(false);
        };
        fetchData();
    }, []);

    async function handleDelete({ id }) {
        return confirmAlert({
            title: 'Confirm Delete!',
            message: `Are you sure to delete import report? This process cannot be undone.`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setPageLoading(true);
                        setLoaderText('Deleting report');
                        const result = await DataImportsProvider.delete(id);
						const { status, message } = result;

                        setPageLoading(false);
                        setLoaderText('Loading');

                        if (status === 'success') {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Report deleted successfully!',
                                showConfirmButton: false,
                                timer: 5000
                            });
                            return setTimeout(async function () {
								window.location = '/dealsheets/uploads';
                            }, 5000);
                        } else {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: message || 'Failed to process request, please try again!',
                                showConfirmButton: false,
                                timer: 5000
                            });
                        }
                        return true;
                    },
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    return (
        <Layout pageLoading={pageLoading} loaderText={loaderText}>
            <div className="content content-fixed pd-b-0">
                <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <Breadcrumb
                        heading="Dealsheet Uploads"
                        items={breadcrumb}
                        actions={actionButtons}
                    />
                </Container>
            </div>
            <Dealsheets.UploadsFilter
                data={uploads}
                filtered={filteredList}
                setFiltered={setFilteredList}
                setResultsLoading={setResultsLoading}
            />
            <Dealsheets.Uploads
                {...props}
                data={filteredList}
                handleDelete={handleDelete}
                resultsLoading={resultsLoading}
            />
        </Layout>
    );
}