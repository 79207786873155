import React, { useState } from 'react';
import Select from 'react-select';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { BulkActionsProvider } from 'providers';
import { ResultsModal } from 'components';

const UpdateSaleIndicators = props => {
    const {
        indicators,
        allSelected,
        selectedRows,
        filterOptions,
    } = props;
    const [ indicator, setIndicator ] = useState('');
    const [ online, setOnline ] = useState(false);
    const [ submitting, setSubmitting ] = useState(false);
    const [ showResultsModal, setShowResultsModal ] = useState(false);
    const [ resultBody, setResultBody ] = useState('');

    async function handleBulkUpdate() {
        if (allSelected) {
            const filters = [];
            setSubmitting(true);

            if (filterOptions.length > 0) {
                filterOptions.map(item => {
                    if (item.field_name.length > 0 && item.field_operator.length > 0) {
                        if (item.field_operator === 'nx') {
                            return filters.push([ item.field_name, 'eq', null ]);
                        }
                        if (item.field_operator === 'ex') {
                            return filters.push([ item.field_name, 'ne', null ]);
                        }
                        return filters.push([ item.field_name, item.field_operator, item.field_value ]);
                    }
                    return item;
                });
            }
            // api call to update database
            var post = { data: { sale_indicator_id: indicator }, all_deals: true, filters };
            if (online) post.data.online = online;
            const result = await BulkActionsProvider.update('dealsheet', post);
            setSubmitting(false);
            if (result.status === 'success') {
                setResultBody(<p>Selected deals have been updated with the selected sale indicator.</p>);
                setShowResultsModal(true);
            } else {
                alert(result.message);
            }
        } else {
            setSubmitting(true);
            const updateData = [];
            selectedRows.map(item => {
                var obj = {
                    item_id: item.id,
                    sale_indicator_id: indicator,
                    dealsheet_no: item.dealsheet_number,
                };
                if (online) obj.online = online;
                return updateData.push(obj);
            });
            // api call to update database
            const result = await BulkActionsProvider.update('dealsheet', { data: updateData, all_deals: false });
            setSubmitting(false);
            if (result.status === 'success') {
                setResultBody(<p><strong>{selectedRows.length}</strong> deal(s) have been updated with the selected sale indicator.</p>);
                setShowResultsModal(true);
            } else {
                alert(result.message);
            }
        }
    };

    return (
        <div className="form-group">
            <ResultsModal
                title="Deals Updated"
                body={resultBody}
                show={showResultsModal}
                setShow={setShowResultsModal}
                buttonText="Back to Manage Deals"
            />
            <Card>
                <CardBody>
                    <label>Bulk Update Sales Indicators</label>
                    <Row>
                        <Col xs={8}>
                            <Select
                                isLoading={indicators.length === 0}
                                options={indicators}
                                onChange={item => {
                                    setOnline(item.label.toLowerCase() === 'digital only sell');
                                    setIndicator(item.value);
                                }}
                            />
                        </Col>
                        <Col xs={4}>
                            <button
                                type="button"
                                className="btn btn-primary btn-block"
                                disabled={selectedRows.length === 0 || indicator.length === 0}
                                onClick={() => handleBulkUpdate()}
                            >
                                {submitting ? 'Processing...' : 'Update'}
                            </button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

export default UpdateSaleIndicators;
