import React from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import queryString from 'query-string';
import { FeatherIcon } from 'components';
import { DevicesProvider } from 'providers';
import { Link } from 'react-router-dom';
import config from '../../config';

const RowActions = props => {
    const { id, handleDeleteDevice } = props;
    return (
        <div>
            <Link to={`/devices/duplicate/${id}`}>
                <FeatherIcon icon="copy" width="16" height="16" />
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to={`/devices/edit/${id}`}>
                <FeatherIcon icon="edit" width="16" height="16" />
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
                href={`/devices/delete/${id}`}
                className="tx-danger"
                onClick={e => {
                    e.preventDefault();
                    return handleDeleteDevice(id);
                }}
            >
                <FeatherIcon icon="trash-2" width="16" height="16" />
            </a>
        </div>
    );
};

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const Table = props => {
    const {
        handleRowSelected,
        devices,
        setDevices,
        resultsLoading,
        setResultsLoading,
        page,
        setPage,
        perPage,
        setPerPage,
        totalRecords,
        setTotalRecords,
        sorting,
        setSorting,
        filterOptions,
    } = props;

    // table headings definition
    const columns = [{
        name: 'Title',
        selector: row => row['title'],
        sortable: true,
        wrap: true,
    },
    // {
    //     name: 'Manufacturer',
    //     selector: row => row['manufacturer'],
    //     sortable: true,
    //     cell: row => <span>{row.manufacturer.name}</span>
    // },
    // {
    //     name: 'Model',
    //     selector: row => row['model'],
    //     sortable: true,
    //     cell: row => <span>{row.model}</span>
    // },
    {
        name: 'Device type',
        selector: row => row['device_type'],
        sortable: true,
        cell: row => <span>{(row.device_type && row.device_type.name) || null}</span>
    },
    {
        name: 'Image',
        selector: row => row['image_default'],
        sortable: true,
        cell: row => <span>{(row.image_default) ? 'Y' : 'N'}</span>
    },
    {
        name: 'Status',
        selector: row => row['status'],
        sortable: true,
        cell: row => <span className={row.status.toLowerCase() === 'active' ? 'tx-teal' : 'tx-danger'}>{row.status}</span>
    },
    {
        name: 'Created',
        selector: row => row['created'],
        sortable: true,
        cell: row => <span>{moment(row.created).format('DD MMM YYYY')}</span>
    },
    {
        name: 'Updated',
        selector: row => row['updated'],
        sortable: true,
        cell: row => <span>{moment(row.created).format('DD MMM YYYY')}</span>
    },
    {
        name: 'Actions',
        sortable: false,
        cell: row => <RowActions {...row} {...props} />
    }];

    const fetchData = async (perPage, page, sorting) => {
        setResultsLoading(true);
        const offset = (page - 1) * perPage;
        const limit = perPage;
        const orderby = `&orderby=[["${sorting.column}", "${sorting.direction}"]]`;
        let devices = [];

        if (Object.keys(filterOptions).length > 0) {
            console.log(filterOptions);
            const querystr = queryString.stringify(filterOptions);
            devices = await DevicesProvider.getAll(offset, limit, `&${querystr}` + orderby);
        } else {
            devices = await DevicesProvider.getAll(offset, limit, orderby);
        }
        setDevices(devices.results);
        setTotalRecords(devices.count);
        setResultsLoading(false);
    };

    const handlePerRowsChange = async (perPage, page) => {
        fetchData(perPage, page, sorting);
        setPerPage(perPage);
    };

    const handlePageChange = async (page) => {
        fetchData(perPage, page, sorting);
        setPage(page);
    };

    const handleSort = (column, sortDirection) => {
        const sortingOrder = {
            column: column.selector,
            direction: sortDirection,
        };
        setSorting(sortingOrder);
        fetchData(perPage, page, sortingOrder);
    };

    // render datatable component
    return (
        <DataTable
            data={devices}
            columns={columns}
            customStyles={customStyles}
            onSelectedRowsChange={handleRowSelected}
            selectableRows={false}
            highlightOnHover
            pagination
            noHeader
            progressPending={resultsLoading}
            paginationServer
            paginationTotalRows={totalRecords}
            paginationRowsPerPageOptions={config.results.pagination.perpageOptions}
            paginationPerPage={perPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSort={handleSort}
        />
    );
};

export default Table;
