import React, { useCallback } from 'react';
import { Card } from 'reactstrap';
import ModelListTable from './ModelListTable';

const ModelList = props => {
    const { setSelectedRows } = props;

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, [ setSelectedRows ]);

    return (
        <div className="dealsheet-result-table-wrapper">
            <Card>
                <ModelListTable
                    handleRowSelected={handleRowSelected}
                    {...props}
                />
            </Card>
        </div>
    );
};

export default ModelList;
