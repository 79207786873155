import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Col, Row } from 'reactstrap';
import { AuthProvider, SessionProvider } from 'providers';
import { Footer } from 'containers';

const ResetPassword = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [submitting, setSubmitting] = useState(false);
    const [feedback, setFeedback] = useState({});

    useEffect(() => {
        const clearSession = async () => await SessionProvider.destroy();
        clearSession();
    }, []);

    const onSubmit = data => {
        handleResetPassword(data);
    };

    async function handleResetPassword(data) {
        setSubmitting(true);
        const result = await AuthProvider.resetPassword(data.email);
        if (result.status === 'success') {
            setFeedback({
                class: 'tx-success',
                message: 'A password reset link has been sent to your email, please look out for it and follow the instructions.'
            });
        } else {
            // error has occured
            setFeedback({
                class: 'tx-danger',
                message: result.response.data.message || result.message
            });
        }
        reset();
        setSubmitting(false);
    };

    return (
        <div className="auth-page-wrapper">
            <div className="content content-fixed content-auth-alt">
                <div className="content content-auth">
                    <Container>
                        <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
                            <div className="sign-wrapper">
                                <div className="wd-100p">
                                    <form method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                                        <div className="logo-wrapper mg-b-40">
                                            <img src={require('images/logo.svg')} alt="DAMS" />
                                            <div className="mg-t-5">
                                                <span className="tx-color-03">Deal Automation Management System</span>
                                            </div>
                                        </div>

                                        <h3 className="mg-b-5">Reset Password</h3>
                                        <p className="mg-b-20">
                                            Please enter your email address below and we will send you a
                                            link to reset your password
                                        </p>
                                        <div className="form-group">
                                            <div className="input-group-lg">
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.email ? 'parsley-error' : ''}`}
                                                    placeholder="Email Address"
                                                    disabled={submitting}
                                                    onKeyUp={() => setFeedback({})}
                                                    {...register('email', {
                                                        required: true,
                                                        pattern: /\S+@\S+\.\S+/
                                                    })}
                                                />
                                            </div>
                                            {errors.email &&
                                                <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                    <li className="parsley-required">This input should be a valid email address.</li>
                                                </ul>}
                                            {feedback.message &&
                                                <Row className="feedback mg-t-5">
                                                    <Col xs={12} className={feedback.class}>
                                                        {feedback.message}
                                                    </Col>
                                                </Row>}
                                        </div>
                                        <Row>
                                            <Col xs={6}>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-block btn-lg btn-uppercase"
                                                    disabled={submitting}
                                                >
                                                    {submitting ? 'Processing...' : 'Reset Password'}
                                                </button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ResetPassword;
