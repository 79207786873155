import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { DevicesProvider } from 'providers';
import { FeatherIcon, Form, Loading } from 'components';

const ModelItem = props => {
    const { manufacturer, name, handleSelect } = props;
    return (
        <a
            role="button"
            href="/"
            className="option-link"
            onClick={e => {
                e.preventDefault();
                return handleSelect(props);
            }}
        >
            {manufacturer.name} {name}
        </a>
    );
};

export default function AddDeviceVariant(props) {
    const { isModal, showModal, saveCallback, deviceModel } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loadingText, setLoadingText] = useState('Loading');
    const [processing, setProcessing] = useState(false);
    const [model, setModel] = useState('');
    const [title, setTitle] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [isValidProductCode, setIsValidProductCode] = useState(true);

    useEffect(() => {
        if (deviceModel) {
            console.log(deviceModel);
            // handleSelect(deviceModel);
        }
        setProcessing(false);
    }, []);

    const onSubmit = data => {
        handleSaveDevice(data);
    };

    const generatePermakey = title => {
        let permakey = '';
        if (title.indexOf('(') !== -1) {
            permakey = title
                .split('(')
                .join('')
                .split(')')
                .join('')
                .replace(' + ', ' and ')
                .split('_')
                .join('-')
                .split(' ')
                .join('-')
                .toLowerCase();
            return permakey;
        }
        permakey = title
            .replace(' + ', ' and ')
            .split('_')
            .join('-')
            .split(' ')
            .join('-')
            .toLowerCase();
        return permakey;
    };

    const handleSaveDevice = async (data) => {
        setLoadingText('Saving');
        setProcessing(true);
        const { color, product_code, storage } = data;
        const {
            id,
            name,
            image,
            features,
            device_type_id,
            manufacturer_id,
            manufacturer_name,
        } = selectedModel;
        const post = {
            title,
            product_code,
            device_type_id,
            permakey: generatePermakey(title),
            features: features || {},
            manufacturer_id: manufacturer_id,
            manufacturer_name: manufacturer_name,
            images: image ? [image] : null,
            image_default: image || null,
            model_id: id,
            model: name,
            variants: null,
        };
        if ((color && color.length > 3) || (storage && storage.length > 2)) {
            post.variants = {
                color,
                storage,
            };
        }
        const result = await DevicesProvider.create(post);
        setProcessing(false);
        if (result.status === 'success') {
            alert('Device successfully created!');
            if (saveCallback && typeof saveCallback === 'function') {
                saveCallback();
            } else {
                window.location = '/devices';
            }
        } else {
            alert(`Error: ${result.message}`);
        }
        setLoadingText(null);
        return true;
    };

    const handleValidateProductCode = async (val) => {
        const data = await DevicesProvider.getAll(0, 1, `&product_code=${val}`);
        if (data) {
            if (data.results) {
                setIsValidProductCode(data.results.length === 0);
            }
        }
        return true;
    };

    const handleSelect = item => {
        setModel(`${item.manufacturer_name} ${item.name}`);
        setSelectedModel(item);
        setTitle(`${item.manufacturer_name} ${item.name}`);
        return setSearchResults([]);
    };

    const handleSearchModel = async e => {
        setSearchResults([]);
        setModel(e.target.value);
        if (e.target.value.length > 2) {
            const data = await DevicesProvider.searchModels('model_name', e.target.value, 0, 50);
            const { results } = data;
            const object = [];
            results.map(item => object.push(<ModelItem {...item} handleSelect={handleSelect} />));
            setSearchResults(object);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            {processing && <Loading text={loadingText} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex align-items-center justify-content-between mg-b-25">
                    <h4 className="mg-b-0 text-navy font-weight-600">{props.title || ''}</h4>
                    <div className="d-flex">
                        <button
                            className="btn btn-sm btn-outline-primary d-flex align-items-center mg-r-5"
                            type="submit"
                        >
                            <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                            <span className="d-none d-sm-inline mg-l-5"> Save</span>
                        </button>
                        <button
                            className="btn btn-sm btn-white d-flex align-items-center"
                            onClick={e => {
                                e.preventDefault();
                                if (isModal && typeof showModal === 'function') {
                                    showModal(false);
                                    return false;
                                }
                                return window.location = '/devices';
                            }}
                        >
                            <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                            <span className="d-none d-sm-inline mg-l-5"> Cancel</span>
                        </button>
                    </div>
                </div>
                <div data-label="Device Information" className="legend">
                    <Row>
                        <Col xs={12} sm={4}>
                            <div className={`form-group ${!model ? 'is-invalid' : ''}`}>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Base Model
                                </label>
                                <Form.AutocompleteInput
                                    value={model}
                                    setValue={setModel}
                                    placeholder="Find and Select Device Base Model..."
                                    classes="form-group"
                                    handleValueChange={handleSearchModel}
                                    setProcessing={setProcessing}
                                    searchResults={searchResults}
                                    secondaryButton={
                                        <Form.Button
                                            text=""
                                            buttonLink
                                            buttonLinkHref="/devices/models/add"
                                            classes="btn btn-outline-light"
                                            icon={<FeatherIcon icon="plus" />}
                                        />
                                    }
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <div className={`form-group ${errors.title ? 'is-invalid' : ''}`}>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Title
                                </label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.title ? 'parsley-error' : ''}`}
                                    value={title || ''}
                                    {...register('title', { required: true })}
                                    onChange={e => setTitle(e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <div className={`form-group ${(errors.product_code || !isValidProductCode) ? 'is-invalid' : ''}`}>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Product code
                                </label>
                                <input
                                    type="text"
                                    className={`form-control ${(errors.product_code || !isValidProductCode) ? 'parsley-error' : ''}`}
                                    onBlur={e => handleValidateProductCode(e.target.value)}
                                    {...register('product_code', { required: true })}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div data-label="Variations" className="legend mg-t-20">
                    <div className="form-group">
                        <div className="input-group mg-b-10">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    Colour
                                </span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter colour name. e.g. Phantom Silver"
                                {...register('color', { required: false })}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group mg-b-10">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    Storage
                                </span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter storage capacity. e.g. 64GB"
                                {...register('storage', { required: false })}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
