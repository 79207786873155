import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FeatherIcon } from 'components';
import { SessionProvider } from 'providers';

const user = SessionProvider.get();
const menu = SessionProvider.getMenu();

const Header = () => {
	const { pathname } = window.location;
	const [ showNavItem, setShowNavItem ] = useState('');
	const [ showDropdownProfile, setShowDropdownProfile ] = useState(false);
	const [ activeNavItem, setActiveNatItem ] = useState('');

	useEffect(() => {
		const uri = pathname.split('/');
		if (uri.length > 1) {
			setActiveNatItem(uri[1] === 'deals' ? 'dealsheets' : uri[1]);
		} else {
			setActiveNatItem('dealsheets');
		}
	}, [ ]);

	const toggleDropdownProfile = (e) => {
		e.preventDefault();
		setShowDropdownProfile(!showDropdownProfile);
	};

	const toggleShowNavItem = item => {
		setShowNavItem(showNavItem === item ? '' : item);
	};

	return (
		<header className="navbar navbar-header navbar-header-fixed">
			<Link to="/" id="mainMenuOpen" className="burger-menu">
				<i data-feather="menu"></i>
			</Link>
			<div className="navbar-brand">
				<Link to="/deals" className="df-logo">
					dealsheet<span>automation</span>
				</Link>
			</div>
			<div id="navBarMenu" className="navbar-menu-wrapper">
				<div className="navbar-menu-header">
					<a href="/" className="df-logo">dealsheet<span>automation</span></a>
					<a id="mainMenuClose" href="/" onClick={(e) => e.preventDefault()}>
						<FeatherIcon icon="x" />
					</a>
				</div>
				{menu &&
				<ul className="nav navbar-menu">
					<li className="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>
					{menu.map(item => (
					<li
						key={item.id}
						className={`nav-item ${item.childitems.length > 0 ? 'with-sub' : ''} ${activeNavItem === item.id ? 'active' : ''} ${showNavItem === item.id ? 'show' : ''}`}
					>
						<a
							href={item.link}
							className="nav-link"
							onClick={(e) => {
								if (item.childitems.length > 0) {
									e.preventDefault();
									toggleShowNavItem(item.id);
								}
							}}
						>
							{item.title}
						</a>
						{(item.childitems.length > 0) &&
						<ul className="navbar-menu-sub">
							{item.childitems.map(subitem => (
							<li
								key={subitem.id}
								className="nav-sub-item"
							>
								<Link
									to={subitem.link}
									className="nav-sub-link"
								>
									{subitem.title}
								</Link>
							</li>))}
						</ul>}
					</li>))}
				</ul>}
			</div>
			<div className="navbar-right">
				<div className="dropdown dropdown-profile">
					<a
						href="/"
						role="button"
						className="dropdown-link"
						data-toggle="dropdown"
						data-display="static"
						onClick={toggleDropdownProfile}
					>
						Logged in as&nbsp;
						<span>{user.first_name}</span>
					</a>
					<div className={`dropdown-menu dropdown-menu-right tx-13 ${showDropdownProfile ? 'show' : ''}`}>
						<h6 className="tx-semibold mg-b-5">{user.first_name} {user.last_name}</h6>
						<p className="mg-b-25 tx-12 tx-color-03">{user.role.description}</p>
						<div className="dropdown-divider"></div>
						<Link to="/auth/login" className="dropdown-item">
							<FeatherIcon icon="power" />
							Logout
						</Link>
					</div>
				</div>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Link to="/auth/login" className="btn btn-buy">
					<FeatherIcon icon="power" />
					<span>Logout</span>
				</Link>
			</div>
		</header>
	);
};

export default Header;
