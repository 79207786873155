import React, { useState } from 'react';
import {
    Col,
    Card,
    CardBody,
    CardHeader,
    Nav,
    NavLink,
    TabContent,
    TabPane,
    Row,
} from 'reactstrap';
import { FeatherIcon } from 'components';
import moment from 'moment';

export default function ContentRole(props) {
    const {
        id,
        name,
        label,
        users,
        created,
        updated,
        archived,
        is_public,
        is_default,
        description,
        handleUpdateRole,
    } = props;
    const [activeTab, setActiveTab] = useState('information');

    return (
        <>
            <div className="contact-content-header">
                <Nav className="nav">
                    <NavLink
                        className={activeTab === 'information' ? 'active' : ''}
                        onClick={() => setActiveTab('information')}
                    >
                        Information
                    </NavLink>
                    <NavLink
                        className={activeTab === 'permissions' ? 'active' : ''}
                        onClick={() => setActiveTab('permissions')}
                    >
                        Permissions
                    </NavLink>
                    <NavLink
                        className={activeTab === 'users' ? 'active' : ''}
                        onClick={() => setActiveTab('users')}
                    >
                        Assigned Users
                    </NavLink>
                </Nav>
                <a href="" id="contactOptions" className="text-secondary mg-l-auto d-xl-none">
                    <FeatherIcon icon="more-horizontal" classes="wd-10" />
                </a>
            </div>
            <div className="contact-content-body ps">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="information" className="pd-20 pd-xl-25">
                        <div className="d-flex align-items-center justify-content-between mg-b-25">
                            <h6 className="mg-b-0">Role: {description}</h6>
                            <div className="d-flex">
                                <a href=" " className="btn btn-sm btn-white tx-primary bd-primary d-flex align-items-center mg-r-5">
                                    <FeatherIcon icon="edit-2" classes="wd-10" />
                                    <span className="d-none d-sm-inline mg-l-5"> Edit</span>
                                </a>
                                <a href=" " className="btn btn-sm btn-white tx-danger bd-danger d-flex align-items-center">
                                    <FeatherIcon icon="trash" classes="wd-10" />
                                    <span className="d-none d-sm-inline mg-l-5"> Delete</span>
                                </a>
                            </div>
                        </div>
                        <Row>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Name
                                </label>
                                <p className="mg-b-0">{name}</p>
                            </Col>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Label
                                </label>
                                <p className="mg-b-0">{label}</p>
                            </Col>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Description
                                </label>
                                <p className="mg-b-0">{description}</p>
                            </Col>
                        </Row>
                        <h6 className="mg-b-20">&nbsp;</h6>
                        <Row>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Created
                                </label>
                                <p className="mg-b-0">{moment(created).format('DD MMMM YYYY')}</p>
                            </Col>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Last Modified
                                </label>
                                <p className="mg-b-0">{updated ? moment(updated).format('DD MMMM YYYY') : '-'}</p>
                            </Col>
                            <Col xs={6} sm />
                        </Row>
                        <h6 className="mg-t-40 mg-b-20">Other Details</h6>
                        <Row>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Is Default?
                                </label>
                                <div className="custom-control custom-switch mr-4">
                                    <input
                                        id="is_default"
                                        type="checkbox"
                                        className="custom-control-input"
                                        checked={is_default}
                                        onChange={(e) => handleUpdateRole(id, {
                                            is_default: e.target.checked,
                                        })}
                                    />
                                    <label className="custom-control-label" htmlFor="archived">
                                        {is_default ? 'Yes' : 'No'}
                                    </label>
                                </div>
                            </Col>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Is Public?
                                </label>
                                <div className="custom-control custom-switch mr-4">
                                    <input
                                        id="is_public"
                                        type="checkbox"
                                        className="custom-control-input"
                                        checked={is_public}
                                        onChange={(e) => handleUpdateRole(id, {
                                            is_public: e.target.checked,
                                        })}
                                    />
                                    <label className="custom-control-label" htmlFor="archived">
                                        {is_public ? 'Yes' : 'No'}
                                    </label>
                                </div>
                            </Col>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Archived/Status
                                </label>
                                <div className="custom-control custom-switch mr-4">
                                    <input
                                        id="archived"
                                        type="checkbox"
                                        className="custom-control-input"
                                        checked={!archived}
                                        onChange={(e) => handleUpdateRole(id, {
                                            archived: !e.target.checked,
                                        })}
                                    />
                                    <label className="custom-control-label" htmlFor="archived">
                                        {archived ? 'Archived' : 'Active'}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="permissions" className="pd-20 pd-xl-25">
                        <div className="d-flex align-items-center justify-content-between mg-b-25">
                            <h6 className="mg-b-0">Permissions</h6>
                            <div className="d-flex">
                                <a href=" " className="btn btn-sm btn-white tx-primary bd-primary d-flex align-items-center mg-r-5">
                                    <FeatherIcon icon="edit-2" classes="wd-10" />
                                    <span className="d-none d-sm-inline mg-l-5"> Edit</span>
                                </a>
                                <a href=" " className="btn btn-sm btn-white tx-danger bd-danger d-flex align-items-center">
                                    <FeatherIcon icon="trash" classes="wd-10" />
                                    <span className="d-none d-sm-inline mg-l-5"> Delete</span>
                                </a>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId="users" className="pd-20 pd-xl-25">
                        <h4 className="mg-b-15 mg-lg-b-25">
                            Users assigned to <span className="tx-primary">{label}</span>
                        </h4>
                        <Card>
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Assign User</h6>
                                <div className="d-flex align-items-center tx-18">
                                    <a href=" " className="link-03 lh-0"><i className="icon ion-md-refresh" /></a>
                                    <a href=" " className="link-03 lh-0 mg-l-10"><i className="icon ion-md-more" /></a>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <FeatherIcon icon="search" classes="wd-10" />
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type 4 or more charaters to search"
                                    />
                                    <div className="input-group-append">
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                        >
                                            Assign
                                        </button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="mg-t-20">
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">List of assigned users</h6>
                                <div className="d-flex align-items-center tx-18">
                                    <a href=" " className="link-03 lh-0"><i className="icon ion-md-refresh" /></a>
                                    <a href=" " className="link-03 lh-0 mg-l-10"><i className="icon ion-md-more" /></a>
                                </div>
                            </CardHeader>
                            <ul className="list-group list-group-flush tx-13">
                                {(users && users.length > 0) ? (
                                    <>
                                        {users.map(user => (
                                            <li key={user.id} className="list-group-item d-flex pd-sm-x-20">
                                                <div className="avatar">
                                                    <span className="avatar-initial rounded-circle bg-gray-600">
                                                        {user.first_name.toUpperCase()[0]}
                                                    </span>
                                                </div>
                                                <div className="pd-l-10">
                                                    <p className="tx-medium mg-b-0">{user.first_name} {user.last_name}</p>
                                                    <small className="tx-12 tx-color-03 mg-b-0">{user.email}</small>
                                                </div>
                                                <div className="mg-l-auto d-flex align-self-center">
                                                    <nav className="nav nav-icon-only">
                                                        <a href="" className="nav-link d-none d-sm-block">
                                                            <FeatherIcon icon="upload" classes="wd-10" />
                                                        </a>
                                                        <a href="" className="nav-link d-none d-sm-block">
                                                            <FeatherIcon icon="slash" classes="wd-10" />
                                                        </a>
                                                    </nav>
                                                </div>
                                            </li>
                                        ))}
                                    </>
                                ) : (
                                    <CardBody className="text-center">
                                        <span className="tx-12 tx-muted">
                                            No assigned users. Use form above to add/assign users.
                                        </span>
                                    </CardBody>
                                )}
                            </ul>
                        </Card>
                    </TabPane>
                </TabContent>
            </div>
        </>
    );
}
