import React from 'react';
import { FeatherIcon } from 'components';
import ListCard from './ListCard';

export default function ContentSideBar(props) {
    const {
        type,
        heading,
        selected,
        subheading,
    } = props;
    return (
        <>
            <div className="clearfix mg-b-25">
                <div id="contactAvatar" className="pos-relative float-left">
                    <div className="avatar avatar-xl">
                        <span className="avatar-initial rounded-circle bg-gray-700 tx-normal">
                            {heading[0]}
                        </span>
                    </div>
                    {type === 'user' && (
                        <a href=" " className="contact-edit-photo">
                            <FeatherIcon icon="edit-2" classes="wd-10" />
                        </a>
                    )}
                </div>
            </div>
            <h5 className="tx-18 tx-xl-20 mg-b-5">{heading}</h5>
            <p className="tx-13 tx-lg-12 tx-xl-13 tx-color-03 mg-b-20">
                {subheading}
            </p>
            <nav className="contact-call-nav mg-b-20">
                <a href=" " className="nav-call">
                    <FeatherIcon icon="phone" classes="wd-10" />
                </a>
                <a href=" " className="nav-video">
                    <FeatherIcon icon="video" classes="wd-10" />
                </a>
                <a href=" " className="nav-msg">
                    <FeatherIcon icon="message-square" classes="wd-10" />
                </a>
            </nav>
            <hr className="mg-y-20" />
            {type === 'user' && (
                <>
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-15">
                        Options
                    </label>
                    <nav className="nav flex-column contact-content-nav mg-b-25">
                        <a href=" " className="nav-link">
                            <FeatherIcon icon="share" classes="wd-10" />
                            Export this User
                        </a>
                        <a href=" " className="nav-link">
                            <FeatherIcon icon="star" classes="wd-10" />
                            Add to Admins
                        </a>
                        <a href=" " className="nav-link">
                            <FeatherIcon icon="slash" classes="wd-10" />
                            Block this User
                        </a>
                    </nav>
                </>
            )}
            {type === 'role' && (
                <>
                    {(selected.users && selected.users.length > 0) ? (
                        <>
                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-15">
                                Assigned Users
                            </label>
                            {selected.users.map(user => (
                                <ListCard
                                    type="role"
                                    key={user.id}
                                    role={{ ...selected }}
                                    handleSelected={() => { }}
                                    showActions={false}
                                    {...user}
                                />
                            ))}
                        </>
                    ) : (
                        <p className="tx-12 tx-muted">No users assigned</p>
                    )}
                </>
            )}
        </>
    );
}
