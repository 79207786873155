import React from 'react';

const FileSizer = ({ data }) => {

    if (!data) return <span />;

    let fileSize = data;
    const conversion = 1024;
    
    
    if (fileSize < conversion) {
        return (
            <span className="file-size ml-4">
                Selected file size: {parseInt(fileSize, 10)} B
            </span>
        );
    }

    // convert to KB's
    fileSize /= conversion;

    if (fileSize < conversion) {
        return (
            <span className="file-size ml-4">
                Selected file size: {parseInt(fileSize, 10)} KB
            </span>
        );
    }

    // convert to MB's
    fileSize /= conversion;

    if (fileSize < conversion) {
        return (
            <span className="file-size ml-4">
                Selected file size: {parseInt(fileSize, 10)} MB
            </span>
        );
    }

    // convert to GB's
    fileSize /= conversion;

    if (fileSize < conversion) {
        return (
            <span className="file-size ml-4">
                Selected file size: {parseInt(fileSize, 10)} GB
            </span>
        );
    }

    return <span />;
};

const UploadFile = props => {
    const { file, handleFileUpload } = props;
    return (
        <>
            <h3 id="wizard1-h-0" tabIndex="-1" className="title current">Select File to Import</h3>
            <p className="mg-b-20">
                Dealsheet Import allows you to easily import dealsheet CSV datafiles.
            </p>
            <div className="legend" data-label="Dealsheet csv">
                <div className="wd-md-50p">
                    <input type="file" name="file" onChange={handleFileUpload} />
                    {file.size && <FileSizer data={file.size} />}
                </div>
            </div>
        </>
    );
};

export default UploadFile;
