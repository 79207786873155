import axios from 'axios';
import queryString from 'query-string';
import config from '../config';
import Session from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
let headers = {};

if (Session.getToken()) {
  headers = {
    'Authorization': `Bearer ${Session.getToken()}`,
  }
}

class SettingsProvider {
  static async all(filters = null) {
    const queryStr = filters ? `?${queryString.stringify(filters)}` : '';
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}settings${queryStr}`,
      crossdomain: true,
      headers
    })
      .then((json) => json.data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async searchSettingByCodeKey(category, key, filter = null) {
    const queryStr = filter ? `?${queryString.stringify(filter)}` : '';
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}settings/${category}/${key + queryStr}`,
      crossdomain: true,
      headers
    })
      .then((json) => json.data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response) return err.response.data;
        return err;
      });
  }
}

export default SettingsProvider;