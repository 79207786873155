import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import utils from 'utils';
import CSVReader from 'react-csv-reader';

const { getKey, validPackageToImport } = utils;

const UploadFile = props => {
    const { file, handleFileUpload, error } = props;
    return (
        <div>
            <p className="mg-b-20">
                Package Importer allows you to easily import devices in CSV datafile.
            </p>
            <div className="legend" data-label="Select File to Import">
                <CSVReader
                    cssClass="custom-file"
                    cssInputClass="custom-file-input"
                    label={<label className="custom-file-label" htmlFor="customFile">{file.name ? file.name : 'Choose file'}</label>}
                    inputId="customFile"
                    accept="text/csv"
                    onFileLoaded={handleFileUpload}
                />
                {file.size && (
                    <span className="tx-success">
                        Selected file size:&nbsp;
                        {file.size >= 1024 && <span>{parseInt((file.size / 1024), 10)} KB</span>}
                        {file.size < 1024 && <span>{file.size} B</span>}
                    </span>)}
                {error && (
                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                        <li className="parsley-required">{error}</li>
                    </ul>)}
            </div>
        </div>
    );
};

export default function ImportPackagesModal(props) {
    const {
        show,
        setShow,
        processing,
        callback,
        networks,
        types,
        truncate,
        setTruncate,
    } = props;
    const { handleSubmit } = useForm();
    const [file, setFile] = useState({});
    const [fileData, setFileData] = useState([]);
    const [error, setError] = useState(null);
    const mandatory = [
        'fup',
        'sms',
        'name',
        'code',
        'data',
        'type',
        'minutes',
        'airtime',
        'network',
        'throttle',
        'upload speed',
        'download speed',
        'contract details',
        'night express data',
    ];

    const handleClose = () => {
        setFile({});
        setError(null);
        setFileData([]);
        setShow(false);
    };

    const onSubmit = () => {
        callback({ file, data: fileData });
    };

    const validateCsvHeaders = data => {
        let valid = true;
        const headers = data[0].map(item => item.toLowerCase());
        mandatory.map(item => {
            if (!headers.includes(item.toLowerCase())) {
                valid = false;
            }
            return item;
        })
        return valid;
    };

    const inPackages = [];

    const formatCsvData = filedata => {
        const data = [];
        const keys = [];
        // console.log(filedata);
        filedata.map((arr, idx) => {
            let obj = {};
            arr.map((item, i) => {
                let key = null;
                if (idx === 0) {
                    key = getKey(item);
                    keys[i] = key;
                } else {
                    key = keys[i];
                    let value = item.trim();

                    if (key === 'network') {
                        networks.map(network => {
                            if (network.label.toLowerCase() === value.toLowerCase()) {
                                obj['provider_id'] = network.value;
                            }
                            return true;
                        });
                    } else if (key === 'type') {
                        obj['is_flte'] = value.toLowerCase().includes('capped');
                        types.map(type => {
                            if (type.label.toLowerCase() === value.toLowerCase()) {
                                obj['type_id'] = type.value;
                            }
                            return true;
                        });
                    } else if (key === 'minutes'
                        || key === 'sms'
                        || key === 'data'
                        || key === 'airtime'
                    ) {
                        if (value.toLowerCase() === 'uncapped' || value.toLowerCase() === 'unlimited') {
                            obj[key] = -1;
                        } else if (value.trim().length === 0) {
                            obj[key] = 0;
                        } else {
                            obj[key] = parseInt(value);
                        }
                    } else if (key === 'fup'
                        || key === 'night_express_data'
                    ) {
                        if (value.trim().length === 0) return obj[key] = null;
                        let val = 0;
                        val = parseInt(value.trim());
                        if (value.toUpperCase().includes('GB')) {
                            val *= 1024; // conversion to GB
                        } else if (value.toUpperCase().includes('TB')) {
                            val *= 1024; // conversion to GB
                            val *= 1024; // conversion to TB
                        }
                        obj[key] = val;
                    } else if (key === 'code'
                        || key === 'description'
                        || key === 'name'
                        || key === 'contract_details'
                    ) {
                        if (value.trim().length === 0) return obj[key] = null;
                        obj[key] = value.trim();
                    } else {
                        if (value.trim().length === 0) return obj[key] = null;
                        obj[key] = value.trim();
                    }
                }
                return true;
            });
            if (validPackageToImport(obj)) {
                if (inPackages.includes(obj.code)) return true;

                obj.permakey = obj.name
                    .trim()
                    .toLowerCase()
                    .replace('+', '')
                    .replace('-', '')
                    .replace('  ', '-')
                    .split(' ')
                    .join('-')
                    .trim() + '_' +
                    obj.code
                        .toLowerCase();
                data.push(obj);
                inPackages.push(obj.code);
            }
            return true;
        });
        // console.log(data);
        setFileData(data);
    };

    const handleFileUpload = (data, fileInfo) => {
        setError(null);
        if (validateCsvHeaders(data)) {
            formatCsvData(data);
            setFile(fileInfo);
        } else setError(<span><strong>VALIDATION ERROR:</strong> Invalid file headers or missing mandatory columns.</span>);
    };

    return (
        <Modal show={show} onHide={handleClose} centered className="special-text-modal">
            <Modal.Header closeButton>
                <h6 className="modal-title">
                    Import Packages
                </h6>
            </Modal.Header>
            <Modal.Body>
                <form id="import-packages" method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="margin-bottom-15">
                        <Col xs={12} className="mg-b-15">
                            <UploadFile
                                file={file}
                                handleFileUpload={handleFileUpload}
                                error={error}
                            />
                        </Col>
                    </Row>
                    <Row className="margin-bottom-15">
                        <Col xs={12} className="mg-b-15">
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="truncate"
                                    checked={truncate}
                                    onChange={e => setTruncate(e.target.checked)}
                                />
                                <label
                                    className="custom-control-label" htmlFor="truncate"
                                >
                                    Truncate (Clear All Packages)
                                </label>
                            </div>
                        </Col>
                    </Row>
                </form>
            </Modal.Body>
            <Modal.Footer className="border-top">
                <Row>
                    <Col xs={12} className="text-right">
                        <button
                            type="button"
                            className="btn btn-secondary tx-13"
                            disabled={processing}
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            type="submit"
                            className="btn btn-primary tx-13"
                            form="import-packages"
                            disabled={processing || fileData.length === 0}
                        >
                            {processing ? 'Processing...' : 'Import'}
                        </button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}
