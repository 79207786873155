import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import {
  Breadcrumb,
  FeatherIcon,
  Loading,
  TextEditor,
} from 'components';
import {
  NetworksProvider,
  PackagesProvider,
} from 'providers';
import Select from 'react-select';
import wysiwyg from 'static/wysiwyg.json';
import Swal from 'sweetalert2';

const flteTypes = ['CAPPED', 'UNCAPPED'];

const AddPackage = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [pageLoading, setPageLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [contractDetails, setContractDetails] = useState('');
  const [networkProviders, setNetworkProviders] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [packageTypes, setPackageTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [showAirtime, setShowAirtime] = useState(false);
  const [showMinutes, setShowMinutes] = useState(false);
  const [showData, setShowData] = useState(true);
  const [showSMS, setShowSMS] = useState(false);
  const breadcrumb = [
    { title: 'Packages', link: '/packages', active: false },
    { title: 'Add Package', active: true },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const data = await NetworksProvider.getAll();
      const types = await PackagesProvider.getTypes();
      if (data.results) {
        if (data.results.length > 0) {
          const providers = [];
          data.results.map((item) =>
            providers.push({ value: item.id, label: item.name })
          );
          setNetworkProviders(providers);
        }
      }
      if (types.results) {
        if (types.results.length > 0) {
          setSelectedType(types.results[0]);
          setPackageTypes(types.results);
        }
      }
      setPageLoading(false);
    };
    fetchData();
  }, []);

  const onSubmit = (data) => {
    handleSavePackage(data);
  };

  const handleSavePackage = async (formData) => {
    const { name, code, description, airtime, minutes, sms, data } = formData;

    if (!selectedNetwork) {
      return Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Please select network provider.',
        showConfirmButton: false,
        timer: 4000
      });
    }

    if (!selectedType) {
      return Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Please select package type.',
        showConfirmButton: false,
        timer: 4000
      });
    }

    setSubmitting(true);
    const post = {
      name,
      code,
      airtime: airtime || null,
      minutes: minutes || null,
      sms: sms || null,
      data: data || null,
      description,
      provider_id: selectedNetwork.id,
      type_id: selectedType.id,
      contract_details: contractDetails,
    };
    const result = await PackagesProvider.create(post);
    const { status, message } = result;

    if (status && status === 'success') {
      Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Package information successfully saved!!',
          showConfirmButton: false,
          timer: 4000
      });
      return setTimeout(async function () {
        setSubmitting(false);
        navigate('/packages');
      }, 4000);
    }

    setSubmitting(false);
    
    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'An unkown error has occurred',
      showConfirmButton: false,
      timer: 4000
    });
  };

  const handleSelectType = (item) => {
    setShowSMS(false);
    setShowData(false);
    setShowAirtime(false);
    setShowMinutes(false);
    setSelectedType(null);

    if (item && item.description) {
      setSelectedType(item);
      switch (item.description.toLowerCase()) {
        case 'data':
        case 'capped':
        case 'uncapped':
        case 'data only':
          setShowData(true);
          register('data', { required: true });
          break;

        case 'voice':
        case 'voice only':
          setShowMinutes(true);
          register('minutes', { required: true });
          break;

        case 'airtime':
        case 'prepaid':
        case 'pre-paid':
        case 'pre paid':
          setShowAirtime(true);
          register('airtime', { required: true });
          break;

        case 'topup':
        case 'top up':
          setShowAirtime(true);
          register('airtime', { required: true });
          break;

        case 'voice + data':
        case 'open contract':
          setShowData(true);
          setShowMinutes(true);
          setShowSMS(true);
          register('data', { required: true });
          register('minutes', { required: true });
          register('sms', { required: true });
          break;

        default: break;
      }
    }
  };

  return (
    <Layout pageLoading={pageLoading}>
      {submitting && <Loading text="Saving..." />}
      <div className="content content-fixed pd-b-0">
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <Breadcrumb heading="Add Package" items={breadcrumb} />
        </Container>
      </div>
      {!pageLoading &&
        <div className="contact-wrapper" style={{ top: 174 }}>
          <div className="contact-content fluid">
            <div className="contact-content-header r-f-0">
              <nav className="nav">
                <a role="button" href="/packages/add" className="nav-link active">
                  Information
                </a>
              </nav>
            </div>
            <div className="contact-content-body ps r-f-0 overflow-y-auto">
              <div className="tab-content">
                <div className="tab-pane show active pd-20 pd-xl-25">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex align-items-center justify-content-between mg-b-25">
                      <h6 className="mg-b-0">Package Information</h6>
                      <div className="d-flex">
                        <button
                          type="submit"
                          className="btn btn-sm btn-outline-primary d-flex align-items-center mg-r-5"
                        >
                          <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                          <span className="d-none d-sm-inline mg-l-5"> Save</span>
                        </button>
                        <Link to="/packages">
                          <button
                            type="button"
                            className="btn btn-sm btn-white d-flex align-items-center"
                          >
                            <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                            <span className="d-none d-sm-inline mg-l-5">
                              {" "}
                              Cancel
                            </span>
                          </button>
                        </Link>
                      </div>
                    </div>
                    <Row style={{ maxWidth: "100%" }}>
                      <Col xs={12} sm={4}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Network Provider
                          </label>
                          <Select
                            options={networkProviders}
                            isLoading={networkProviders.length === 0}
                            isDisabled={networkProviders.length === 0}
                            onChange={(item) =>
                              setSelectedNetwork({
                                id: item.value,
                                name: item.label,
                              })
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={4}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Name
                          </label>
                          <input
                            type="text"
                            className={`form-control ${errors.name ? "parsley-error" : ""
                              }`}
                            placeholder="Enter package name..."
                            {...register('name', { required: true })}
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={4}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Code
                          </label>
                          <input
                            type="text"
                            className={`form-control ${errors.code ? "parsley-error" : ""
                              }`}
                            placeholder="Enter package code..."
                            {...register('code', { required: true })}
                          />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Package Type
                          </label>
                          <Row>
                            <Col xs={12} sm={7}>
                              <div className="btn-group" role="group">
                                {packageTypes.length > 0 &&
                                  packageTypes.map((item) => (
                                    <button
                                      key={item.id}
                                      type="button"
                                      className={`btn btn-${selectedType &&
                                          selectedType.id === item.id
                                          ? "primary"
                                          : "dark"
                                        }`}
                                      onClick={() => handleSelectType(item)}
                                    >
                                      {item.description}
                                    </button>
                                  ))}
                              </div>
                            </Col>
                            <Col xs={12} sm={5} className="package-types">
                              <div className="input-group">
                                {showAirtime && (
                                  <>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        Airtime
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className={`form-control ${errors.airtime ? "parsley-error" : ""
                                        }`}
                                      placeholder="Value in Rands"
                                      {...register('airtime', { required: true })}
                                    />
                                  </>
                                )}

                                {showMinutes && (
                                  <>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        Minutes
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className={`form-control ${errors.minutes ? "parsley-error" : ""
                                        }`}
                                      placeholder="Minute bundles"
                                      {...register('minutes', { required: true })}
                                    />
                                  </>
                                )}

                                {showData && (
                                  <>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        DATA
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className={`form-control ${errors.data ? "parsley-error" : ""
                                        }`}
                                      placeholder="Data in MB"
                                      {...register('data', { required: true })}
                                    />
                                  </>
                                )}

                                {showSMS && (
                                  <>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        SMS
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className={`form-control ${errors.sms ? "parsley-error" : ""
                                        }`}
                                      placeholder="SMS bundles"
                                      {...register('sms', { required: true })}
                                    />
                                  </>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      {selectedType && selectedType.description && flteTypes.includes(selectedType.description.toUpperCase()) &&
                        <Col xs={12}>
                          <div className="form-group">
                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                              FLTE Attributes
                            </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  Download Speed
                                </span>
                              </div>
                              <input
                                type="text"
                                className={`form-control ${errors.download_speed ? "parsley-error" : ""
                                  }`}
                                placeholder="Speed in Mbps"
                                {...register('download_speed', { required: false })}
                              />

                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  Upload Speed
                                </span>
                              </div>
                              <input
                                type="text"
                                className={`form-control ${errors.upload_speed ? "parsley-error" : ""
                                  }`}
                                placeholder="Speed in Mbps"
                                {...register('upload_speed', { required: false })}
                              />

                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  Throttle
                                </span>
                              </div>
                              <input
                                type="text"
                                className={`form-control ${errors.throttle ? "parsley-error" : ""
                                  }`}
                                placeholder="Throttle"
                                {...register('throttle', { required: false })}
                              />

                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  Night Express Data
                                </span>
                              </div>
                              <input
                                type="text"
                                className={`form-control ${errors.night_express_data ? "parsley-error" : ""
                                  }`}
                                placeholder="Data in MB"
                                {...register('night_express_data', { required: false })}
                              />

                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  FUP
                                </span>
                              </div>
                              <input
                                type="text"
                                className={`form-control ${errors.fup ? "parsley-error" : ""
                                  }`}
                                placeholder="Fair Usage Policy"
                                {...register('fup', { required: false })}
                              />
                            </div>
                          </div>
                        </Col>}
                      <Col xs={12}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Description (Optional)
                          </label>
                          <input
                            type="text"
                            className={`form-control ${errors.description ? "parsley-error" : ""
                              }`}
                            placeholder="Enter package description..."
                            {...register('description', { required: false })}
                          />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Contract Details (Optional)
                          </label>
                          <TextEditor.WYSIWYG
                            model={contractDetails}
                            setModel={setContractDetails}
                            placeholderText="Enter contract details..."
                            toolbarButtons={
                              wysiwyg.toolbarButtons.contractDetails
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </Layout>
  );
};

export default AddPackage;
