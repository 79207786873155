const AWS = require('aws-sdk');
const config = require('../config');

const getDealDescription = (data, network) => {
    switch (network.toLowerCase()) {
        case 'mtn':
            // build description for MTN
            const device    = data['device_description'] ? data['device_description'].split('_').join(' ') : '';
            const term      = parseInt(data['contract_term']);
            const pricePlan = data['price_plan'];
            const price     = data['total_subscription'];
            return `${device} @ ${price} PM on ${pricePlan} Price plan (${term} Months)`;

        default: return data['description'];
    }
};

const getDeviceDescription = description => {
    if (description) {
        const descr = (description) ? description.split('_').join(' ') : description;
        const array = descr.replace('(PRODUCT)', '').split('+');
        return array.length > 0 ? array[0].trim() : descr;
    }
    return description;
};

const getExtraValue = (data, network, bundles) => {
    const extraValue = [];
    switch (network.toLowerCase()) {
        case 'mtn':
            const { bundle_description, price_plan } = data;
            if (bundle_description && bundle_description.length > 0) {
                if (bundles && bundles.length > 0) {
                    bundles.map(item => {
                        const { description, icon, id, keywords, title } = item;
                        const checkArray = [];
                        const object = {
                            id,
                            icon,
                            description,
                            title,
                            additional_text: [],
                        };
                        if (keywords && keywords.length > 0) {
                            keywords.map(keyword => {
                                if (bundle_description.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
                                    const value = bundle_description.split(' + ');
                                    if (value && value.length > 0) {
                                        value.map(label => {
                                            if (label.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
                                                if (checkArray.indexOf(label) === -1) {
                                                    object.additional_text.push(label);
                                                    checkArray.push(label);
                                                }
                                            }
                                            return label;
                                        });
                                    }
                                }
                                return keyword;
                            });
                        }
                        if (object.additional_text.length > 0) {
                            object.additional_text = JSON.stringify(object.additional_text);
                            extraValue.push(object);
                        }
                        return item;
                    });
                }
            } else if (price_plan && price_plan.length > 0) {
                if (bundles && bundles.length > 0) {
                    bundles.map(item => {
                        const { description, icon, id, keywords, title } = item;
                        const checkArray = [];
                        const object = {
                            id,
                            icon,
                            description,
                            title,
                            additional_text: [],
                        };
                        if (keywords && keywords.length > 0) {
                            keywords.map(keyword => {
                                if (price_plan.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
                                    const value1 = price_plan.substr(price_plan.indexOf('(') + 1);
                                    const index  = value1.indexOf(')');
                                    const value2 = value1.substr(0, index).split(' + ');
                                    if (value2 && value2.length > 0) {
                                        value2.map(label => {
                                            if (label.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
                                                if (checkArray.indexOf(label) === -1) {
                                                    object.additional_text.push(label);
                                                    checkArray.push(label);
                                                }
                                            }
                                            return label;
                                        });
                                    }
                                }
                                return keyword;
                            });
                        }
                        if (object.additional_text.length > 0) {
                            object.additional_text = JSON.stringify(object.additional_text);
                            extraValue.push(object);
                        }
                        return item;
                    });
                }
            }
              
            break;
        
        case 'vodacom':
            const { vas_1, vas_2 } = data;
            let vas1 = vas_1;
            let vas2 = vas_2;

            if (vas1 && vas1.length > 0) {
                if (bundles && bundles.length > 0) {
                    bundles.map(item => {
                        const { description, icon, id, keywords, title } = item;
                        const checkArray = [];
                        const object = {
                            id,
                            icon,
                            description,
                            title,
                            additional_text: [],
                        };
                        if (keywords && keywords.length > 0) {
                            keywords.map(keyword => {
                                if (vas1.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
                                    if (checkArray.indexOf(vas1) === -1) {
                                        object.additional_text.push(vas1);
                                        checkArray.push(vas1);
                                    }
                                }
                                return keyword;
                            });
                        }
                        if (object.additional_text.length > 0) {
                            object.additional_text = JSON.stringify(object.additional_text);
                            extraValue.push(object);
                        }
                        return item;
                    });
                }
            }
            if (vas2 && vas2.length > 0) {
                if (bundles && bundles.length > 0) {
                    bundles.map(item => {
                        const { description, icon, id, keywords, title } = item;
                        const checkArray = [];
                        const object = {
                            id,
                            icon,
                            description,
                            title,
                            additional_text: [],
                        };
                        if (keywords && keywords.length > 0) {
                            keywords.map(keyword => {
                                if (vas2.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
                                    if (checkArray.indexOf(vas2) === -1) {
                                        object.additional_text.push(vas2);
                                        checkArray.push(vas2);
                                    }
                                }
                                return true;
                            });
                        }
                        if (object.additional_text.length > 0) {
                            object.additional_text = JSON.stringify(object.additional_text);
                            extraValue.push(object);
                        }
                        return item;
                    });
                }
            }
            break;

        default: break;
    }
    return (extraValue.length > 0) ? extraValue : null;
};

const getExtraData = (data, network) => {
    switch (network.toLowerCase()) {
        case 'mtn':
            const { data_bundle } = data;
            if (data_bundle) {
                const mtnDataBundle = data_bundle.replace('None', '');
                if (mtnDataBundle && mtnDataBundle.length > 0) {
                    return mtnDataBundle;
                }
            }
            break;
        
        case 'vodacom':
            const { vas_1 } = data;
            if (vas_1 && vas_1.length > 0) {
                var vcDataBundle = vas_1
                    .replace('R 0.00', '')
                    .replace('R0.00', '')
                    .trim();
    
                if (vcDataBundle && vcDataBundle.length > 0) {
                    var arr;
                    if (vcDataBundle.toUpperCase().indexOf(' DATA X ') !== -1) {
                        arr = vcDataBundle.toUpperCase().split(' DATA ');
                        if (vcDataBundle.toUpperCase().indexOf(' DATA X 1') !== -1) {
                            vcDataBundle = `Extra ${arr[0].trim()} Promotional Data Once-Off`;
                        } else {
                            var matches = arr[1].match(/(\d+)/);
                            vcDataBundle = `Extra ${arr[0].trim()} Promotional Data Per Month for ${matches[0]} Months`;
                        }
                    } else if (vcDataBundle.toUpperCase().indexOf('GB - ') !== -1) {
                        arr = vcDataBundle.toUpperCase().split('GB - ');
                        if (arr.length === 2) {
                            var match1 = arr[0].match(/(\d+)/);
                            var match2 = arr[1].match(/(\d+)/);
                            vcDataBundle = `Extra ${match1[0]}GB Promotional Data Per Month for ${match2[0]} Month(s)`;
                        }
                    }
                    return vcDataBundle && vcDataBundle !== '0' ? vcDataBundle : null;
                }
            }
            break;

        default: break;
    }
    return null;
};

const getFreeAccessory = (data, network) => {
    let accessory = null;
    switch (network.toLowerCase()) {
        case 'mtn':
            const { freebie_line } = data;
            if ((freebie_line && freebie_line.toLowerCase() === 'none_none') || (freebie_line && freebie_line.toLowerCase() === 'none')) {
                accessory = null;
            } else if (freebie_line && freebie_line.length > 0) {
                accessory = freebie_line.split('_').join(' ');
            }
            return accessory;

        case 'vodacom':
            if (data['device_description'] && data['device_description'].includes('+')) {
                const arr = data['device_description'].split('+');
                if (arr.length >= 2) {
                    accessory = arr[1];
                }
            }
            if (!accessory) {
                accessory  = data['accessory_1'] ? data['accessory_1'] : '';
                accessory += data['accessory_2'] ? `, ${data['accessory_2']}` : '';
                accessory += data['accessory_3'] ? `, ${data['accessory_3']}` : '';
                accessory += data['accessory_4'] ? `, ${data['accessory_4']}` : '';
            }
            return accessory;

        default: return null;
    }
};

const getPackageCategory = (code, network, term = '') => {
    let category = null;
    let packages = [];
    switch (network.toLowerCase()) {
        case 'mtn':
            packages = [
                // Open Line
                { code: 'M4M_{TERM}_XS', category: 'Open Line', tariff: 'MTN Made For Me XS' },
                { code: 'M4M_{TERM}_S', category: 'Open Line', tariff: 'MTN Made For Me S' },
                { code: 'M4M_{TERM}_M', category: 'Open Line', tariff: 'MTN Made For Me M' },
                { code: 'M4M_{TERM}_L', category: 'Open Line', tariff: 'MTN Made For Me L' },
                { code: 'M4M_{TERM}_XL', category: 'Open Line', tariff: 'MTN Made For Me XL' },
                { code: 'M4M_{TERM}_SKYSU', category: 'Open Line', tariff: 'MTN Sky Supreme/Sky Black' },
                { code: 'M4M_{TERM}_SKYL', category: 'Open Line', tariff: 'My MTN Sky Lite' },
                { code: 'M4M_{TERM}_SKYM', category: 'Open Line', tariff: 'My MTN Sky Med/Sky VIP' },

                // Top Up
                { code: 'M4M_{TERM}_XSTU', category: 'Top Up', tariff: 'MTN Made For Me XS TopUp' },
                { code: 'M4M_{TERM}_STU', category: 'Top Up', tariff: 'MTN Made For Me S TopUp' },
                { code: 'M4M_{TERM}_MTU', category: 'Top Up', tariff: 'MTN Made For Me M TopUp' },
                { code: 'M4M_{TERM}_LTU', category: 'Top Up', tariff: 'MTN Made For Me L TopUp' },
                { code: 'M4M_{TERM}_XLTU', category: 'Top Up', tariff: 'MTN  Made For Me XL TopUp' },
                { code: 'M4M_{TERM}_SKYLTU', category: 'Top Up', tariff: 'My MTN Sky TopUp' },
                { code: 'M4M_{TERM}_SKYMTU', category: 'Top Up', tariff: 'My MTN Sky VIP Top-Up' },
            ];
            break;

        case 'vodacom':
            packages = [
                // Tariff
                { code: 'UCG', category: 'Top Up', tariff: 'uChoose Flexi 65' },
                { code: 'UCH', category: 'Top Up', tariff: 'uChoose Flexi 125' },
                { code: 'UCI', category: 'Top Up', tariff: 'uChoose Flexi 175' },
                { code: 'UCJ', category: 'Top Up', tariff: 'uChoose Flexi 230' },
                { code: 'UCK', category: 'Top Up', tariff: 'uChoose Flexi 390' },
                { code: 'UCL', category: 'Top Up', tariff: 'uChoose Flexi 550' },
                { code: 'UCN', category: 'Top Up', tariff: 'uChoose Flexi 810' },

                // Smart Packages Tariff
                { code: 'SRT', category: 'Open Line', tariff: 'Smart XS +' },
                { code: 'SMA', category: 'Open Line', tariff: 'Smart Data 1GB' },
                { code: 'SRS', category: 'Open Line', tariff: 'Smart S +' },
                { code: 'SMB', category: 'Open Line', tariff: 'Smart Data 2GB' },
                { code: 'SRM', category: 'Open Line', tariff: 'Smart M +' },
                { code: 'SMF', category: 'Open Line', tariff: 'Smart Data 4GB' },
                { code: 'SRL', category: 'Open Line', tariff: 'Smart L +' },
                { code: 'RM1', category: 'Open Line', tariff: 'RED Data 6GB' },
                { code: 'RMX', category: 'Open Line', tariff: 'RED Data 10GB' },
                { code: 'SRX', category: 'Open Line', tariff: 'Red Select +' },
                { code: 'RRA', category: 'Open Line', tariff: 'Red Classic +' },
                { code: 'RM2', category: 'Open Line', tariff: 'RED Data 25GB' },
                { code: 'RRP', category: 'Open Line', tariff: 'Red Premium +' },
                { code: 'RM3', category: 'Open Line', tariff: 'RED Data 50GB' },
                { code: 'RRV', category: 'Open Line', tariff: 'Red VIP +' },
                { code: 'UPT', category: 'Top Up', tariff: 'Smart Top Up XS +' },
                { code: 'UD1', category: 'Top Up', tariff: 'Smart Top Up Data 1GB' },
                { code: 'URS', category: 'Top Up', tariff: 'Smart Top Up S +' },
                { code: 'UD2', category: 'Top Up', tariff: 'Smart Top Up Data 2GB' },
                { code: 'URM', category: 'Top Up', tariff: 'Smart Top Up M +' },
                { code: 'UD4', category: 'Top Up', tariff: 'Smart Top Up Data 4GB' },
                { code: 'URL', category: 'Top Up', tariff: 'Smart Top Up L +' },
                { code: 'UR6', category: 'Top Up', tariff: 'RED Top Up Data 6GB' },
                { code: 'URX', category: 'Top Up', tariff: 'Red Select Top Up +' },

                // Promotional Packages Tariff
                { code: '3DV', category: 'Open Line', tariff: '500 mb Data Price plan' },
                { code: '3D1', category: 'Open Line', tariff: '1GB Data Price plan' },
                { code: '3D2', category: 'Open Line', tariff: '2GB Data Price plan' },
                { code: '3D3', category: 'Open Line', tariff: '3GB Data Price plan' },
                { code: '3DG', category: 'Open Line', tariff: '5GB Data Price plan' },
                { code: '3DH', category: 'Open Line', tariff: '10GB Data Price plan' },
                { code: '3DI', category: 'Open Line', tariff: '20GB Data Price plan' },
                { code: '3DM', category: 'Open Line', tariff: '30GB Data Price plan' },
                { code: '3DN', category: 'Open Line', tariff: '50GB Data Price plan' },
                { code: '3DP', category: 'Open Line', tariff: '100GB Data Price plan' },
                { code: '3EV', category: 'Top Up', tariff: '500 mb Data Price plan Top up' },
                { code: '3E1', category: 'Top Up', tariff: '1GB Data Price plan Top up' },
                { code: '3E2', category: 'Top Up', tariff: '2GB Data Price plan Top up' },
                { code: '3E3', category: 'Top Up', tariff: '3GB Data Price plan Top up' },
                { code: '3EJ', category: 'Top Up', tariff: '5GB Data Price plan Top up' },
                { code: '3EK', category: 'Top Up', tariff: '10GB Data Price plan Top up' },
                { code: '3EL', category: 'Top Up', tariff: '20GB Data Price plan Top up' },
                { code: '3KM', category: 'Top Up', tariff: '30GB Data Price plan Top up' },
                { code: '3KN', category: 'Top Up', tariff: '50GB Data Price plan Top up' },
                { code: '3KP', category: 'Top Up', tariff: '100GB Data Price plan Top up' },
                { code: '3J1', category: 'Open Line', tariff: 'My Meg 500 MBB 12 month - Contract' },
                { code: '3J2', category: 'Open Line', tariff: '1 GIG MBB 12 month - Contract' },
                { code: '3J3', category: 'Open Line', tariff: '2 GIG MBB 12 month - Contract' },
                { code: '3J4', category: 'Open Line', tariff: '3 GIG MBB 12 month - Contract' },
                { code: '3JG', category: 'Open Line', tariff: '5 GIG MBB 12 month - Contract' },
                { code: '3JH', category: 'Open Line', tariff: '10 GIG MBB 12 month - Contract' },
                { code: '3JI', category: 'Open Line', tariff: '20 GIG MBB 12 month - Contract' },
                { code: '3DJ', category: 'Open Line', tariff: '30 GIG MBB 12 month - Contract' },
                { code: '3DK', category: 'Open Line', tariff: '50 GIG MBB 12 month - Contract' },
                { code: '3DL', category: 'Open Line', tariff: '100 GIG MBB 12 month - Contract' },
                { code: '3K1', category: 'Top Up', tariff: 'My Meg 500 MBB 12 month - Top Up' },
                { code: '3K2', category: 'Top Up', tariff: '1 GIG MBB 12 month - Top Up' },
                { code: '3K3', category: 'Top Up', tariff: '2 GIG MBB 12 month - Top Up' },
                { code: '3K4', category: 'Top Up', tariff: '3 GIG MBB 12 month - Top Up' },
                { code: '3KG', category: 'Top Up', tariff: '5 GIG MBB 12 month - Top Up' },
                { code: '3KH', category: 'Top Up', tariff: '10 GIG MBB 12 month - Top Up' },
                { code: '3KI', category: 'Top Up', tariff: '20 GIG MBB 12 month - Top Up' },
                { code: '3KJ', category: 'Top Up', tariff: '30 GIG MBB 12 month - Top Up' },
                { code: '3KK', category: 'Top Up', tariff: '50 GIG MBB 12 month - Top Up' },
                { code: '3KL', category: 'Top Up', tariff: '100 GIG MBB 12 month - Top Up' },
                { code: '3EA', category: 'Top Up', tariff: '250 MB Data Price plan Top Up' },
            ];
            break;
        
        default: return category;
    }
    if (packages.length > 0) {
        packages.map(item => {
            if (item.code.replace('_{TERM}_', term) === code) {
                category = item.category;
            }
            return true;
        })
    }
    return category;
};

const customMatchedField = (field, network) => {
    switch (network.toLowerCase()) {
        case 'mtn':
            return field === 'extra_value' ||
                field === 'description' ||
                field === 'dealsheet_type' ||
                field === 'package_title' ||
                field === 'channel' ||
                field === 'free_accessory_override';

        case 'vodacom':
            return field === 'extra_value' ||
                field === 'description' ||
                field === 'free_accessory' ||
                field === 'freebie_line' ||
                field === 'dealsheet_type' ||
                field === 'package_title' ||
                field === 'channel' ||
                field === 'free_accessory_override';
        default: return false;
    }
};

const formatCurrency = value => {
    if (typeof value === 'number') return value;
    return parseFloat(value.replace('R', '').trim());
};

const getReducedSub = data => {
    const { total_subscription, tariff_subscription } = data;
    if (total_subscription && tariff_subscription) {
        if (total_subscription < tariff_subscription) {
            return tariff_subscription - total_subscription;
        }
    }
    return null;
};

const validContractTerm = (data, settings) => {
    const { contract_terms } = settings;
    if (!data['contract_term']) return false;
    return contract_terms.includes(parseInt(data['contract_term']));
};

const validRecordToImport = (data, settings) => {
    const { minimum_total_subs } = settings;
    if (data['dealsheet_number'] &&
        data['dealsheet_number'].trim() !== '' &&
        parseFloat(data['total_subscription']) < minimum_total_subs
    ) {
        return false;
    }
    if (!data['package_code']) return false;
    if (!data['device_description']) return false;
    if (!validContractTerm(data, settings)) return false;
    return true;
}

const validPackageToImport = object => {
    if (Object.keys(object).length > 0) {
        const {
            sms,
            data,
            name,
            code,
            type_id,
            airtime,
            minutes,
            provider_id,
        } = object;
        if ((sms || data || airtime || minutes) &&
            name && name.length > 0 &&
            code && code.length > 0 &&
            type_id && type_id.length > 0 &&
            provider_id && provider_id.length > 0
        ) {
            return true;
        }
    }
    return false;
}

const getKey = value => (
    value
        .split(' - ')
        .join('_')
        .split(' ')
        .join('_')
        .split('(')
        .join('')
        .split(')')
        .join('')
        .trim()
        .toLowerCase()
);

const uploadJsonToS3 = async (json, Key) => {
    AWS.config.update({
        // accessKeyId: config.default.aws.s3.options.accessKeyId,
        // secretAccessKey: config.default.aws.s3.options.secretAccessKey,
        region: config.default.aws.s3.options.region,
    });
    const s3 = new AWS.S3();
    const buf = Buffer.from(JSON.stringify(json));
    const data = {
        Bucket: config.default.aws.s3.options.bucketName,
        Key,
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: 'application/json',
        ACL: 'public-read'
    };
    
    return s3.upload(data, function (err, data) {
        if (err) {
            console.log(err);
            console.log('Error uploading data: ', data);
            return err;
        } else {
            console.log('succesfully uploaded!!!');
            return data;
        }
    });
};

export default {
    customMatchedField,
    formatCurrency,
    getDealDescription,
    getDeviceDescription,
    getExtraData,
    getExtraValue,
    getFreeAccessory,
    getPackageCategory,
    getReducedSub,
    validRecordToImport,
    validPackageToImport,
    uploadJsonToS3,
    getKey,
};
