import React from 'react';
import { Card } from 'reactstrap';
import { FeatherIcon } from 'components';
import DataTable from 'react-data-table-component';

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const RowActions = ({
    id,
    name,
    archived,
    handleDelete,
    setManufacturer,
    setShowUpdateModal,
}) => {
    return (
        <div>
            <a
                href={`/devices/manufacturers/edit/${id}`}
                onClick={e => {
                    e.preventDefault();
                    setManufacturer({ id, name, archived });
                    return setShowUpdateModal(true);
                }}
            >
                <FeatherIcon icon="edit" width="16" height="16" />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
                href={`/devices/manufacturers/delete/${id}`}
                className="tx-danger"
                onClick={e => {
                    e.preventDefault();
                    return handleDelete(id, name);
                }}
            >
                <FeatherIcon icon="trash-2" width="16" height="16" />
            </a>
        </div>
    );
};

const ManufacturerList = props => {
    const { data, handleUpdate } = props;

    // table headings definition
    const columns = [{
        name: 'Name',
        selector: row => row['name'],
        sortable: true,
    }, {
        name: 'Archived/Status',
        sortable: true,
        width: '180px',
        selector: row => row['archived'],
        cell: row => (
            <div className="custom-control custom-switch mr-4">
                <input
                    id="archived"
                    type="checkbox"
                    className="custom-control-input"
                    checked={!row.archived}
                    onChange={(e) => handleUpdate(row.id, {
                        archived: !e.target.checked,
                    })}
                />
                <label className="custom-control-label" htmlFor="archived">
                    {row.archived ? 'Archived' : 'Active'}
                </label>
            </div>
        )
    }, {
        name: 'Actions',
        sortable: false,
        width: '120px',
        cell: row => <RowActions {...row} {...props} />
    }];

    return (
        <div className="dealsheet-result-table-wrapper">
            <Card>
                <DataTable
                    data={data}
                    columns={columns}
                    customStyles={customStyles}
                    selectableRows={false}
                    highlightOnHover
                    pagination
                    noHeader
                />
            </Card>
        </div>
    );
};

export default ManufacturerList;
