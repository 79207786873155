import React from 'react';
import { Modals } from 'components';
import { Col, Row } from 'reactstrap';

export default function AddType(props) {
    const {
        show,
        reset,
        errors,
        register,
        onCreate,
        handleSubmit,
        setShow,
    } = props;
    return (
        <Modals.Default
            show={show}
            setShow={setShow}
            modalTitle="Add Device Type"
            callback={() => reset()}
        >
            <form method="post" autoComplete="off" onSubmit={handleSubmit(onCreate)}>
                <div className={`form-group ${errors.name ? 'is-invalid' : ''}`}>
                    <label>
                        Name: <span className="tx-danger">*</span>
                    </label>
                    <input
                        type="text"
                        className={`form-control ${errors.name ? 'parsley-error' : ''}`}
                        placeholder="Enter device type"
                        {...register('name', {
                            required: true,
                        })}
                    />
                    {errors.name && (
                        <ul className="parsley-errors-list filled">
                            <li className="parsley-required">
                                This input should be a valid device type.
                            </li>
                        </ul>
                    )}
                </div>
                <Row>
                    <Col xs={12} className="text-right mg-b-15">
                        <button
                            type="submit"
                            className="btn btn-primary tx-13"
                        >
                            Save Changes
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            type="button"
                            className="btn btn-secondary tx-13"
                            onClick={() => {
                                reset();
                                setShow(false);
                            }}
                        >
                            Cancel
                        </button>
                    </Col>
                </Row>
            </form>
        </Modals.Default>
    );
}