import axios from 'axios';
import config from '../config';
import Session from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
let headers   = {};

if (Session.getToken()) {
  headers = {
    'Authorization': `Bearer ${Session.getToken()}`,
  }
}

class DealsheetsProvider {
  static async getAll(offset=config.results.offset, limit=config.results.limit, querystr = '') {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}dealsheets?offset=${offset}&limit=${limit}${querystr}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async get(id) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}dealsheets/${id}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async filter(filter, offset=config.results.offset, limit=config.results.limit) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}dealsheets?filter=${filter}&offset=${offset}&limit=${limit}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async add(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}dealsheets`,
      crossdomain: true,
      headers,
      data
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async update(id, data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}dealsheets/${id}`,
      crossdomain: true,
      headers,
      data
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async delete(id) {
    return await axios({
      mode: 'no-cors',
      method: 'DELETE',
      url: `${baseurl}dealsheets/${id}`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async updateMultiple(data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}dealsheets`,
      crossdomain: true,
      headers,
      data
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async makeLive(data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}dealsheets`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }
}

export default DealsheetsProvider;
