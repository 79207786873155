import AddPackageType from './AddPackageType';
import Alert from './Alert';
import Confirm from './Confirm';
import Default from './Default';
import DealsheetImportSelectNetwork from './DealsheetImportSelectNetwork';
import DealsToBeImported from './DealsToBeImported';
import DeviceMatchCriteria from './DeviceMatchCriteria';
import ImportDevices from './ImportDevices';
import UpdatePackageType from './UpdatePackageType';
import UploadingDeviceImage from './UploadingDeviceImage';
import UploadImage from './UploadImage';
import Uploading from './Uploading';

export default {
    AddPackageType,
    Alert,
    Confirm,
    Default,
    DealsheetImportSelectNetwork,
    DealsToBeImported,
    DeviceMatchCriteria,
    ImportDevices,
    UpdatePackageType,
    UploadingDeviceImage,
    UploadImage,
    Uploading,
};
