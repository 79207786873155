import React, { useCallback, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { EditSpecialTextModal, ResultsModal } from 'components';
import { DealsheetsProvider } from 'providers';
import Table from './Table';
import 'react-confirm-alert/src/react-confirm-alert.css';

const DealsheetResult = props => {
    const {
        selectedRows,
        setSelectedRows,
        resultsLoading,
        setResultsLoading,
        setAllSelected,
        handleDeleteDeal,
    } = props;

    const [ showEditSpecialTextModal, setShowEditSpecialTextModal ] = useState(false);
    const [ processing, setProcessing ] = useState(false);
    const [ showResultsModal, setShowResultsModal ] = useState(false);
    const [ editRowData, setEditRowData ] = useState({});
    const [ resultsModalInfo, setResultsModalInfo ] = useState({});
    const [ indicators, setIndicators ] = useState([]);

    const fetchData = async () => {
        if (props.indicators && props.indicators.length > 0) {
            const indicators = props.indicators.filter((indicator) => (indicator.label.toLowerCase().includes('all') || indicator.label.toLowerCase().includes('digital')));
            setIndicators(indicators);
        }
    };

    useEffect(() => {
        if (resultsLoading) {
            fetchData();
        }
    }, [resultsLoading, props.indicators]);

    const handleRowSelected = useCallback(state => {
        setAllSelected(state.allSelected);
        setSelectedRows(state.selectedRows);
    }, [ setSelectedRows, setAllSelected ]);

    function handleMakeLive() {
        confirmAlert({
            title: 'Confirm Make LIVE',
            message: 'You are about to push "Sell All" and "Digital Only Sell" deals to Mondo Web.',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: async () => {
                        setProcessing(true);
                        const siid = [];
                        indicators.map(item => siid.push(item.id));
                        const updateData = {
                            online: true,
                            sale_indicators: siid,
                        };
                        const result = await DealsheetsProvider.makeLive(updateData);
                        setProcessing(false);
                        if (result.status === 'success') {
                            setResultsModalInfo({
                                title: 'Deals Updated',
                                body: <p>Deals have been updated and will appear on Mondo Web within 24 hours.</p>,
                                buttonText: 'Back to Manage Deals',
                            });
                            setShowResultsModal(true);
                        } else {
                            setResultsModalInfo({
                                title: 'Error',
                                titleClasses: 'tx-danger',
                                body: <p>{result.message}</p>,
                                buttonText: 'Back to Manage Deals',
                                buttonClass: 'btn btn-danger',
                            });
                            setShowResultsModal(true);
                        }
                    },
                },
                {
                    label: 'Cancel',
                }
            ]
        });
    };

    return (
        <div className="dealsheet-result-table-wrapper">
            <EditSpecialTextModal
				show={showEditSpecialTextModal}
				setShow={setShowEditSpecialTextModal}
                bulkEdit={false}
                selectedRows={selectedRows}
                data={editRowData}
            />
            <ResultsModal
                title={resultsModalInfo.title}
                body={resultsModalInfo.body}
                show={showResultsModal}
                setShow={setShowResultsModal}
                buttonText={resultsModalInfo.buttonText}
            />
            <Card>
                <Table
                    {...props}
                    handleRowSelected={handleRowSelected}
                    setShowEditSpecialTextModal={setShowEditSpecialTextModal}
                    resultsLoading={resultsLoading}
                    setResultsLoading={setResultsLoading}
                    setEditRowData={setEditRowData}
                    handleDeleteDeal={handleDeleteDeal}
                />
                <Card className="card-body mg-b-5">
                    <Row>
                        <Col xs={12} sm={4}>
                            <div className="export-csv-wrapper form-group">
                                <Card>
                                    <CardBody>
                                        {/* <Row>
                                            <Col xs={8}>
                                                <p>
                                                    Export ALL deals to CSV.<br/>
                                                    The filters above have no effect on this export.
                                                </p>
                                            </Col>
                                            <Col xs={4} className="text-right pd-t-5">
                                                {resultsLoading && 
                                                <button
                                                    className="btn btn-secondary"
                                                    disabled
                                                >
                                                    Please wait...
                                                </button>}
                                                {!resultsLoading && dealsheets &&
                                                <CSVLink
                                                    data={dealsheets}
                                                    className="btn btn-secondary"
                                                    filename={`dams_export_${moment().format('YYYYMMDD')}`}
                                                    onClick={() => {
                                                        setResultsModalInfo({
                                                            title: 'Deals Exported',
                                                            body: <p><strong>{dealsheets.length}</strong> deals have been exported to CSV and downloaded to your PC.</p>,
                                                            buttonText: 'Back to Manage Deals'
                                                        });
                                                        setShowResultsModal(true);
                                                    }}
                                                >
                                                    Export CSV
                                                </CSVLink>}
                                            </Col>
                                        </Row> */}
                                        <Row>
                                            <Col xs={8}>
                                                <p>
                                                    To Export deals to CSV please click
                                                    button to contact DBA.
                                                </p>
                                            </Col>
                                            <Col xs={4} className="text-right pd-t-5">
                                                <a href="mailto:ameer.ismail@rewardsco.co.za?subject=DAMS Export CSV Request" className="btn btn-secondary">
                                                    Request CSV
                                                </a>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                        <Col xs={12} sm={4} />
                        <Col xs={12} sm={4} className="text-right pd-t-20">
                            <button
                                type="button"
                                className="btn btn-success"
                                disabled={processing}
                                onClick={() => handleMakeLive()}
                            >
                                {processing ? 'Processing...' : 'Make LIVE'}
                            </button>
                        </Col>
                    </Row>
                </Card>
            </Card>
        </div>
    );
};

export default DealsheetResult;
