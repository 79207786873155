import axios from 'axios';
import config from '../config';
import Session from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
let headers   = {};

if (Session.getToken()) {
  headers = {
    'Authorization': `Bearer ${Session.getToken()}`,
  }
}

class PackagesProvider {
  static async create(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}packages`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async update(id, data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}packages/${id}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async getAll(offset=config.results.offset, limit=config.results.limit, filters=null) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}packages?offset=${offset}&limit=${limit + (filters ? `&${filters}` : '')}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async get(id) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}packages/${id}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async search(prop, value, offset=config.results.offset, limit=config.results.limit) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}packages/search/${prop}/${value}?offset=${offset}&limit=${limit}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async createType(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}package_types`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async getTypes(querystr='', offset=config.results.offset, limit=config.results.limit) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}package_types?offset=${offset}&limit=${limit + querystr ? `&${querystr}` : ''}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async getType(id) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}package_types/${id}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async updateType(id, data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}package_types/${id}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async deleteType(id) {
    return await axios({
      mode: 'no-cors',
      method: 'DELETE',
      url: `${baseurl}package_types/${id}`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async delete(id) {
    return await axios({
      mode: 'no-cors',
      method: 'DELETE',
      url: `${baseurl}packages/${id}`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }
}

export default PackagesProvider;
