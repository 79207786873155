import React, { useState } from 'react';
import { FeatherIcon } from 'components';
import { PackagesProvider } from 'providers';

const PackageAutoCompleteInput = props => {
    const { setPackage, defaultPackage } = props;
    const [packageOptions, setPackageOptions] = useState([]);
    const [showAutoCompleteDropdown, setShowAutoCompleteDropdown] = useState(false);
    const [loadingAutoCompleteResults, setLoadingAutoCompleteResults] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState('');

    if (defaultPackage && !selectedPackage) {
        setSelectedPackage(defaultPackage);
    }

    async function autoComplete(value) {
        setPackage('');
        setShowAutoCompleteDropdown(value.length > 1);
        if (value.length > 1) {
            setPackageOptions([]);
            setLoadingAutoCompleteResults(true);
            const data = await PackagesProvider.search('name', value);
            setPackageOptions(data.results);
            setLoadingAutoCompleteResults(false);
        }
    }

    function handleSelect(item) {
        const selectedPackage = item.name;
        setPackage(item.id);
        setSelectedPackage(selectedPackage);
        setShowAutoCompleteDropdown(false);
    }

    return (
        <div className={`input-with-dropdown-options ${showAutoCompleteDropdown ? 'show' : ''}`}>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Find and select package..."
                    onKeyUp={e => autoComplete(e.target.value)}
                    value={selectedPackage}
                    onChange={e => setSelectedPackage(e.target.value)}
                />
                <div className="input-group-append">
                    {selectedPackage.length === 0 &&
                        <span className="input-group-text">
                            <FeatherIcon icon="search" width="16" height="16" />
                        </span>}
                    {selectedPackage.length > 0 &&
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                setPackage('');
                                setSelectedPackage('');
                                setShowAutoCompleteDropdown(false);
                                return true;
                            }}
                        >
                            <FeatherIcon icon="x" width="16" height="16" />
                        </button>}
                </div>
            </div>
            <ul className="dropdown-options">
                {loadingAutoCompleteResults && <li className="text-center">Loading...</li>}
                {!loadingAutoCompleteResults &&
                    packageOptions.length > 0 &&
                    packageOptions.map(item => {
                        return (
                            <li key={item.id} className="option-item">
                                <a
                                    role="button"
                                    href="/"
                                    className="option-link"
                                    onClick={e => {
                                        e.preventDefault();
                                        return handleSelect(item);
                                    }}
                                >
                                    {item.name} ({item.code})
                                </a>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

export default PackageAutoCompleteInput;
