import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Layout } from 'containers';
import {
  Modals,
  Breadcrumb,
  FeatherIcon,
  ImageManager,
  TextEditor,
} from 'components';
import {
  DevicesProvider,
  SessionProvider,
  SettingsProvider,
  ManufacturersProvider,
} from 'providers';
import moment from 'moment';
import S3 from 'react-aws-s3';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import wysiwyg from 'static/wysiwyg.json';
import Swal from 'sweetalert2';
import config from '../../config';

const configurations = SessionProvider.getConfigurations();
const {
    ASSET_ACCESS_KEY_ID,
    ASSET_SECRET_ACCESS_KEY,
    MONDO_ASSET_ACCESS_KEY_ID,
    MONDO_ASSET_SECRET_ACCESS_KEY,
} = configurations;

const selectStyles = {
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

const RowActions = (props) => {
  const { id } = props;
  return (
    <div className="mg-r-20">
      {/* <Link to={`/devices/duplicate/${id}`}>
        <a href={`/devices/duplicate/${id}`}>
          <FeatherIcon icon="copy" width="16" height="16" />
        </a>
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
      <Link to={`/devices/edit/${id}`}>
        <a href={`/devices/edit/${id}`}>
          <FeatherIcon icon="edit" width="16" height="16" />
        </a>
      </Link>
    </div>
  );
};

const columns = [
  {
    name: "",
    sortable: false,
    wrap: true,
    width: '80px',
    cell: (row) => (
      <>
        <div className="avatar">
          {row.image_default ? (
            <img src={config.aws.s3.url + row.image_default}
              className="rounded-circle"
              alt={row.title}
            />
          ) : (
            <span className="avatar-initial rounded-circle bg-gray-600">
              {row.title[0]}
            </span>
          )}
        </div>
      </>
    )
  },
  {
    name: "Device",
    selector: "title",
    sortable: true,
    wrap: true,
    minWidth: '250px',
    cell: (row) => (
      <div className="pd-sm-l-10">
        <p className="tx-medium mg-b-0">{row.title}</p>
        <small className="tx-12 tx-color-03 mg-b-0">
          {row.manufacturer_name}
          {' '}
          {row.model}
          {' '}
          {row.device_type.name}
        </small>
      </div>
    )
  },
  {
    name: "Colour",
    selector: "colour",
    sortable: true,
    cell: (row) => (
      <div className="activity-body mg-l-0">
        <span className="d-inline-block wd-7 ht-7 bg-gray-400 rounded-circle mg-r-5" />
        {row.colour}
      </div>
    ),
  },
  {
    name: "Storage",
    selector: "storage",
    sortable: true,
    cell: (row) => (
      <div className="activity-body mg-l-0">
        <span>{row.storage}</span>
      </div>
    ),
  },
  {
    name: "Image",
    selector: "image_default",
    sortable: true,
    cell: (row) => (
      <>
        {row.image_default ? (
          <div className="wd-35 ht-35 bd bd-2 tx-success rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
            <FeatherIcon icon="check" />
          </div>
        ) : (
          <div className="wd-35 ht-35 bd bd-2 tx-danger rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
            <FeatherIcon icon="x" />
          </div>
        )}
      </>
    ),
  },
  {
    name: "Created",
    selector: "created",
    sortable: true,
    cell: (row) => (
      <div className="pd-l-0">
        <p className="tx-medium mg-b-2">
          {moment(row.created).format("MMM DD, YYYY")}
        </p>
        <small className="tx-12 tx-color-03 mg-b-0">
          {moment(row.created).format("hh:mma")}
        </small>
      </div>
    ),
  },
  {
    name: "Modified",
    selector: "updated",
    sortable: true,
    cell: (row) => (
      <div className="pd-l-0">
        <p className="tx-medium mg-b-2">
          {moment(row.updated).format("MMM DD, YYYY")}
        </p>
        <small className="tx-12 tx-color-03 mg-b-0">
          {moment(row.updated).format("hh:mma")}
        </small>
      </div>
    ),
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    cell: (row) => (
      <span
        className={
          row.status.toLowerCase() === "active" ? "tx-success" : "tx-danger"
        }
      >
        {row.status}
      </span>
    ),
  },
  {
    name: "",
    width: '60px',
    sortable: false,
    cell: (row) => <RowActions {...row} />,
  },
];

const EditModel = () => {
  const location = useLocation();
  const { id } = useParams();
  const { search } = location;
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [processing, setProcessing] = useState(true);
  const [model, setModel] = useState({});
  const [types, setTypes] = useState([]);
  const [devices, setDevices] = useState([]);
  const [mainImg, setMainImg] = useState(null);
  const [features, setFeatures] = useState({});
  const [settings, setSettings] = useState({});
  const [modelImages, setModelImages] = useState([]);
  const [defaultImg, setDefaultImg] = useState(null);
  const [manufacturers, setManufacturers] = useState([]);
  const [deviceTypeId, setDeviceTypeId] = useState(null);
  const [manufacturerId, setManufacturerId] = useState(null);
  const [manufacturerName, setManufacturerName] = useState(null);
  const [activeTab, setActiveTab] = useState('information');
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [mainImageSaveDisabled, setMainImageSaveDisabled] = useState(true);
  const [showUploadingAlertModal, setShowUploadingAlertModal] = useState(false);
  const [alertModalContent, setAlertModalContent] = useState({});
  const [modelDescription, setModelDescription] = useState('');
  const [uploadingSteps, setUploadingSteps] = useState({
    uploadDams: 'pending',
    uploadMondo: 'pending',
  });
  const breadcrumb = [
    { title: 'Base Device Models', link: '/devices/models', active: false },
    { title: 'Edit Base Model', active: true },
  ];

  async function fetchData() {
    if (!processing) setProcessing(true);

    if (search) {
      if (search.indexOf('?tab=images') !== -1) {
        setActiveTab('images');
      }
    }
    if (!id) window.location = '/devices/models';

    // fetch config settings from the database
    const configs = await SettingsProvider.all({
      category: 'aws',
      key: 's3',
    });

    if (configs && configs.length > 0) {
      const settings = {};
      configs.map((item) => {
        const { subcategory, value } = item;
        settings[subcategory] = value;
      });
      setSettings(settings);
    }

    const filter = `&model_id=${id}`;
    const manufacturers = await ManufacturersProvider.getAll();
    const types = await DevicesProvider.getDeviceTypes();
    const model = await DevicesProvider.getModel(id);
    const images = await DevicesProvider.getImages(id, 'models/');
    const devices = await DevicesProvider.getAll(
      config.results.offset,
      config.results.limit,
      filter
    );
    setModel(model);
    setModelImages(images);
    setDevices(devices.results || []);
    setModelDescription(model.description || '');

    if (manufacturers.results && manufacturers.results.length > 0) {
      const _manufacturers = [];
      manufacturers.results.map((item) =>
        _manufacturers.push({ value: item.id, label: item.name })
      );
      setManufacturers(_manufacturers);
    }

    if (types.results && types.results.length > 0) {
      const _types = [];
      types.results.map((item) =>
        _types.push({ value: item.id, label: item.name })
      );
      setTypes(_types);
    }

    setDeviceTypeId(model.device_type.id);
    setManufacturerId(model.manufacturer.id);
    setManufacturerName(model.manufacturer.name);

    if (model && model.features && model.features.children) {
      setFeatures(model.features.children);
    }

    if (model && model.image) {
      setDefaultImg(model.image);
      setMainImg(model.image);
    }
    setProcessing(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSetMainImage = async () => {
    setProcessing(true);
    const image = defaultImg.replace('images/', '');
    const result = await DevicesProvider.updateModel(id, { image });
    setProcessing(false);

    if (result.status === 'success') {
      setMainImageSaveDisabled(true);
      setMainImg(defaultImg);
      return Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Base Device successfully updated!',
        showConfirmButton: false,
        timer: 5000
      });
    }

    setMainImageSaveDisabled(true);
    setDefaultImg(mainImg);

    // error occured
    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: result.message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 5000
    });
  };

  const onSubmit = async (data) => {
    setProcessing(true);
    const { code, feature, name } = data;
    const formData = {
      code,
      device_type_id: deviceTypeId,
      manufacturer_id: manufacturerId,
      manufacturer_name: manufacturerName,
      description: modelDescription,
    };

    if (feature) {
      const modelFeatures = [];
      features.map(item => {
        const { key } = item;
        modelFeatures.push({
          ...item,
          value: feature[key] || item.value,
        })
      });
      formData.features = {
        title: `${manufacturerName} ${name}`,
        key: model.features.key,
        children: modelFeatures,
      }
    }

    const response = await DevicesProvider.updateModel(id, formData);
    const { status } = response;

    setProcessing(false);

    if (status === 'success') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Base Device updated successfully!',
        showConfirmButton: false,
        timer: 5000
      });
      return setTimeout(async function () {
        fetchData();
      }, 5000);
    }

    // error occured
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 5000
    });
  };

  const handleUploadImage = async (fileInput) => {
    const file = fileInput.current.files[0];
    setShowUploadModal(false);
    setUploadingSteps({
      uploadDams: 'in progress',
      uploadMondo: 'pending',
    });
    setShowUploadingAlertModal(true);

    const { aws } = config;
    const { s3 } = aws;
    const {
      bucketName,
      region,
    } = s3.options;

    const { device_type, manufacturer, name } = model;
    const deviceType = device_type.name.split('/').join('-').split(' ').join('-').toLowerCase();
    const modelName = name.split('/').join('-').split(' ').join('-').toLowerCase();
    const directory = (`models/${deviceType}/${manufacturer.name}/${modelName.split(' ').join('-')}`).toLowerCase();

    const s3config = {
      bucketName,
      dirName: directory,
      region,
      accessKeyId: ASSET_ACCESS_KEY_ID,
      secretAccessKey: ASSET_SECRET_ACCESS_KEY,
    };

    const ReactS3Client = new S3(s3config);

    return ReactS3Client
      .uploadFile(file, 'default')
      .then(async data => {
        console.log(data);
        setUploadingSteps({
          uploadDams: 'complete',
          uploadMondo: 'in progress',
        });
        // upload same file to Mondo S3 bucket
        return handleUploadImageToMondo(file, directory);
      })
      .catch(err => {
        setShowUploadingAlertModal(false);
        setAlertModalContent({
          title: 'Upload Error!',
          body: <p>{err.message}</p>,
          type: 'error',
        });
        setShowAlertModal(true);
      });
  };

  async function handleUploadImageToMondo(file, dirName) {
    const { aws } = config;
    const { s3 } = aws;
    const { mondo } = s3;
    const {
      region,
      bucketName,
    } = mondo.options;

    const s3config = {
      bucketName,
      dirName,
      region,
      accessKeyId: MONDO_ASSET_ACCESS_KEY_ID,
      secretAccessKey: MONDO_ASSET_SECRET_ACCESS_KEY,
    };

    const ReactS3Client = new S3(s3config);

    return ReactS3Client
      .uploadFile(file, 'default')
      .then(data => {
        console.log(data);
        setUploadingSteps({
          uploadDams: 'complete',
          uploadMondo: 'complete',
        });
        setShowUploadingAlertModal(false);
        setAlertModalContent({
          title: 'Image Uploaded!',
          body: <p><strong>Device Image</strong> successfully uploaded!.</p>,
          callback: () => window.location = `/devices/models/edit/${id}?tab=images`,
          type: 'success',
        });
        setShowAlertModal(true);
      })
      .catch(err => {
        setShowUploadingAlertModal(false);
        setAlertModalContent({
          title: 'Upload Error!',
          body: <p>{err.message}</p>,
          type: 'error',
        });
        setShowAlertModal(true);
      });
  }

  return (
    <Layout pageLoading={processing}>
      <div className="content content-fixed pd-b-0">
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <Breadcrumb
            heading={`Update Base Device${model.id ? " : " + model.manufacturer.name + " " + model.name : ""
              }`}
            items={breadcrumb}
          />
        </Container>
      </div>
      <Modals.Alert
        show={showAlertModal}
        setShow={setShowAlertModal}
        {...alertModalContent}
      />
      <Modals.Uploading
        steps={uploadingSteps}
        show={showUploadingAlertModal}
        setShow={setShowUploadingAlertModal}
      />
      {model.id && showUploadModal && (
        <Modals.UploadImage
          show={showUploadModal}
          setShow={setShowUploadModal}
          setProcessing={setProcessing}
          setShowAlert={setShowAlertModal}
          setSaveDisabled={setMainImageSaveDisabled}
          setUploadingSteps={setUploadingSteps}
          setAlertContent={setAlertModalContent}
          setShowUploadingAlert={setShowUploadingAlertModal}
          handleUploadImage={handleUploadImage}
          {...model}
        />
      )}
      {model.id && (
        <div className="contact-wrapper" style={{ top: 174 }}>
          <div className="contact-content fluid">
            <div className="contact-content-header r-f-0">
              <nav className="nav">
                <a
                  role="button"
                  href="/devices/models"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab("information");
                  }}
                  className={`nav-link ${activeTab === "information" ? "active" : ""
                    }`}
                >
                  Information
                </a>
                <a
                  role="button"
                  href="/devices/models"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab("images");
                  }}
                  className={`nav-link ${activeTab === "images" ? "active" : ""
                    }`}
                >
                  Images
                </a>
              </nav>
            </div>
            <div className="contact-content-body ps r-f-0 overflow-y-auto">
              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === "information" ? "show active" : ""
                    } pd-20 pd-xl-25`}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex align-items-center justify-content-between mg-b-25">
                      <h5 className="mg-b-0">&nbsp;</h5>
                      <div className="d-flex">
                        <button
                          className="btn btn-sm btn-outline-primary d-flex align-items-center mg-r-5"
                          type="submit"
                        >
                          <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                          <span className="d-none d-sm-inline mg-l-5">
                            {" "}
                            Save
                          </span>
                        </button>
                        <button
                          className="btn btn-sm btn-white d-flex align-items-center"
                          onClick={(e) => {
                            e.preventDefault();
                            return (window.location = "/devices/models");
                          }}
                        >
                          <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                          <span className="d-none d-sm-inline mg-l-5">
                            {" "}
                            Cancel
                          </span>
                        </button>
                      </div>
                    </div>
                    <div
                      data-label="Base Device Model Information"
                      className="legend"
                    >
                      <Row>
                        <Col xs={12} sm={3}>
                          <div
                            className={`form-group ${!deviceTypeId ? "is-invalid" : ""
                              }`}
                          >
                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                              Device Type
                            </label>
                            <Select
                              className={!deviceTypeId ? "parsley-error" : ""}
                              name="device_type"
                              options={types}
                              isLoading={types.length === 0}
                              isDisabled={types.length === 0}
                              value={{
                                value: model.device_type.id,
                                label: model.device_type.name,
                              }}
                              onChange={(input) => setDeviceTypeId(input.value)}
                              styles={selectStyles}
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={3}>
                          <div
                            className={`form-group ${!manufacturerId ? "is-invalid" : ""
                              }`}
                          >
                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                              Manufacturer
                            </label>
                            <Select
                              className={!manufacturerId ? "parsley-error" : ""}
                              name="manufacturer_id"
                              options={manufacturers}
                              isLoading={manufacturers.length === 0}
                              isDisabled={manufacturers.length === 0}
                              value={{
                                value: model.manufacturer.id,
                                label: model.manufacturer.name,
                              }}
                              styles={selectStyles}
                              onChange={(input) => {
                                setManufacturerId(input.value);
                                return setManufacturerName(input.label);
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={3}>
                          <div
                            className={`form-group ${errors.name ? "is-invalid" : ""
                              }`}
                          >
                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                              Model
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errors.name ? "parsley-error" : ""
                                }`}
                              defaultValue={model.name}
                              {...register('name', { required: true })}
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={3}>
                          <div
                            className={`form-group ${errors.code ? "is-invalid" : ""
                              }`}
                          >
                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                              Code
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errors.code ? "parsley-error" : ""
                                }`}
                              defaultValue={model.code}
                              {...register('code', { required: true })}
                              disabled
                            />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="form-group">
                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                              Description (Optional)
                            </label>
                            <TextEditor.WYSIWYG
                              model={modelDescription}
                              setModel={setModelDescription}
                              placeholderText="Enter description..."
                              toolbarButtons={
                                wysiwyg.toolbarButtons.contractDetails
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {features && (
                      <div
                        data-label="Feature Information"
                        className="legend mg-t-20 feature-information"
                      >
                        {features.length > 0 && features.map((feature) => (
                          <li
                            key={feature.key}
                            className="list-group-item d-flex align-items-center"
                          >
                            <div>
                              <h6 className="tx-13 tx-inverse tx-semibold mg-b-0">
                                {feature.title}
                              </h6>
                              <span className="d-block tx-11 text-muted">
                                {feature.key}
                              </span>
                            </div>
                            <div>
                              <input
                                type="text"
                                className={`form-control ${errors[feature.key] ? 'parsley-error' : ''}`}
                                defaultValue={feature.value}
                                {...register(`feature.${feature.key}`, { required: false })}
                              />
                            </div>
                          </li>
                        ))}
                      </div>
                    )}
                  </form>
                  {(devices && devices.length > 0) && (
                    <div
                      className="legend mg-t-20"
                      data-label={`List of Devices for the ${model.manufacturer.name} ${model.name}`}
                      style={{
                        paddingTop: 5,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <div className="dealsheet-result-table-wrapper">
                        <Card>
                          <DataTable
                            data={devices}
                            columns={columns}
                            selectableRows={false}
                            highlightOnHover
                            pagination
                            noHeader
                          />
                        </Card>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`tab-pane ${activeTab === "images" ? "show active" : ""
                    } pd-20 pd-xl-25`}
                >
                  <div className="d-flex align-items-center justify-content-between mg-b-25">
                    <h6 className="mg-b-0">{model.manufacturer.name} {model.name} File Manager</h6>
                    <div className="d-flex">
                      <button
                        className="btn btn-sm btn-outline-success d-flex align-items-center mg-r-5"
                        onClick={() => handleSetMainImage()}
                        disabled={mainImageSaveDisabled}
                      >
                        <FeatherIcon icon="check" classes="wd-10 mg-r-5" />
                        <span className="d-none d-sm-inline mg-l-5">
                          {" "}
                          Set Main Image
                        </span>
                      </button>
                      <button
                        className="btn btn-sm btn-primary d-flex align-items-center mg-r-5"
                        onClick={() => setShowUploadModal(true)}
                      >
                        <FeatherIcon icon="upload" classes="wd-10 mg-r-5" />
                        <span className="d-none d-sm-inline mg-l-5">
                          {" "}
                          Upload
                        </span>
                      </button>
                      <Link to="/devices">
                        <button className="btn btn-sm btn-white d-flex align-items-center">
                          <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                          <span className="d-none d-sm-inline mg-l-5">
                            {" "}
                            Cancel
                          </span>
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="file-manager-wrapper">
                    <Row style={{ maxWidth: "100%" }}>
                      <Col xs={12}>
                        <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                          Available AWS S3 Image(s)
                        </label>
                      </Col>
                      <Col xs={12}>
                        <ImageManager
                          setShowUploadModal={setShowUploadModal}
                          setSaveDisabled={setMainImageSaveDisabled}
                          defaultImg={defaultImg}
                          setDefaultImg={setDefaultImg}
                          setMainImg={setMainImg}
                          images={modelImages}
                          mainImg={mainImg}
                          root="models/"
                        />
                      </Col>
                      <Col xs={12}>
                        <hr className="mg-y-40 bd-0" />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default EditModel;
