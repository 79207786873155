import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';

const UpdateUserRoleModal = props => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const {
        id,
        show,
        setShow,
        name,
        label,
        description,
        callback,
        roleUpdating,
        setRoleUpdating,
    } = props;

    const handleClose = () => setShow(false);

    const onSubmit = data => {
        setRoleUpdating(true);
        callback(id, data);
    };

    return (
        <Modal show={show} onHide={handleClose} centered className="special-text-modal">
            <Modal.Header closeButton>
                <h6 className="modal-title">
                    Edit {label} Role
                    </h6>
            </Modal.Header>
            <Modal.Body>
                <form method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="margin-bottom-15">
                        <Col xs={12}>
                            <div className={`form-group has-feedback ${errors.name ? 'has-error' : ''}`}>
                                <label>Name</label>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    className="form-control"
                                    {...register('name', { required: true, minLength: 4 })}
                                    defaultValue={name}
                                />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className={`form-group has-feedback ${errors.label ? 'has-error' : ''}`}>
                                <label>Label</label>
                                <input
                                    type="text"
                                    placeholder="Label"
                                    className="form-control"
                                    {...register('label', { required: true, minLength: 4 })}
                                    defaultValue={label}
                                />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className={`form-group has-feedback ${errors.email ? 'has-error' : ''}`}>
                                <label>Description</label>
                                <input
                                    type="text"
                                    placeholder="Description"
                                    className="form-control"
                                    {...register('description', { required: true, minLength: 4 })}
                                    defaultValue={description}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-right mg-b-15">
                            <button
                                type="button"
                                className="btn btn-secondary tx-13"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <button
                                type="submit"
                                className="btn btn-primary tx-13"
                                disabled={roleUpdating}
                            >
                                {roleUpdating ? 'Processing...' : 'Save Changes'}
                            </button>
                        </Col>
                    </Row>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default UpdateUserRoleModal;
