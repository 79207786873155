import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import { Form, Pagination } from 'components';
import { DealsheetsProvider, DevicesProvider } from 'providers';

const getRowId = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .join('')
        .split('"')
        .join('');
};

const ExistingDevice = props => {
    const {
        device_description,
        baseDevices,
        processing,
        setShowVariant,
        setProcessing,
        updatedDevices,
        setActiveDevice,
        selectedDevices,
        setSelectedDevices,
        activeDevice,
        updateReport,
        reference,
    } = props;
    const { handleSubmit } = useForm();
    const rowId = getRowId(device_description);
    const [hasColor, setHasColor] = useState(false);
    const [hasStorage, setHasStorage] = useState(false);
    const [rowDisabled, setRowDisabled] = useState(false);
    const [deviceSelected, setDeviceSelected] = useState(false);
    const [colorsLoading, setColorsLoading] = useState(false);
    const [storageLoading, setStorageLoading] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedStorage, setSelectedStorage] = useState(null);
    const [storage, setStorage] = useState([]);
    const [colors, setColors] = useState([]);

    const onSubmit = async () => {
        // validate if matching device has been selected
        if (!selectedModel.value) {
            return alert(`Please select base device for "${device_description}"`);
        }
        // make sure device is selected
        if (!deviceSelected) {
            return alert(`Please at least one variant for "${device_description}"`);
        }
        const data = {
            update: { device_id: deviceSelected },
            where: { device_description, reference }
        };
        setProcessing(true);
        setActiveDevice(rowId);
        const result = await DealsheetsProvider.updateMultiple(data);
        const { status } = result;
        if (status && status === 'success') {
            updateReport();
            updatedDevices.push(rowId);
        } else alert('Error has occurred, please try again');
        setActiveDevice(null);
        setProcessing(false);
    };

    const getButtonText = () => {
        if (processing && activeDevice === rowId)
            return 'SAVING...';
        if (updatedDevices.includes(rowId))
            return 'UPDATE';
        return 'SAVE';
    };

    const getButtonClass = () => {
        if (rowDisabled || processing || !deviceSelected)
            return 'btn btn-block btn-disabled';
        if (updatedDevices.includes(rowId))
            return 'btn btn-block btn-success';
        return 'btn btn-block btn-warning text-white';
    };

    async function handleGetVariantsByModel(item) {
        setColorsLoading(true);
        setStorageLoading(true);
        setSelectedModel(item);
        setDeviceSelected(false);

        // check if device has no variants, if so
        // mark device as found, and allow user to save
        // without have to select variants
        const devices = await DevicesProvider.getAll(0, 1, `&model_id=${item.value}`);
        if (devices.results && devices.results.length === 1) {
            if (!devices.results[0].variants) {
                setDeviceSelected(devices.results[0].id);
                const selected = selectedDevices;
                selected[rowId] = devices.results[0];
                setSelectedDevices(selected);
                setColorsLoading(false);
                setStorageLoading(false);
                return true;
            }
        }

        setColors([]);
        setStorage([]);
        const colorVariants = await DevicesProvider.getVariants(item.value, 'color');
        const storageVariants = await DevicesProvider.getVariants(item.value, 'storage');
        setHasStorage(storageVariants.results ? true : false);
        setHasColor(colorVariants.results ? true : false);
        setStorage(storageVariants.results || []);
        setColors(colorVariants.results || []);
        setColorsLoading(false);
        setStorageLoading(false);
    };

    async function handleGetVariantsByModelStorage(item) {
        setColorsLoading(true);
        setDeviceSelected(false);
        setSelectedStorage(item);

        setColors([]);
        const colorVariants = await DevicesProvider.getVariants(selectedModel.value, 'color', `storage=${item.label}`);
        setColors(colorVariants.results || []);
        setColorsLoading(false);
    };

    async function handleGetVariantsByModelColor(item) {
        setStorageLoading(true);
        setSelectedColor(item);
        setDeviceSelected(false);

        // check if device has no storage, if so
        // mark device as found, and allow user to save
        // without have to select variants
        let val = selectedModel.label;
        val += (selectedStorage) ? ` ${selectedStorage.label}` : '';
        val += ` ${item.label}`;
        const devices = await DevicesProvider.search('title', val);
        
        if (devices.results && devices.results.length === 1) {
            setDeviceSelected(devices.results[0].id);
            const selected = selectedDevices;
            selected[rowId] = devices.results[0];
            setSelectedDevices(selected);
            setStorageLoading(false);
            return true;
        }
        
        setStorageLoading(false);
    };

    const selectIsDisabled = (key) => {
        let disabled = false;
        if (key === 'storage') {
            disabled = rowDisabled || !hasStorage || storage.length === 0;
        } else {
            disabled = rowDisabled || !hasColor || colors.length === 0 || (!selectedStorage && hasStorage);
        }
        return disabled;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="existing-info-wrapper">
                <Col xs={9}>
                    <Row>
                        <Col xs={3}>
                            <Select
                                options={baseDevices}
                                placeholder="Select a base device"
                                onChange={item => handleGetVariantsByModel(item)}
                                isDisabled={rowDisabled}
                            // menuIsOpen
                            />
                        </Col>
                        <Col xs={3}>
                            <Select
                                options={storage}
                                placeholder="Select Storage"
                                onChange={item => handleGetVariantsByModelStorage(item)}
                                isDisabled={selectIsDisabled('storage')}
                                isLoading={storageLoading}
                            />
                        </Col>
                        <Col xs={3}>
                            <Select
                                options={colors}
                                placeholder="Select Color"
                                onChange={item => handleGetVariantsByModelColor(item)}
                                isDisabled={selectIsDisabled('color')}
                                isLoading={colorsLoading}
                            />
                        </Col>
                        <Col xs={3}>
                            <div className="mg-t-10">
                                <a
                                    href="/devices/add"
                                    onClick={e => {
                                        e.preventDefault();
                                        if (rowDisabled) return false;
                                        setShowVariant(true);
                                    }}
                                >
                                    Add Device Variant
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={3}>
                    <Row>
                        <Col xs={7}>
                            <div className="pd-t-10">
                                <Form.Checkbox
                                    id={rowId}
                                    name={rowId}
                                    label="Ignore this Device"
                                    onChange={e => setRowDisabled(e.target.checked)}
                                />
                            </div>
                        </Col>
                        <Col xs={5}>
                            <Form.Button
                                type="submit"
                                classes={getButtonClass()}
                                disabled={rowDisabled || processing || !deviceSelected}
                                text={getButtonText()}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );
};

const TableRow = props => {
    const { device_description } = props;
    return (
        <div className="table-row">
            <Row>
                <Col md={3}>
                    <div className="mg-t-10 pd-l-10">
                        {device_description}
                    </div>
                </Col>
                <Col md={9}>
                    <div className="pd-r-10">
                        <ExistingDevice {...props} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default function UnmatchedDevicesList(props) {
    const { devicesReport } = props;
    const [selectedDevices, setSelectedDevices] = useState({});
    const [displayData, setDisplayData] = useState([]);
    const [perPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        const pageCount = Math.ceil(devicesReport.length / perPage);
        setPageCount(pageCount);
        setDisplayData(devicesReport);
    }, [ ]);

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPage;
        setOffset(offset);
        setCurrentPage(selectedPage);
        const slice = displayData.slice(
            offset,
            offset + perPage
        );
        const reportData = slice.map(item => item);
        console.log(reportData)
        setDisplayData(reportData);
    };

    return (
        <div className="matching-info-wrapper">
            <Row className="heading">
                <Col md={3}>
                    Unmatched Device Name
                    <hr className="mg-t-5" />
                </Col>
                <Col md={9}>
                    Existing Device
                    <hr className="mg-t-5" />
                </Col>
            </Row>
            {displayData.map((item, index) => (
                <TableRow
                    key={index.toString()}
                    selectedDevices={selectedDevices}
                    setSelectedDevices={setSelectedDevices}
                    {...props}
                    {...item}
                />
            ))}
            <Pagination
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
            />
        </div>
    );
};
