import React from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import customStyles from 'static/styles.json';
import config from '../../config';
import { Card } from 'reactstrap';

const Actions = props => {
    const { id, handleDelete, status } = props;
    return (
        <div className="text-right">
            {status.toLowerCase() === 'success' ? (
                <a href={`/dealsheets/uploads/${id}`}>
                    View Report
                </a>
            ) : (
                <span className="tx-color-04">No report</span>
            )}
            <a
                href={`/dealsheets/delete/${id}`}
                className="tx-danger mg-l-15"
                onClick={e => {
                    e.preventDefault();
                    return handleDelete(props);
                }}
            >
                Delete
            </a>
        </div>
    );
};

const Status = ({ status }) => {
    if (status.toLowerCase() === 'error' || status.toLowerCase() === 'failed') {
        return <span className="tx-danger">{status}</span>;
    } else if (status.toLowerCase() === 'processing' || status.toLowerCase() === 'pending') {
        return <span className="tx-warning">{status}</span>;
    } else if (status.toLowerCase() === 'uploaded') {
        return <span className="tx-primary">{status}</span>;
    }
    return <span className="tx-teal">{status}</span>;
};

export default function Uploads(props) {
    const {
        data,
        resultsLoading,
    } = props;
    const { results } = config;
    const { pagination } = results;
    const { perpageOptions } = pagination;

    const columns = [{
        name: 'Upload Date',
        selector: row => row['created'],
        sortable: true,
        cell: row => <span>{moment(row.created).format('YYYY-MM-DD HH:mm')}</span>
    }, {
        name: 'Upload #',
        selector: row => row['reference'],
        sortable: false,
    }, {
        name: 'Network',
        sortable: false,
        cell: row => <span>{row.network_provider.name}</span>
    }, {
        name: 'Uploaded By',
        sortable: false,
        cell: row => <span>{`${row.user.first_name} ${row.user.last_name}`}</span>
    }, {
        name: 'Deals',
        selector: row => row['total_deals'],
        sortable: true,
    }, {
        name: 'Status',
        selector: row => row['status'],
        sortable: true,
        cell: row => <Status {...row} />
    }, {
        name: 'Expiry Date',
        selector: row => row['valid_to'],
        sortable: true,
        cell: row => <span>{moment(row.valid_to).format('DD MMMM YYYY')}</span>
    }, {
        name: 'Actions',
        sortable: false,
        cell: row => <Actions {...props} {...row} />,
    }];

    return (
        <div className="dealsheet-result-table-wrapper">
            <Card>
                <DataTable
                    data={data}
                    columns={columns}
                    customStyles={customStyles.datatable.default}
                    progressPending={resultsLoading}
                    paginationRowsPerPageOptions={perpageOptions}
                    highlightOnHover
                    pagination
                    noHeader
                />
            </Card>
        </div>
    );
};
