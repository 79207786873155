import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

const UpdateSpecialText = props => {
    const { selectedRows, setShowEditSpecialTextModal } = props;
    return (
        <div className="form-group">
            <Card>
                <CardBody>
                    <Row>
                        <Col xs={8}>
                            <label>Bulk Update Special Text</label>
                            <p className="mg-b-0">
                                To bulk edit special text, first select the deals you
                                would like to update and then click "Enter Special Text".
                            </p>
                        </Col>
                        <Col xs={4} className="pd-t-25">
                            <button
                                className="btn btn-primary btn-block"
                                disabled={selectedRows.length === 0}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowEditSpecialTextModal(true);
                                }}
                            >
                                Enter Special Text
                            </button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

export default UpdateSpecialText;
