import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';

const UpdateUserDetailsModal = props => {
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const {
        id,
        show,
        setShow,
        first_name,
        last_name,
        email,
        role,
        callback,
        roles,
        userUpdating,
        setUserUpdating,
    } = props;
    const [ selectedRoleId, setSelectedRoleId ] = useState(role && role.id || null);

    useEffect(() => {
        reset();
    });
	  
	const handleClose = () => setShow(false);

    const onSubmit = data => {
        if (selectedRoleId) {
            data.role_id = selectedRoleId;
            setUserUpdating(true);
            callback(id, data);
            return true;
        } else return alert('Please select user role');
    };

    if (!id) return false;

    return (
        <Modal show={show} onHide={handleClose} centered className="special-text-modal">
            <Modal.Header closeButton>
                <h6 className="modal-title">
                    Edit {first_name}
                </h6>
            </Modal.Header>
            <Modal.Body>
                <form method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="margin-bottom-15">
                        <Col xs={12}>
                            <div className="form-group">
                                <label>First name</label>
                                <input
                                    type="text"
                                    placeholder="First name"
                                    className={`form-control ${errors.first_name ? 'parsley-error' : ''}`}
                                    {...register('first_name', { required: true, minLength: 4 })}
                                    defaultValue={first_name}
                                />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="form-group">
                                <label>Last name</label>
                                <input
                                    type="text"
                                    placeholder="Last name"
                                    className={`form-control ${errors.last_name ? 'parsley-error' : ''}`}
                                    {...register('last_name', { required: true, minLength: 4 })}
                                    defaultValue={last_name}
                                />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="form-group">
                                <label>Email address</label>
                                <input
                                    type="text"
                                    placeholder="Email address"
                                    className={`form-control ${errors.email ? 'parsley-error' : ''}`}
                                    {...register('email', { required: true, minLength: 4 })}
                                    defaultValue={email}
                                />
                            </div>
                        </Col>
                        {role &&
                        <Col xs={12}>
                            <div className="form-group">
                                <label>Role</label>
                                <Select
                                    name="role_id"
                                    options={roles}
                                    defaultValue={{ label: role.label, value: role.id }}
                                    className={errors.role_id ? 'parsley-error' : ''}
                                    onChange={item => {
                                        setSelectedRoleId(item.value);
                                        return true;
                                    }}
                                />
                            </div>
                        </Col>}
                    </Row>
                    <Row>
                        <Col xs={12} className="text-right mg-b-15">
                            <button
                                type="button"
                                className="btn btn-secondary tx-13"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <button
                                type="submit"
                                className="btn btn-primary tx-13"
                                disabled={userUpdating}
                            >
                                {userUpdating ? 'Processing...' : 'Save Changes'}
                            </button>
                        </Col>
                    </Row>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default UpdateUserDetailsModal;
