import React, { useCallback, useState } from 'react';
import { Card } from 'reactstrap';
import { useQuery } from '@apollo/client';
import UsersGQL from 'queries/users';
import Table from './Table';

const UserListTable = props => {
    const { data, loading } = useQuery(UsersGQL.listUsers);
    const [ selectedRows, setSelectedRows ] = useState([]);

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    });

    return (
        <div className="dealsheet-result-table-wrapper">
            <Card>
                <Table
                    {...props}
                    resultsLoading={loading}
                    selectedRows={selectedRows}
                    users={data && data.users || []}
                    handleRowSelected={handleRowSelected}
                />
            </Card>
        </div>
    );
};

export default UserListTable;
