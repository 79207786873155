import { gql } from '@apollo/client';

export default {
    listUsers: gql`
        query ListUsers {
            users {
                id
                email
                status
                archived
                username
                last_name
                first_name
                last_login
                permissions
                prompt_change_password
                created
                updated
                role_id
                role {
                    id
                    label
                    description
                }
            }
        }
    `,
    getUser: gql`
        query GetUser($id: ID!) {
            user(id: $id) {
                id
                first_name
                last_name
                email
                username
                profile
                mobile
                prompt_change_password
                role_id
                permissions
                settings
                last_login
                archived
                status
                created
                updated
                role {
                    id
                    label
                    description
                }
            }
        }
    `,
    listRoles: gql`
        query ListUserRoles {
            userRoles {
                id
                name
                label
                description
                is_default
                is_public
                archived
                created
                updated
                users {
                    id
                    email
                    username
                    last_name
                    first_name
                }
            }
        }
    `
};
