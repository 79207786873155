import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { FeatherIcon } from 'components';

const ExtractData = props => {
    const {
        logStep,
        stepError2,
        stepError3,
        stepError4,
        stepError5,
    } = props;
    return (
        <>
            <h3 id="wizard1-h-0" tabIndex="-1" className="title current">Extract Data.</h3>
            <p className="mg-b-20">
                The following tasks will now be performed. Please monitor the execution by
                checking the import log below.
            </p>
            <Card>
                <CardHeader>
                    <div className="d-sm-flex align-items-center justify-content-between">
                        <h6 className="mg-b-0">Import Log</h6>
                        <p className="tx-12 tx-color-03 mg-b-0">
                            {logStep < 6 ?
                            <span>Importing in progress...</span> :
                            <span className="tx-success">Import Completed!</span>}
                        </p>
                    </div>
                </CardHeader>
                <CardBody className="pd-20">
                    <ul className="activity tx-13">
                        <li className="activity-item">
                            <div className={`activity-icon ${logStep > 1 ? 'bg-success-light tx-success' : 'bg-primary-light tx-primary'}`}>
                                <FeatherIcon icon={logStep > 1 ? 'check' : 'clock'} />
                            </div>
                            <div className="activity-body">
                                <p className="mg-b-2"><strong>Preparing import</strong></p>
                                <small className={logStep > 1 ? 'tx-success' : 'tx-primary'}>{logStep > 1 ? 'Complete' : 'In Progress'}</small>
                            </div>
                        </li>
                        <li className="activity-item">
                            <div className={`activity-icon ${logStep < 2 ? 'bg-light' : ''} ${(logStep === 2 && stepError2.success) ? 'bg-primary-light tx-primary' : ''} ${logStep > 2 ? 'bg-success-light tx-success' : ''} ${!stepError2.success ? 'bg-pink-light tx-pink' : ''}`}>
                                {logStep < 2 && <FeatherIcon icon="more-horizontal" />}
                                {logStep === 2 && <FeatherIcon icon={stepError2.success ? 'clock' : 'x'} />}
                                {logStep > 2 && <FeatherIcon icon="check" />}
                            </div>
                            <div className="activity-body">
                                <p className="mg-b-2"><strong>Saving dealsheet</strong> into the database</p>
                                {logStep < 2 && <small className="tx-color-03">Pending</small>}
                                {logStep === 2 && stepError2.success && <small className="tx-primary">In Progress</small>}
                                {logStep > 2 && <small className="tx-success">Complete</small>}
                                {!stepError2.success && <small className="tx-pink">Error: {stepError2.error}</small>}
                            </div>
                        </li>
                        <li className="activity-item">
                            <div className={`activity-icon ${logStep < 3 ? 'bg-light' : ''} ${logStep === 3 && stepError3.success ? 'bg-primary-light tx-primary' : ''} ${logStep > 3 ? 'bg-success-light tx-success' : ''} ${!stepError3.success ? 'bg-pink-light tx-pink' : ''}`}>
                                {logStep < 3 && <FeatherIcon icon="more-horizontal" />}
                                {logStep === 3 && <FeatherIcon icon={stepError3.success ? 'clock' : 'x'} />}
                                {logStep > 3 && <FeatherIcon icon="check" />}
                            </div>
                            <div className="activity-body">
                                <p className="mg-b-2"><strong>Extracting packages</strong> from dealsheet</p>
                                {logStep < 3 && <small className="tx-color-03">Pending</small>}
                                {logStep === 3 && stepError3.success && <small className="tx-primary">In Progress</small>}
                                {logStep > 3 && <small className="tx-success">Complete</small>}
                                {!stepError3.success && <small className="tx-pink">Error: {stepError3.error}</small>}
                            </div>
                        </li>
                        <li className="activity-item">
                            <div className={`activity-icon ${logStep < 4 ? 'bg-light' : ''} ${logStep === 4 && stepError4.success ? 'bg-primary-light tx-primary' : ''} ${logStep > 4 ? 'bg-success-light tx-success' : ''} ${!stepError4.success ? 'bg-pink-light tx-pink' : ''}`}>
                                {logStep < 4 && <FeatherIcon icon="more-horizontal" />}
                                {logStep === 4 && <FeatherIcon icon={stepError4.success ? 'clock' : 'x'} />}
                                {logStep > 4 && <FeatherIcon icon="check" />}
                            </div>
                            <div className="activity-body">
                                <p className="mg-b-2"><strong>Mapping packages</strong> to deals</p>
                                {logStep < 4 && <small className="tx-color-03">Pending</small>}
                                {logStep === 4 && stepError4.success && <small className="tx-primary">In Progress</small>}
                                {logStep > 4 && <small className="tx-success">Complete</small>}
                                {!stepError4.success && <small className="tx-pink">Error: {stepError4.error}</small>}
                            </div>
                        </li>
                        <li className="activity-item">
                            <div className={`activity-icon ${logStep < 5 ? 'bg-light' : ''} ${logStep === 5 && stepError5.success ? 'bg-primary-light tx-primary' : ''} ${logStep > 5 ? 'bg-success-light tx-success' : ''} ${!stepError5.success ? 'bg-pink-light tx-pink' : ''}`}>
                                {logStep < 5 && <FeatherIcon icon="more-horizontal" />}
                                {logStep === 5 && <FeatherIcon icon={stepError5.success ? 'clock' : 'x'} />}
                                {logStep > 5 && <FeatherIcon icon="check" />}
                            </div>
                            <div className="activity-body">
                                <p className="mg-b-2"><strong>Mapping devices</strong> to deals</p>
                                {logStep < 5 && <small className="tx-color-03">Pending</small>}
                                {logStep === 5 && stepError5.success && <small className="tx-primary">In Progress</small>}
                                {logStep > 5 && <small className="tx-success">Complete</small>}
                                {!stepError5.success && <small className="tx-pink">Error: {stepError5.error}</small>}
                            </div>
                        </li>
                        <li className="activity-item">
                            <div className={`activity-icon ${logStep < 6 ? 'bg-light' : ''} ${logStep === 6 ? 'bg-success-light tx-success' : ''}`}>
                                {logStep < 6 && <FeatherIcon icon="more-horizontal" />}
                                {logStep === 6 && <FeatherIcon icon="check" />}
                            </div>
                            <div className="activity-body">
                                <p className="mg-b-2"><strong>Finished</strong> performing tasks.</p>
                                {logStep < 6 && <small className="tx-color-03">Pending</small>}
                                {logStep === 6 && <small className="tx-success">Complete</small>}
                            </div>
                        </li>
                    </ul>
                </CardBody>
            </Card>
        </>
    );
};

export default ExtractData;
