import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';

const fieldOperators = [
    { value: 'iLike', label: 'Contains', tag: 'normal' },
    { value: 'eq', label: 'Equals', tag: 'normal' },
    { value: 'gt', label: 'Greater Than', tag: 'normal' },
    { value: 'gte', label: 'Greater Than Or Equal To', tag: 'normal' },
    { value: 'lt', label: 'Less Than', tag: 'normal' },
    { value: 'lte', label: 'Less Than Or Equal To', tag: 'normal' },
    { value: 'ne', label: 'Does Not Equal', tag: 'normal' },
    { value: 'ex', label: 'Exists', tag: 'custom' },
    { value: 'nx', label: 'Does Not Exist', tag: 'custom' },
];

const AddFieldRow = props => {
    const {
        idx,
        count,
        dbFields,
        setCount,
        filterOptions,
        setFilterOptions,
    } = props;
    const [fieldNames, setFieldNames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showFilterValue, setShowFilterValue] = useState(true);
    const [fieldName, setFieldName] = useState('');
    const [fieldOperator, setFieldOperator] = useState('eq');
    const [fieldValue, setFieldValue] = useState('');
    const [relatedOperators, setRelatedOperators] = useState(fieldOperators);

    useEffect(() => {
        if (dbFields && fieldNames.length === 0) {
            if (dbFields.length > 0) {
                const data = [];
                dbFields.sort((a, b) => a < b ? -1 : (a > b ? 1 : 0)).map(item => {
                    var fieldname = item.split('_').join(' ').replace(' id', '');
                    var label = `${fieldname[0].toUpperCase() + fieldname.substr(1)}`;
                    var tag = 'normal';
                    if (label.toLowerCase() === 'device description') { label = 'Dealsheet device'; }
                    else if (label.toLowerCase() === 'package category') { label = 'Category'; }
                    else if (label.toLowerCase() === 'dealsheet number') { label = 'Dealsheet no'; }
                    else if (label.toLowerCase() === 'contract term') { label = 'Term'; }
                    else if (label.toLowerCase() === 'package data') { label = 'Package Data (SMS,AirTime,Minutes & Data)'; tag = 'custom'; }
                    return data.push({ value: item, label, tag });
                });
                setFieldNames(data);
                setLoading(false);
            } else {
                if (loading) setLoading(false);
            }
        }
    }, []);

    const loadRelatedFieldOperators = () => {
        const data = [];
        {
            fieldOperators.map(
                fields => {
                    if (fields.tag === 'custom') {
                        data.push({ value: fields.value, label: fields.label, tag: fields.tag });
                    }
                }
            )
        }
        setRelatedOperators(data);
    }

    return (
        <Row>
            <Col xs={12} md={11}>
                <Row>
                    <Col xs={12} md={4}>
                        <div className="form-group">
                            <Select
                                name="field_name[]"
                                options={fieldNames}
                                isLoading={loading}
                                onChange={item => {
                                    setFieldName(item.value);
                                    var options = filterOptions;
                                    options[idx] = {};
                                    options[idx] = {
                                        field_name: item.value,
                                        field_operator: fieldOperator,
                                        field_value: fieldValue,
                                    };
                                    setFilterOptions(options);
                                    (item.tag === 'custom') ? loadRelatedFieldOperators() : setRelatedOperators(fieldOperators);
                                }}
                            />
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className="form-group">
                            <Select
                                name="field_operator[]"
                                options={relatedOperators}
                                isLoading={loading}
                                onChange={item => {
                                    var operator = null;
                                    if (item.value === 'ex') {
                                        operator = 'ne';
                                        setShowFilterValue(false);
                                    } else if (item.value === 'nx') {
                                        operator = 'eq';
                                        setShowFilterValue(false);
                                    } else {
                                        operator = item.value;
                                        setShowFilterValue(true);
                                    }
                                    setFieldOperator(item.value);
                                    var options = filterOptions;
                                    options[idx] = {};
                                    options[idx] = {
                                        field_name: fieldName,
                                        field_operator: operator,
                                        field_value: fieldValue,
                                    };
                                    setFilterOptions(options);
                                }}
                            />
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        {showFilterValue &&
                            <div className="form-group">
                                <input
                                    className="form-control"
                                    name="field_value[]"
                                    placeholder="Enter filter value..."
                                    onKeyUp={item => {
                                        setFieldValue(item.target.value);
                                        var options = filterOptions;
                                        options[idx] = {};
                                        options[idx] = {
                                            field_name: fieldName,
                                            field_operator: fieldOperator,
                                            field_value: item.target.value,
                                        };
                                        setFilterOptions(options);
                                    }}
                                />
                            </div>}
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={1} className="text-left">
                {count > 1 &&
                    <div className="form-group">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                if (filterOptions.length === count) {
                                    var options = filterOptions;
                                    options.pop();
                                    setFilterOptions(options);
                                }
                                setCount(count - 1);
                            }}
                        >
                            -
                        </button>
                    </div>}
            </Col>
        </Row>
    );
};

export default AddFieldRow;
