import React from 'react';
import moment from 'moment';

const ImportComplete = props => {
    const {
        dealType,
        networkName,
        validFromDate,
        validToDate,
    } = props;
    return (
        <div>
            <h3 id="wizard1-h-0" tabIndex="-1" className="title current">{networkName} Dealsheet Import Complete</h3>
            <p className="mg-b-30">
                You have successfully imported {networkName} {dealType} Dealsheet, valid from {moment(validFromDate).format('DD-MMM-YYYY')} to {moment(validToDate).format('DD-MMM-YYYY')}. See detailed import results below:
            </p>
        </div>
    );
};

export default ImportComplete;
