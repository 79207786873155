import React, { useState } from 'react';
import {
    Card,
    CardBody,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import { Layout } from 'containers';
import {
    Breadcrumb,
    FeatherIcon,
} from 'components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import UsersGQL from 'queries/users';

export default function EditUser() {
    const { id } = useParams();
    const { data, loading } = useQuery(UsersGQL.getUser, { variables: { id } });
    const [activeTab, setActiveTab] = useState('1');
    const breadcrumb = [
        { title: 'Users', link: '/users', active: false },
        { title: 'Update', active: true },
    ];

    const actionButtons = [{
        type: 'button-link',
        title: 'Add New User',
        classes: 'btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5',
        icon: <FeatherIcon icon="plus" classes="wd-10 mg-r-5" />,
        link: '/users/add'
    }];

    if (!loading) console.log(data.user);

    return (
        <Layout pageLoading={loading}>
            <div className="content content-fixed pd-b-0 manufacturers">
                <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <Breadcrumb
                        heading={data && data.user ? `${data.user.first_name} ${data.user.last_name}` : ''}
                        actions={actionButtons}
                        items={breadcrumb}
                    />
                </Container>
            </div>
            <div className="dealsheet-result-table-wrapper">
                <Card>
                    <CardBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={activeTab === '1' ? 'active' : ''}
                                    onClick={() => setActiveTab('1')}
                                >
                                    Information
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={activeTab === '2' ? 'active' : ''}
                                    onClick={() => setActiveTab('2')}
                                >
                                    Permissions
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className="bd bd-gray-300 bd-t-0 pd-20">
                            <TabPane tabId="1">
                                <h4>
                                    Information
                                </h4>
                            </TabPane>
                            <TabPane tabId="2">
                                <h4>
                                    Permissions
                                </h4>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </div>
        </Layout>
    );
}