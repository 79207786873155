import React, { useEffect, useState } from 'react';
import { FeatherIcon } from 'components';
import dealsheetImportData from 'static/dealsheet-import.json';
import utils from 'utils';

const { customMatchedField } = utils;
const { databaseFields } = dealsheetImportData;

const FieldMapping = props => {
    const {
        fields,
        fileData,
        setFields,
        setShowLoader,
        setDisableNextBtn,
        setShowRecordsToBeImported,
        setPageLoading,
        tableData,
        setTableData,
        settings,
        network,
    } = props;

    const [ requiredCount, setRequiredCount ] = useState(0);
    const [ requiredTotal, setRequiredTotal ] = useState(0);
    const [ mappedTotal, setMappedTotal ] = useState(0);
    const csvFields = fileData[0] || [];

    const handleFilter = item => {
        if (csvFields && Object.keys(csvFields).length > 0) {
            return Object.keys(csvFields).filter(field => {
                const key = field
                    .split('\n')
                    .join(' ')
                    .split(' - ')
                    .join(' ')
                    .split('-')
                    .join(' ')
                    .replace('  ',' ')
                    .split(' ')
                    .join('_')
                    .toLowerCase();
                if (item === 'dealsheet_number' && (key === 'dealsheet_no' || key === 'deal_id_reference')) {
                    return true;
                }
                if (item === 'valid_from' && (key === 'from_date' || key === 'comms_start_date')) {
                    return true;
                }
                if (item === 'valid_to' && (key === 'to_date' || key === 'comms_end_date')) {
                    return true;
                }
                if (item === 'contract_term' && key === 'contract_months') {
                    return true;
                }
                if (item === 'total_subscription' && key === 'total_subs') {
                    return true;
                }
                if (item === 'device_cost' && key === 'handset_cost') {
                    return true;
                }
                if (item === 'tariff_description' && key === 'tariff') {
                    return true;
                }
                if (item === 'package_code' && key === 'eppix_tariff') {
                    return true;
                }
                if (item === 'free_accessory' && key === 'freebies_description') {
                    return true;
                }
                if (item === 'freebie_line' && key === 'freebies_description') {
                    return true;
                }
                if (item === 'reduced_subscription' && key === 'reduced_subs') {
                    return true;
                }
                if (item === 'device_description' && (key === 'handset' || key === 'oem_and_device')) {
                    return true;
                }
                if (item === 'anytime_bundle' && (key === 'anytime_bundle' || key === 'anytime_minute_bundle')) {
                    return true;
                }
                return item.toLowerCase() === key;
            });
        }
    };
    
    const fetchData = () => {
        let reqCount = 0;
        let reqTotal = 0;
        let mapTotal = 0;
        const dataObj = [];
        const fields = {};

        setPageLoading(true);

        const dbFields = (settings && settings.fields && settings.fields[network.toLowerCase()])
            ? settings.fields[network.toLowerCase()]
            : databaseFields;

        dbFields.map(item => {
            const { name, required } = item;
            const found = handleFilter(name);
            const custom = customMatchedField(name, network);
            reqCount += (required && (found.length > 0 || custom)) ? 1 : 0;
            reqTotal += (required) ? 1 : 0;
            
            if (found.length > 0) {
                fields[item.name] = found[0];
                mapTotal++;
            } else mapTotal += custom ? 1 : 0;

            dataObj.push({
                name,
                required,
                found: found.length > 0,
                selected: (found.length > 0) ? found[0] : null,
                children: csvFields,
                checked: (found.length > 0),
                custom,
            });
        });

        setDisableNextBtn(reqCount !== reqTotal);
        setRequiredCount(reqCount);
        setRequiredTotal(reqTotal);
        setMappedTotal(mapTotal);
        setTableData(dataObj);
        setPageLoading(false);
        setFields(fields);
    };

    useEffect(() => {
        fetchData();
    }, [ ]);

    const handleSelectChange = (val, item) => {
        let reqCount = 0;
        let reqTotal = 0;
        let mapTotal = 0;
        const tData = [];
        const data = fields;
        data[item.name] = val;
        tableData.map(row => {
            const { name, required, found, selected, children, checked, custom } = row;
            reqTotal += (required) ? 1 : 0;
            if (name === item.name) {
                reqCount += (required && val !== 'None') ? 1 : 0;
                mapTotal += (val !== 'None') ? 1 : 0;
                return tData.push({
                    name,
                    required,
                    found: (val !== 'None'),
                    selected: (val !== 'None'),
                    children,
                    checked: (val !== 'None'),
                    custom,
                });
            }
            reqCount += (required && (found || custom)) ? 1 : 0;
            mapTotal += (found || custom) ? 1 : 0;
            return tData.push({
                name,
                required,
                found,
                selected,
                children,
                checked,
                custom,
            });
        });
        setTableData(tData);
        setMappedTotal(mapTotal);
        setDisableNextBtn(reqCount !== reqTotal);
        setRequiredCount(reqCount);
        setRequiredTotal(reqTotal);
        return setFields(data);
    };

    const handleCheckboxSelect = (item, status) => {
        const data = [];
        tableData.map(trow => {
            const { name, required, found, selected, children, checked, custom } = trow;
            if (item.name === name) {
                return data.push({
                    name,
                    required,
                    found,
                    selected,
                    children,
                    checked: status,
                    custom,
                });
            }
            return data.push({
                name,
                required,
                found,
                selected,
                children,
                checked,
                custom,
            });
        });
        setTableData(data);
    };

    return (
        <div>
            <h3 id="wizard1-h-0" tabIndex="-1" className="title current">Data Configuration</h3>
            <p className="mg-b-10">
                Use the wizard below to map the fields in your file to fields in the database.
                ( <span className="text-danger">*</span> ) marks all required fields.
            </p>
            {(requiredTotal > 0) &&
            <div className={`alert alert-${(requiredCount !== requiredTotal) ? 'warning' : 'success'} mg-b-20`}>
                {requiredCount} out of {requiredTotal} required fields mapped, and a total of {mappedTotal} mapped fields.
                If you wish to view deals to be imported&nbsp;
                <a
                    href=" "
                    onClick={e => {
                        e.preventDefault();
                        setShowLoader(true);
                        setShowRecordsToBeImported(true);
                    }}
                >
                    click here
                </a>
            </div>}
            <div className="table-responsive">
                <table className="table table-dashboard mg-b-0">
                    <thead>
                        <tr>
                            <th width="30">&nbsp;</th>
                            <th width="20%" style={{ width: '20%' }}>Database Field Name</th>
                            <th>Spreadsheet Column</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData && tableData.map(item => (
                            <tr key={item.name}>
                                <td width="30">
                                    <input
                                        type="checkbox"
                                        onChange={e => handleCheckboxSelect(item, e.target.checked)}
                                        checked={item.custom || item.checked}
                                        disabled={item.custom || item.required}
                                    />
                                </td>
                                <td
                                    width="20%"
                                    style={{ width: '20%' }}
                                >
                                    <span>
                                        {item.name}
                                    </span>
                                    {item.required ? <span className="text-danger">*</span> : ''}
                                </td>
                                <td>
                                    <select
                                        name={item.name}
                                        defaultValue={item.custom ? 'custom' : item.selected}
                                        onChange={e => handleSelectChange(e.target.value, item)}
                                        disabled={item.custom}
                                    >
                                        <option value={null}>None</option>
                                        {csvFields && Object.keys(csvFields).map(column => (
                                            <option key={column.toString()} value={column}>
                                                {column}
                                            </option>
                                        ))}
                                        <option value="custom">Custom</option>
                                    </select>
                                    {(item.found || item.custom) &&
                                    <span className="mg-l-5 text-success">
                                        <FeatherIcon icon="check" width="16" height="16" />
                                    </span>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FieldMapping;
