import React from 'react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { FeatherIcon } from 'components';
import FileCard from './FileCard';

const ImageManager = props => {
    const {
        images,
        setShowUploadModal,
    } = props;

    return (
        <div>
            <Row>
                {images && images.length > 0 && images.map((image, idx) => {
                    if (image.Size && image.Size > 0) {
                        return (
                            <Col xs={6} sm={4} md={3} className="mg-b-10" key={idx.toString()}>
                                <FileCard
                                    {...image}
                                    {...props}
                                />
                            </Col>
                        );
                    } else return (<div key={idx.toString()}></div>);
                })}
                <Col xs={6} sm={4} md={3} className="mg-b-10">
                    <Card
                        className="card-file"
                        onClick={() => setShowUploadModal(true)}
                    >
                        <div className="card-file-thumb tx-gray-600">
                            <FeatherIcon icon="plus" width="60" height="60" />
                        </div>
                        <CardBody>
                            <h6>
                                <a
                                    href="/"
                                    className="link-02"
                                    onClick={e => {
                                        e.preventDefault();
                                    }}
                                >
                                    Add Image
                                </a>
                            </h6>
                            <span>Click to upload new image</span>
                        </CardBody>
                        <CardFooter>
                            Upload image to AWS S3 Bucket
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ImageManager;
