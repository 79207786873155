import React from 'react';
import Role from './ContentRole';
import User from './ContentUser';

export default function Content(props) {
    const { type } = props;

    if (type === 'role') {
        return <Role {...props} />;
    }

    if (type === 'user') {
        return <User {...props} />;
    }

    return <div />;
}
