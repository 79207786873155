import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import { Form, Pagination } from 'components';
import { BulkActionsProvider, DealsheetsProvider } from 'providers';

const getRowId = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .join('')
        .split('"')
        .join('');
};

const ExistingPackage = props => {
    const {
        setProcessing,
        package_code,
        packages,
        processing,
        activePackage,
        updatedPackages,
        setActivePackage,
        tariff_description,
        setSelectedPackages,
        selectedPackages,
        setUpload,
        reference,
    } = props;
    const { handleSubmit } = useForm();
    const rowId = getRowId(package_code);
    const [packageId, setPackageId] = useState(null);
    const [rowDisabled, setRowDisabled] = useState(false);
    const [existingPackages, setExistingPackages] = useState([]);
    const [packageSelected, setPackageSelected] = useState(false);

    useEffect(() => {
        const existingPackages = packages.map(item => ({
            ...item,
            label: `${item.label} (${item.code})`,
        }));
        setExistingPackages(existingPackages);
    }, []);

    const onSubmit = async () => {
        // validate if matching package has been selected
        if (!packageId) {
            return alert(`Please select package for "${tariff_description} (${package_code})"`);
        }
        const data = {
            update: { package_id: packageId },
            where: { package_code, reference }
        };
        setProcessing(true);
        setActivePackage(package_code);
        const result = await DealsheetsProvider.updateMultiple(data);
        const { status } = result;
        if (status && status === 'success') {
            updatedPackages.push(package_code);
            // update import report data
            const response = await BulkActionsProvider.update('upload_report', { reference });
            if (response.data) setUpload(response.data);
        } else alert('Error has occurred, please try again');
        setActivePackage(null);
        setProcessing(false);
    };

    const getButtonText = () => {
        if (processing && activePackage === package_code)
            return 'SAVING...';
        if (updatedPackages.includes(package_code))
            return 'UPDATE';
        return 'SAVE';
    };

    const getButtonClass = () => {
        if (rowDisabled || processing || !packageSelected)
            return 'btn btn-block btn-disabled';
        if (updatedPackages.includes(package_code))
            return 'btn btn-block btn-success';
        return 'btn btn-block btn-warning text-white';
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {existingPackages.length === 0 ? (
                <div className="my-5 text-center">
                    <p>Loading...</p>
                </div>
            ) : (
                <Row className="existing-info-wrapper">
                    <Col xs={5}>
                        <Select
                            options={existingPackages}
                            placeholder="Select an existing package"
                            value={selectedPackages[rowId] || null}
                            onChange={item => {
                                setPackageId(item.value);
                                setPackageSelected(true);
                                const selected = selectedPackages;
                                selected[rowId] = item;
                                setSelectedPackages(selected);
                            }}
                            isDisabled={rowDisabled || processing}
                        />
                    </Col>
                    <Col xs={5}>
                        <div className="pd-t-10 pd-l-20">
                            <Form.Checkbox
                                id={rowId}
                                name={rowId}
                                label="Ignore this Package"
                                onChange={e => setRowDisabled(e.target.checked)}
                                disabled={processing}
                            />
                        </div>
                    </Col>
                    <Col xs={2}>
                        <Form.Button
                            type="submit"
                            classes={getButtonClass()}
                            text={getButtonText()}
                            disabled={rowDisabled || processing || !packageSelected}
                        />
                    </Col>
                </Row>
            )}
        </form>
    );
};

const TableRow = props => {
    const { package_code, tariff_description } = props;
    return (
        <div className="table-row">
            <Row>
                <Col md={4}>
                    <div className="mg-t-10 pd-l-10">
                        {tariff_description}
                    </div>
                </Col>
                <Col md={2}>
                    <div className="mg-t-10">
                        {package_code}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="pd-r-10">
                        <ExistingPackage {...props} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default function UnmatchedPackagesList(props) {
    const { packagesReport } = props;
    const [selectedPackages, setSelectedPackages] = useState({});
    const [displayData, setDisplayData] = useState([]);
    const [perPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        const pageCount = Math.ceil(packagesReport.length / perPage);
        setPageCount(pageCount);
        setDisplayData(packagesReport);
    }, [ ]);

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPage;
        setOffset(offset);
        setCurrentPage(selectedPage);
        const slice = displayData.slice(
            offset,
            offset + perPage
        );
        const reportData = slice.map(item => item);
        console.log(reportData)
        setDisplayData(reportData);
    };

    return (
        <div className="matching-info-wrapper">
            <Row className="heading">
                <Col md={4}>
                    Unmatched Package Name
                    <hr className="mg-t-5" />
                </Col>
                <Col md={2}>
                    Package Code
                    <hr className="mg-t-5" />
                </Col>
                <Col md={6}>
                    Existing Package
                    <hr className="mg-t-5" />
                </Col>
            </Row>
            {displayData.map((item, index) => (
                <TableRow
                    key={index.toString()}
                    setSelectedPackages={setSelectedPackages}
                    selectedPackages={selectedPackages}
                    {...props}
                    {...item}
                />
            ))}
            <Pagination
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
            />
        </div>
    );
};
