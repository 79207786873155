import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import { DevicesProvider } from 'providers';
import {
  Breadcrumb,
  Devices,
  FeatherIcon,
  Form,
  Loading,
} from 'components';
import Swal from 'sweetalert2';

const ModelItem = (props) => {
  const { manufacturer, name, handleSelect } = props;
  return (
    <a
      role="button"
      href="/"
      className="option-link"
      onClick={e => {
        e.preventDefault();
        return handleSelect(props);
      }}
    >
      {manufacturer.name} {name}
    </a>
  );
};

export default function AddDevice(props) {
  const breadcrumb = [
    { title: 'Devices', link: '/devices', active: false },
    { title: 'Add Device', active: true },
  ];
  const { isModal, showModal, deviceModel } = props;
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    trigger,
    reset,
  } = useForm();
  const [loadingText, setLoadingText] = useState('Loading');
  const [processing, setProcessing] = useState(false);
  const [model, setModel] = useState('');
  const [title, setTitle] = useState('');
  const [selectedModel, setSelectedModel] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [productCode, setProductCode] = useState('');
  const [storage, setStorage] = useState('');
  const [color, setColor] = useState('');

  async function fetchData() {
    setProcessing(false);
  }

  useEffect(() => {
    if (deviceModel) {
      console.log(deviceModel);
      // handleSelect(deviceModel);
    }
    fetchData();
  }, []);

  const onSubmit = data => {
    handleSaveDevice(data);
  };

  const generatePermakey = title => {
    let permakey = '';
    if (title.indexOf('(') !== -1) {
      permakey = title
        .split('(')
        .join('')
        .split(')')
        .join('')
        .replace(' + ', ' and ')
        .split('_')
        .join('-')
        .split(' ')
        .join('-')
        .toLowerCase();
      return permakey;
    }
    permakey = title
      .replace(' + ', ' and ')
      .split('_')
      .join('-')
      .split(' ')
      .join('-')
      .toLowerCase();
    return permakey;
  };

  const handleSaveDevice = async (data) => {
    setLoadingText('Saving');
    setProcessing(true);
    
    const { product_code, variants } = data;
    const { color, storage } = variants;
    const {
      id,
      name,
      image,
      features,
      device_type_id,
      manufacturer_id,
      manufacturer_name,
    } = selectedModel;
    const post = {
      title,
      product_code,
      device_type_id,
      permakey: generatePermakey(title),
      features: features ? { ...features, title } : {},
      manufacturer_id: manufacturer_id,
      manufacturer_name: manufacturer_name,
      images: image ? [image] : null,
      image_default: image || null,
      model_id: id,
      model: name,
      variants,
    };
    
    // validate product code
    const validProductCode = await handleValidate('product_code', product_code);

    if (!validProductCode) {
      setProcessing(false);
      return Swal.fire({
        position: 'center',
        icon: 'error',
        title: `Validation Error! Product code "${product_code}" already exists.`,
        showConfirmButton: false,
        timer: 5000
      });
    }

    // validate generated permakey
    const validPermakey = await handleValidate('permakey', post.permakey);

    if (!validPermakey) {
      setProcessing(false);
      return Swal.fire({
        position: 'center',
        icon: 'error',
        title: `Validation Error! Device permakey "${post.permakey}" already exists.`,
        showConfirmButton: false,
        timer: 5000
      });
    }

    if (!color || !storage || color.length === 0 || storage.length === 0) {
      setProcessing(false);
      return Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Please specify device variants (Color and Storage)!',
        showConfirmButton: false,
        timer: 5000
      });
    }

    const result = await DevicesProvider.create(post);
    setProcessing(false);
    if (result.status === 'success') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Device successfully created!',
        showConfirmButton: false,
        timer: 5000
      });
      return setTimeout(async function () {
        window.location = '/devices';
      }, 5000);
    }

    setLoadingText(null);

    // error occured
    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: result.message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 5000
    });
  };

  const handleValidate = async (key, value) => {
    const data = await DevicesProvider.getAll(0, 1, `&${key}=${value}`);
    if (data) {
      if (data.results) {
        return data.results.length === 0;
      }
      return false;
    }
    return true;
  };

  const handleSelect = item => {
    const name = `${item.manufacturer_name} ${item.name}`;
    let newTitle = name;
    newTitle += storage ? ` ${storage}` : '';
    newTitle += color ? ` ${color}` : '';

    resetForm();
    setSelectedModel(item);
    setModel(name);
    setTitle(newTitle);
    setProductCode(newTitle.split(' ').join('-').toUpperCase());
    return setSearchResults([]);
  };

  const handleSearchModel = async e => {
    setSearchResults([]);
    setModel(e.target.value);
    if (e.target.value.length > 2) {
      const data = await DevicesProvider.searchModels('model_name', e.target.value, 0, 50);
      const { results } = data;
      const object = [];
      if (results && results.length > 0) {
        results.map(item => object.push(<ModelItem {...item} handleSelect={handleSelect} />));
      }
      setSearchResults(object);
    }
  };

  const resetForm = () => {
    reset();
  };

  const handleProductCodeChange = (value) => {
    setProductCode(value);
    setValue('product_code', value);
    return trigger('product_code');
  };

  return (
    <Layout pageLoading={processing}>
      <div className="content content-fixed pd-b-0">
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <Breadcrumb
            heading="Add New Device Variant"
            items={breadcrumb}
          />
        </Container>
      </div>
      <Container fluid style={{ backgroundColor: 'white' }}>
        <div style={{ position: 'relative', padding: '30px 0' }}>
          {processing && <Loading text={loadingText} />}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex align-items-center justify-content-between mg-b-25">
              <h4 className="mg-b-0 text-navy font-weight-600">{props.title || ''}</h4>
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-outline-primary d-flex align-items-center mg-r-5"
                  type="submit"
                >
                  <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                  <span className="d-none d-sm-inline mg-l-5"> Save</span>
                </button>
                <button
                  className="btn btn-sm btn-white d-flex align-items-center"
                  onClick={e => {
                    e.preventDefault();
                    if (isModal && typeof showModal === 'function') {
                      showModal(false);
                      return false;
                    }
                    return window.location = '/devices';
                  }}
                >
                  <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                  <span className="d-none d-sm-inline mg-l-5"> Cancel</span>
                </button>
              </div>
            </div>
            <div data-label="Device Information" className="legend">
              <Row>
                <Col xs={12} sm={4}>
                  <div className={`form-group ${!model ? 'is-invalid' : ''}`}>
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      Base Model
                    </label>
                    <Form.AutocompleteInput
                      value={model}
                      setValue={setModel}
                      placeholder="Find and Select Device Base Model..."
                      classes="form-group"
                      handleValueChange={handleSearchModel}
                      setProcessing={setProcessing}
                      searchResults={searchResults}
                      secondaryButton={
                        <Form.Button
                          text=""
                          buttonLink
                          buttonLinkHref="/devices/models/add"
                          classes="btn btn-outline-light"
                          icon={<FeatherIcon icon="plus" />}
                        />
                      }
                    />
                  </div>
                </Col>
                <Col xs={12} sm={4}>
                  <div className={`form-group ${errors.title ? 'is-invalid' : ''}`}>
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      Title
                    </label>
                    <input
                      name="title"
                      type="text"
                      className={`form-control ${errors.title ? 'parsley-error' : ''}`}
                      {...register('title', { required: true })}
                      value={title || ''}
                      onChange={e => {
                        setTitle(e.target.value);
                        setProductCode(e.target.value.split(' ').join('-').toUpperCase());
                      }}
                    />
                  </div>
                </Col>
                <Col xs={12} sm={4}>
                  <div className={`form-group ${errors.product_code ? 'is-invalid' : ''}`}>
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      Product code
                    </label>
                    <input
                      name="product_code"
                      type="text"
                      className={`form-control ${errors.product_code ? 'parsley-error' : ''}`}
                      {...register('product_code', {
                        required: true,
                      })}
                      onChange={e => handleProductCodeChange(e.target.value)}
                      value={productCode}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {selectedModel && (
              <Devices.Variants
                {...selectedModel}
                title={title}
                setTitle={setTitle}
                setProductCode={handleProductCodeChange}
                setSelectedStorage={setStorage}
                setSelectedColor={setColor}
                selectedStorage={storage}
                selectedColor={color}
                register={register}
                errors={errors}
                newDevice
              />
            )}
          </form>
        </div>
      </Container>
    </Layout>
  );
}
