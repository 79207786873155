import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import UpdateSaleIndicators from './UpdateSaleIndicators';
import UpdateSpecialText from './UpdateSpecialText';
import UpdateDevices from './UpdateDevices';

const DealsheetBulkAction = props => {
    return (
        <div className="bulk-action-wrapper">
            <Card>
                <CardBody>
                    <Row>
                        <Col xs={12} sm={6} lg={3}>
                            <UpdateSaleIndicators {...props} />
                        </Col>
                        <Col xs={12} sm={6} lg={5}>
                            <UpdateSpecialText {...props} />
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                            <UpdateDevices {...props} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

export default DealsheetBulkAction;
