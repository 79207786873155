import React from 'react';
import Paginate from 'react-paginate';
import { Col, Row } from 'reactstrap';

export default function Pagination(props) {
    return (
        <div className="mg-t-20">
            <hr />
            <Row>
                <Col xs={6}>&nbsp;</Col>
                <Col xs={6}>
                    <nav aria-label="Page navigation example">
                        <Paginate
                            {...props}
                            // pageCount={1}
                            // pageRangeDisplayed={1}
                            // marginPagesDisplayed={1}
                            nextClassName="page-item"
                            nextLinkClassName="page-link page-link-icon"
                            containerClassName="pagination mg-b-0 justify-content-end"
                            disabledClassName="disabled"
                            previousLinkClassName="page-link page-link-icon"
                            previousClassName="page-item"
                            pageLinkClassName="page-link"
                            pageClassName="page-item"
                            activeClassName="active"
                            previousLabel={<i className="fa fa-chevron-left" />}
                            nextLabel={<i className="fa fa-chevron-right" />}
                        />
                    </nav>
                </Col>
            </Row>
        </div>
    );
}