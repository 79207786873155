import axios from 'axios';
import config from '../config';
import Session from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
let headers   = {};

if (Session.getToken()) {
  headers = {
    'Authorization': `Bearer ${Session.getToken()}`,
  }
}

class BulkActionsProvider {
  static async create(type, data, queryParam = '') {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}bulk/save/${type + queryParam}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async extract(type, data = null) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}bulk/extract/${type}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async map(type, data = null) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}bulk/map/${type}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async update(type, data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}bulk/update/${type}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }
}

export default BulkActionsProvider;
