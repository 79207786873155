import React, { useState } from 'react';
import { FeatherIcon, Modals } from 'components';
import { PackagesProvider } from 'providers';
import { confirmAlert } from 'react-confirm-alert';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import 'react-confirm-alert/src/react-confirm-alert.css';

const RowActions = props => {
    const {
        id,
        active,
        description,
        setSelected,
        handleDelete,
        setShowUpdateModal,
    } = props;
    return (
        <div>
            <a
                href={`/packages/types/delete/${id}`}
                onClick={(e) => {
                    e.preventDefault();
                    setSelected({
                        id,
                        active,
                        description,
                    });
                    setShowUpdateModal(true);
                }}
            >
                <FeatherIcon icon="edit" width="16" height="16" />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
                href={`/packages/types/delete/${id}`}
                className="tx-danger"
                onClick={(e) => {
                    e.preventDefault();
                    handleDelete({ id, description });
                }}
            >
                <FeatherIcon icon="trash-2" width="16" height="16" />
            </a>
        </div>
    );
};

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

export default function PackageTypes(props) {
    const { data, fetchData, setPageLoading } = props;
    const [selected, setSelected] = useState({});
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const columns = [{
        name: 'Description',
        selector: row => row['description'],
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row['active'],
        sortable: true,
        cell: row => (
            <span>
                {row.active ? 
                    <span className="text-success">Active</span> :
                    <span className="text-danger">Inactive</span>
                }
            </span>
        )
    }, {
        name: 'Actions',
        sortable: false,
        width: '100px',
        cell: row => (
            <RowActions
                {...row}
                setSelected={setSelected}
                handleDelete={handleDelete}
                setShowUpdateModal={setShowUpdateModal}
            />
        )
    }];

    async function handleDelete(data) {
        const { id, description } = data;
        confirmAlert({
            title: 'Confirm Delete',
            message: `Are you sure you want to delete package type: ${description}?`,
            buttons: [
                {
                    label: 'Yes, continue',
                    onClick: async () => {
                        setPageLoading(true);
                        const response = await PackagesProvider.deleteType(id);
                        const { status } = response;
                        setPageLoading(false);
                        if (status && status === 'success') {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Request processed successfully!',
                                showConfirmButton: false,
                                timer: 4000
                            });
                            return setTimeout(async function() {
                                fetchData();
                            }, 4000);
                        }
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Failed to process request, please try again!',
                            showConfirmButton: false,
                            timer: 4000
                        });
                    }
                },
                {
                    label: 'Cancel',
                }
            ]
        });
    }

    return (
        <>
            <Modals.UpdatePackageType
                {...selected}
                fetchData={fetchData}
                show={showUpdateModal}
                setShow={setShowUpdateModal}
            />
            <DataTable
                data={data}
                columns={columns}
                customStyles={customStyles}
                highlightOnHover
                pagination
                noHeader
            />
        </>
    );
}