import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { DealsheetsProvider } from 'providers';
import FieldRows from './FieldRows';

const DealsheetFilter = (props) => {
    const {
        fetchData,
        filterOptions,
        setFilterOptions,
        setDealsheets,
        setResultsLoading,
        perPage,
        setTotalRecords,
        setHasMatchedPackage,
        setHasMatchedDevice,
        hasMatchedPackage,
        hasMatchedDevice,
        setAllDeals,
        allDeals,
        sorting,
    } = props;
    const [rowCount, setRowCount] = useState(1);
    const [collapsed, setCollapsed] = useState(false);
    const [processing, setProcessing] = useState(false);
    const toggleCollapsed = () => setCollapsed(!collapsed);

    async function handleSubmit(e) {
        e.preventDefault();
        if (filterOptions.length > 0) {
            setProcessing(true);
            const filters = [];
            filterOptions.map(item => {
                if (item.field_name.length > 0 && item.field_operator.length > 0) {
                    if (item.field_operator === 'nx') {
                        return filters.push([item.field_name, 'eq', null]);
                    }
                    if (item.field_operator === 'ex') {
                        return filters.push([item.field_name, 'ne', null]);
                    }
                    return filters.push([item.field_name, item.field_operator, item.field_value]);
                }
                return item;
            });

            if (filters.length > 0) {
                setResultsLoading(true);
                const offset = 0;
                const limit = perPage;
                const querystr = JSON.stringify(filters);
                let orderby = `&orderby=[["${sorting.column}", "${sorting.direction}"]]`;

                if (allDeals) {
                    orderby += `&all_deals=${allDeals}`;
                } else {
                    orderby += `&has_matched_device=${hasMatchedDevice}&has_matched_package=${hasMatchedPackage}`;
                }

                const data = await DealsheetsProvider.filter(querystr + orderby, offset, limit);
                if (data) {
                    setDealsheets(data.results);
                    setTotalRecords(data.count);
                } else alert('Error');
                setResultsLoading(false);
            } else alert('Incomplete filter options, please complete selection!');
            setProcessing(false);
        } else {
            // alert('Please select filter options!');
            fetchData();
        };
    };

    return (
        <div className="filter-wrapper">
            <button
                className={`btn btn-primary btn-block rounded-0 btn-filter-heading ${collapsed ? 'collapsed' : ''}`}
                data-toggle="collapse"
                type="button"
                aria-expanded="false"
                aria-controls="collapseFilters"
                onClick={toggleCollapsed}
            >
                Filter Options
            </button>
            <div className={`collapse mg-t-0 ${collapsed ? '' : 'show'}`} id="collapseFilters">
                <Card>
                    <CardBody>
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col xs={12} md={11}>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <div className="form-group mg-b-0">
                                                <label>Select field...</label>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <div className="form-group mg-b-0">
                                                <label>Select operator...</label>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            {/* <div className="form-group mg-b-0">
                                                <label>Enter filter value...</label>
                                            </div> */}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <FieldRows
                                filterOptions={filterOptions}
                                setFilterOptions={setFilterOptions}
                                count={rowCount}
                                setCount={setRowCount}
                                {...props}
                            />
                            <Row>
                                <Col xs={12} md={5}>
                                    <div className="d-flex mt-2">
                                        <div className="custom-control custom-switch mr-4">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="hasMatchedPackage"
                                                checked={hasMatchedPackage}
                                                onChange={(e) => {
                                                    setAllDeals(false);
                                                    setHasMatchedPackage(e.target.checked);
                                                }}
                                                disabled={processing}
                                            />
                                            <label className="custom-control-label" htmlFor="hasMatchedPackage">Deal has matched package?</label>
                                        </div>
                                        <div className="custom-control custom-switch mr-4">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="hasMatchedDevice"
                                                checked={hasMatchedDevice}
                                                onChange={(e) => {
                                                    setAllDeals(false);
                                                    setHasMatchedDevice(e.target.checked);
                                                }}
                                                disabled={processing}
                                            />
                                            <label className="custom-control-label" htmlFor="hasMatchedDevice">Deal has matched device?</label>
                                        </div>
                                        <div className="custom-control custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="all"
                                                checked={allDeals}
                                                onChange={(e) => {
                                                    setHasMatchedDevice(false);
                                                    setHasMatchedPackage(false);
                                                    setAllDeals(e.target.checked);
                                                }}
                                                disabled={processing}
                                            />
                                            <label className="custom-control-label" htmlFor="all">All</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} md={2}>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                        disabled={processing}
                                    >
                                        {processing ? 'Processing...' : 'Apply Filters'}
                                    </button>
                                </Col>
                                <Col xs={6} md={2}>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block"
                                        disabled={processing}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location = '/';
                                        }}
                                    >
                                        Reset Filters
                                    </button>
                                </Col>
                                <Col xs={12} md={3}>
                                    <div className="mg-t-10">
                                        {rowCount < 5 &&
                                            <a
                                                role="button"
                                                href="/"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setRowCount(rowCount + 1);
                                                }}
                                            >
                                                <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus">
                                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                                </svg>
                                                &nbsp;Add Filter
                                            </a>}
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default DealsheetFilter;
