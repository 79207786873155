import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Container } from 'reactstrap';
import { Layout } from 'containers';
import { useQuery } from '@apollo/client';
import {
    DealsheetsProvider,
    SaleIndicatorsProvider,
} from 'providers';
import {
    Breadcrumb,
    DealsheetBulkAction,
    DealsheetFilter,
    DealsheetResult,
    EditSpecialTextModal,
    FeatherIcon,
    ResultsModal,
} from 'components';
import Swal from 'sweetalert2';
import DealsGQL from 'queries/deals';
import config from '../../config';

const excludedColumns = [
    'id',
    'price_plan',
    'pay_in',
    'eppix_package',
    'eppix_tariff',
    'created',
    'updated',
    'last_updated',
    'status',
    'archived',
    'promo_deal',
    'promo_id',
    'promo_offer_id',
    'accessories',
    'package_description',
    'freebie_line',
    'reduced_subscription',
    'tariff_subscription',
    'device',
    'device_id',
    'package_id',
    'sale_indicator_id',
    'provider_id',
    'on_net_minute_bundle',
    'anytime_bundle',
    'sms_bundle',
    'data_bundle',
    'bundle_description',
    'description',
    'package_title',
];

const actionButtons = [{
    type: 'button-link',
    link: '/dealsheets/import',
    title: 'Import Dealsheet',
    classes: 'btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5',
    icon: <FeatherIcon icon="download" classes="wd-10 mg-r-5" />,
}, {
    type: 'button-link',
    link: '/deals/add',
    title: 'Add Deal',
    classes: 'btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-10',
    icon: <FeatherIcon icon="plus" classes="wd-10 mg-r-5" />,
}];

const Dealsheets = (props) => {
    const { data, loading } = useQuery(DealsGQL.list);
    const [dbFields, setDbFields] = useState([]);
    const [indicators, setIndicators] = useState([]);
    const [filterOptions, setFilterOptions] = useState([]);
    const [deals, setDeals] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loaderText, setLoaderText] = useState('Loading');
    const [showEditSpecialTextModal, setShowEditSpecialTextModal] = useState(false);
    const [hasMatchedPackage, setHasMatchedPackage] = useState(false);
    const [hasMatchedDevice, setHasMatchedDevice] = useState(false);
    const [allDeals, setAllDeals] = useState(true);
    const [showResultsModal, setShowResultsModal] = useState(false);
    const [resultsLoading, setResultsLoading] = useState(true);
    const [allSelected, setAllSelected] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(config.results.pagination.perpage);
    const [sorting, setSorting] = useState(config.results.sorting.dealsheets);
    const [totalRecords, setTotalRecords] = useState(0);

    function getDatabaseFields(data) {
        if (data) {
            if (data.length > 0) {
                if (data.length > 0) {
                    const cols = Object.keys(data[0]);
                    const fields = cols.filter(item => {
                        return excludedColumns.indexOf(item) === -1;
                    });
                    fields.push('matched_device');
                    fields.push('package_category');
                    fields.push('package_data');
                    setDbFields(fields);
                }
            }
        }
    };

    const fetchData = async () => {
        if (!resultsLoading) setResultsLoading(true);
        const offset = 0;
        const limit = perPage;
        let orderby = `&orderby=[["${sorting.column}", "${sorting.direction}"]]`;

        if (allDeals) {
            orderby += `&all_deals=${allDeals}`;
        } else {
            orderby += `&has_matched_device=${hasMatchedDevice}&has_matched_package=${hasMatchedPackage}`;
        }

        const dealsheets = await DealsheetsProvider.getAll(offset, limit, orderby);
        if (dealsheets && dealsheets.results) {
            getDatabaseFields(dealsheets.results);
            // setDealsheets(dealsheets.results);
            setTotalRecords(dealsheets.count);
        }

        const indicators = [];
        const data = await SaleIndicatorsProvider.getAll();
        if (data.results && data.results.length > 0) {
            await data.results.map((indicator) => indicators.push({ value: indicator.id, label: indicator.description }));
            setIndicators(indicators);
        }

        setResultsLoading(false);
    };

    useEffect(() => {
        if (data) {
            // setDeals(data.deals || []);

            if (data.saleIndicators) {
                const indicators = [];
                data.saleIndicators.map(indicator => indicators.push({
                    value: indicator.id,
                    label: indicator.description,
                }));
                console.log(indicators)
                setIndicators(indicators);
            }
        }
        return () => { };
    }, [data, loading]);

    const handleDeleteDeal = ({ id, dealsheet_number }) => {
        return confirmAlert({
            title: 'Confirm Delete!',
            message: `Are you sure to delete deal (${dealsheet_number})? This process cannot be undone.`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setResultsLoading(true);
                        setLoaderText('Deleting deal');
                        const result = await DealsheetsProvider.delete(id);
                        const { status, message } = result;

                        setResultsLoading(false);
                        setLoaderText('Loading');

                        if (status === 'success') {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Deal deleted successfully!',
                                showConfirmButton: false,
                                timer: 5000
                            });
                            return setTimeout(async function () {
                                window.location = '/deals';
                            }, 5000);
                        } else {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: message || 'Failed to process request, please try again!',
                                showConfirmButton: false,
                                timer: 5000
                            });
                        }
                        return true;
                    },
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    return (
        <Layout
            pageLoading={loading}
            loaderText={loaderText}
        >
            <div className="content content-fixed pd-b-0">
                <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <Breadcrumb actions={actionButtons} />
                </Container>
            </div>
            <ResultsModal
                title="Deals Updated"
                body={<p><strong>{selectedRows.length}</strong> deal(s) have been updated with the selected sale indicator.</p>}
                show={showResultsModal}
                setShow={setShowResultsModal}
                buttonText="Back to Manage Deals"
            />
            <EditSpecialTextModal
                setShowResultsModal={setShowResultsModal}
                setShow={setShowEditSpecialTextModal}
                show={showEditSpecialTextModal}
                data={selectedRows}
                bulkEdit
            />
            {dbFields.length > 0 && (
                <DealsheetFilter
                    perPage={perPage}
                    sorting={sorting}
                    dealsheets={deals}
                    allDeals={allDeals}
                    dbFields={dbFields}
                    fetchData={fetchData}
                    setDealsheets={setDeals}
                    setDbFields={setDbFields}
                    setAllDeals={setAllDeals}
                    filterOptions={filterOptions}
                    resultsLoading={resultsLoading}
                    setTotalRecords={setTotalRecords}
                    setFilterOptions={setFilterOptions}
                    hasMatchedDevice={hasMatchedDevice}
                    hasMatchedPackage={hasMatchedPackage}
                    setResultsLoading={setResultsLoading}
                    setHasMatchedDevice={setHasMatchedDevice}
                    setHasMatchedPackage={setHasMatchedPackage}
                    {...props}
                />
            )}
            <DealsheetBulkAction
                setShowEditSpecialTextModal={setShowEditSpecialTextModal}
                filterOptions={filterOptions}
                selectedRows={selectedRows}
                allSelected={allSelected}
                indicators={indicators}
            />
            <DealsheetResult
                page={page}
                perPage={perPage}
                dealsheets={deals}
                setPerPage={setPerPage}
                setDealsheets={setDeals}
                selectedRows={selectedRows}
                filterOptions={filterOptions}
                resultsLoading={resultsLoading}
                setSelectedRows={setSelectedRows}
                handleDeleteDeal={handleDeleteDeal}
                setResultsLoading={setResultsLoading}
                totalRecords={totalRecords}
                setTotalRecords={setTotalRecords}
                allSelected={allSelected}
                setAllSelected={setAllSelected}
                sorting={sorting}
                setSorting={setSorting}
                indicators={indicators}
                setPage={setPage}
            />
        </Layout>
    );
};

export default Dealsheets;
