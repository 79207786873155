import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import UsersGQL from 'queries/users';
import RoleCard from './RoleCard';

export default function RoleList(props) {
    const { data, loading } = useQuery(UsersGQL.listRoles);
    const [active, setActive] = useState();
    return (
        <div className="pd-y-20 pd-x-10 contact-list">
            <label id="contactA" className="contact-list-divider">
                User Roles
            </label>
            {loading && <div className="loading" />}
            {data && data.userRoles && data && data.userRoles.map((item) => (
                <RoleCard
                    key={item.id}
                    active={active}
                    setActive={setActive}
                    {...props}
                    {...item}
                />
            ))}
        </div>
    );
}
