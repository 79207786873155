import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { FeatherIcon, Form } from 'components';
import { DevicesProvider } from 'providers';

const PhoneVariants = props => {
    const {
        title,
        errors,
        register,
        variants,
        newDevice,
        setTitle,
        setProductCode,
        selectedColor,
        selectedStorage,
        setSelectedColor,
        setSelectedStorage,
    } = props;
    const camera = newDevice ? '' : variants.camera;
    const color = newDevice ? '' : variants.color;
    const storage = newDevice ? '' : variants.storage;

    const [colorOptions, setColorOptions] = useState([]);
    const [cameraOptions, setCameraOptions] = useState([]);
    const [storageOptions, setStorageOptions] = useState([]);

    async function fetchData() {
        const attributes = await DevicesProvider.attributes();
        if (attributes.results && attributes.results.length > 0) {
            const camOpts = [];
            const colOpts = [];
            const stoOpts = [];
            attributes.results.map(item => {
                const { group_name, subcategory, value } = item;
                switch (subcategory) {
                    case 'camera':
                        camOpts.push({ value, label: value });
                        break;

                    case 'storage':
                        stoOpts.push({ value, label: value });
                        break;

                    case 'color':
                    case 'colors':
                        value.split(',').map(i => colOpts.push({ value: i, label: `${i} (${group_name})` }));
                        break;

                    default: break;
                }
            });
            setColorOptions(colOpts);
            setCameraOptions(camOpts);
            setStorageOptions(stoOpts);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div data-label="Variant Information" className="legend mg-t-20">
            <Row>
                <Col xs={12} sm={4}>
                    <div className={`form-group ${errors['variants.storage'] ? 'is-invalid' : ''}`}>
                        <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Storage
                        </label>
                        <div className="input-group">
                            {storageOptions.length > 0 ? (
                                <select
                                    defaultValue={storage}
                                    className={`custom-select ${errors['variants.storage'] ? 'parsley-error' : ''}`}
                                    {...register('variants.storage', { required: false })}
                                    onChange={e => {
                                        setSelectedStorage(e.target.value);
                                        let newTitle = title
                                            .split(selectedStorage)
                                            .join('')
                                            .trim();

                                        if (e.target.value.length > 0) {
                                            newTitle = newTitle
                                                .split(selectedColor)
                                                .join('')
                                                .trim();

                                            newTitle += ` ${e.target.value}`;
                                            newTitle += (selectedColor ? ` ${selectedColor}` : '');
                                        }
                                        setTitle(newTitle);
                                        setProductCode(newTitle.split(' ').join('-').toUpperCase());
                                    }}
                                >
                                    <option value="">Not Set</option>
                                    {storageOptions.map(item => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue="Loading..."
                                    disabled
                                />
                            )}
                            <div className="input-group-append">
                                <Form.Button
                                    text=""
                                    classes="btn btn-outline-light"
                                    icon={<FeatherIcon icon="plus" />}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={4}>
                    <div className={`form-group ${errors['variants.color'] ? 'is-invalid' : ''}`}>
                        <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Colour
                        </label>
                        <div className="input-group">
                            {colorOptions.length > 0 ? (
                                <select
                                    defaultValue={color}
                                    {...register('variants.color', { required: false })}
                                    className={`custom-select ${errors['variants.color'] ? 'parsley-error' : ''}`}
                                    onChange={e => {
                                        setSelectedColor(e.target.value);
                                        let newTitle = title
                                            .split(selectedColor)
                                            .join('')
                                            .trim();

                                        if (e.target.value.length > 0) {
                                            newTitle = newTitle
                                                .split(selectedStorage)
                                                .join('')
                                                .trim();

                                            newTitle += (selectedStorage ? ` ${selectedStorage}` : '');
                                            newTitle += ` ${e.target.value}`;
                                        }
                                        setTitle(newTitle);
                                        setProductCode(newTitle.split(' ').join('-').toUpperCase());
                                    }}
                                >
                                    <option value="">Not Set</option>
                                    {colorOptions.map(item => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue="Loading..."
                                    disabled
                                />
                            )}
                            <div className="input-group-append">
                                <Form.Button
                                    text=""
                                    classes="btn btn-outline-light"
                                    icon={<FeatherIcon icon="plus" />}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={4}>
                    <div className={`form-group ${errors['variants.camera'] ? 'is-invalid' : ''}`}>
                        <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Camera
                        </label>
                        <div className="input-group">
                            {cameraOptions.length > 0 ? (
                                <select
                                    name="variants.camera"
                                    defaultValue={camera}
                                    {...register('variants.camera', { required: false })}
                                    className={`custom-select ${errors['variants.camera'] ? 'parsley-error' : ''}`}
                                >
                                    <option value="">Not Set</option>
                                    {cameraOptions.map(item => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue="Loading..."
                                    disabled
                                />
                            )}
                            <div className="input-group-append">
                                <Form.Button
                                    text=""
                                    classes="btn btn-outline-light"
                                    icon={<FeatherIcon icon="plus" />}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const LaptopTabletVariants = props => {
    const { storage, colour, camera, colors } = props;
    console.log(props);
    return <div />
    return (
        <div data-label="Variant Information" className="legend mg-t-20">
            <Row>
                <Col xs={12} sm={4}>
                    <div className="form-group">
                        <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Storage
                        </label>
                        <div className="input-group">
                            <select
                                className="custom-select"
                                defaultValue={storage}
                            >
                                <option>Not Set</option>
                                {storage.map(item => (
                                    <option key={item} value={item}>{item}</option>
                                ))}
                            </select>
                            <div className="input-group-append">
                                <Form.Button
                                    text=""
                                    classes="btn btn-outline-light"
                                    icon={<FeatherIcon icon="plus" />}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={4}>
                    <div className="form-group">
                        <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Colour
                        </label>
                        <div className="input-group">
                            <select
                                className="custom-select"
                                defaultValue={colour}
                            >
                                <option>Not Set</option>
                                {colors.map(item => (
                                    <option key={item} value={item}>{item}</option>
                                ))}
                            </select>
                            <div className="input-group-append">
                                <Form.Button
                                    text=""
                                    classes="btn btn-outline-light"
                                    icon={<FeatherIcon icon="plus" />}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={4}>
                    <div className="form-group">
                        <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Camera
                        </label>
                        <div className="input-group">
                            <select
                                className="custom-select"
                                defaultValue={camera}
                            >
                                <option>Not Set</option>
                                {camera.map(item => (
                                    <option key={item} value={item}>{item}</option>
                                ))}
                            </select>
                            <div className="input-group-append">
                                <Form.Button
                                    text=""
                                    classes="btn btn-outline-light"
                                    icon={<FeatherIcon icon="plus" />}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const DeviceVariants = props => {
    const { device_type } = props;
    return (
        <div>
            {device_type.name === 'Phone' && <PhoneVariants {...props} />}
            {(device_type.name === 'Laptop' || device_type.name === 'Tablet') && <LaptopTabletVariants {...props} />}
        </div>
    );
};

export default DeviceVariants;
