import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Col, Row } from 'reactstrap';
import { AuthProvider, SessionProvider } from 'providers';
import { useParams } from 'react-router-dom';
import { Footer } from 'containers';

const ConfirmResetPassword = () => {
    const { token } = useParams();
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [ submitting, setSubmitting ] = useState(false);
    const [ feedback, setFeedback ] = useState({});

    useEffect(() => {
        const clearSession = async () => await SessionProvider.destroy();
        clearSession();
    }, []);

    if (!token) {
        window.location = '/auth/login';
        return;
    }

    const onSubmit = data => {
        handleConfirmResetPassword(data);
    };

    async function handleConfirmResetPassword(data) {
        const { password1, password2 } = data;

        // validate if user passwords match
        if (password1 !== password2) {
            setFeedback({
                class: 'tx-danger',
                message: 'Passwords do not match.'
            });
            return false;
        }

        const params = {
            new_password1: password1,
            new_password2: password2,
            token,
        };
        setSubmitting(true);
        const result = await AuthProvider.resetPasswordConfirm(params);
        if (result.status === 'success') {
            setFeedback({
                class: 'tx-success',
                message: 'Your password has been successfully reset.'
            });
            // redirect to the login page
            setTimeout(function() {
                window.location = '/auth/login';
            }, 3000);
        } else {
            // error has occured
            setFeedback({
                class: 'tx-danger',
                message: result.response.data.message || result.message
            });
        }
        reset();
        setSubmitting(false);
    };

    return (
        <div className="auth-page-wrapper">
            <div className="content content-fixed content-auth-alt">
                <div className="content content-auth">
                    <Container>
                        <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
                            <div className="sign-wrapper">
                                <div className="wd-100p">
                                    <form method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                                        <div className="logo-wrapper mg-b-60">
                                            <img src={require('images/logo.svg')} alt="DAMS" />
                                            <div className="mg-t-5">
                                                <span className="tx-color-03">Deal Automation Management System</span>
                                            </div>
                                        </div>

                                        <h3 className="mg-b-5">Reset Password</h3>
                                        <p className="mg-b-20">
                                            Please enter your NEW password and confirm it below.
                                        </p>
                                        <div className="form-group">
                                            <div className="input-group-lg">
                                                <input
                                                    type="password"
                                                    className={`form-control ${errors.password1 ? 'parsley-error' : ''}`}
                                                    placeholder="Enter new password"
                                                    disabled={submitting}
                                                    {...register('password1', { required: true, minLength: 4 })}
                                                />
                                            </div>
                                            {errors.password1 &&
                                            <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                <li className="parsley-required">Make sure password is at least 4 characters long.</li>
                                            </ul>}
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group-lg">
                                                <input
                                                    type="password"
                                                    className={`form-control ${errors.password2 ? 'parsley-error' : ''}`}
                                                    placeholder="Confirm new password"
                                                    disabled={submitting}
                                                    {...register('password2', { required: true, minLength: 4 })}
                                                />
                                            </div>
                                            {errors.password2 &&
                                            <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                <li className="parsley-required">Make sure password is at least 4 characters long.</li>
                                            </ul>}
                                            {feedback.message &&
                                            <Row className="feedback mg-t-5">
                                                <Col xs={12} className={feedback.class}>
                                                    {feedback.message}
                                                </Col>
                                            </Row>}
                                        </div>
                                        <Row>
                                            <Col xs={6}>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-block btn-lg btn-uppercase"
                                                    disabled={submitting}
                                                >
                                                    {submitting ? 'Processing...' : 'Reset Password'}
                                                </button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ConfirmResetPassword;
