import React, { useEffect, useState } from 'react';
import { FeatherIcon } from 'components';
import { DevicesProvider } from 'providers';

const DeviceAutoCompleteInput = props => {
    const { setDevice, defaultDevice } = props;
    const [ deviceOptions, setDeviceOptions ] = useState([]);
    const [ showAutoCompleteDropdown, setShowAutoCompleteDropdown ] = useState(false);
    const [ loadingAutoCompleteResults, setLoadingAutoCompleteResults ] = useState(false);
    const [ selectedDevice, setSelectedDevice ] = useState('');

    if (defaultDevice && !selectedDevice) {
        setSelectedDevice(defaultDevice);
    }

    useEffect(() => {
        setSelectedDevice(defaultDevice || '');
    }, [ ]);

    async function autoComplete(value) {
        setDevice('');
        setDeviceOptions([]);
        setShowAutoCompleteDropdown(value.length > 1);
        if (value.length > 1) {
            setDeviceOptions([]);
            setLoadingAutoCompleteResults(true);
            const data = await DevicesProvider.search('device_name', value);
            setDeviceOptions(data.results);
            setLoadingAutoCompleteResults(false);
        }
    }

    function handleSelect(item) {
        const selectedDevice = `${item.title}`;
        setDevice(item.id);
        setSelectedDevice(selectedDevice);
        setShowAutoCompleteDropdown(false);
    }

    return (
        <div className={`input-with-dropdown-options ${showAutoCompleteDropdown ? 'show' : ''}`}>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Find and select device..."
                    onKeyUp={e => autoComplete(e.target.value)}
                    value={selectedDevice}
                    onChange={e => setSelectedDevice(e.target.value)}
                />
                <div className="input-group-append">
                    {selectedDevice.length === 0 &&
                    <span className="input-group-text">
                        <FeatherIcon icon="search" width="16" height="16" />
                    </span>}
                    {selectedDevice.length > 0 &&
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            setSelectedDevice('');
                            setDevice('');
                            setDeviceOptions([]);
                            return true;
                        }}
                    >
                        <FeatherIcon icon="x" width="16" height="16" />
                    </button>}
                </div>
            </div>
            {deviceOptions.length > 0 &&
            <ul className="dropdown-options">
                {loadingAutoCompleteResults && <li className="text-center">Loading...</li>}
                {!loadingAutoCompleteResults &&
                    deviceOptions.map(item => {
                    return (
                        <li key={item.id} className="option-item">
                            <a
                                role="button"
                                href="/"
                                className="option-link"
                                onClick={e => {
                                    e.preventDefault();
                                    return handleSelect(item);
                                }}
                            >
                                {item.title}
                            </a>
                        </li>
                    );
                })}
            </ul>}
        </div>
    );
};

export default DeviceAutoCompleteInput;
