import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Layout } from 'containers';
import { Breadcrumb } from 'components';
import { AttributesProvider } from 'providers';
import { TagsInput } from 'react-tag-input-component';
import Swal from 'sweetalert2';

const DeviceAttributes = () => {
    const breadcrumb = [
        { title: 'Devices', link: '/devices', active: false },
        { title: 'Attributes', active: true },
    ];
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        reset,
    } = useForm();
    const [active, setActive] = useState(null);
    const [categories, setCategories] = useState({});
    const [processing, setProcessing] = useState(true);
    const [selectedColors, setSelectedColors] = useState({});
    const [newColorIds, setNewColorIds] = useState([]);

    async function fetchData() {
        if (!processing) setProcessing(true);
        const attributes = await AttributesProvider.getAll(0, 100, 'category=devices');

        if (attributes.results) {
            const categories = [];
            attributes.results.map(item => {
                if (!Object.keys(categories).includes(item.subcategory)) {
                    categories[item.subcategory] = [];
                }
                return categories[item.subcategory].push(item);
            });
            setActive(Object.keys(categories)[0]);
            setCategories(categories);
        }
        setProcessing(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const toggleActive = (e) => {
        const id = e.target.id;
        if (id === 'color') {
            const colors = {};
            const array = categories[id];
            array.map(item => {
                colors[item.id] = [];
                return colors[item.id] = item.json_value;
            });
            setSelectedColors(colors);
        }
        setActive(id);
        clearErrors();
    };

    const onSubmit = async data => {
        setProcessing(true);
        const postData = {
            ...data,
            category: 'devices',
            subcategory: active,
            group_name: data.group_name || 'Phone',
            tooltip: data.tooltip || data.value,
        };

        const result = await AttributesProvider.create(postData);
        if (result.status === 'success') {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Attribute added successfully!',
                showConfirmButton: false,
                timer: 5000
            });
            return setTimeout(async function () {
                reset();
                fetchData();
            }, 5000);
        } else {
            setProcessing(false);
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: result.message || 'Failed to process request, please try again!',
                showConfirmButton: false,
                timer: 5000
            });
        }
        return true;
    };

    const handleDeleteAttribute = async ({ id }) => {
        return confirmAlert({
            title: 'Confirm Delete!',
            message: 'Are you sure to delete device attribute, as this may affect devices attached to it? This process cannot be undone.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setProcessing(true);
                        const result = await AttributesProvider.destroy(id);
                        if (result.status === 'success') {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Attribute deleted successfully!',
                                showConfirmButton: false,
                                timer: 5000
                            });
                            return setTimeout(async function () {
                                fetchData();
                            }, 5000);
                        } else {
                            setProcessing(false);
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: result.message || 'Failed to process request, please try again!',
                                showConfirmButton: false,
                                timer: 5000
                            });
                        }
                        return true;
                    },
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    const onTagInputChange = (val, item) => {
        setSelectedColors({ ...selectedColors, [item.id]: val });
    };

    const onTagInputAdd = (val, item) => {
        console.log('add', val, item);
    };

    const onTagInputRemove = (val, item) => {
        console.log('remove', val, item);
    };

    return (
        <Layout pageLoading={processing}>
            <div className="content content-fixed pd-b-0 device-attributes">
                <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <Breadcrumb
                        heading="Manage Attributes"
                        items={breadcrumb}
                    />
                    <Card>
                        <CardHeader className="bd-b-0 pd-t-20 pd-lg-t-25 pd-l-20 pd-lg-l-25 d-flex flex-column flex-sm-row align-items-sm-start justify-content-sm-between">
                            <div>
                                <h6 className="mg-b-5" style={{ textTransform: 'capitalize' }}>Manage {active} Attributes</h6>
                                <p className="tx-12 tx-color-03 mg-b-0">
                                    Manage all {active} attributes that are linked to devices.
                                </p>
                            </div>
                            <div className="btn-group mg-t-20 mg-sm-t-0">
                                {categories && active && Object.keys(categories).map(item => (
                                    <button
                                        id={item}
                                        key={item}
                                        type="button"
                                        className={`btn btn-sm btn-white btn-uppercase ${active === item ? 'active' : ''}`}
                                        onClick={toggleActive}
                                    >
                                        {item}
                                    </button>
                                ))}
                            </div>
                        </CardHeader>
                        {categories && active && (
                            <CardBody className="pd-lg-25">
                                {active === 'color' ? (
                                    <div>
                                        {categories[active] && categories[active].map(attribute => (
                                            <div
                                                className="legend"
                                                data-label={attribute.group_name}
                                                style={{
                                                    color: attribute.code.toLowerCase() === '#ffffff' ?
                                                        '#eeeeee' : 
                                                        attribute.code
                                                }}
                                                key={attribute.id}
                                            >
                                                <TagsInput
                                                    onChange={val => onTagInputChange(val, attribute)}
                                                    value={selectedColors[attribute.id] || []}
                                                    name={`${active}_${attribute.group_name.toLowerCase()}`}
                                                    placeHolder="Type and press enter to add new value"
                                                    // beforeAddValidate={val => onTagInputAdd(val, attribute)}
                                                    onRemoved={val => onTagInputRemove(val, attribute)}
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm mg-y-10"
                                                    style={{ display: 'none' }}
                                                >
                                                    Save changes
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div>
                                        <fieldset className="form-fieldset">
                                            <legend>Add New {active} Attribute</legend>
                                            <form method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                                <div className="d-sm-flex wd-sm-60p">
                                                    <div className="form-group mg-b-0">
                                                        <label>Value: <span className="tx-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control wd-250 wd-xs-250"
                                                            placeholder="Enter value"
                                                            {...register('value', {
                                                                required: true
                                                            })}
                                                        />
                                                        {errors.value && (
                                                            <ul className="parsley-errors-list filled">
                                                                <li className="parsley-required">
                                                                    This input should be a valid {active} value.
                                                                </li>
                                                            </ul>
                                                        )}
                                                    </div>
                                                    <div className="mg-sm-l-10 mg-t-10 mg-sm-t-25 pd-t-4">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary pd-x-20"
                                                        >
                                                            Save Changes
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </fieldset>
                                        <table className="table table-borderless table-sm table-hover tx-13 tx-nowrap mg-b-0 mg-t-20">
                                            <thead>
                                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                                    <th className="wd-5p">Link</th>
                                                    <th>Attribute</th>
                                                    <th className="text-right">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {categories[active] && categories[active].map(attribute => (
                                                    <tr key={attribute.id}>
                                                        <td className="align-middle text-left">
                                                            <Link to={`/devices?filter=true&${active}=${attribute.value}`}>
                                                                <i className="fa fa-external-link" />
                                                            </Link>
                                                        </td>
                                                        <td className="align-middle tx-medium">
                                                            {attribute.value}
                                                        </td>
                                                        <td className="align-middle text-right">
                                                            <button
                                                                type="button"
                                                                className="btn btn-link tx-danger"
                                                                onClick={() => handleDeleteAttribute(attribute)}
                                                            >
                                                                <i className="tx-danger fa fa-trash-o" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </CardBody>
                        )}
                    </Card>
                </Container>
            </div>
        </Layout>
    );
};

export default DeviceAttributes;
