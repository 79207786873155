import React from 'react';
import { Col, Row } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const DeviceMatchCriteria = props => {
    const {
        show,
        setShow,
        callback,
        processing,
        setProcessing,
    } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleClose = () => setShow(false);

    const onSubmit = data => {
        setProcessing(true);
        if (callback) callback(data);
        return data;
    };

    return (
        <Modal show={show} onHide={handleClose} centered className="confirm-modal">
            <Modal.Header closeButton>
                <h6 className="modal-title">
                    New Device Matching Criteria
                </h6>
            </Modal.Header>
            <Modal.Body>
                <form method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label>Criteria</label>
                        <input
                            type="text"
                            placeholder="Define Criteria"
                            className={`form-control ${errors.criteria ? 'parsley-error' : ''}`}
                            {...register('criteria', { required: true, minLength: 4 })}
                        />
                    </div>
                    <Row>
                        <Col xs={12} className="text-right mg-b-15">
                            <button
                                type="button"
                                className="btn btn-secondary tx-13"
                                disabled={processing}
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <button
                                type="submit"
                                className="btn btn-primary tx-13"
                                disabled={processing}
                            >
                                {processing ? 'Processing...' : 'Save'}
                            </button>
                        </Col>
                    </Row>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default DeviceMatchCriteria;
