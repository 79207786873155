import React from 'react';
import ExpectedLayout from './ExpectedLayout';

const MapInfo = props => {
    const {
        data,
        networkName,
        invalidLayout,
        validator,
        setInvalidLayout,
    } = props;
    const headings = data[0];
    return (
        <>
            <h3 id="wizard1-h-0" tabIndex="-1" className="title current">Data Configuration.</h3>
            <p className="mg-b-20">
                Dealsheet Import allows you to easily import dealsheet CSV datafiles.
            </p>
            <div className="legend mb-4" data-label="Expected layout">
                <div className="table-responsive">
                    <table className="table table-sm table-hover mg-b-0">
                        <ExpectedLayout network={networkName} />
                    </table>
                </div>
            </div>
            {invalidLayout &&
                <div className="alert alert-danger">
                    <p>
                        Invalid layout has been detected on the imported file. Please amend the imported
                        file structure to match the expected layout for {networkName}.
                        Please check unmatched columns <strong>in red</strong> below.
                    </p>
                </div>}
            <div className="legend" data-label="Imported file data">
                <div className="table-responsive">
                    <table className="table table-sm table-hover table-striped mg-b-0">
                        <thead>
                            <tr>
                                {headings.length > 0 && headings.map((heading, i) => {
                                    let classname = '';
                                    const head = headings[i]
                                    .split('\n')
                                    .join(' ')
                                    .toLowerCase()
                                    .trim()
                                    .replace('handset  cost', 'handset cost');
                                    if ((validator[networkName.toLowerCase()][i] && validator[networkName.toLowerCase()][i].toLowerCase() === head)) {
                                        classname = 'tx-success';
                                    } else {
                                        classname = 'tx-danger';
                                        if (!invalidLayout) {
                                            setInvalidLayout(true);
                                        }
                                    }
                                    return (
                                        <th key={heading}>
                                            <span className={classname}>
                                                {heading}
                                            </span>
                                        </th>)
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {data.slice(1, 5).map((row, idx) => {
                                if (idx > 0) {
                                    return (
                                        <tr key={(idx + 111).toString()}>
                                            {row.length > 0 && row.map((item, index) => (
                                                <td key={index.toString()}>{item}</td>
                                            ))}
                                        </tr>
                                    );
                                }
                                return true;
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default MapInfo;
