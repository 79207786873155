import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { FeatherIcon } from 'components';
import { SessionProvider } from 'providers';
import S3 from 'react-aws-s3';
import config from '../../config';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';

const configurations = SessionProvider.getConfigurations();
const {
    ASSET_ACCESS_KEY_ID,
    ASSET_SECRET_ACCESS_KEY,
    MONDO_ASSET_ACCESS_KEY_ID,
    MONDO_ASSET_SECRET_ACCESS_KEY,
} = configurations;

const UploadDeviceImageModal = props => {
    const {
        id,
        images,
        device_type,
        manufacturer,
        permakey,
        show,
        setShow,
        setShowAlert,
        setAlertContent,
        setUploadingSteps,
        setShowUploadingAlert,
    } = props;
    const handleClose = () => setShow(false);
    const fileInput = useRef();

    async function handleUploadImage(event) {
        event.preventDefault();
        setShow(false);
        setUploadingSteps({
            uploadDams: 'in progress',
            uploadMondo: 'pending',
        });
        setShowUploadingAlert(true);

        // upload device image to the AWS S3 bucket

        const { aws } = config;
        const { s3 } = aws;
        const {
            bucketName,
            region,
            dirName,
        } = s3.options;

        const file = fileInput.current.files[0];

        const deviceType = device_type.name.split('/').join('-').split(' ').join('-').toLowerCase();
        const directory = (`${dirName}/${deviceType}/${manufacturer.name}/${permakey.split(`${manufacturer.name.toLowerCase()}-`).join('')}`).toLowerCase();

        const s3config = {
            bucketName,
            dirName: directory,
            region,
            accessKeyId: ASSET_ACCESS_KEY_ID,
            secretAccessKey: ASSET_SECRET_ACCESS_KEY,
        };

        const ReactS3Client = new S3(s3config);

        return ReactS3Client
            .uploadFile(file, 'default')
            .then(async data => {
                console.log(data);

                // update dams
                images.push(directory);
                console.log(directory, images);
                // await DevicesProvider.update(id, {
                //     images,
                //     image_default: directory,
                // });

                setUploadingSteps({
                    uploadDams: 'complete',
                    uploadMondo: 'in progress',
                });
                // upload same file to Mondo S3 bucket
                return handleUploadImageToMondo(file, deviceType);
            })
            .catch(err => {
                setShowUploadingAlert(false);
                setAlertContent({
                    title: 'Upload Error!',
                    body: <p>{err.message}</p>,
                    type: 'error',
                });
                setShowAlert(true);
            });
    }

    async function handleUploadImageToMondo(file, deviceType) {
        const { aws } = config;
        const { s3 } = aws;
        const { mondo } = s3;
        const { bucketName, region, dirName } = mondo.options;
        const directory = (`${dirName}/${deviceType}/${manufacturer.name}/${permakey.split(`${manufacturer.name.toLowerCase()}-`).join('')}`).toLowerCase();

        const s3config = {
            bucketName,
            dirName: directory,
            region,
            accessKeyId: MONDO_ASSET_ACCESS_KEY_ID,
            secretAccessKey: MONDO_ASSET_SECRET_ACCESS_KEY,
        };

        const ReactS3Client = new S3(s3config);

        return ReactS3Client
            .uploadFile(file, 'default')
            .then(data => {
                console.log(data);
                setUploadingSteps({
                    uploadDams: 'complete',
                    uploadMondo: 'complete',
                });
                setShowUploadingAlert(false);
                setAlertContent({
                    title: 'Image Uploaded!',
                    body: <p><strong>Device Image</strong> successfully uploaded!.</p>,
                    callback: () => window.location = `/devices/edit/${id}?tab=images`,
                    type: 'success',
                });
                setShowAlert(true);
            })
            .catch(err => {
                setShowUploadingAlert(false);
                setAlertContent({
                    title: 'Upload Error!',
                    body: <p>{err.message}</p>,
                    type: 'error',
                });
                setShowAlert(true);
            });
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <h6 className="modal-title">
                    Upload Device Image
                </h6>
            </Modal.Header>
            <Modal.Body>
                <p className="mg-b-20">
                    This action will upload the image to the active device's path on the S3 bucket.
                </p>
                <div className="legend" data-label="Select File to Upload">
                    <form id="image-uploader-form" onSubmit={handleUploadImage}>
                        <input type="file" ref={fileInput} />
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-sm btn-primary d-flex align-items-center"
                    type="submit"
                    form="image-uploader-form"
                >
                    <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                    <span className="d-none d-sm-inline mg-l-5">
                        Upload
                    </span>
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadDeviceImageModal;
