import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import {
    Loading,
    FeatherIcon,
    TextEditor,
} from 'components';
import { NetworksProvider, PackagesProvider } from 'providers';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import wysiwyg from 'static/wysiwyg.json';

const selectStyles = {
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    menu: (base) => ({
        ...base,
        zIndex: 9999
    })
};

export default function AddPackage(props) {
    const { isModal, showModal, title, saveCallback } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [ pageLoading, setPageLoading ] = useState(true);
    const [ submitting, setSubmitting ] = useState(false);
    const [loadingText, setLoadingText] = useState('Loading');
    const [ contractDetails, setContractDetails ] = useState('');
    const [ networkProviders, setNetworkProviders ] = useState([]);
    const [ selectedNetwork, setSelectedNetwork ] = useState(null);
    const [ packageTypes, setPackageTypes ] = useState([]);
    const [ selectedType, setSelectedType ] = useState(null);
    const [ showAirtime, setShowAirtime ] = useState(true);
    const [ showMinutes, setShowMinutes ] = useState(false);
    const [ showData, setShowData ] = useState(false);
    const [ showSMS, setShowSMS ] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await NetworksProvider.getAll();
            const types = await PackagesProvider.getTypes();
            if (data.results) {
                if (data.results.length > 0) {
                    const providers = [];
                    data.results.map(item => providers.push({ value: item.id, label: item.name }));
                    setNetworkProviders(providers);
                }
            }
            if (types.results) {
                if (types.results.length > 0) {
                    setSelectedType(types.results[0]);
                    setPackageTypes(types.results);
                }
            }
            setPageLoading(false);
        };
        fetchData();
    }, [ ]);

    const onSubmit = data => {
        handleSavePackage(data);
    };

    const handleSavePackage = async (formData) => {
        const { name, code, description, airtime, minutes, sms, data } = formData;

        if (!selectedNetwork) {
            return alert('Please select network provider.');
        }

        if (!selectedType) {
            return alert('Please select package type.');
        }
        setLoadingText('Saving');
        setSubmitting(true);
        const post = {
            name,
            code,
            airtime: airtime || null,
            minutes: minutes || null,
            sms: sms || null,
            data: data || null,
            description,
            provider_id: selectedNetwork.id,
            type_id: selectedType.id,
            contract_details: contractDetails,
        };
        const result = await PackagesProvider.create(post);
        if (result.status === 'success') {
            alert('Package information successfully saved!');
            if (saveCallback && typeof saveCallback === 'function') {
                saveCallback();
            } else {
                window.location = '/packages';
            }
        } else {
            alert(result.message);
        }
        setSubmitting(false);
        setLoadingText(null);
    };

    const handleSelectType = item => {
        setShowSMS(false);
        setShowData(false);
        setShowAirtime(false);
        setShowMinutes(false);
        setSelectedType(null);

        if (item && item.description) {
            setSelectedType(item);
            switch (item.description.toLowerCase()) {
                case 'data':
                case 'data only':
                    setShowData(true);
                    register('data', { required: true });
                    break;

                case 'voice':
                case 'voice only':
                    setShowMinutes(true);
                    register('minutes', { required: true });
                    break;

                case 'airtime':
                case 'prepaid':
                case 'pre-paid':
                case 'pre paid':
                    setShowAirtime(true);
                    register('airtime', { required: true });
                    break;

                case 'topup':
                case 'top up':
                    setShowAirtime(true);
                    register('airtime', { required: true });
                    break;

                case 'voice + data':
                case 'open contract':
                    setShowData(true);
                    setShowMinutes(true);
                    setShowSMS(true);
                    register('data', { required: true });
                    register('minutes', { required: true });
                    register('sms', { required: true });
                    break;
            }
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            {(pageLoading || submitting) && <Loading text={loadingText} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex align-items-center justify-content-between mg-b-25">
                    {title ?
                    <h4 className="mg-b-0 text-navy font-weight-600">{title}</h4> :
                    <h6 className="mg-b-0">Package Information</h6>}
                    <div className="d-flex">
                        <button type="submit" className="btn btn-sm btn-outline-primary d-flex align-items-center mg-r-5">
                            <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                            <span className="d-none d-sm-inline mg-l-5"> Save</span>
                        </button>
                        {isModal ?
                        <button
                            type="button"
                            className="btn btn-sm btn-white d-flex align-items-center"
                            onClick={() => showModal(false)}
                        >
                            <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                            <span className="d-none d-sm-inline mg-l-5"> Cancel</span>
                        </button> :
                        <Link to="/packages">
                            <button type="button" className="btn btn-sm btn-white d-flex align-items-center">
                                <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                                <span className="d-none d-sm-inline mg-l-5"> Cancel</span>
                            </button>
                        </Link>}
                    </div>
                </div>
                <div data-label="Package Information" className="legend mg-t-10">
                    <Row>
                        <Col xs={12} sm={4}>
                            <div className="form-group mg-b-0">
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Network Provider
                                </label>
                                <Select
                                    options={networkProviders}
                                    isLoading={networkProviders.length === 0}
                                    isDisabled={networkProviders.length === 0}
                                    onChange={item => setSelectedNetwork({ id: item.value, name: item.label })}
                                    style={selectStyles}
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <div className="form-group mg-b-0">
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.name ? 'parsley-error' : ''}`}
                                    placeholder="Enter package name..."
                                    {...register('name', { required: true })}
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <div className="form-group mg-b-0">
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Code
                                </label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.code ? 'parsley-error' : ''}`}
                                    placeholder="Enter package code..."
                                    {...register('code', { required: true })}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div data-label="Package Type" className="legend mg-t-20">
                    <Row>
                        <Col xs={12}>
                            <div className="form-group mg-b-0">
                                {/* <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Package Type
                                </label> */}
                                <Row>
                                    <Col xs={12} sm={5}>
                                        <div className="btn-group" role="group">
                                            {packageTypes.length > 0 && packageTypes.map(item =>
                                            <button
                                                key={item.id}
                                                type="button"
                                                className={`btn btn-${(selectedType && selectedType.id === item.id) ? 'primary' : 'dark'}`}
                                                onClick={() => handleSelectType(item)}
                                            >
                                                {item.description}
                                            </button>)}
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={7} className="package-types">
                                        <div className="input-group">
                                            {showAirtime &&
                                            <>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Airtime</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.airtime ? 'parsley-error' : ''}`}
                                                    placeholder="Value in Rands"
                                                    {...register('airtime', { required: true })}
                                                />
                                            </>}

                                            {showMinutes &&
                                            <>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Minutes</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.minutes ? 'parsley-error' : ''}`}
                                                    placeholder="Minute bundles"
                                                    {...register('minutes', { required: true })}
                                                />
                                            </>}

                                            {showData &&
                                            <>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">DATA</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.data ? 'parsley-error' : ''}`}
                                                    placeholder="Data in MB"
                                                    {...register('data', { required: true })}
                                                />
                                            </>}

                                            {showSMS &&
                                            <>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">SMS</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.sms ? 'parsley-error' : ''}`}
                                                    placeholder="SMS bundles"
                                                    {...register('sms', { required: true })}
                                                />
                                            </>}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div data-label="Description (Optional)" className="legend mg-t-20">
                    <Row>
                        <Col xs={12}>
                            <div className="form-group mg-b-0">
                                {/* <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Description (Optional)
                                </label> */}
                                <input
                                    type="text"
                                    className={`form-control ${errors.description ? 'parsley-error' : ''}`}
                                    placeholder="Enter package description..."
                                    {...register('description', { required: false })}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div data-label="Contract Details" className="legend mg-t-20">
                    <Row>
                        <Col xs={12}>
                            <div className="form-group mg-b-0">
                                {/* <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Contract Details (Optional)
                                </label> */}
                                <TextEditor.WYSIWYG
                                    model={contractDetails}
                                    setModel={setContractDetails}
                                    placeholderText="Enter contract details..."
                                    toolbarButtons={wysiwyg.toolbarButtons.contractDetails}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </form>
        </div>
    );
}
