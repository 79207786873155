import React from 'react';
import { FeatherIcon } from 'components';

export default function RoleCard(props) {
    const {
        id,
        name,
        label,
        active,
        description,
        handleSelected,
    } = props;
    return (
        <div
            className={`media ${(active && active === id) ? 'active' : ''}`}
            onClick={() => handleSelected({ ...props, key: 'role' })}
        >
            <div className="avatar avatar-sm">
                <span className="avatar-initial rounded-circle bg-gray-700">
                    {name[0]}
                </span>
            </div>
            <div className="media-body mg-l-10">
                <h6 className="tx-13 mg-b-3">{label}</h6>
                <span className="tx-12">{description}</span>
            </div>
            {/* make sure that administrator cannot be updated nor deleted */}
            {(name.toLowerCase() !== 'admin' || name.toLowerCase() !== 'unassigned') && (
                <nav>
                    <a href=" ">
                        <FeatherIcon icon="edit-2" classes="wd-10" />
                    </a>
                    <a href=" ">
                        <FeatherIcon icon="trash-2" classes="wd-10 tx-danger" />
                    </a>
                </nav>
            )}
        </div>
    );
}
