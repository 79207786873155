import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import { PackagesProvider } from 'providers';
import Swal from 'sweetalert2';

export default function AddPackageType(props) {
    const {
        show,
        setShow,
        fetchData,
    } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [processing, setProcessing] = useState(false);

    const handleClose = () => setShow(false);

    async function onSubmit(data) {
        setProcessing(true);
        const response = await PackagesProvider.createType(data);
        const { status } = response;
        setProcessing(false);
        if (status && status === 'success') {
            setShow(false);
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Request processed successfully!',
                showConfirmButton: false,
                timer: 6000
            });
            return setTimeout(async function() {
                fetchData();
            }, 6000);
        }
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Failed to process request, please try again!',
            showConfirmButton: false,
            timer: 6000
        });
    }

    return (
        <Modal show={show} onHide={handleClose} centered className="special-text-modal">
            <Modal.Header closeButton>
                <h6 className="modal-title">
                    Add New Package Type
                </h6>
            </Modal.Header>
            <Modal.Body>
                <form method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="margin-bottom-15">
                        <Col xs={12}>
                            <div className={`form-group has-feedback ${errors.description ? 'has-error' : ''}`}>
                                <label>Description</label>
                                <input
                                    type="text"
                                    placeholder="Description"
                                    className="form-control"
                                    {...register('description', { required: true, minLength: 4 })}
                                    disabled={processing}
                                />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className={`form-group has-feedback ${errors.active ? 'has-error' : ''}`}>
                                <label>Active</label>
                                <select
                                    className="form-control"
                                    {...register('active', { required: true })}
                                    disabled={processing}
                                >
                                    <option value="">Please Select</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-right mg-b-15">
                            <button
                                type="button"
                                className="btn btn-secondary tx-13"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <button
                                type="submit"
                                className="btn btn-primary tx-13"
                                disabled={processing}
                            >
                                {processing ? 'Processing...' : 'Save Changes'}
                            </button>
                        </Col>
                    </Row>
                </form>
            </Modal.Body>
        </Modal>
    );
}
