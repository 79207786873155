import React from 'react';
import { FeatherIcon } from 'components';
import PropTypes from 'prop-types';

export default function ListCard(props) {
    const {
        id,
        type,
        role,
        active,
        username,
        last_name,
        first_name,
        showActions,
        handleSelected,
    } = props;
    return (
        <div
            className={`media ${(active && active === id) ? 'active' : ''}`}
            onClick={() => handleSelected({ ...props, key: 'user' })}
        >
            <div className="avatar avatar-sm avatar-offline">
                <span className="avatar-initial rounded-circle bg-gray-700">
                    {first_name[0]}
                </span>
            </div>
            <div className="media-body mg-l-10">
                <h6 className="tx-13 mg-b-3">{first_name} {last_name}</h6>
                <span className="tx-12">
                    {type === 'role' && username ? <span className="tx-muted">@{username}</span> : role.label}
                </span>
            </div>
            {showActions && (
                <nav>
                    <a href=" ">
                        <FeatherIcon icon="edit-2" classes="wd-10" />
                    </a>
                    <a href=" ">
                        <FeatherIcon icon="trash-2" classes="wd-10 tx-danger" />
                    </a>
                </nav>
            )}
        </div>
    );
}

ListCard.propTypes = {
    showActions: PropTypes.bool,
    type: PropTypes.string,
};

ListCard.defaultProps = {
    showActions: true,
    type: 'user',
};
