import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { NetworksProvider, PackagesProvider } from 'providers';
import Select from 'react-select';
import queryString from 'query-string';
import AutoCompleteInput from './AutoCompleteInput';

const PackagesFilter = (props) => {
    const {
        resultsLoading,
        setPackages,
        networks,
        setNetworks,
        types,
        setTypes,
        setResultsLoading,
    } = props;
    const [packageId, setPackageId] = useState(null);
    const [typeId, setTypeId] = useState(null);
    const [packageCode, setPackageCode] = useState(null);
    const [networkId, setNetworkId] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasContractDetails, setHasContractDetails] = useState(true);
    const toggleCollapsed = () => setCollapsed(!collapsed);

    useEffect(() => {
        const fetchData = async () => {
            const data = await NetworksProvider.getAll();
            const types = await PackagesProvider.getTypes();
            if (data.results) {
                if (data.results.length > 0) {
                    const networks = [];
                    data.results.map(item => networks.push({ value: item.id, label: item.name }));
                    setNetworks(networks);
                }
            }
            if (types.results) {
                if (types.results.length > 0) {
                    const obj = [];
                    types.results.map(item => obj.push({ value: item.id, label: item.description }));
                    setTypes(obj);
                }
            }
            setIsLoading(false);
        };
        fetchData();
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        setProcessing(true);
        const filters = {};

        if (packageId || packageCode || networkId || typeId) {
            if (packageId) filters.id = packageId;
            if (packageCode) filters.code = packageCode;
            if (networkId) filters.provider_id = networkId;
            if (typeId) filters.type_id = typeId;
        }

        filters.has_contract_details = hasContractDetails ? 'Yes' : 'No';

        const packages = await PackagesProvider.getAll(0, 1000, queryString.stringify(filters));
        setPackages(packages.results || []);
        setProcessing(false);
    }

    async function handlehasContractDetailsChange(checked) {
        setProcessing(true);
        setResultsLoading(true);
        setHasContractDetails(checked);
        const filters = {};

        if (packageId || packageCode || networkId || typeId) {
            if (packageId) filters.id = packageId;
            if (packageCode) filters.code = packageCode;
            if (networkId) filters.provider_id = networkId;
            if (typeId) filters.type_id = typeId;
        }

        filters.has_contract_details = checked ? 'Yes' : 'No';

        const packages = await PackagesProvider.getAll(0, 1000, queryString.stringify(filters));
        setPackages(packages.results || []);
        setResultsLoading(false);
        setProcessing(false);
    };

    return (
        <div className="filter-wrapper">
            <button
                className={`btn btn-primary btn-block rounded-0 btn-filter-heading ${collapsed ? 'collapsed' : ''}`}
                data-toggle="collapse"
                type="button"
                aria-expanded="false"
                aria-controls="collapseFilters"
                onClick={toggleCollapsed}
            >
                Filter Options
            </button>
            <div className={`collapse mg-t-0 ${collapsed ? '' : 'show'}`} id="collapseFilters">
                <Card>
                    <CardBody>
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col xs={12} className="mg-b-15">
                                    <Row>
                                        <Col xs={12} md={3}>
                                            <div className="form-group mg-b-0">
                                                <label>Pacakage name</label>
                                                <AutoCompleteInput
                                                    setPackage={setPackageId}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <div className="form-group mg-b-0">
                                                <label>Package code</label>
                                                <input
                                                    type="text"
                                                    name="package_code"
                                                    className="form-control"
                                                    placeholder="Package code"
                                                    disabled={resultsLoading}
                                                    onKeyUp={(e) => {
                                                        if (e.target.value.length > 0) {
                                                            setPackageCode(e.target.value);
                                                        } else {
                                                            setPackageCode(null);
                                                        }
                                                        return true;
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <div className="form-group mg-b-0">
                                                <label>Network provider</label>
                                                <Select
                                                    options={networks}
                                                    name="provider_id"
                                                    isLoading={isLoading}
                                                    isDisabled={resultsLoading}
                                                    onChange={(item) => {
                                                        if (item.value.length > 0) {
                                                            setNetworkId(item.value);
                                                        } else {
                                                            setNetworkId(null);
                                                        }
                                                        return true;
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <div className="form-group mg-b-0">
                                                <label>Package type</label>
                                                <Select
                                                    options={types}
                                                    name="type_id"
                                                    isDisabled={resultsLoading}
                                                    onChange={(item) => {
                                                        if (item.value.length > 0) {
                                                            setTypeId(item.value);
                                                        } else {
                                                            setTypeId(null);
                                                        }
                                                        return true;
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} md={2}>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                        disabled={processing || resultsLoading}
                                    >
                                        {processing ? 'Processing...' : 'Apply Filters'}
                                    </button>
                                </Col>
                                <Col xs={6} md={2}>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block"
                                        disabled={processing || resultsLoading}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location = '/packages';
                                        }}
                                    >
                                        Reset Filters
                                    </button>
                                </Col>
                                <Col xs={6} md={4} style={{ paddingTop: "10px" }} >
                                    <div className="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="hasContractDetails"
                                            checked={hasContractDetails}
                                            onChange={(e) => handlehasContractDetailsChange(e.target.checked)}
                                            disabled={processing || resultsLoading}
                                        />
                                        <label className="custom-control-label" htmlFor="hasContractDetails">Package has contract details?</label>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default PackagesFilter;
