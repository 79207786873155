import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert';
import { Container, Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import {
  Breadcrumb,
  Loading,
  UpdateUserRoleModal,
  Users,
} from 'components';
import { UsersProvider } from 'providers';
import Swal from 'sweetalert2';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Roles = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [roleUpdating, setRoleUpdating] = useState(false);
  const [showUpdateRoleInfo, setShowUpdateRoleInfo] = useState({});
  const [processing, setProcessing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const breadcrumb = [
    { title: "Users", link: "/users", active: false },
    { title: "Roles", active: true },
  ];

  const onSubmit = (data) => {
    handleAddRole(data);
  };

  async function handleAddRole(data) {
    setSubmitting(true);

    const result = await UsersProvider.addRole(data);

    if (result.status === "success") {
      alert("Role successfully added!");
      window.location = "/users/roles";
    } else {
      setSubmitting(false);
      alert(result.message);
    }
  }

  async function handleUpdate(id, data) {
    setSubmitting(true);
    setRoleUpdating(true);
    const result = await UsersProvider.updateRole(id, data);
    const { status, message } = result;
    setRoleUpdating(false);
    setSubmitting(false);

    if (status === 'success') {
      setShowUpdateModal(false);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: `${data.label || 'User Role'} successfully updated!`,
        showConfirmButton: false,
        timer: 5000
      });
      return setTimeout(async function () {
        return window.location.href = '/users/roles';
      }, 5000);
    }

    // error occured
    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 5000
    });
  }

  const handleEditRole = (data) => {
    setShowUpdateRoleInfo(data);
    setShowUpdateModal(true);
  };

  const handleDeleteRole = (data) => {
    confirmAlert({
      title: "Confirm Delete",
      message: `Are you sure you want to delete ${data.label}?`,
      buttons: [
        {
          label: "Yes, Delete",
          onClick: async () => {
            setProcessing(true);
            const result = await UsersProvider.deleteRole(data.id);

            if (result.status === "success") {
              alert("Role successfully deleted!");
              window.location = "/users/roles";
            } else {
              setProcessing(false);
              alert(result.message);
            }
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  return (
    <Layout>
      <div className="content content-fixed">
        <UpdateUserRoleModal
          {...showUpdateRoleInfo}
          show={showUpdateModal}
          callback={handleUpdate}
          setShow={setShowUpdateModal}
          roleUpdating={roleUpdating}
          setRoleUpdating={setRoleUpdating}
        />
        {processing && <Loading text="Processing..." />}
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <Breadcrumb heading="User Role Management" items={breadcrumb} />
          <form
            method="post"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col xs={10}>
                <Row>
                  <Col xs={12} sm={4}>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Role name"
                        className={`form-control ${errors.name ? "parsley-error" : ""
                          }`}
                        {...register('name', { required: true, minLength: 3 })}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={4}>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Label"
                        className={`form-control ${errors.label ? "parsley-error" : ""
                          }`}
                        {...register('label', { required: true, minLength: 3 })}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={4}>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Description"
                        className={`form-control ${errors.description ? "parsley-error" : ""
                          }`}
                        {...register('description', { required: true, minLength: 3 })}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={2}>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary">
                    {submitting ? "Processing..." : "Add Role"}
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
      <Users.Roles
        handleDeleteRole={handleDeleteRole}
        handleEditRole={handleEditRole}
        handleUpdate={handleUpdate}
      />
    </Layout>
  );
};

export default Roles;
