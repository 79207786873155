import axios from 'axios';
import Cookies from 'universal-cookie';
import config from '../config';

const headers = {
    'Content-Type': 'application/json',
};

const cookies = new Cookies();

class SessionProvider {

    static api = config.api[config.stage];
    static cookiename = config.auth.cookie.name;

    static start(token, menu = null, configurations = null) {
        this.destroy();
        this.set(token);
        if (menu) {
            this.setMenu(menu);
        }
        if (configurations) {
            this.setConfigurations(configurations);
        }
    }

    static destroy() {
        cookies.remove(`${this.cookiename}__configurations`);
        cookies.remove(`${this.cookiename}__menu`);
        cookies.remove(this.cookiename);
    }

    static setCookie(key, value, options = { path: '/' }) {
        cookies.set(key, value, options);
        return true;
    }

    static getCookie(key) {
        return cookies.get(key);
    }

    static set(jwt) {
        this.setCookie(this.cookiename, jwt);
    }

    static setMenu(menu) {
        this.setCookie(`${this.cookiename}__menu`, JSON.stringify(menu));
    }

    static setConfigurations(configurations) {
        this.setCookie(`${this.cookiename}__configurations`, JSON.stringify(configurations));
    }

    static get() {
        const jwt = this.getCookie(this.cookiename);
        let session;
        try {
            if (jwt) {
                const base64Url = jwt.split('.')[1];
                const base64 = base64Url.replace('-', '+').replace('_', '/');
                session = JSON.parse(window.atob(base64));
            }
        } catch (error) {
            console.log(error);
        }
        return session;
    }

    static getMenu() {
        const menu = this.getCookie(`${this.cookiename}__menu`);
        return (menu) ? menu : [];
    }

    static getConfigurations() {
        const configurations = this.getCookie(`${this.cookiename}__configurations`);
        return (configurations) ? configurations : {};
    }

    static async isValid() {
        let valid = false;
        const token = this.getToken();

        if (token) {
            headers.Authorization = `Bearer ${token}`;
            valid = await axios({
                mode: 'no-cors',
                method: 'POST',
                url: `${this.api.baseurl}auth/valid`,
                crossdomain: true,
                headers,
            })
                .then((json) => json.data)
                .then(res => {
                    const { status } = res;
                    return status && status === 'success' || false;
                })
                .catch(() => {
                    return false;
                });
        }
        return valid;
    }

    // return the token from the session storage
    static getToken() {
        return this.getCookie(this.cookiename) || null;
    }
}

export default SessionProvider;
