import axios from "axios";
import config from "../config";
import Session from "./SessionProvider";

const baseurl = config.api[config.stage].baseurl;
let headers = {};

if (Session.getToken()) {
  headers = {
    Authorization: `Bearer ${Session.getToken()}`,
  };
}

class AuditTrailProvider {
  static async insertLog(section, subsection, action, url) {
    return await axios({
      mode: "no-cors",
      method: "POST",
      url: `${baseurl}audit-trail`,
      crossdomain: true,
      headers,
      data: {
        url,
        section,
        subsection,
        action,
      },
    })
      .then((json) => json.data)
      .then((res) => res)
      .catch((err) => {
        if (err.response) return err.response.data;
        return err;
      });
  }
}

export default AuditTrailProvider;
