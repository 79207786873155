import React from 'react';
import moment from 'moment';
import { Card, CardBody, CardFooter } from 'reactstrap';
import config from '../../config';

const FileCard = props => {
    const {
        Key,
        Size,
        LastModified,
        defaultImg,
        setDefaultImg,
        setSaveDisabled,
        mainImg,
        root
    } = props;

    const arr = Key.split('/');
    const name = arr[arr.length - 1];

    return (
        <Card
            role="button"
            className={`card-file ${(root + defaultImg) === Key ? 'selected' : ''}`}
            onClick={() => {
                if (defaultImg) {
                    setSaveDisabled(true);
                    return setDefaultImg(null);
                }
                setDefaultImg(Key);
                setSaveDisabled(false);
            }}
        >
            <div className="img-selected-overlay" />
            {mainImg === Key &&
                <div className="tag-label">
                    <span>Main Image</span>
                </div>}
            <div
                className="card-file-thumb tx-danger"
                style={{
                    backgroundImage: `url(${config.aws.s3.url + Key})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundColor: '#fff',
                }}
            />
            <CardBody>
                <h6>
                    <a
                        href="/"
                        className="link-02"
                        onClick={e => {
                            e.preventDefault();
                        }}
                    >
                        {name}
                    </a>
                </h6>
                <span>{parseInt(Size / 1024, 10)}kb</span>
            </CardBody>
            <CardFooter>
                <span className="d-none d-sm-inline">Last modified: </span>{moment(LastModified).fromNow()}
            </CardFooter>
            <div className="selector">
                <input
                    type="checkbox"
                    checked={defaultImg === Key}
                    onChange={() => {
                        if (defaultImg) {
                            setSaveDisabled(true);
                            return setDefaultImg(null);
                        }
                    }}
                />
            </div>
        </Card>
    );
};

export default FileCard;
