import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { SessionProvider } from 'providers';
import { PreLoader } from 'components';

export default function({ component: RouteComponent, ...rest }) {
    const [loaded, setLoaded] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    async function fetchData() {
        const isAuth = await SessionProvider.isValid();
        setIsAuthenticated(isAuth);
        setLoaded(true);
    }

    useEffect(() => {
        if (!loaded) {
            fetchData();
        }
        return () => {};
    }, []);

    if (!loaded) return <PreLoader text="Loading" />;

    if (isAuthenticated) {
        return <RouteComponent {...rest} />;
    }

    return <Navigate to={`/auth/login?rurl=${window.location.pathname.substring(1)}`} />;
}