import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { FeatherIcon } from 'components';
import moment from 'moment';

const StepItem = props => {
    const {
        activeTab,
        description,
        steps,
        step,
        title,
        isNavItem,
        setActiveTab,
    } = props;

    const getIcon = step => {
        if (steps[step] === 'pending') return 'more-horizontal';
        if (steps[step] === 'in progress') return 'refresh-cw';
        if (steps[step] === 'complete') return 'check';
        if (steps[step] === 'error') return 'x';
        if (steps[step] === 'warning') return 'minus';
    };

    const getIconClass = step => {
        if (steps[step] === 'in progress') return 'fa-spin';
        return '';
    };

    const getStepItem = step => {
        if (steps[step] === 'in progress') return 'active';
        if (steps[step] === 'complete') return 'complete';
        if (steps[step] === 'error') return 'error';
        if (steps[step] === 'warning') return 'warning';
        return '';
    };

    if (isNavItem) {
        return (
            <li className={`nav-item ${activeTab === step ? 'show active' : ''}`}>
                <a
                    role="tab"
                    href=" "
                    className={`nav-link ${steps[step] === 'error' ? 'text-danger' : ''}`}
                    onClick={e => {
                        e.preventDefault();
                        return setActiveTab(step);
                    }}
                >
                    {title}
                </a>
            </li>
        );
    }

    return (
        <li className={`step-item ${getStepItem(step)}`}>
            <a href=" " className="step-link" onClick={e => e.preventDefault()}>
                <span className="step-icon">
                    <FeatherIcon icon={getIcon(step)} classes={getIconClass(step)} />
                </span>
                <div>
                    <span className="step-title">{title}</span>
                    <span className="step-desc">{description}</span>
                </div>
            </a>
        </li>
    );
};

const Processing = props => {
    const {
        setDisableNextBtn,
        steps,
        errors,
        showLogs,
        lastImportData,
        setActiveTab,
        activeTab,
        title,
    } = props;

    useEffect(() => {
        const fetchData = async () => {
            setDisableNextBtn(true);
        };
        fetchData();
    }, []);

    const StepItemContent = ({ step }) => {
        if (steps[step] === 'complete' || steps[step] === 'warning') {
            return (
                <p className="text-success mg-t-15">Done</p>
            );
        }
        if (steps[step] === 'error') {
            return (
                <p className="text-danger mg-t-15">
                    {errors.length > 0 ? errors[0] : ''}
                </p>
            );
        }
        return <p>...</p>;
    };

    return (
        <div>
            <h3 id="wizard1-h-0" tabIndex="-1" className="title current">{title}</h3>
            <p className="mg-b-30">
                The following tasks will now be performed. Please monitor the execution by
                checking the import log below.
            </p>
            <div className="mg-t-20">
                <ul className="steps">
                    <StepItem
                        steps={steps}
                        step={0}
                        title="Preparing Import"
                        description="Extracting data from file and uploading to S3"
                    />
                    <StepItem
                        steps={steps}
                        step={1}
                        title="Save Dealsheet"
                        description="Downloading from S3 and saving to the database"
                    />
                    <StepItem
                        steps={steps}
                        step={2}
                        title="Match Packages"
                        description="Extracting and matching packages"
                    />
                    <StepItem
                        steps={steps}
                        step={3}
                        title="Match Devices"
                        description="Extracting and matching devices"
                    />
                </ul>
                <div className="mg-t-35">
                    <hr />
                    <Row>
                        <Col xs={6}>
                            <p className="text-muted mg-b-5">Last import date</p>
                            {lastImportData && lastImportData.created ? (
                                <strong>
                                    {moment(lastImportData.created).format('DD MMMM YYYY HH:mm:ss')}
                                </strong>) : '-'}
                        </Col>
                        <Col xs={6}>
                            <p className="text-muted mg-b-5">Details from last import</p>
                            {lastImportData && lastImportData.data ? (
                                <>
                                    <strong>{lastImportData.data.total} deals imported</strong><br />
                                    <strong>{lastImportData.data.mapped_to_packages} deals mapped to packages</strong><br />
                                    <strong>{lastImportData.data.mapped_to_device} deals mapped to devices</strong>
                                </>
                            ) : '-'}
                        </Col>
                    </Row>
                </div>
                {showLogs &&
                    <div className="mg-t-30">
                        <ul className="nav nav-tabs" role="tablist">
                            <StepItem
                                steps={steps}
                                step={0}
                                title="Preparing Import"
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                isNavItem
                            />
                            <StepItem
                                steps={steps}
                                step={1}
                                title="Save Dealsheet"
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                isNavItem
                            />
                            <StepItem
                                steps={steps}
                                step={2}
                                title="Match Packages"
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                isNavItem
                            />
                            <StepItem
                                steps={steps}
                                step={3}
                                title="Match Devices"
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                isNavItem
                            />
                        </ul>
                        <div className="tab-content bd bd-gray-300 bd-t-0 pd-20">
                            <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`} role="tabpanel">
                                <StepItemContent step={0} />
                            </div>
                            <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`} role="tabpanel">
                                <StepItemContent step={1} />
                            </div>
                            <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`} role="tabpanel">
                                <StepItemContent step={2} />
                            </div>
                            <div className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`} role="tabpanel">
                                <StepItemContent step={3} />
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    );
};

export default Processing;
