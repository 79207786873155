import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert';
import { Container, Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import {
  Breadcrumb,
  Loading,
  UpdateUserDetailsModal,
  Users,
} from 'components';
import { UsersProvider } from 'providers';
import { useQuery } from '@apollo/client';
import UsersGQL from 'queries/users';
import Select from 'react-select';
import 'react-confirm-alert/src/react-confirm-alert.css';

const UsersPage = () => {
  const { data, loading } = useQuery(UsersGQL.listRoles);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [userUpdating, setUserUpdating] = useState(false);
  const [showUpdateUserInfo, setShowUpdateUserInfo] = useState({});
  const [selectedRole, setSelectedRole] = useState('');
  const breadcrumb = [{ title: 'Users', active: true }];
  
  // async function fetchData() {
  //   const roles = await UsersProvider.getRoles();
  //   setRoles(roles.results);
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const onSubmit = (data) => {
    handleAddUser(data);
  };

  async function handleAddUser(data) {
    if (selectedRole.length > 0) {
      setSubmitting(true);
      data.role_id = selectedRole;
      const result = await UsersProvider.create(data);

      if (result.status === "success") {
        alert("User successfully added!");
        window.location = "/users";
      } else {
        setSubmitting(false);
        alert(result.message);
      }
    } else alert("Please select user role");
  }

  const handleEditUser = (data) => {
    setShowUpdateUserInfo(data);
    setShowUpdateModal(true);
  };

  const handleUpdateUserDetails = async (id, data) => {
    const result = await UsersProvider.update(id, data);

    if (result.status === "success") {
      alert("User successfully updated!");
      window.location = "/users";
      setUserUpdating(false);
      setShowUpdateModal(false);
    } else {
      setUserUpdating(false);
      alert(result.message);
    }
  };

  const handleDeleteUser = (data) => {
    confirmAlert({
      title: "Confirm Delete",
      message: `Are you sure you want to delete ${data.first_name}?`,
      buttons: [
        {
          label: "Yes, Delete",
          onClick: async () => {
            setProcessing(true);
            const result = await UsersProvider.delete(data.id);

            if (result.status === "success") {
              alert("User successfully deleted!");
              window.location = "/users";
            } else {
              setProcessing(false);
              alert(result.message);
            }
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleResetPassword = (data) => {
    confirmAlert({
      title: "Confirm Reset Password",
      message: `Are you sure you want to reset password for ${data.first_name}?`,
      buttons: [
        {
          label: "Yes, Reset",
          onClick: async () => {
            setProcessing(true);
            const result = await UsersProvider.resetPassword(data.id);

            if (result.status === "success") {
              alert("User password successfully reset and sent!");
            } else {
              setProcessing(false);
              alert(result.message);
            }
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  return (
    <Layout
      pageLoading={loading}
    >
      <div className="content content-fixed">
        <UpdateUserDetailsModal
          {...showUpdateUserInfo}
          show={showUpdateModal}
          setShow={setShowUpdateModal}
          callback={handleUpdateUserDetails}
          userUpdating={userUpdating}
          setUserUpdating={setUserUpdating}
          roles={data && data.userRoles.map(item => ({ value: item.id, label: item.label }))}
        />
        {processing && <Loading text="Processing..." />}
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <Breadcrumb heading="User Management" items={breadcrumb} />
          <form
            method="post"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col xs={10} sm={10} md={10}>
                <Row>
                  <Col xs={12} sm={3}>
                    <div
                      className={`form-group has-feedback ${errors.first_name ? "has-error" : ""
                        }`}
                    >
                      <input
                        type="text"
                        className={`form-control ${errors.first_name ? "parsley-error" : ""
                          }`}
                        placeholder="First name"
                        {...register('first_name', { required: true, minLength: 2 })}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={3}>
                    <div
                      className={`form-group has-feedback ${errors.last_name ? "has-error" : ""
                        }`}
                    >
                      <input
                        type="text"
                        className={`form-control ${errors.last_name ? "parsley-error" : ""
                          }`}
                        placeholder="Last name"
                        {...register('last_name', { required: true, minLength: 2 })}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={3}>
                    <div
                      className={`form-group has-feedback ${errors.email ? "has-error" : ""
                        }`}
                    >
                      <input
                        type="text"
                        className={`form-control ${errors.email ? "parsley-error" : ""
                          }`}
                        placeholder="Email address"
                        {...register('email', { required: true, minLength: 2 })}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={3}>
                    <div className="form-group">
                      <Select
                        options={data && data.userRoles.map(item => ({ value: item.id, label: item.label }))}
                        onChange={(item) => setSelectedRole(item.id)}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={2} sm={2} md={2}>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={submitting || selectedRole.length === 0}
                  >
                    {submitting ? "Processing..." : "Add User"}
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
      <Users.ListTable
        handleEditUser={handleEditUser}
        handleDeleteUser={handleDeleteUser}
        handleResetPassword={handleResetPassword}
      />
    </Layout>
  );
};

export default UsersPage;
