import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Layout } from 'containers';
import {
  DealsheetsProvider,
  SaleIndicatorsProvider,
} from 'providers';
import {
  Breadcrumb,
  DeviceAutoCompleteInput,
  FeatherIcon,
  Packages,
  TextEditor,
} from 'components';
import wysiwyg from 'static/wysiwyg.json';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';

const actionButtons = [{
	type: 'button-link',
	link: '/dealsheets/import',
	title: 'Import Dealsheet',
	classes: 'btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5',
	icon: <FeatherIcon icon="download" classes="wd-10 mg-r-5" />,
}, {
	type: 'button-link',
	link: '/deals/add',
	title: 'Add Deal',
	classes: 'btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-10',
	icon: <FeatherIcon icon="plus" classes="wd-10 mg-r-5" />,
}];

export default function EditDeal() {
  const { dealsheet_number } = useParams();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [id, setId] = useState('');
  const [specialText, setSpecialText] = useState('');
  const [loaderText, setLoaderText] = useState('Loading');
  const [dealsheet, setDealsheet] = useState({});
  const [processing, setProcessing] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [packageId, setPackageId] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [defaultDevice, setDefaultDevice] = useState('');
  const [defaultPackage, setDefaultPackage] = useState('');
  const [saleIndicators, setSaleIndicators] = useState([]);
  const [saleIndicator, setSaleIndicator] = useState('');
  const [defaultSaleIndicator, setDefaultSaleIndicator] = useState({});
  const [expiryDate, setExpiryDate] = useState(null);
  const breadcrumb = [
    { title: 'Deals', link: '/deals', active: false },
    { title: 'Edit Deal', active: true },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const dealsheets = await DealsheetsProvider.getAll(
        0,
        1,
        `&dealsheet_number=${dealsheet_number}`
      );
      const indicators = await SaleIndicatorsProvider.getAll();
      if (indicators.results && indicators.results.length > 0) {
        const saleIndicators = [];
        indicators.results.map((item) =>
          saleIndicators.push({ value: item.id, label: item.description })
        );
        setSaleIndicators(saleIndicators);
      }
      if (dealsheets.results && dealsheets.results.length > 0) {
        const dealsheet = dealsheets.results[0];
        setId(dealsheet.id);
        setDealsheet(dealsheet);
        setSpecialText(dealsheet.special_text || "");
        setDeviceId(dealsheet.device_id || "");
        setDefaultDevice(dealsheet.device ? `${dealsheet.device.title}` : "");
        setDefaultPackage(dealsheet.package ? dealsheet.package.name : "");
        setPackageId(dealsheet.package_id || "");
        setDefaultSaleIndicator(
          dealsheet.sale_indicator
            ? {
              value: dealsheet.sale_indicator.id,
              label: dealsheet.sale_indicator.description,
            }
            : {}
        );
        setSaleIndicator(dealsheet.sale_indicator_id || "");
        setExpiryDate(dealsheet.valid_to ? new Date(dealsheet.valid_to) : null);
      }
      setPageLoading(false);
    };
    fetchData();
  }, []);

  const onSubmit = async (formData) => {
    setLoaderText("Processing");
    setProcessing(true);
    const object = formData;
    object.sale_indicator = saleIndicator;
    object.device_id = deviceId;
    object.package_id = packageId;
    object.valid_to = moment(expiryDate).format("YYYY-MM-DD");
    object.special_text = specialText;
    const response = await DealsheetsProvider.update(id, object);
    const { status } = response;

    if (status && status === "success") {
      alert("Deal information successfully updated!");
    } else {
      alert(
        `ERROR: ${response.message ||
        "An error has occurred, please try again or contact system administrator."
        }`
      );
    }
    setProcessing(false);
    setLoaderText("Loading");
  };

  return (
    <Layout pageLoading={pageLoading || processing} loaderText={loaderText}>
      <div className="content content-fixed pd-b-0">
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <Breadcrumb
            heading={dealsheet.dealsheet_number || ""}
            items={breadcrumb}
            actions={actionButtons}
          />
        </Container>
      </div>
      {dealsheet.id && (
        <div className="contact-wrapper" style={{ top: 174 }}>
          <div className="contact-content fluid">
            <div className="contact-content-header r-f-0">
              <nav className="nav">
                <a
                  role="button"
                  href={`/deals/edit/${dealsheet_number}`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="nav-link active"
                >
                  Information
                </a>
              </nav>
            </div>
            <div className="contact-content-body ps r-f-0 overflow-y-auto">
              <div className="tab-content">
                <div className="tab-pane show active pd-20 pd-xl-25">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex align-items-center justify-content-between mg-b-25">
                      <h5 className="mg-b-0">Deal Information</h5>
                      <div className="d-flex">
                        <button
                          className="btn btn-sm btn-outline-primary d-flex align-items-center mg-r-5"
                          type="submit"
                        >
                          <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                          <span className="d-none d-sm-inline mg-l-5">
                            {" "}
                            Save
                          </span>
                        </button>
                        <button
                          className="btn btn-sm btn-white d-flex align-items-center"
                          onClick={(e) => {
                            e.preventDefault();
                            return (window.location = "/deals");
                          }}
                        >
                          <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                          <span className="d-none d-sm-inline mg-l-5">
                            {" "}
                            Cancel
                          </span>
                        </button>
                      </div>
                    </div>
                    <Row>
                      <Col xs={12} sm={3}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Dealsheet Number
                          </label>
                          <input
                            type="text"
                            name="dealsheet_number"
                            className="form-control"
                            defaultValue={dealsheet.dealsheet_number || ""}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={3}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Dealsheet Device
                          </label>
                          <input
                            type="text"
                            name="device_description"
                            className="form-control"
                            defaultValue={dealsheet.device_description || ""}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={3}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Matched Device
                          </label>
                          <DeviceAutoCompleteInput
                            defaultDevice={defaultDevice}
                            setDevice={setDeviceId}
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={3}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Package
                          </label>
                          <Packages.AutoCompleteInput
                            defaultPackage={defaultPackage}
                            setPackage={setPackageId}
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={3}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Total Subscription
                          </label>
                          <div className="input-group mg-b-10">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                style={{ minWidth: "auto" }}
                              >
                                R
                              </span>
                            </div>
                            <input
                              type="text"
                              name="total_subscription"
                              className="form-control"
                              defaultValue={dealsheet.total_subscription || ""}
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={3}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Term
                          </label>
                          <div className="input-group mg-b-10">
                            <input
                              type="text"
                              name="contract_term"
                              className="form-control"
                              defaultValue={dealsheet.contract_term || ""}
                              disabled
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                style={{ minWidth: "auto" }}
                              >
                                {dealsheet.contract_term &&
                                  dealsheet.contract_term === "1"
                                  ? "month"
                                  : "months"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={3}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Expiry Date
                          </label>
                          <DatePicker
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            selected={expiryDate}
                            onChange={(date) => setExpiryDate(date)}
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={3}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Sale Indicator
                          </label>
                          <Select
                            isLoading={saleIndicators.length === 0}
                            options={saleIndicators}
                            onChange={(item) => setSaleIndicator(item.value)}
                            value={defaultSaleIndicator}
                          />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Description
                          </label>
                          <input
                            type="text"
                            name="description"
                            className="form-control"
                            defaultValue={dealsheet.description || ""}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Free Accessory
                          </label>
                          <input
                            type="text"
                            name="free_accessory"
                            className="form-control"
                            defaultValue={dealsheet.free_accessory || ""}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Free Accessory Override
                          </label>
                          <input
                            type="text"
                            className={`form-control ${errors.free_accessory_override
                                ? "parsley-error"
                                : ""
                              }`}
                            placeholder="Enter free accessory override..."
                            {...register('free_accessory_override', { required: false })}
                            defaultValue={
                              dealsheet.free_accessory_override || ""
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Special Text
                          </label>
                          <TextEditor.WYSIWYG
                            model={specialText}
                            placeholderText="Enter special text..."
                            setModel={setSpecialText}
                            toolbarButtons={wysiwyg.toolbarButtons.specialText}
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
