import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import { DevicesProvider, ManufacturersProvider, SettingsProvider } from 'providers';
import { CSVLink } from 'react-csv';
import { useForm } from 'react-hook-form';
import templateData from 'static/base-device-template.json';
import CSVReader from 'react-csv-reader';
import PropTypes from 'prop-types';
import utils from 'utils';

const { getKey } = utils;

const UploadFile = props => {
    const {
        file,
        error,
        subheading,
        handleFileUpload,
        handleViewBeforeImport,
    } = props;
    return (
        <div>
            <p className="mg-b-20">
                {subheading}
            </p>
            <div className="legend" data-label="Select CSV File to Import">
                <CSVReader
                    cssClass="custom-file"
                    cssInputClass="custom-file-input"
                    label={<label className="custom-file-label" htmlFor="customFile">{file.name ? file.name : 'Choose file'}</label>}
                    inputId="customFile"
                    accept="text/csv"
                    onFileLoaded={handleFileUpload}
                />
                {file.size && (
                    <>
                        <span className="tx-success">
                            Selected file size:&nbsp;
                            {file.size >= 1024 && <span>{parseInt((file.size / 1024), 10)} KB</span>}
                            {file.size < 1024 && <span>{file.size} B</span>}
                        </span>
                        {/* <span>
                            .{' '}
                            <a
                                type="button"
                                href=" "
                                onClick={handleViewBeforeImport}
                            >
                                Click here
                            </a>
                            {' '}to view before importing
                        </span> */}
                    </>
                )}
                {error && (
                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                        <li className="parsley-required">{error}</li>
                    </ul>
                )}
            </div>
        </div>
    );
};

export default function ImportDevicesModal(props) {
    const {
        show,
        setShow,
        processing,
        callback,
        title,
        subheading,
        truncate,
        setTruncate,
        models,
        type,
    } = props;
    const { handleSubmit } = useForm();
    const [file, setFile] = useState({});
    const [size, setSize] = useState('md');
    const [error, setError] = useState(null);
    const [headers, setHeaders] = useState({});
    const [fileData, setFileData] = useState([]);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [activeTab, setActiveTab] = useState('import');
    const [manufacturers, setManufacturers] = useState([]);
    const [importTemplate, setImportTemplate] = useState({});
    const [disableBtn, setDisableBtn] = useState(false);
    const [componentLoading, setComponentLoading] = useState(true);
    const [showDataToBeImported, setShowDataToBeImported] = useState(false);
    const [mandatory, setMandatory] = useState([]);
    const isBaseDevice = type.toLowerCase().includes('device base models');

    async function fetchData() {
        const deviceTypes = [];
        const manufacturers = [];
        const types = await DevicesProvider.getDeviceTypes();
        const brands = await ManufacturersProvider.getAll(0, 200);
        const fields = await SettingsProvider.searchSettingByCodeKey('device', 'fields');
        const templateData = await SettingsProvider.searchSettingByCodeKey('device', 'template');

        if (templateData && templateData.value) {
            setImportTemplate(templateData.value);
        }

        // define mandatory fields based on [db] settings
        if (fields && fields.value) {
            const mandatory = [];
            const key = (isBaseDevice) ? 'base_models' : 'variants';
            fields.value[key].map(item => {
                const { name, required } = item;
                if (required) {
                    mandatory.push(name);
                }
            });
            setMandatory(mandatory);
        }

        if (types.results) {
            if (types.results.length > 0) {
                types.results.map(item => deviceTypes.push({ value: item.id, label: item.name }));
                setDeviceTypes(deviceTypes);
            }
        }
        if (brands.results) {
            if (brands.results.length > 0) {
                brands.results.map(item => manufacturers.push({ value: item.id, label: item.name }));
                setManufacturers(manufacturers);
            }
        }
        setComponentLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleClose = () => setShow(false);

    const onSubmit = () => {
        callback({ file, data: fileData, headers });
    };

    const validateCsvHeaders = data => {
        let valid = true;
        const headers = data[0];

        mandatory.map(item => {
            if (!headers.includes(item)) {
                valid = false;
            }
            return item;
        })
        return valid;
    };

    const generatePermakey = (data, base) => {
        const { manufacturer_name, model, title } = data;
        let permakey = '';
        if (base) {
            permakey = (manufacturer_name + '-' + model);
        } else {
            permakey = title;
        }
        if (permakey.includes('(')) {
            permakey = permakey
                .split('(')
                .join('')
                .split(')')
                .join('')
                .replace(' + ', ' and ')
                .split('_')
                .join('-')
                .split(' ')
                .join('-')
                .toLowerCase();
            return permakey;
        }
        permakey = permakey
            .replace(' + ', ' and ')
            .split('_')
            .join('-')
            .split(' ')
            .join('-')
            .toLowerCase();
        return permakey;
    };

    const getDeviceFeatures = (data, key, value) => {
        if (data) {
            const { children } = data;
            if (children.length > 0) {
                const features = children.map(item => {
                    if (item.title.toLowerCase().includes(key.toLowerCase())) {
                        return { ...item, value };
                    }
                    return item;
                });
                return { ...data, children: features };
            }
        }
        return null;
    }

    const formatCsvData = filedata => {
        const data = [];
        const keys = [];

        if (filedata.length > 0) {
            const headers = {};
            filedata[0].map(item => {
                const key = getKey(item);
                headers[key] = item;
            });
            setHeaders(headers);
        }

        filedata.map((a, idx) => {
            let obj = {};
            let isSIM = false;
            let isModemRouter = false;
            a.map((item, i) => {
                if (idx === 0) {
                    let key = getKey(item);
                    keys[i] = key;
                } else {
                    let key = keys[i];
                    let value = item.trim();
                    if (key === 'manufacturer') {
                        // manufacturer
                        obj['manufacturer_name'] = value;
                        key = 'manufacturer_id';
                        manufacturers.map(m => {
                            if (value.toLowerCase() === 'caterpillar' && m.label.toLowerCase() === 'cat') {
                                value = m.value;
                            } else if (m.label.toLowerCase() === value.toLowerCase()) {
                                value = m.value;
                            }
                            return true;
                        });
                        obj[key] = value;
                    } else if (key === 'device_type') {
                        // device type
                        key = 'device_type_id';
                        const deviceType = (value.toLowerCase().includes('router')) ? 'modem/router' : value.toLowerCase();
                        deviceTypes.map(d => {
                            if (d.label.toLowerCase().includes(deviceType)) {
                                value = d.value;
                                if (d.label.toLowerCase().includes('sim')) {
                                    isSIM = true;
                                } else if (d.label.toLowerCase().includes('modem')
                                    || d.label.toLowerCase().includes('router')) {
                                    isModemRouter = true;
                                }
                            }
                            return true;
                        });
                        obj[key] = value;
                    } else {
                        if (value.length > 0) {
                            obj[key] = value.trim();
                        }
                    };
                }
                return true;
            });

            if (Object.keys(obj).length > 0) {
                if ((obj.manufacturer_id && obj.manufacturer_id.length > 0) && (obj.model && obj.model.length > 0)) {
                    if (!isBaseDevice) {

                        const [baseModel] = models.filter(item => (
                            item.name.toLowerCase() === obj.model.toLowerCase() &&
                            item.manufacturer.name.toLowerCase() === obj.manufacturer_name.toLowerCase()
                        ));

                        if (baseModel) {
                            obj.model_id = baseModel.id;
                            obj.manufacturer_id = baseModel.manufacturer.id;
                            obj.device_type_id = baseModel.device_type.id;
                            obj.model = baseModel.name;
                            obj.variants = {};

                            // assign model image to device
                            if (baseModel.image) {
                                obj.images = [baseModel.image];
                                obj.image_default = baseModel.image;
                            }

                            if (obj.colour) {
                                obj.variants = { color: obj.colour };
                            }

                            // do features
                            if (obj.storage) {
                                obj.features = getDeviceFeatures(baseModel.features, 'storage', obj.storage);
                                obj.variants = { ...obj.variants, storage: obj.storage };
                            } else obj.features = baseModel.features;

                            if (Object.keys(obj.variants).length === 0) obj.variants = null;
                        }
                        delete obj.base_model;
                    }

                    obj.permakey = generatePermakey(obj, isBaseDevice);
                    obj.product_code = obj.permakey.toUpperCase();

                    // if SIM ONLY device: clear features
                    if (isSIM) obj.features = null;
                    console.log('object:', obj)
                    return data.push(obj);
                }
            }
            return true;
        });
        setFileData(data);
    };

    const handleFileUpload = (data, fileInfo) => {
        try {
            setError(null);
            setDisableBtn(true);

            if (validateCsvHeaders(data)) {
                setDisableBtn(false);
                formatCsvData(data);
                setFile(fileInfo);
            } else {
                setError(<span><strong>VALIDATION ERROR:</strong> Invalid file headers or missing mandatory columns.</span>);
            }
        } catch (err) {
            console.log(err, err.message)
            setError(
                <span>
                    <strong>ERROR:</strong> There's an error reading your file. Please make sure your file is valid.
                </span>
            );
        }
    };

    const handleViewBeforeImport = (e) => {
        e.preventDefault();
        setSize('xxl');
        setShowDataToBeImported(true);
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className="special-text-modal"
            backdrop="static"
            size={size}
        >
            <Modal.Header closeButton>
                <h4 className="modal-title">
                    {title}
                </h4>
            </Modal.Header>
            <Modal.Body>
                <form id="import-devices" method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="margin-bottom-15">
                        <Col xs={12} className="mg-b-15">
                            <UploadFile
                                file={file}
                                error={error}
                                subheading={subheading}
                                handleFileUpload={handleFileUpload}
                                handleViewBeforeImport={handleViewBeforeImport}
                            />
                        </Col>
                    </Row>
                    <Row className="margin-bottom-15">
                        <Col xs={12} className="mg-b-15">
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="truncate"
                                    checked={truncate}
                                    onChange={e => setTruncate(e.target.checked)}
                                />
                                <label
                                    className="custom-control-label" htmlFor="truncate"
                                >
                                    Truncate (Clear All {type})
                                </label>
                            </div>
                        </Col>
                    </Row>
                </form>
            </Modal.Body>
            <Modal.Footer
                className="text-left border-top"
                style={{
                    display: 'none',
                }}
            >
                {showDataToBeImported && (
                    <Row>
                        <Col xs={12}>
                            <p>Below is a list of all base devices uploaded and to be imported/saved.</p>

                            <div>
                                <ul className="nav nav-line" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${activeTab === 'import' ? 'active' : ''}`}
                                            id="import-tab"
                                            data-toggle="tab"
                                            href="#import"
                                            role="tab"
                                            aria-controls="import"
                                            aria-selected={activeTab === 'import'}
                                            onClick={e => {
                                                e.preventDefault();
                                                setActiveTab('import');
                                            }}
                                        >
                                            Data to be Imported
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${activeTab === 'upload' ? 'active' : ''}`}
                                            id="upload-tab"
                                            data-toggle="tab"
                                            href="#upload"
                                            role="tab"
                                            aria-controls="upload"
                                            aria-selected={activeTab === 'upload'}
                                            onClick={e => {
                                                e.preventDefault();
                                                setActiveTab('upload');
                                            }}
                                        >
                                            Uploaded Data
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content bd bd-gray-300 bd-t-0 pd-0" id="myTabContent">
                                    <div
                                        className={`tab-pane fade ${activeTab === 'import' ? 'show active' : ''}`}
                                        id="import"
                                        role="tabpanel"
                                        aria-labelledby="import-tab"
                                    >
                                        <div>
                                            {/* <DealsTable data={importedData} /> */}
                                        </div>
                                    </div>
                                    <div
                                        className={`tab-pane fade ${activeTab === 'upload' ? 'show active' : ''}`}
                                        id="upload"
                                        role="tabpanel"
                                        aria-labelledby="upload-tab"
                                    >
                                        <div>
                                            {/* <DealsTable data={uploadedData} imported={importedDN} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
            </Modal.Footer>
            <Modal.Footer className="border-top d-block">
                <Row>
                    <Col xs={12} md={6}>
                        {!componentLoading ? (
                            <CSVLink
                                filename={isBaseDevice
                                    ? 'base-device-import-template.csv'
                                    : 'device-import-template.csv'}
                                data={isBaseDevice
                                    ? importTemplate.base_device
                                    : importTemplate.device}
                            >
                                <button
                                    type="button"
                                    className="btn btn-secondary tx-13"
                                    disabled={componentLoading}
                                >
                                    <i className="fa fa-download mr-2" />
                                    Download Template
                                </button>
                            </CSVLink>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-secondary tx-13"
                                disabled={componentLoading}
                            >
                                <i className="fa fa-refresh fa-spin mr-2" />
                                Retrieving template...
                            </button>
                        )}
                    </Col>
                    <Col xs={12} md={6} className="text-right">
                        <button
                            type="button"
                            className="btn btn-secondary tx-13"
                            onClick={handleClose}
                        >
                            <i className="fa fa-times mr-2" />
                            Cancel
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            type="submit"
                            form="import-devices"
                            className="btn btn-primary tx-13"
                            disabled={processing || fileData.length === 0 || disableBtn}
                        >
                            {processing ? (
                                <>
                                    <i className="fa fa-refresh fa-spin mr-2" />
                                    Processing
                                </>
                            ) : (
                                <>
                                    <i className="fa fa-upload mr-2" />
                                    Import
                                </>
                            )}
                        </button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

ImportDevicesModal.propTypes = {
    title: PropTypes.string,
    subheading: PropTypes.string,
    type: PropTypes.string,
};

ImportDevicesModal.defaultProps = {
    title: 'Import Devices',
    subheading: 'Device Importer allows you to easily import devices in CSV datafile.',
    type: 'Devices',
};
