import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Container } from 'reactstrap';
import { Layout } from 'containers';
import { BulkActionsProvider, DevicesProvider } from 'providers';
import {
    Breadcrumb,
    Devices,
    FeatherIcon,
    Loading,
    ImportDevicesModal,
} from 'components';
import Swal from 'sweetalert2';
import config from '../../config';

export default function DevicesIndex() {
    const breadcrumb = [{ title: 'Devices', active: true }];
    const [filterOptions, setFilterOptions] = useState({});
    const [resultsLoading, setResultsLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [truncate, setTruncate] = useState(false);
    const [devices, setDevices] = useState([]);
    const [models, setModels] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showImportModal, setShowImportModal] = useState(false);
    const [loadingText, setLoadingText] = useState('Importing devices');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(config.results.pagination.perpage);
    const [sorting, setSorting] = useState(config.results.sorting.devices);
    const [totalRecords, setTotalRecords] = useState(0);

    async function fetchData() {
        if (!resultsLoading) setResultsLoading(true);

        const offset = 0;
        const limit = perPage;
        const orderby = `&has_image=No&orderby=[["${sorting.column}","${sorting.direction}"], ["created","desc"]]`;
        const devices = await DevicesProvider.getAll(offset, limit, orderby);
        const models = await DevicesProvider.getModels(offset, 1000);

        if (devices.count > 0) {
            setDevices(devices.results);
            setTotalRecords(devices.count);
        }

        setModels(models.results || []);
        setResultsLoading(false);
    };

    // load data on page load
    useEffect(() => {
        fetchData();
    }, []);

    const actionButtons = [
        {
            type: 'button',
            title: 'Import Devices',
            classes: 'btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5',
            icon: <FeatherIcon icon="download" classes="wd-10 mg-r-5" />,
            action: e => {
                e.preventDefault();
                setShowImportModal(true);
            }
        },
        {
            type: 'button-link',
            title: 'Add Device',
            classes: 'btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5',
            icon: <FeatherIcon icon="plus" classes="wd-10 mg-r-5" />,
            link: '/devices/add'
        },
    ];

    const handleImportDevices = async (object) => {
        setLoadingText('Importing devices');
        setProcessing(true);

        const { data } = object;

        var chunks = data.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / 40);

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // start a new chunk
            }

            resultArray[chunkIndex].push(item);
            return resultArray;
        }, []);

        let importedCount = 0;
        const failedChunk = [];
        const importError = [];

        await Promise.all(chunks.map(async (chunk, index) => {
            const query = (index === 0 && truncate) ? '?truncate=true' : '';
            const response = await BulkActionsProvider.create('devices', chunk, query);
            const { status, message } = response;
            if (status === 'success') {
                const { data } = response;
                importedCount += data.length;
                return true;
            } else {
                failedChunk.push(chunk);
                if (importError.indexOf(message) === -1) {
                    importError.push({ save_dealsheet: message });
                }
                return false;
            }
        }));

        setProcessing(false);
        setShowImportModal(false);

        // change step
        if (failedChunk.length === 0) {
            setLoadingText('Fetching devices');
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: `${importedCount} devices imported successfully!`,
                showConfirmButton: false,
                timer: 5000
            });
            return setTimeout(async function () {
                fetchData();
            }, 5000);
        }
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Failed to process request, please try again!',
            showConfirmButton: false,
            timer: 5000
        });
        return setTimeout(async function () {
            fetchData();
        }, 5000);
    };

    const handleDeleteDevice = async id => {
        return confirmAlert({
            title: 'Confirm Delete!',
            message: 'Are you sure to delete device, as this may affect deals attached to it? This process cannot be undone.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setProcessing(true);
                        setLoadingText('Deleting device');
                        const result = await DevicesProvider.delete(id);
                        setProcessing(false);
                        if (result.status === 'success') {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Device deleted successfully!',
                                showConfirmButton: false,
                                timer: 5000
                            });
                            return setTimeout(async function () {
                                fetchData();
                            }, 5000);
                        } else {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: result.message || 'Failed to process request, please try again!',
                                showConfirmButton: false,
                                timer: 5000
                            });
                        }
                        return true;
                    },
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    return (
        <Layout>
            <ImportDevicesModal
                models={models}
                truncate={truncate}
                show={showImportModal}
                setTruncate={setTruncate}
                setShow={setShowImportModal}
                callback={handleImportDevices}
                processing={processing}
            />
            {processing && <Loading text={loadingText} />}
            <div className="content content-fixed pd-b-0">
                <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <Breadcrumb
                        heading="Device Variants"
                        items={breadcrumb}
                        actions={actionButtons}
                        setShowImportModal={setShowImportModal}
                    />
                </Container>
            </div>
            <Devices.Filter
                resultsLoading={resultsLoading}
                setResultsLoading={setResultsLoading}
                devices={devices}
                setDevices={setDevices}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setTotalRecords={setTotalRecords}
                setFilterOptions={setFilterOptions}
            />
            <Devices.List
                setSelectedRows={setSelectedRows}
                resultsLoading={resultsLoading}
                setResultsLoading={setResultsLoading}
                filterOptions={filterOptions}
                setDevices={setDevices}
                devices={devices}
                sorting={sorting}
                setSorting={setSorting}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                totalRecords={totalRecords}
                setTotalRecords={setTotalRecords}
                handleDeleteDevice={handleDeleteDevice}
            />
        </Layout>
    );
}
