import React from 'react';
import { Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ImportOptions = props => {
    const {
        selected,
        networks,
        validFromDate,
        setValidFromDate,
        validToDate,
        setValidToDate,
        handleSelectNetwork,
        showDealTypeInput,
        setDisableNextBtn,
        setDealType,
    } = props;

    return (
        <>
            <h3 id="wizard1-h-0" tabIndex="-1" className="title current">Select network and additional options.</h3>
            <p className="mg-b-20">
                Dealsheet Import allows you to easily import dealsheet CSV datafiles.
            </p>
            <div className="wd-md-50p">
                <div className="form-group">
                    <label>Network Provider</label>
                    <select defaultValue={selected} className="form-control select2" onChange={e => handleSelectNetwork(e.target.value)}>
                        <option value="">Select</option>
                        {networks.length > 0 && networks.map(network => (
                            <option key={network.id} value={network.id}>{network.name}</option>
                        ))}
                    </select>
                </div>
                {showDealTypeInput &&
                <>
                    <div className="form-group">
                        <label>Deal Type</label>
                        <select
                            className="form-control select2"
                            onChange={e => {
                                setDisableNextBtn(false);
                                setDealType(e.target.value);
                            }}
                        >
                            <option value="">Select</option>
                            <option value="Generic">Generic</option>
                            <option value="Exclusive">Exclusive</option>
                            <option value="Hot Deals">Hot Deals</option>
                            <option value="Promotion">Promotion</option>
                            <option value="Recommended">Recommended</option>
                        </select>
                    </div>
                    <Row>
                        <Col xs={6}>
                            <div className="form-group">
                                <label>Valid From</label>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    selected={validFromDate}
                                    onChange={date => setValidFromDate(date)}
                                />
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="form-group">
                                <label>Valid To</label>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    selected={validToDate}
                                    onChange={date => setValidToDate(date)}
                                />
                            </div>
                        </Col>
                    </Row>
                </>}
            </div>
        </>
    );
};

export default ImportOptions;
