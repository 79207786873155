import React from 'react';
import DataTable from 'react-data-table-component';
import { FeatherIcon } from 'components';

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const RowActions = props => {
    const {
        id,
        name,
        label,
        description,
        handleEditRole,
        handleDeleteRole,
    } = props;
    return (
        <div>
            <a
                href={`/users/roles/edit/${id}`}
                onClick={(e) => {
                    e.preventDefault();
                    handleEditRole({
                        id,
                        name,
                        label,
                        description,
                    });
                }}
            >
                <FeatherIcon icon="edit" width="16" height="16" />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
                href={`/users/roles/delete/${id}`}
                className="tx-danger"
                onClick={(e) => {
                    e.preventDefault();
                    handleDeleteRole({ id, label });
                }}
            >
                <FeatherIcon icon="trash-2" width="16" height="16" />
            </a>
        </div>
    );
};

const Table = props => {
    const {
        roles,
        handleUpdate,
        resultsLoading,
    } = props;

    // table headings definition
    const columns = [{
        name: 'Name',
        selector: row => row['name'],
        sortable: true,
    },
    {
        name: 'Label',
        selector: row => row['label'],
        sortable: true,
    },
    {
        name: 'Description',
        selector: row => row['description'],
        sortable: true,
    },
    {
        name: 'Is Default?',
        selector: row => row['is_default'],
        maxWidth: '130px',
        sortable: true,
        cell: row => (
            <div className="custom-control custom-switch mr-4">
                <input
                    id="archived"
                    type="checkbox"
                    className="custom-control-input"
                    checked={row.is_default}
                    onChange={(e) => handleUpdate(row.id, {
                        is_default: e.target.checked,
                    })}
                />
                <label className="custom-control-label" htmlFor="archived">
                    {row.is_default ? 'Yes' : 'No'}
                </label>
            </div>
        ),
    },
    {
        name: 'Is Public?',
        selector: row => row['is_public'],
        maxWidth: '130px',
        sortable: true,
        cell: row => (
            <div className="custom-control custom-switch mr-4">
                <input
                    id="archived"
                    type="checkbox"
                    className="custom-control-input"
                    checked={row.is_public}
                    onChange={(e) => handleUpdate(row.id, {
                        is_public: e.target.checked,
                    })}
                />
                <label className="custom-control-label" htmlFor="archived">
                    {row.is_public ? 'Yes' : 'No'}
                </label>
            </div>
        ),
    },
    {
        name: 'Archived/Status',
        selector: row => row['archived'],
        maxWidth: '150px',
        sortable: true,
        cell: row => (
            <div className="custom-control custom-switch mr-4">
                <input
                    id="archived"
                    type="checkbox"
                    className="custom-control-input"
                    checked={!row.archived}
                    onChange={(e) => handleUpdate(row.id, {
                        archived: !e.target.checked,
                    })}
                />
                <label className="custom-control-label" htmlFor="archived">
                    {row.archived ? 'Archived' : 'Active'}
                </label>
            </div>
        ),
    },
    {
        name: 'Actions',
        sortable: false,
        maxWidth: '100px',
        cell: row => <RowActions {...row} {...props} />,
    }];

    // render datatable component
    return (
        <DataTable
            data={roles}
            columns={columns}
            customStyles={customStyles}
            highlightOnHover
            pagination
            noHeader
            progressPending={resultsLoading}
        />
    );
};

export default Table;
