import axios from 'axios';
import config from '../config';
import Session from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
let headers = {};

if (Session.getToken()) {
    headers = {
        'Authorization': `Bearer ${Session.getToken()}`,
    }
}

class BundlesProvider {
    static async getAll(offset = config.results.offset, limit = config.results.limit, filters = null) {
        return await axios({
            mode: 'no-cors',
            method: 'GET',
            url: `${baseurl}bundles?offset=${offset}&limit=${limit + (filters ? `&${filters}` : '')}`,
            crossdomain: true,
            headers,
        })
            .then((json) => json.data)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response) return err.response.data;
                return err;
            });
    }
}

export default BundlesProvider;
