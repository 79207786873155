import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import { useAuth } from 'context/auth';
import { Container } from 'reactstrap';
import { AuthProvider, SessionProvider } from 'providers';
import { ResultsModal } from 'components';
import { Footer } from 'containers';
import queryString from 'query-string';

const Login = () => {
    const { setAuthTokens } = useAuth();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [submitting, setSubmitting] = useState(false);
    const [showResultsModal, setShowResultsModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');

    useEffect(() => {
        const clearSession = async () => await SessionProvider.destroy();
        clearSession();
    }, []);

    const onSubmit = data => {
        handleLogin(data);
    };

    async function handleLogin(data) {
        setSubmitting(true);
        const { username, password } = data;
        const result = await AuthProvider.login(username, password);
        if (result.status === 'success') {
            const { data } = result;
            // notification
            Store.addNotification({
                title: 'Logged in successfully!',
                message: 'Redirecting...',
                type: 'success',
                insert: 'top',
                container: 'top-center',
                animationIn: ['animated', 'fadeIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });

            // set user menu 
            // this is based on user role (from api response)
            const menu = (data.user && data.user.user_role && data.user.user_role.settings && data.user.user_role.settings.menu) ? data.user.user_role.settings.menu : null;

            // set application configurations
            const configurations = data.configurations || null;

            // start user session
            SessionProvider.start(data.token, menu, configurations);
            setAuthTokens(data.token);

            let url = '/deals';
            const parsed = queryString.parse(window.location.search);

            if (parsed && parsed.rurl) {
                url = (`/${parsed.rurl}`).replace('//', '/');
            }

            return window.location.href = url;
        } else {
            setSubmitting(false);
            setModalTitle('Error');
            setModalBody(result.message);
            setShowResultsModal(true);
        }
    };

    return (
        <div className="auth-page-wrapper">
            <ResultsModal
                title={modalTitle}
                titleClasses="tx-danger"
                body={modalBody}
                show={showResultsModal}
                setShow={setShowResultsModal}
                buttonText="Close"
                buttonClassName="btn btn-white"
                buttonOnClick={(e) => {
                    e.preventDefault();
                    setShowResultsModal(false);
                    return true;
                }}
            />
            <div className="content content-fixed content-auth">
                <Container>
                    <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
                        <div className="media-body align-items-center d-none d-lg-flex">
                            <div className="mx-wd-600">
                                <img src="/images/img15.png" className="img-fluid" alt="" />
                            </div>
                            <div className="pos-absolute b-0 l-0 tx-12 tx-center">
                                Deal Automation Management Systems by&nbsp;
                                <a href="https://www.rewardsco.co.za" target="_blank">
                                    Rewardsco (rewardsco.co.za)
                                </a>
                            </div>
                        </div>
                        <div className="sign-wrapper">
                            <div className="wd-100p">
                                <form method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                                    {/* <div className="logo-wrapper mg-b-60">
                                        <img src="/images/logo.svg" alt="DAMS" />
                                        <div className="mg-t-5">
                                            <span className="tx-color-03">Deal Automation Management System</span>
                                        </div>
                                    </div> */}

                                    <h3 className="tx-color-01 mg-b-5">Sign In</h3>
                                    <p className="tx-color-03 tx-16 mg-b-40">Welcome back! Please signin to continue.</p>

                                    <div className={`form-group ${errors.username ? 'is-invalid' : ''}`}>
                                        <label>Username</label>
                                        <div className="input-group-lg">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.username ? 'parsley-error' : ''}`}
                                                placeholder="Email or username"
                                                disabled={submitting}
                                                {...register('username', { required: true, minLength: 3 })}
                                            />
                                        </div>
                                        {errors.username &&
                                            <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                <li className="parsley-required">This input should be a valid username.</li>
                                            </ul>}
                                    </div>

                                    <div className={`form-group ${errors.password ? 'is-invalid' : ''}`}>
                                        <div className="d-flex justify-content-between mg-b-5">
                                            <label className="mg-b-0-f">Password</label>
                                            <Link to="/auth/password/reset" className="tx-13">
                                                Forgot password?
                                            </Link>
                                        </div>
                                        <div className="input-group-lg">
                                            <input
                                                type="password"
                                                className={`form-control ${errors.password ? 'parsley-error' : ''}`}
                                                placeholder="Password"
                                                disabled={submitting}
                                                {...register('password', { required: true, minLength: 3 })}
                                            />
                                        </div>
                                        {errors.password &&
                                            <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                <li className="parsley-required">This input should be a valid password.</li>
                                            </ul>}
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block btn-lg btn-uppercase"
                                        disabled={submitting}
                                    >
                                        {submitting ? 'Processing...' : 'Sign In'}
                                    </button>
                                    <div className="divider-text">or</div>
                                    <button
                                        type="button"
                                        className="btn btn-outline-facebook btn-block"
                                    >
                                        Sign in with MSAL
                                    </button>
                                    <div className="tx-13 mg-t-20 tx-center">
                                        Don't have an account?&nbsp;
                                        <a href="mailto:">Request Account</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default Login;
