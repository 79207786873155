import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Devices, Modals, Packages } from 'components';
import { BulkActionsProvider } from 'providers';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import NumberFormat from 'react-currency-format';
import UnmatchedDevicesList from './UnmatchedDevicesList';
import UnmatchedPackagesList from './UnmatchedPackagesList';

export default function UploadDetails(props) {
    const {
        id,
        created,
        reference,
        total_deals,
        valid_to,
        fetchData,
        user,
        activeTab,
        toggleTabs,
        total_devices,
        total_packages,
        setPageLoading,
        unmatched_devices,
        unmatched_packages,
        unmatched_deals,
        setProcessing,
        getPacakges,
        getDevices,
    } = props;
    const [deviceModel, setDeviceModel] = useState({});
    const [showAddPackage, setShowAddPackage] = useState(false);
    const [showAddBaseDevice, setShowAddBaseDevice] = useState(false);
    const [showAddDeviceVariant, setShowAddDeviceVariant] = useState(false);
    if (!id) return null;
    const diff = moment().diff(valid_to, 'days');

    async function handlePackageSave() {
        setShowAddPackage(false);
        setProcessing(true);
        await getPacakges();
        setProcessing(false);
    }

    async function handleBaseDeviceSave() {
        setShowAddBaseDevice(false);
        setProcessing(true);
        await getDevices();
        setProcessing(false);
    }

    async function handleDeviceVariantSave() {
        setShowAddDeviceVariant(false);
        setProcessing(true);
        // await getPacakges();
        setProcessing(false);
    }

    const handleMapPackages = async () => {
        return confirmAlert({
            title: 'Confirm',
            message: 'Are you sure you want to auto-match packages?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setPageLoading(true);
                        await BulkActionsProvider.map('packages', { reference });
                        fetchData();
                    },
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    const handleMapDevices = async () => {
        return confirmAlert({
            title: 'Confirm',
            message: 'Are you sure you want to auto-match devices?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setPageLoading(true);
                        await BulkActionsProvider.map('devices', { reference });
                        fetchData();
                    },
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    return (
        <>
            <Modals.Default
                size="xl"
                show={showAddBaseDevice}
                setShow={setShowAddBaseDevice}
            >
                <Devices.AddBaseDevice
                    title="Add Base Device"
                    showModal={setShowAddBaseDevice}
                    saveCallback={handleBaseDeviceSave}
                    isModal
                />
            </Modals.Default>
            <Modals.Default
                size="xl"
                show={showAddDeviceVariant}
                setShow={setShowAddDeviceVariant}
            >
                <Devices.AddVariant
                    title="Add New Device Variant"
                    showModal={setShowAddDeviceVariant}
                    saveCallback={handleDeviceVariantSave}
                    deviceModel={deviceModel}
                    isModal
                />
            </Modals.Default>
            <Modals.Default
                size="xl"
                show={showAddPackage}
                setShow={setShowAddPackage}
            >
                <Packages.Add
                    title="Add New Package"
                    showModal={setShowAddPackage}
                    saveCallback={handlePackageSave}
                    isModal
                />
            </Modals.Default>
            <div className="dealsheet-upload-details">
                <div className="dealsheet-upload-details__title">
                    <Row>
                        <Col xs={12} sm={6}>
                            <Card>
                                <CardBody>
                                    <h3>
                                        Post Upload Report
                                    </h3>
                                    <p>Upload No: <strong>{reference}</strong></p>
                                    <p>
                                        Upload on&nbsp;
                                        <strong>{moment(created).format('DD MMMM YYYY')}</strong>
                                        &nbsp;at&nbsp;
                                        <strong>{moment(created).format('HH:mm')}</strong>
                                    </p>
                                    <p>Upload by <strong>{user.first_name} {user.last_name}</strong></p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} sm={3}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={6}>
                                            <small>Active Deals Uploaded</small>
                                            <h3>TOTAL</h3>
                                        </Col>
                                        <Col xs={6} className="text-right">
                                            <NumberFormat
                                                value={total_deals}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={value => <h2 className="text-success mt-2">{value}</h2>}
                                            />
                                        </Col>
                                        <Col xs={12}><hr className="mg-t-0 mg-b-10" /></Col>
                                        <Col xs={8} className="text-navy font-size-15 font-weight-400">Devices</Col>
                                        <Col xs={4} className="text-right font-size-15 font-weight-400">
                                            <NumberFormat
                                                value={total_devices || 0}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                            />
                                        </Col>

                                        <Col xs={8} className="text-navy font-size-15 font-weight-400">Packages</Col>
                                        <Col xs={4} className="text-right font-size-15 font-weight-400">
                                            <NumberFormat
                                                value={total_packages || 0}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} sm={3}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={6}>
                                            <small>Unmatched</small>
                                            <h3>Deals</h3>
                                        </Col>
                                        <Col xs={6} className="text-right">
                                            <NumberFormat
                                                value={unmatched_deals}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={value => <h2 className="text-danger mt-2">{value}</h2>}
                                            />
                                        </Col>
                                        <Col xs={12}><hr className="mg-t-0 mg-b-10" /></Col>
                                        <Col xs={8} className="text-navy font-size-15 font-weight-400">Unmatched Devices</Col>
                                        <Col xs={4} className="text-right text-danger font-size-15 font-weight-400">
                                            {unmatched_devices || 0}
                                        </Col>

                                        <Col xs={8} className="text-navy font-size-15 font-weight-400">Unmatched Packages</Col>
                                        <Col xs={4} className="text-right text-danger font-size-15 font-weight-400">
                                            {unmatched_packages || 0}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                {diff <= 0 &&
                    <div className="dealsheet-upload-details__content mg-t-15">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    role="tab"
                                    data-toggle="tab"
                                    id="devices-tab"
                                    href="#devices"
                                    aria-controls="devices"
                                    aria-selected={activeTab === 'devices'}
                                    className={`nav-link ${activeTab === 'devices' ? 'active' : ''}`}
                                    onClick={e => {
                                        e.preventDefault();
                                        toggleTabs('devices');
                                    }}
                                >
                                    <small>Unmatched</small>
                                    <span>Devices</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    role="tab"
                                    data-toggle="tab"
                                    id="packages-tab"
                                    href="#packages"
                                    aria-controls="packages"
                                    className={`nav-link ${activeTab === 'packages' ? 'active' : ''}`}
                                    aria-selected={activeTab === 'packages'}
                                    onClick={e => {
                                        e.preventDefault();
                                        toggleTabs('packages');
                                    }}
                                >
                                    <small>Unmatched</small>
                                    <span>Packages</span>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content bd bd-gray-300 bg-white bd-t-0 pd-20">
                            <div className={`tab-pane fade ${activeTab === 'devices' ? 'show active' : ''}`} id="devices" role="tabpanel" aria-labelledby="devices-tab">
                                <div>
                                    <Row>
                                        <Col sm={8}>
                                            <h5>
                                                <strong className="text-danger">
                                                    <NumberFormat
                                                        value={unmatched_devices || 0}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    />
                                                </strong>
                                                &nbsp;unmatched devices found
                                            </h5>
                                        </Col>
                                        {(unmatched_devices && unmatched_devices > 0) ?
                                        <Col sm={4} className="text-right">
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary btn-xs mr-2"
                                                onClick={handleMapDevices}
                                            >
                                                AUTO-MATCH DEVICES
                                            </button>
                                            <a
                                                href="/devices/models/add"
                                                className="btn btn-success btn-xs"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setShowAddBaseDevice(true);
                                                }}
                                            >
                                                ADD BASE DEVICE
                                            </a>
                                        </Col> : ''}
                                    </Row>
                                </div>
                                <hr />
                                {(activeTab === 'devices') &&
                                <UnmatchedDevicesList
                                    {...props}
                                    setDeviceModel={setDeviceModel}
                                    setShowVariant={setShowAddDeviceVariant}
                                />}
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'packages' ? 'show active' : ''}`} id="packages" role="tabpanel" aria-labelledby="packages-tab">
                                <div>
                                    <Row>
                                        <Col sm={8}>
                                            <h5>
                                                <strong className="text-danger">
                                                    <NumberFormat
                                                        value={unmatched_packages}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    />
                                                </strong>
                                                &nbsp;unmatched packages found
                                            </h5>
                                        </Col>
                                        {(unmatched_packages && unmatched_packages > 0) ?
                                        <Col sm={4} className="text-right">
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary btn-xs mr-2"
                                                onClick={handleMapPackages}
                                            >
                                                AUTO-MATCH PACKAGES
                                            </button>
                                            <a
                                                href="/devices/models/add"
                                                className="btn btn-success btn-xs"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setShowAddPackage(true);
                                                }}
                                            >
                                                ADD NEW PACKAGE
                                            </a>
                                        </Col> : ''}
                                    </Row>
                                </div>
                                <hr />
                                {activeTab === 'packages' &&
                                <UnmatchedPackagesList
                                    {...props}
                                />}
                            </div>
                        </div>
                    </div>}
            </div>
        </>
    );
}
