import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Layout } from 'containers';
import { useParams, useLocation } from 'react-router-dom';
import { Breadcrumb, Dealsheets } from 'components';
import {
    BulkActionsProvider,
    DataImportsProvider,
    DevicesProvider,
    PackagesProvider,
} from 'providers';
import moment from 'moment';
import queryString from 'query-string';

export default function UploadDetails() {
    const { id } = useParams();
    const location = useLocation();
    const breadcrumb = [
        { title: 'Dealsheet Uploads', link: '/dealsheets/uploads', active: false },
        { title: 'Dealsheet Upload Details', active: true },
    ];
    const [activeTab, setActiveTab] = useState('devices');
    const [pageLoading, setPageLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [upload, setUpload] = useState({});
    const [devicesReport, setDevicesReport] = useState([]);
    const [packagesReport, setPackagesReport] = useState([]);
    const [updatedPackages, setUpdatedPackages] = useState([]);
    const [activePackage, setActivePackage] = useState(null);
    const [updatedDevices, setUpdatedDevices] = useState([]);
    const [activeDevice, setActiveDevice] = useState(null);
    const [packages, setPackages] = useState([]);
    const [models, setModels] = useState([]);

    const fetchData = async () => {
        if (!pageLoading) setPageLoading(true);
        const qstr = queryString.parse(location.search);
        const importDetails = await DataImportsProvider.get(id);
        getDevices();
        getPacakges();
        await toggleTabs(qstr.tab || 'devices');
        setUpload(importDetails);
        setPageLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    async function getDevices() {
        const offset = 0;
        const limit = 1000;
        const orderby = [['manufacturer_name', 'ASC'], ['name', 'ASC']];
        const queryString = `&orderby=${JSON.stringify(orderby)}`;
        const models = await DevicesProvider.getModels(offset, limit, queryString);
        
        if (models.results) {
            const baseDevices = models.results.map(item => ({
                value: item.id,
                label: `${item.manufacturer.name} ${item.name}`,
                device_type: item.device_type.name,
            }));
            setModels(baseDevices);
        }
    }

    async function getPacakges() {
        const packages = await PackagesProvider.getAll(0, 1000);
        if (packages.results) {
            const packageList = packages.results.map(item => ({
                value: item.id,
                label: item.name,
                code: item.code,
            }));
            setPackages(packageList);
        }
    }

    async function toggleTabs(tab) {
        setPageLoading(true);
        window.history.pushState({}, null, `/dealsheets/uploads/${id}?tab=${tab}`);
        const report = await DataImportsProvider.report(id, tab);
        if (tab === 'devices') setDevicesReport(report);
        if (tab === 'packages') setPackagesReport(report);
        setActiveTab(tab);
        setPageLoading(false);
    }

    async function updateReport() {
        const { reference } = upload;
        const result = await BulkActionsProvider.update('upload_report', { reference });
        if (result.data) setUpload(result.data);
        return true;
    }

    return (
        <Layout pageLoading={pageLoading}>
            <div className="content content-fixed pd-b-0">
                <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <Breadcrumb
                        heading={upload.reference ? `${upload.network_provider.name} Dealsheet valid from ${moment(upload.valid_from).format('DD MMM YYYY')} to ${moment(upload.valid_to).format('DD MMM YYYY')}` : ''}
                        items={breadcrumb}
                    />
                </Container>
            </div>
            <div className="content content-fixed mg-t-0 pd-t-0">
                <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <Dealsheets.UploadDetails
                        {...upload}
                        packages={packages}
                        baseDevices={models}
                        activeTab={activeTab}
                        fetchData={fetchData}
                        setUpload={setUpload}
                        getDevices={getDevices}
                        toggleTabs={toggleTabs}
                        getPacakges={getPacakges}
                        setActiveTab={setActiveTab}
                        updateReport={updateReport}
                        devicesReport={devicesReport}
                        packagesReport={packagesReport}
                        setProcessing={setProcessing}
                        setPageLoading={setPageLoading}
                        setUpdatedPackages={setUpdatedPackages}
                        setUpdatedDevices={setUpdatedDevices}
                        setActivePackage={setActivePackage}
                        setActiveDevice={setActiveDevice}
                        updatedPackages={updatedPackages}
                        updatedDevices={updatedDevices}
                        activePackage={activePackage}
                        activeDevice={activeDevice}
                        processing={processing}
                    />
                </Container>
            </div>
        </Layout>
    );
}