import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ImportOptions = props => {
    const {
        selected,
        networks,
        validFromDate,
        setValidFromDate,
        validToDate,
        setValidToDate,
        handleSelectNetwork,
        showDealTypeInput,
        setDisableNextBtn,
        setDealType,
        truncateDb,
        setTruncateDb,
    } = props;
    
    useEffect(() => {
        const fetchData = async () => {
            setDisableNextBtn(false);
        };
        fetchData();
    }, []);
    return (
        <div>
            <h3 id="wizard1-h-0" tabIndex="-1" className="title current">Select Network and Additional Options.</h3>
            <p className="mg-b-10">
                Use the wizard below to map the fields in your file to fields in the database.
            </p>
            <div className="wd-md-50p">
                <div className="form-group">
                    <label>Network Provider</label>
                    <select
                        defaultValue={selected}
                        className="form-control select2"
                        onChange={e => {
                            if (e.target.value.length === 0) {
                                return setDisableNextBtn(true);
                            }
                            console.log('selected', e.target.value)
                            setDisableNextBtn(false);
                            handleSelectNetwork(e.target.value);
                        }}
                    >
                        <option value="">Select</option>
                        {networks.length > 0 && networks.map(network => (
                            <option key={network.id} value={network.id}>{network.name}</option>
                        ))}
                    </select>
                </div>
                {showDealTypeInput &&
                <>
                    <div className="form-group">
                        <label>Deal Type</label>
                        <select
                            className="form-control select2"
                            defaultValue='Generic'
                            onChange={e => {
                                if (e.target.value.length === 0) {
                                    return setDisableNextBtn(true);
                                }
                                setDisableNextBtn(false);
                                setDealType(e.target.value);
                            }}
                        >
                            <option value="">Select</option>
                            <option value="Generic">Generic</option>
                            <option value="Exclusive">Exclusive</option>
                            <option value="Hot Deals">Hot Deals</option>
                            <option value="Promotion">Promotion</option>
                            <option value="Recommended">Recommended</option>
                        </select>
                    </div>
                    <Row>
                        <Col xs={6}>
                            <div className="form-group">
                                <label>Valid From</label>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    selected={validFromDate}
                                    onChange={date => setValidFromDate(date)}
                                />
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="form-group">
                                <label>Valid To</label>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    selected={validToDate}
                                    onChange={date => setValidToDate(date)}
                                />
                            </div>
                        </Col>
                    </Row>
                    {/* <div className="form-group">
                        <label>Device Matching Criteria</label>
                        <div className="input-group">
                            <select
                                className="form-control select2"
                                onChange={e => setDeviceMatchCriteria(e.target.value)}
                                value={deviceMatchCriteria}
                            >
                                <option value="">Select</option>
                                {criteriaList && criteriaList.map(item => (
                                <option key={item.value} value={item.value}>{item.label}</option>))}
                            </select>
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-light" type="button"
                                    onClick={() => setShowDeviceMatchModal(true)}
                                >
                                    Add New
                                </button>
                            </div>
                        </div>
                    </div> */}
                    <div className="form-group">
                        <div className="custom-control custom-switch">
                            <input
                                id="truncate"
                                type="checkbox"
                                className="custom-control-input"
                                onChange={e => setTruncateDb(e.target.checked)}
                                defaultChecked={truncateDb}
                            />
                            <label className="custom-control-label" htmlFor="truncate">
                                Truncate/Delete Old Dealsheet Data from DB?
                            </label>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    );
};

export default ImportOptions;
