import React from 'react';
import { FeatherIcon } from 'components';

export default function Search(props) {
    return (
        <div className="contact-sidebar-header">
            <FeatherIcon icon="search" classes="wd-10" />
            <div className="search-form">
                <input
                    type="search"
                    className="form-control"
                    placeholder="Search users"
                />
            </div>
            <a href=" " className="btn btn-xs btn-icon btn-primary" data-toggle="modal">
                <span>
                    <FeatherIcon icon="user-plus" classes="wd-10" />
                </span>
            </a>
        </div>
    );
}