import React from 'react';
import { Modal } from 'react-bootstrap';
import { FeatherIcon } from 'components';

const UploadingDeviceImage = props => {
    const {
        show,
        setShow,
        steps,
    } = props;
    const handleClose = () => setShow(false);
    return (
        <Modal show={show} onHide={handleClose} centered className="confirm-modal" backdrop="static">
            <Modal.Body>
                <div>
                    <div>
                        <div className="content clearfix pd-0">
                            <h3 className="title">Upload in progress...</h3>
                            <p className="mg-b-20">This may take a few minutes to complete. Please do not close the window whilst upload is in progress.</p>
                            <hr />
                        </div>
                        <ul className="steps">
                            <li className={`step-item ${steps.uploadDams === 'in progress' ? 'active' : ''} ${steps.uploadDams === 'complete' ? 'complete' : ''}`}>
                                <a href="/" className="step-link" onClick={e => e.preventDefault()}>
                                    <span className="step-number">
                                        {steps.uploadDams === 'pending' &&
                                        <FeatherIcon icon="more-horizontal" width="16" height="16" />}
                                        {steps.uploadDams === 'in progress' &&
                                        <FeatherIcon icon="refresh-cw" width="16" height="16" classes="fa-spin" />}
                                        {steps.uploadDams === 'complete' &&
                                        <FeatherIcon icon="check" width="16" height="16" />}
                                    </span>
                                    <span className="step-title">Uploading to DAMS</span>
                                </a>
                            </li>
                            <li className={`step-item ${steps.uploadMondo === 'in progress' ? 'active' : ''} ${steps.uploadMondo === 'complete' ? 'complete' : ''}`}>
                                <a href="/" className="step-link" onClick={e => e.preventDefault()}>
                                    <span className="step-number">
                                        {steps.uploadMondo === 'pending' &&
                                        <FeatherIcon icon="more-horizontal" width="16" height="16" />}
                                        {steps.uploadMondo === 'in progress' &&
                                        <FeatherIcon icon="refresh-cw" width="16" height="16" classes="fa-spin" />}
                                        {steps.uploadMondo === 'complete' &&
                                        <FeatherIcon icon="check" width="16" height="16" />}
                                    </span>
                                    <span className="step-title">Uploading to Mondo</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default UploadingDeviceImage;