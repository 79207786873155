import Complete from './Complete';
import ExtractData from './ExtractData';
import FieldMapping from './FieldMapping';
import ImportOptions from './ImportOptions';
import MapInfo from './MapInfo';
import Options from './Options';
import PostUploadReport from './PostUploadReport';
import Processing from './Processing';
import Steps from './Steps';
import StepActions from './StepActions';
import UploadFile from './UploadFile';

export default {
    Complete,
    ExtractData,
    FieldMapping,
    ImportOptions,
    MapInfo,
    Options,
    PostUploadReport,
    Processing,
    Steps,
    StepActions,
    UploadFile,
};
