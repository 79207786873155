import React from 'react';

const ExpectedLayout = props => {
    const { network } = props;
    if (network.toLowerCase() === 'mtn') {
        return (
            <thead>
                <tr>
                    <th>Contract Term</th>
                    <th>Exclusive To</th>
                    <th>OEM and Device</th>
                    <th>Freebies - Description</th>
                    <th>Price Plan</th>
                    <th>Total Subscription</th>
                    <th>Deal ID Reference</th>
                    <th>Comms Start Date</th>
                    <th>Comms End Date</th>
                    <th>Pay In</th>
                    <th>Promo Offer ID</th>
                    <th>EPPIX Package</th>
                    <th>EPPIX Tariff</th>
                    <th>Package Description</th>
                    <th>Tariff Description</th>
                    <th>ON-NET Minute Bundle</th>
                    <th>Anytime Minute Bundle</th>
                    <th>SMS Bundle</th>
                    <th>Data Bundle</th>
                    <th>Bundle Description</th>
                    <th>Trader Reference</th>
                </tr>
            </thead>
        );
    }
    if (network.toLowerCase() === 'vodacom') {
        return (
            <thead>
                <tr>
                    <th>Dealsheet No</th>
                    <th>Description</th>
                    <th>Dealer Qualification</th>
                    <th>Dealer Qualification Ref Dealsheet No</th>
                    <th>Updated EPPIX</th>
                    <th>Dealsheet Type</th>
                    <th>Error Message</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Package Code</th>
                    <th>Service Codes</th>
                    <th>Financed Handset</th>
                    <th>No Financed Charges</th>
                    <th>Hero Deal</th>
                    <th>Replacement Value</th>
                    <th>Handset Cost</th>
                    <th>Cost Red Subs</th>
                    <th>Other Costs</th>
                    <th>Total Cost</th>
                    <th>SIM Support Code</th>
                    <th>SIM Support Value</th>
                    <th>Connection Support Code</th>
                    <th>Connection Support Value</th>
                    <th>Hardware Support Code</th>
                    <th>Hardware Support Value</th>
                    <th>Add Support</th>
                    <th>Channel Support</th>
                    <th>VSP Support</th>
                    <th>Customer Selling Price (Excl.Vat)</th>
                    <th>No of Units</th>
                    <th>No of IMEIS</th>
                    <th>Upload to Online</th>
                    <th>Product Code</th>
                    <th>Upgrade Product Groups</th>
                    <th>Accessory Type 1</th>
                    <th>Accessory Prod-Desc 1</th>
                    <th>Accessory Qty 1</th>
                    <th>Accessory 1 Cost</th>
                    <th>Accessory Type 2</th>
                    <th>Accessory Prod-Desc 2</th>
                    <th>Accessory Qty 2</th>
                    <th>Accessory 2 Cost</th>
                    <th>Accessory Type 3</th>
                    <th>Accessory Prod-Desc 3</th>
                    <th>Accessory Qty 3</th>
                    <th>Accessory 3 Cost</th>
                    <th>Accessory Type 4</th>
                    <th>Accessory Prod-Desc 4</th>
                    <th>Accessory Qty 4</th>
                    <th>Accessory 4 Cost</th>
                    <th>Accessory Type 5</th>
                    <th>Accessory Prod-Desc 5</th>
                    <th>Accessory Qty 5</th>
                    <th>Accessory 5 Cost</th>
                    <th>Contract Months</th>
                    <th>Apply ARPU</th>
                    <th>Accounts</th>
                    <th>Payback Period</th>
                    <th>Linked Promo Deals</th>
                    <th>DirectHW</th>
                    <th>DirectOTH</th>
                    <th>Finance Amount / Reduced Subscription</th>
                    <th>VAS 1</th>
                    <th>VAS 2</th>
                    <th>VAS 3</th>
                    <th>Accessory 1</th>
                    <th>Accessory 2</th>
                    <th>Accessory 3</th>
                    <th>Accessory 4</th>
                    <th>Handset</th>
                    <th>Total Subs</th>
                    <th>Reduced Subs</th>
                    <th>Tariff Subscription</th>
                    <th>Tariff</th>
                </tr>
            </thead>
        );
    }
    return null;
};

export default ExpectedLayout;
