import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { DevicesProvider } from 'providers';
import { useForm } from 'react-hook-form';
import { Container } from 'reactstrap';
import {
    Breadcrumb,
    Devices,
    FeatherIcon,
} from 'components';
import { Layout } from 'containers';
import Swal from 'sweetalert2';

const DeviceTypes = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const [type, setType] = useState(null);
    const [types, setTypes] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const breadcrumb = [
        { title: 'Devices', link: '/devices', active: false },
        { title: 'Types', active: true },
    ];

    const actionButtons = [{
        type: 'button',
        title: 'Add Device Type',
        classes: 'btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5',
        icon: <FeatherIcon icon="plus" classes="wd-10 mg-r-5" />,
        action: () => setShowAddModal(true),
    }];

    async function fetchData() {
        if (!pageLoading) setPageLoading(true);
        const types = await DevicesProvider.getDeviceTypes();
        setTypes(types.results || []);
        setPageLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    async function handleDelete(id, name) {
        return confirmAlert({
            title: 'Confirm Delete',
            message: `Are you sure you want to delete ${name}? This action cannot be undone, and will fail if there are any devices attached to ${name}.`,
            buttons: [
                {
                    label: 'Yes, Continue',
                    onClick: async () => {
                        setPageLoading(true);
                        const result = await DevicesProvider.deleteDeviceType(id);

                        if (result.status === 'success') {
                            setPageLoading(false);
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: `${name} successfully deleted!`,
                                showConfirmButton: false,
                                timer: 5000
                            });
                            return setTimeout(async function () {
                                fetchData();
                            }, 5000);
                        }

                        // error occured
                        setPageLoading(false);
                        return Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: result.message || 'Failed to process request, please try again!',
                            showConfirmButton: false,
                            timer: 5000
                        });
                    },
                },
                {
                    label: 'Cancel',
                },
            ],
        });
    }

    async function handleUpdate(id, data) {
        setPageLoading(true);
        const result = await DevicesProvider.updateDeviceType(id, data);
        const { status, message } = result;

        if (status === 'success') {
            setPageLoading(false);
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: `${data.name || 'Device Type'} successfully updated!`,
                showConfirmButton: false,
                timer: 5000
            });
            return setTimeout(async function () {
                fetchData();
            }, 5000);
        }

        // error occured
        setPageLoading(false);
        return Swal.fire({
            position: 'center',
            icon: 'error',
            title: message || 'Failed to process request, please try again!',
            showConfirmButton: false,
            timer: 5000
        });
    }

    async function onUpdate(data) {
        const { name } = data;
        const permakey = name.toLowerCase().split(' ').join('-');
        return handleUpdate(type.id, { name, permakey });
    }

    async function onCreate(data) {
        setPageLoading(true);
        data.permakey = data.name.toLowerCase().split(' ').join('-');
        const result = await DevicesProvider.createDeviceType(data);
        const { status, message } = result;

        if (status === 'success') {
            setPageLoading(false);
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: `${data.name} successfully created!`,
                showConfirmButton: false,
                timer: 5000
            });
            return setTimeout(async function () {
                fetchData();
            }, 5000);
        }

        // error occured
        setPageLoading(false);
        return Swal.fire({
            position: 'center',
            icon: 'error',
            title: message || 'Failed to process request, please try again!',
            showConfirmButton: false,
            timer: 5000
        });
    }

    return (
        <Layout pageLoading={pageLoading}>
            <Devices.UpdateType
                type={type}
                reset={reset}
                errors={errors}
                setType={setType}
                register={register}
                onUpdate={onUpdate}
                show={showUpdateModal}
                handleSubmit={handleSubmit}
                setShow={setShowUpdateModal}
            />
            <Devices.AddType
                reset={reset}
                errors={errors}
                register={register}
                onCreate={onCreate}
                show={showAddModal}
                setShow={setShowAddModal}
                handleSubmit={handleSubmit}
            />
            <div className="content content-fixed pd-b-0 device-types">
                <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <Breadcrumb
                        heading="Manage Device Types"
                        actions={actionButtons}
                        items={breadcrumb}
                    />
                </Container>
            </div>
            <Devices.TypeList
                data={types}
                setType={setType}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                setShowUpdateModal={setShowUpdateModal}
            />
        </Layout>
    );
};

export default DeviceTypes;
