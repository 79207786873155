import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Container } from 'reactstrap';
import { Layout } from 'containers';
import { Breadcrumb, DealsheetImport, FeatherIcon, Modals } from 'components';
import {
    BulkActionsProvider,
    BundlesProvider,
    DataImportsProvider,
    NetworksProvider,
    SaleIndicatorsProvider,
    SessionProvider,
} from 'providers';
import Papa from 'papaparse';
import S3 from 'react-aws-s3';
import moment from 'moment';
import Swal from 'sweetalert2';
import config from '../../config';

window.Buffer = window.Buffer || require('buffer').Buffer;

const actionButtons = [
    {
        type: 'button',
        link: '/dealsheets/import',
        title: 'Re-start Import',
        classes: 'btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5',
        icon: <FeatherIcon icon="refresh-cw" classes="wd-10 mg-r-5" />,
        action: () => window.location = '/dealsheets/import',
    }
];

const ImportDealsheet = () => {
    const breadcrumb = [{ title: 'Import Dealsheet', active: true }];
    const [step, setStep] = useState(1);
    const [file, setFile] = useState({});
    const [fields, setFields] = useState({});
    const [network, setNetwork] = useState({});
    const [networks, setNetworks] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [dealType, setDealType] = useState('Generic');
    const [retryImport, setRetryImport] = useState(false);
    const [importData, setImportData] = useState([]);
    const [networkName, setNetworkName] = useState('');
    const [importErrors, setImportErrors] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [showImportLogs, setShowImportLogs] = useState(false);
    const [truncateDb, setTruncateDb] = useState(false);
    const [disableNextBtn, setDisableNextBtn] = useState(true);
    const [disableFinishBtn, setDisableFinishBtn] = useState(true);
    const [invalidLayout, setInvalidLayout] = useState(false);
    const [showDealTypeInput, setShowDealTypeInput] = useState(false);
    const [referenceNumber, setReferenceNumber] = useState(null);
    const [showDeviceMatchModal, setShowDeviceMatchModal] = useState(false);
    const [deviceMatchCriteria, setDeviceMatchCriteria] = useState('default');
    const [uploadReferenceId, setUploadReferenceId] = useState(null);
    const [lastImportData, setLastImportData] = useState(null);
    const [uploadFileName, setUploadFileName] = useState(null);
    const [saleIndicator, setSaleIndicator] = useState({});
    const [showRecordsToBeImported, setShowRecordsToBeImported] = useState(false);
    const [deviceMatchModalProcessing, setDeviceMatchModalProcessing] = useState(false);
    const [deviceMatchCriteriaList, setDeviceMatchCriteriaList] = useState([
        { value: 'default', label: 'Default' },
        { value: 'two', label: '[Manufacturer] [Model] [Storage] [Colour]' },
        { value: 'three', label: '[Manufacturer] [Model] [Colour] [Storage]' }
    ]);
    const [bundles, setBundles] = useState([]);
    const [settings, setSettings] = useState({});
    const [importedDeals, setImportedDeals] = useState([]);
    const [validFromDate, setValidFromDate] = useState(new Date());
    const [validToDate, setValidToDate] = useState(new Date());
    const [importedDealsTotal, setImportedDealsTotal] = useState(0);
    const [importedDealsFailed, setImportedDealsFailed] = useState(0);
    const [processingActiveTab, setProcessingActiveTab] = useState(0);
    const [importedPackages, setImportedPackages] = useState(0);
    const [importedDevices, setImportedDevices] = useState(0);
    const [processingTitle, setProcessingTitle] = useState('');
    const [importSteps, setImportSteps] = useState([
        'pending',
        'pending',
        'pending',
        'pending',
    ]);

    async function fetchData() {
        const settings = await DataImportsProvider.settings();
        const networks = await NetworksProvider.getAll();
        const bundles = await BundlesProvider.getAll();
        const imports = await DataImportsProvider.getAll(0, 1, 'description=dealsheet_import&orderby=[["created", "desc"]]');
        const saleIndicator = await SaleIndicatorsProvider.getAll();
        if (networks.results) {
            if (networks.results.length > 0) {
                setNetworks(networks.results);
            }
        }
        if (bundles.results) {
            if (bundles.results.length > 0) {
                setBundles(bundles.results);
            }
        }
        if (imports.results) {
            if (imports.results.length > 0) {
                setLastImportData(imports.results[0]);
            }
        }
        if (saleIndicator.results) {
            if (saleIndicator.results.length > 0) {
                setSaleIndicator(saleIndicator.results.filter(item => item.name === 'Digital')[0]);
            }
        }
        setSettings(settings);
        setPageLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleFileUpload = async (e) => {
        let filename = 'DU';

        const file = e.target.files[0];

        setDisableNextBtn(true);
        setFile({});

        if (!e.target.files || e.target.files.length === 0) return false;

        if (file.name) {
            switch (true) {
                case (file.name.toUpperCase().includes('MTN')):
                    const [mtn] = networks.filter(item => item.name.toUpperCase().includes('MTN'));
                    handleSelectNetwork(mtn.id);
                    filename += mtn.name.substring(0, 3).toUpperCase();
                    break;

                case (file.name.toUpperCase().includes('VODACOM')):
                    const [vodacom] = networks.filter(item => item.name.toUpperCase().includes('VODACOM'));
                    handleSelectNetwork(vodacom.id);
                    filename += vodacom.name.substring(0, 3).toUpperCase();
                    break;

                case (file.name.toUpperCase().includes('TELKOM')):
                    const [telkom] = networks.filter(item => item.name.toUpperCase().includes('TELKOM'));
                    handleSelectNetwork(telkom.id);
                    filename += telkom.name.substring(0, 3).toUpperCase();
                    break;

                // wrong filename
                default:
                    return Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Invalid filename. Correct network prodiver name needs to be specified in the CSV file name.',
                        showConfirmButton: true,
                        confirmButtonText: 'Okey, try again!',
                        confirmButtonColor: '#0168fa',
                    });

            }
        }
        
        const fna = file.name.split('.');
        const ext = fna[fna.length - 1];
        
        filename += moment().format('YYMMDDHHmm');
        filename += `.${ext}`;

        // Passing file data (file) to parse using Papa.parse
        return Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setFile(file);
                setFileData(results.data);
                setUploadFileName(filename);
                setDisableNextBtn(false);
            },
        });
    };

    const confirmImport = () => {
        let message = `
            You are about to import ${networkName} dealsheet (for ${dealType} deals 
            valid from ${moment(validFromDate).format('DD-MMM-YYYY')} 
            to ${moment(validToDate).format('DD-MMM-YYYY')}). 
            ${truncateDb ? 'NB: All Previous DB Data will be Erased. ' : ''}
            Do you wish to continue?`;

        return confirmAlert({
            title: 'Confirm Import',
            message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        handleExtract();
                        setStep(step + 1);
                        setDisableNextBtn(true);
                        setInvalidLayout(false);
                    },
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    const handleExtract = async () => {
        const errors = [];
        setProcessingActiveTab(0);
        setProcessingTitle('Importing Dealsheet');
        setImportSteps([
            'in progress',
            'pending',
            'pending',
            'pending',
        ]);

        // insert import configuration into the database
        const referenceNumber = uploadFileName.replace('.csv', '');
        const uploadResult = await DataImportsProvider.post(
            'dealsheets',
            'upload',
            {
                provider: network,
                description: 'dealsheet_import',
                reference: referenceNumber,
                total_deals: importedDeals.length,
                valid_from: moment(validFromDate).format('YYYY-MM-DD'),
                valid_to: moment(validToDate).format('YYYY-MM-DD'),
                status: 'UPLOADED',
                data: {
                    networkName,
                    uploadFileName,
                    truncateDb,
                    fieldMapping: importData,
                },
            }
        );
        
        // retrieve configurations
        const configurations = SessionProvider.getConfigurations();

        // upload file to s3 bucket
        const {
            bucketName,
            region,
        } = config.aws.s3.options;

        const s3config = {
            region,
            bucketName,
            dirName: 'dealsheet-uploads',
            accessKeyId: configurations.ASSET_ACCESS_KEY_ID,
            secretAccessKey: configurations.ASSET_SECRET_ACCESS_KEY,
        };

        const ReactS3Client = new S3(s3config);
        const uploadInfo = await ReactS3Client
            .uploadFile(file, uploadFileName)
            .then(data => data)
            .catch(err => {
                errors.push(err);
                return false;
            });

        if (!uploadInfo) {
            setImportErrors(errors);
            setShowImportLogs(true);
            setProcessingTitle('Importing Error');
            return setImportSteps([
                'error',
                'pending',
                'pending',
                'pending',
            ]);
        }

        if (uploadResult.status && uploadResult.status === 'success') {
            setUploadReferenceId(uploadResult.data.id);
            setProcessingTitle('Processing Dealsheet');
            setProcessingActiveTab(1);
            setImportSteps([
                'complete',
                'in progress',
                'pending',
                'pending',
            ]);

            return;

            const extractResult = await DataImportsProvider.post(
                'dealsheets',
                'extract',
                {
                    import_id: uploadResult.data.id,
                    ...uploadInfo,
                }
            );

            if (extractResult.status && extractResult.status === 'success') {

                const failedRecords = uploadResult.data.failed;
                setImportedDealsFailed(failedRecords);
                setProcessingTitle('Mapping Packages');
                setProcessingActiveTab(2);
                setImportSteps([
                    'complete',
                    'complete',
                    'in progress',
                    'pending',
                ]);
                return mapPackages(referenceNumber);
            } else errors.push(extractResult.message || 'An unkown error has occurred. Please try again or contact system administrator for further investigation!');

            setImportErrors(errors);
            setShowImportLogs(true);
            setProcessingTitle('Saving Dealsheet Error');

            // remove import
            if (uploadResult.data && uploadResult.data.id) {
                await DataImportsProvider.delete(uploadResult.data.id, false);
            }
            
            return setImportSteps([
                'complete',
                'error',
                'pending',
                'pending',
            ]);
        } else errors.push(uploadResult.message || 'An unkown error has occurred. Please try again or contact system administrator for further investigation!');

        setImportErrors(errors);
        setShowImportLogs(true);
        setProcessingTitle('Importing Error');

        // remove import
        if (uploadResult.data && uploadResult.data.id) {
            await DataImportsProvider.delete(uploadResult.data.id, false);
        }
        
        return setImportSteps([
            'error',
            'pending',
            'pending',
            'pending',
        ]);
    };

    const mapPackages = async (reference, continueWithWarning = false) => {
        const importError = importErrors;
        // const exctractResult = await BulkActionsProvider.extract('packages');
        const mapResult = await BulkActionsProvider.map('packages', { network: networkName, reference });
        // setImportedPackages(exctractResult.count || 0);
        if (mapResult.status && mapResult.status === 'success') {
            setProcessingTitle('Mapping Devices');
            setProcessingActiveTab(3);
            if (continueWithWarning) {
                setImportSteps([
                    'complete',
                    'warning',
                    'complete',
                    'in progress',
                ]);
                return mapDevices(reference, true);
            } else {
                setImportSteps([
                    'complete',
                    'complete',
                    'complete',
                    'in progress',
                ]);
                return mapDevices(reference);
            }
        } else importError.push(mapResult.message);

        if (continueWithWarning) {
            setProcessingTitle('Package Mapping Error');
            setImportSteps([
                'complete',
                'warning',
                'error',
                'pending',
            ]);
        } else {
            setProcessingTitle('Package Mapping Error');
            setImportSteps([
                'complete',
                'complete',
                'error',
                'pending',
            ]);
        }
        setRetryImport(true);
        setDisableNextBtn(false);
        setImportErrors(importError);
        setShowImportLogs(true);
    };

    /**
     * Map devices to deals
     * 
     * @param {bool} continueWithWarning 
     * @returns 
     */
    const mapDevices = async (reference, continueWithWarning = false) => {
        const response = await BulkActionsProvider.map('devices', { network: networkName, reference });
        if (response.status === 'success') {
            setProcessingTitle('Import Complete');
            if (continueWithWarning) {
                setImportSteps([
                    'complete',
                    'warning',
                    'complete',
                    'complete',
                ]);
            } else {
                setImportSteps([
                    'complete',
                    'complete',
                    'complete',
                    'complete',
                ]);
            }
            setStep(5);
            setDisableNextBtn(false);
            setImportedDevices(response.count || 0);
            setDisableFinishBtn(false);
            return setDisableNextBtn(false);
        }
        setShowImportLogs(true);
        setRetryImport(true);
        setDisableNextBtn(false);
        setProcessingTitle('Device Mapping Error');
        if (continueWithWarning) {
            setImportSteps([
                'complete',
                'warning',
                'complete',
                'error',
            ]);
        } else {
            setImportSteps([
                'complete',
                'complete',
                'complete',
                'error',
            ]);
        }
    };

    /**
     * Handle network selectio
     * 
     * @param {string} value
     */
    const handleSelectNetwork = value => {
        setNetwork(value);
        setShowDealTypeInput(false);
        setNetworkName('');
        networks.map(item => {
            if (item.id === value) {
                setNetworkName(item.name);
                setDisableNextBtn(true);
                setShowDealTypeInput(true);
                switch (item.name.toLowerCase()) {
                    case 'mtn':
                        setValidFromDate(new Date(moment().format('YYYY-MM-09')));
                        setValidToDate(new Date(moment().add(1, 'month').format('YYYY-MM-08')));
                        break;

                    case 'vodacom':
                        setValidFromDate(new Date(moment().format('YYYY-MM-03')));
                        setValidToDate(new Date(moment().add(1, 'month').format('YYYY-MM-06')));
                        break;

                    default:
                        setValidFromDate(new Date(moment().format('YYYY-MM-02')));
                        setValidToDate(new Date(moment().add(1, 'month').format('YYYY-MM-05')));
                        break;
                }
            }
            return item;
        });
        return true;
    };

    /**
     * Save device match criteria
     * 
     * @param {object} data
     */
    const handleSaveDeviceMatchCriteria = data => {
        const { criteria } = data;
        const list = deviceMatchCriteriaList;
        list.push({ value: criteria, label: criteria });
        setShowDeviceMatchModal(false);
        setDeviceMatchModalProcessing(false);
        setDeviceMatchCriteriaList(list);
        setDeviceMatchCriteria(criteria);
    };

    return (
        <Layout pageLoading={pageLoading}>
            <Modals.DeviceMatchCriteria
                callback={handleSaveDeviceMatchCriteria}
                show={showDeviceMatchModal}
                setShow={setShowDeviceMatchModal}
                processing={deviceMatchModalProcessing}
                setProcessing={setDeviceMatchModalProcessing}
            />
            {showRecordsToBeImported &&
                <Modals.DealsToBeImported
                    fields={fields}
                    bundles={bundles}
                    network={network}
                    dealType={dealType}
                    settings={settings}
                    fileData={fileData}
                    networkName={networkName}
                    saleIndicator={saleIndicator}
                    uploadedCount={fileData.length}
                    show={showRecordsToBeImported}
                    setImportedDeals={setImportedDeals}
                    setShow={setShowRecordsToBeImported}
                    setReferenceNumber={setReferenceNumber}
                    setImportedDealsTotal={setImportedDealsTotal}
                    importedDealsTotal={importedDealsTotal}
                    setShowLoader={setPageLoading}
                    importedDeals={importedDeals}
                    validFromDate={validFromDate}
                    validToDate={validToDate}
                />
            }
            <div className="content content-fixed pd-b-0">
                <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <Breadcrumb
                        heading="Import Dealsheet from File"
                        items={breadcrumb}
                        actions={actionButtons}
                    />
                </Container>
            </div>
            <div className="tx-13 mg-b-25">
                <div className="wizard clearfix">
                    <DealsheetImport.Steps step={step} />
                    <div className="content clearfix">
                        <section className="body current" role="tabpanel">
                            {step === 1 &&
                                <DealsheetImport.UploadFile
                                    file={file}
                                    handleFileUpload={handleFileUpload}
                                />}
                            {step === 2 &&
                                <DealsheetImport.ImportOptions
                                    selected={network}
                                    networks={networks}
                                    truncateDb={truncateDb}
                                    validToDate={validToDate}
                                    validFromDate={validFromDate}
                                    showDealTypeInput={showDealTypeInput}
                                    criteriaList={deviceMatchCriteriaList}
                                    showDeviceMatchModal={showDeviceMatchModal}
                                    deviceMatchCriteria={deviceMatchCriteria}
                                    setDealType={setDealType}
                                    setTruncateDb={setTruncateDb}
                                    setValidToDate={setValidToDate}
                                    setValidFromDate={setValidFromDate}
                                    setDisableNextBtn={setDisableNextBtn}
                                    handleSelectNetwork={handleSelectNetwork}
                                    setDeviceMatchCriteria={setDeviceMatchCriteria}
                                    setShowDeviceMatchModal={setShowDeviceMatchModal}
                                />}
                            {step === 3 &&
                                <DealsheetImport.FieldMapping
                                    file={file}
                                    fields={fields}
                                    network={networkName}
                                    fileData={fileData}
                                    setFields={setFields}
                                    tableData={importData}
                                    setTableData={setImportData}
                                    pageLoading={pageLoading}
                                    setPageLoading={setPageLoading}
                                    setDisableNextBtn={setDisableNextBtn}
                                    setShowRecordsToBeImported={setShowRecordsToBeImported}
                                    setShowLoader={setPageLoading}
                                    settings={settings}
                                />}
                            {step === 4 &&
                                <DealsheetImport.Processing
                                    steps={importSteps}
                                    errors={importErrors}
                                    showLogs={showImportLogs}
                                    setSteps={setImportSteps}
                                    lastImportData={lastImportData}
                                    setDisableNextBtn={setDisableNextBtn}
                                    setActiveTab={setProcessingActiveTab}
                                    activeTab={processingActiveTab}
                                    setShowLogs={setShowImportLogs}
                                    setTitle={setProcessingTitle}
                                    title={processingTitle}
                                />}
                            {step === 5 &&
                                <DealsheetImport.Complete
                                    dealType={dealType}
                                    networkName={networkName}
                                    validToDate={validToDate}
                                    validFromDate={validFromDate}
                                    dealsTotal={importedDealsTotal}
                                    dealsFailed={importedDealsFailed}
                                    packages={importedPackages}
                                    devices={importedDevices}
                                />}
                            {step === 6 &&
                                <DealsheetImport.PostUploadReport
                                    id={uploadReferenceId}
                                    pageLoading={pageLoading}
                                    setPageLoading={setPageLoading}
                                />}
                        </section>
                    </div>

                    {step < 6 &&
                        <DealsheetImport.StepActions
                            step={step}
                            retry={retryImport}
                            setStep={setStep}
                            confirmImport={confirmImport}
                            invalidLayout={invalidLayout}
                            disableNextBtn={disableNextBtn}
                            setPageLoading={setPageLoading}
                            disableFinishBtn={disableFinishBtn}
                            setDisableNextBtn={setDisableNextBtn}
                        />}
                </div>
            </div>
        </Layout>
    );
};

export default ImportDealsheet;
