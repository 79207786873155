import React from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import queryString from 'query-string';
import { FeatherIcon } from 'components';
import { Link } from 'react-router-dom';
import { DevicesProvider } from 'providers';
import config from '../../config';

const RowActions = ({ id, name, manufacturer, handleDeleteRecord }) => {
    return (
        <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to={`/devices/models/edit/${id}`}>
                <FeatherIcon icon="edit" width="16" height="16" />
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
                href={`/devices/models/delete/${id}`}
                className="tx-danger"
                onClick={e => {
                    e.preventDefault();
                    return handleDeleteRecord(id, `${manufacturer.name} ${name}`);
                }}
            >
                <FeatherIcon icon="trash-2" width="16" height="16" />
            </a>
        </div>
    );
};

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const Table = props => {
    const {
        handleRowSelected,
        results,
        setResults,
        resultsLoading,
        setResultsLoading,
        page,
        setPage,
        perPage,
        setPerPage,
        totalRecords,
        setTotalRecords,
        sorting,
        setSorting,
        filterOptions,
    } = props;

    // table headings definition
    const columns = [
    {
        name: 'Manufacturer',
        selector: row => row['manufacturer_name'],
        sortable: true,
        cell: row => <span>{row.manufacturer.name}</span>
    },
    {
        name: 'Model',
        selector: row => row['name'],
        sortable: true,
    },
    {
        name: 'Device type',
        sortable: false,
        cell: row => <span>{row.device_type.name}</span>
    },
    {
        name: 'Image',
        selector: row => row['image'],
        sortable: false,
        cell: row => <span>{(row.image && row.image.length > 0) ? 'Y' : 'N'}</span>
    },
    {
        name: 'Released',
        selector: row => row['released'],
        sortable: true,
        cell: row => <span>{row.released ? moment(row.released).format('DD MMM YYYY') : <em className="text-muted">no specified</em>}</span>
    },
    {
        name: 'Created',
        selector: row => row['created'],
        sortable: true,
        cell: row => <span>{row.created && moment(row.created).format('DD MMM YYYY')}</span>
    },
    {
        name: 'Last Modified',
        selector: row => row['last_updated'],
        sortable: true,
        cell: row => <span>{row.last_updated && moment(row.last_updated).format('DD MMM YYYY')}</span>
    },
    {
        name: 'Actions',
        sortable: false,
        cell: row => <RowActions {...row} {...props} />
    }];

    const fetchData = async (perPage, page, sorting) => {
        setResultsLoading(true);
        const offset  = (page - 1) * perPage;
        const limit   = perPage;
        let orderby = '';
        let devices   = [];

        if (sorting.length > 0) {
            orderby = `&orderby=${JSON.stringify(sorting)}`;
        } else {
            orderby = `&orderby=[["${sorting.column}", "${sorting.direction}"]]`;
        }

        if (Object.keys(filterOptions).length > 0) {
            const querystr = queryString.stringify(filterOptions);
            devices = await DevicesProvider.getModels(offset, limit, `&${querystr}` + orderby);
        } else {
            devices = await DevicesProvider.getModels(offset, limit, orderby);
        }
        setResults(devices.results);
        setTotalRecords(devices.count);
        setResultsLoading(false);
    };

    const handlePerRowsChange = async (perPage, page) => {
        fetchData(perPage, page, sorting);
        setPerPage(perPage);
    };

    const handlePageChange = async (page) => {
        fetchData(perPage, page, sorting);
        setPage(page);
    };

    const handleSort = (column, sortDirection) => {
        const sortingOrder = {
            column: column.selector,
            direction: sortDirection,
        };
        setSorting(sortingOrder);
        fetchData(perPage, page, sortingOrder);
    };

    // render datatable component
    return (
        <DataTable
            data={results}
            columns={columns}
            customStyles={customStyles}
            onSelectedRowsChange={handleRowSelected}
            selectableRows={false}
            highlightOnHover
            pagination
            noHeader
            progressPending={resultsLoading}
            paginationServer
            paginationTotalRows={totalRecords}
            paginationRowsPerPageOptions={config.results.pagination.perpageOptions}
            paginationPerPage={perPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSort={handleSort}
        />
    );
};

export default Table;
