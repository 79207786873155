import AWS from 'aws-sdk/global';
import config from '../config';

class FileManagerProvider {
  /**
   * List AWS S3 Objects
   * Returns some or all (up to 1,000) of the objects in a
   * bucket. You can use the request parameters as selection
   * criteria to return a subset of the objects in a bucket.
   * A 200 OK response can contain valid or invalid XML.
   * Make sure to design your application to parse the
   * contents of the response and handle it appropriately.
   * 
   * @param {string} folder  (optional)
   */
  static async list(folder = '', itemCount = 1000, options = config.aws.s3.devices.options) {
    AWS.config.update(options);
    const s3 = new AWS.S3();
    const Bucket = options.bucketName; 
    return new Promise((resolve, reject) => {
      const params = {
        Bucket,
        MaxKeys: itemCount,
        Prefix: `${config.aws.s3.options.dirName}/${folder}`,
      };
      s3.listObjectsV2(params, (err, data) => {
        if (err) reject(err);
        resolve(data.Contents);
      });
    });
  }

  /**
   * Retrieve objects from AWS S3.
   * To use GET, you must have READ access to the object.
   * If you grant READ access to the anonymous user, you
   * can return the object without using an authorization
   * header.
   * 
   * @param {string} key
   */
  static async get(Key, options = config.aws.s3.devices.options) {
    AWS.config.update(options);
    const s3 = new AWS.S3();
    const Bucket = options.bucketName; 
    return new Promise((resolve, reject) => {
      const params = {
        Bucket,
        Key,
      };
      s3.getObject(params, (err, data) => {
        if (err) reject(err);
        resolve(data);
      });
    });
  }

  /**
   * Upload/Add File to AWS S3.
   * Adds an object to a bucket. You must have WRITE
   * permissions on a bucket to add an object to it.
   * 
   * @param {string} key 
   */
  static async upload(Body, Key, options = config.aws.s3.devices.options) {
    AWS.config.update(options);
    const s3 = new AWS.S3();
    const Bucket = options.bucketName; 
    return new Promise((resolve, reject) => {
      const params = {
        Bucket,
        Body,
        Key,
      };
      s3.putObject(params, (err, data) => {
        if (err) reject(err);
        resolve(data);
      });
    });
  }

  static async uploadJson(json, Key, options = config.aws.s3.options) {
    AWS.config.update(options);
    const s3 = new AWS.S3();
    const Bucket = options.bucketName; 
    const buf = Buffer.from(JSON.stringify(json));
    return new Promise((resolve, reject) => {
      const params = {
        Bucket,
        Key,
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: 'application/json',
        ACL: 'public-read'
      };
      s3.putObject(params, (err, data) => {
        if (err) reject(err);
        resolve(data);
      });
    });
  }
}

export default FileManagerProvider;
