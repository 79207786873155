import React from 'react';
import { useQuery } from '@apollo/client';
import UsersGQL from 'queries/users';

export default function Export() {
    const { data, loading } = useQuery(UsersGQL.listRoles);
    return (
        <div className="pd-y-25 pd-x-20">
            <h6 className="tx-12 tx-semibold tx-spacing-1 tx-uppercase">Export Users</h6>
            <p className="tx-13 tx-color-03 mg-b-20">
                You can export users and import them into other email apps.
            </p>
            <div className="form-group">
                <label className="tx-13">Which users do you want to export?</label>
                <select className="custom-select tx-13">
                    <option value="all">All Users</option>
                    {data && data.userRoles && data && data.userRoles.map((item) => (
                        <option
                            key={item.id}
                            value={item.id}
                        >
                            {item.label}
                        </option>
                    ))}
                </select>
            </div>
            <button className="btn btn-sm btn-primary">
                Export
            </button>
        </div>
    );
}