import React from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { FeatherIcon } from 'components';

const RowActions = props => {
    const {
        id,
        role,
        first_name,
        last_name,
        email,
        role_id,
        handleEditUser,
        handleDeleteUser,
        handleResetPassword,
    } = props;
    return (
        <div>
            <a
                href={`/users/edit/${id}`}
                onClick={(e) => {
                    e.preventDefault();
                    handleEditUser({
                        id,
                        first_name,
                        last_name,
                        email,
                        role_id,
                        role,
                    });
                }}
            >
                <FeatherIcon icon="edit" width="16" height="16" />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
                href={`/users/reset/${id}`}
                onClick={(e) => {
                    e.preventDefault();
                    handleResetPassword({ id, first_name });
                }}
            >
                <FeatherIcon icon="refresh-cw" width="16" height="16" />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
                href={`/users/delete/${id}`}
                className="tx-danger"
                onClick={(e) => {
                    e.preventDefault();
                    handleDeleteUser({ id, first_name });
                }}
            >
                <FeatherIcon icon="trash-2" width="16" height="16" />
            </a>
        </div>
    );
};

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const Table = props => {
    const {
        handleRowSelected,
        users,
        resultsLoading,
    } = props;

    // table headings definition
    const columns = [{
        name: 'First name',
        selector: row => row['first_name'],
        sortable: true,
    },
    {
        name: 'last name',
        selector: row => row['last_name'],
        sortable: true,
    },
    {
        name: 'Username',
        selector: row => row['username'],
        sortable: true,
    },
    {
        name: 'Role',
        selector: row => row['role'],
        sortable: false,
        cell: row => <span>{row.role.description}</span>
    },
    {
        name: 'Email',
        selector: row => row['email'],
        sortable: true,
        cell: row => <a href={`mailto:${row.email}`}>{row.email}</a>
    },
    {
        name: 'Created',
        selector: row => row['created'],
        sortable: true,
        cell: row => <span>{moment(row.created).format('DD MMM YYYY')}</span>
    },
    {
        name: 'Last login',
        selector: row => row['last_login'],
        sortable: true,
        cell: row => <span>{row.last_login ? moment(row.last_login).fromNow() : '-'}</span>
    },
    {
        name: 'Actions',
        sortable: false,
        cell: row => <RowActions {...row} {...props} />
    }];

    // render datatable component
    return (
        <DataTable
            data={users}
            columns={columns}
            customStyles={customStyles}
            onSelectedRowsChange={handleRowSelected}
            highlightOnHover
            pagination
            noHeader
            progressPending={resultsLoading}
        />
    );
};

export default Table;
