import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import queryString from 'query-string';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { DeviceAutoCompleteInput } from 'components';
import { DevicesProvider, ManufacturersProvider } from 'providers';

const DeviceFilter = (props) => {
    const { resultsLoading, setDevices, setTotalRecords, setFilterOptions, setResultsLoading } = props;
    const [ deviceId, setDeviceId ] = useState('');
    const [ productCode, setProductCode ] = useState(null);
    const [ deviceTypeId, setDeviceTypeId ] = useState(null);
    const [ manufacturers, setManufacturers ] = useState([]);
    const [ manufacturer, setManufacturer ] = useState(null);
    const [ collapsed, setCollapsed ] = useState(false);
    const [ processing, setProcessing ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ hasImage, setHasImage ] = useState(false);
    const [ deviceTypes, setDeviceTypes ] = useState([]);
    const toggleCollapsed = () => setCollapsed(!collapsed);

    useEffect(() => {
        const fetchData = async () => {
            const types = await DevicesProvider.getDeviceTypes();
            const brands = await ManufacturersProvider.getAll();
            if (types.results) {
                if (types.results.length > 0) {
                    const deviceTypes = [];
                    types.results.map(item => deviceTypes.push({ value: item.id, label: item.name }));
                    setDeviceTypes(deviceTypes);
                }
            }
            if (brands.results) {
                if (brands.results.length > 0) {
                    const manufacturers = [];
                    brands.results.map(item => manufacturers.push({ value: item.id, label: item.name }));
                    setManufacturers(manufacturers);
                }
            }
            setIsLoading(false);
        };
        fetchData();
    }, [ ]);

    async function handleSubmit(e) {
        e.preventDefault();
        const filters = {};

        setProcessing(true);
        setResultsLoading(true);

        if (deviceId || productCode || deviceTypeId || manufacturer) {
            if (deviceId)     filters.id              = deviceId;
            if (productCode)  filters.product_code    = productCode;
            if (deviceTypeId) filters.device_type_id  = deviceTypeId;
            if (manufacturer) filters.manufacturer_id = manufacturer;
        }

        filters.has_image = hasImage ? 'Yes' : 'No';
        
        if (Object.keys(filters).length > 0) {
            setFilterOptions(filters);
            const querystr = queryString.stringify(filters);
            const devices = await DevicesProvider.getAll(0, 100, `&${querystr}`);
            setResultsLoading(false);
            setDevices(devices.results);
            setTotalRecords(devices.count);
        }
        
        setProcessing(false);
        setResultsLoading(false);
    };

    async function handleHasImageChange(checked) {
        setHasImage(checked);
        const filters = {};

        setProcessing(true);
        setResultsLoading(true);

        if (deviceId || productCode || deviceTypeId || manufacturer) {
            if (deviceId)     filters.id              = deviceId;
            if (productCode)  filters.product_code    = productCode;
            if (deviceTypeId) filters.device_type_id  = deviceTypeId;
            if (manufacturer) filters.manufacturer_id = manufacturer;
        }

        filters.has_image = checked ? 'Yes' : 'No';
        
        if (Object.keys(filters).length > 0) {
            setFilterOptions(filters);
            const querystr = queryString.stringify(filters);
            const devices = await DevicesProvider.getAll(0, 100, `&${querystr}`);
            setResultsLoading(false);
            setDevices(devices.results);
            setTotalRecords(devices.count);
        }
        
        setProcessing(false);
        setResultsLoading(false);
    };

    return (
        <div className="filter-wrapper">
            <button
                className={`btn btn-primary btn-block rounded-0 btn-filter-heading ${collapsed ? 'collapsed' : ''}`}
                data-toggle="collapse"
                type="button"
                aria-expanded="false"
                aria-controls="collapseFilters"
                onClick={toggleCollapsed}
            >
                Filter Options
            </button>
            <div className={`collapse mg-t-0 ${collapsed ? '' : 'show'}`} id="collapseFilters">
                <Card>
                    <CardBody>
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col xs={12} className="mg-b-15">
                                    <Row>
                                        <Col xs={12} md={3}>
                                            <div className="form-group mg-b-0">
                                                <label>Device type</label>
                                                <Select
                                                    options={deviceTypes}
                                                    name="device_type_id"
                                                    isLoading={isLoading}
                                                    isDisabled={resultsLoading}
                                                    onChange={(item) => {
                                                        if (item.value.length > 0) {
                                                            setDeviceTypeId(item.value);
                                                        } else {
                                                            setDeviceTypeId(null);
                                                        }
                                                        return true;
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <div className="form-group mg-b-0">
                                                <label>Manufacturer</label>
                                                <Select
                                                    options={manufacturers}
                                                    isDisabled={resultsLoading}
                                                    onChange={(item) => {
                                                        if (item.value.length > 0) {
                                                            setManufacturer(item.value);
                                                        } else {
                                                            setManufacturer(null);
                                                        }
                                                        return true;
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <div className="form-group mg-b-0">
                                                <label>Device name</label>
                                                <DeviceAutoCompleteInput setDevice={setDeviceId} />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <div className="form-group mg-b-0">
                                                <label>Product code</label>
                                                <input
                                                    type="text"
                                                    name="product_code"
                                                    className="form-control"
                                                    placeholder="Product code"
                                                    disabled={resultsLoading}
                                                    onKeyUp={(e) => {
                                                        if (e.target.value.length > 0) {
                                                            setProductCode(e.target.value);
                                                        } else {
                                                            setProductCode(null);
                                                        }
                                                        return true;
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} md={2}>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                        disabled={processing || resultsLoading}
                                    >
                                        {processing ? 'Processing...' : 'Apply Filters'}
                                    </button>
                                </Col>
                                <Col xs={6} md={2}>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block"
                                        disabled={processing || resultsLoading}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location  = '/devices';
                                        }}
                                    >
                                        Reset Filters
                                    </button>
                                </Col>
                                <Col xs={6} md={2} style={{ paddingTop: "10px" }} >
                                    <div className="custom-control custom-switch">
                                        <input 
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            id="hasImage"
                                            value={hasImage ? 'Yes' : 'No'}
                                            checked={hasImage} 
                                            onChange={(e) => handleHasImageChange(e.target.checked)}
                                            disabled={processing || resultsLoading}
                                        />
                                        <label className="custom-control-label" htmlFor="hasImage">Device has image?</label>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default DeviceFilter;
