import React, { useCallback } from 'react';
import { Card } from 'reactstrap';
import ListTable from './ListTable';

const DeviceListTable = props => {
    const { setSelectedRows } = props;

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, [ setSelectedRows ]);

    return (
        <div className="dealsheet-result-table-wrapper">
            <Card>
                <ListTable
                    handleRowSelected={handleRowSelected}
                    {...props}
                />
            </Card>
        </div>
    );
};

export default DeviceListTable;
