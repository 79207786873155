import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert';
import { Container, Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import { Link, useParams, useLocation } from 'react-router-dom';
import { DevicesProvider, SettingsProvider, SessionProvider } from 'providers';
import {
  Breadcrumb,
  Devices,
  FeatherIcon,
  Form,
  ImageManager,
  Modals,
} from 'components';
import S3 from 'react-aws-s3';
import Swal from 'sweetalert2';
import config from '../../config';

const configurations = SessionProvider.getConfigurations();
const {
    ASSET_ACCESS_KEY_ID,
    ASSET_SECRET_ACCESS_KEY,
    MONDO_ASSET_ACCESS_KEY_ID,
    MONDO_ASSET_SECRET_ACCESS_KEY,
} = configurations;

const ModelItem = (props) => {
  const { manufacturer, name, handleSelect } = props;
  return (
    <a
      role="button"
      href="/"
      className="option-link"
      onClick={(e) => {
        e.preventDefault();
        return handleSelect(props);
      }}
    >
      {manufacturer.name} {name}
    </a>
  );
};

const EditDevice = () => {
  const location = useLocation();
  const { id } = useParams();
  const { search } = location;
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [images, setImages] = useState([]);
  const [device, setDevice] = useState({});
  const [features, setFeatures] = useState([]);
  const [activeTab, setActiveTab] = useState('information');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [defaultImg, setDefaultImg] = useState(null);
  const [mainImg, setMainImg] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [model, setModel] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isValidProductCode, setIsValidProductCode] = useState(true);
  const [productCode, setProductCode] = useState('');
  const [storage, setStorage] = useState('');
  const [title, setTitle] = useState('');
  const [color, setColor] = useState('');
  const breadcrumb = [
    { title: 'Devices', link: '/devices', active: false },
    { title: 'Edit Device', active: true },
  ];
  const [settings, setSettings] = useState({});
  const [alertModalContent, setAlertModalContent] = useState({});
  const [showUploadingAlertModal, setShowUploadingAlertModal] = useState(false);
  const [uploadingSteps, setUploadingSteps] = useState({
    uploadDams: 'pending',
    uploadMondo: 'pending',
  });

  async function fetchData() {
    if (!processing) setProcessing(true);

    if (search) {
      if (search.indexOf('?tab=images') !== -1) {
        setActiveTab('images');
      }
    }

    // fetch config settings from the database
    const configs = await SettingsProvider.all({
      category: 'aws',
      key: 's3',
    });

    if (configs && configs.length > 0) {
      const settings = {};
      configs.map((item) => {
        const { subcategory, value } = item;
        settings[subcategory] = value;
      });
      setSettings(settings);
    }

    const device = await DevicesProvider.get(id);

    // sometimes the api fails in dev
    if (!device || !device.id) {
      return fetchData();
    }

    const images = await DevicesProvider.getImages(id);

    if (device.variants) {
      setColor(device.variants.color || '');
      setStorage(device.variants.storage || '');
    }

    setTitle(device.title);
    setProductCode(device.product_code);
    setModel(`${device.manufacturer_name} ${device.model}`);
    setDevice(device);

    if (images && images.length > 0) {
      setImages(images);
    }

    if (device && device.features && device.features.children) {
      setFeatures(device.features.children);
    }
    if (device && device.image_default) {
      setDefaultImg(device.image_default);
      setMainImg(device.image_default);
    }
    setProcessing(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = (data) => {
    handleSaveDevice(data);
  };

  const generatePermakey = (title) => {
    let permakey = "";
    if (title.indexOf("(") !== -1) {
      permakey = title
        .split("(")
        .join("")
        .split(")")
        .join("")
        .replace(" + ", " and ")
        .split("_")
        .join("-")
        .split(" ")
        .join("-")
        .toLowerCase();
      return permakey;
    }
    permakey = title
      .replace(" + ", " and ")
      .split("_")
      .join("-")
      .split(" ")
      .join("-")
      .toLowerCase();
    return permakey;
  };

  const handleSaveDevice = async (data) => {
    setProcessing(true);
    const {
      title,
      feature,
      variants,
      product_code,
    } = data;
    const { color, storage } = variants;

    const post = {
      title,
      colour: color,
      storage,
      variants,
      product_code,
      permakey: generatePermakey(title),
    };

    if (feature) {
      const deviceFeatures = [];
      features.map(item => {
        const { key } = item;
        deviceFeatures.push({
          ...item,
          value: feature[key] || item.value,
        })
      });
      post.features = {
        title,
        key: device.features.key,
        children: deviceFeatures,
      }
    }

    const result = await DevicesProvider.update(id, post);
    const { status } = result;

    setProcessing(false);

    if (status === "success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Device updated successfully!',
        showConfirmButton: false,
        timer: 5000
      });
      return setTimeout(async function () {
        fetchData();
      }, 5000);
    }

    // error occured
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: result.message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 5000
    });
  };

  const handleValidateProductCode = async (val) => {
    const data = await DevicesProvider.getAll(0, 1, `&product_code=${val}`);
    if (data) {
      if (data.results) {
        if (data.results.length === 0) {
          return setIsValidProductCode(data.results.length === 0);
        }
        let valid = true;
        data.results.map(item => {
          if (item.id !== id) {
            return valid = false;
          }
        });
        if (valid) setIsValidProductCode(valid);
      }
    }
    return true;
  };

  const handleDeleteDevice = async () => {
    return confirmAlert({
      title: 'Confirm Delete!',
      message:
        'Are you sure to delete device, as this may affect deals attached to it? This process cannot be undone.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            setProcessing(true);
            const result = await DevicesProvider.delete(id);
            setProcessing(false);

            if (result.status === 'success') {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Device successfully deleted!',
                showConfirmButton: false,
                timer: 5000
              });
              return setTimeout(async function () {
                window.location = '/devices';
              }, 5000);
            }

            // error occured
            return Swal.fire({
              position: 'center',
              icon: 'error',
              title: result.message || 'Failed to process request, please try again!',
              showConfirmButton: false,
              timer: 5000
            });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const handleSetMainImage = async () => {
    setProcessing(true);
    const image = defaultImg.replace('images/', '');
    const result = await DevicesProvider.update(id, { image_default: image });
    setProcessing(false);

    if (result.status === 'success') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Device successfully updated!',
        showConfirmButton: false,
        timer: 5000
      });
      setSaveDisabled(true);
      setMainImg(defaultImg);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: result.message || 'Failed to process request, please try again!',
        showConfirmButton: false,
        timer: 5000
      });
      setSaveDisabled(true);
      setDefaultImg(mainImg);
    }
  };

  const handleSelect = (item) => {
    setModel(`${item.manufacturer_name} ${item.name}`);
    return setSearchResults([]);
  };

  const handleSearchModel = async (e) => {
    setSearchResults([]);
    setModel(e.target.value);
    if (e.target.value.length > 2) {
      const data = await DevicesProvider.searchModels(
        "model_name",
        e.target.value,
        0,
        50
      );
      const { results } = data;
      const object = [];
      results.map((item) =>
        object.push(<ModelItem {...item} handleSelect={handleSelect} />)
      );
      setSearchResults(object);
    }
  };

  async function handleUploadImage(fileInput) {
    const file = fileInput.current.files[0];
    setShowUploadModal(false);
    setUploadingSteps({
      uploadDams: 'in progress',
      uploadMondo: 'pending',
    });
    setShowUploadingAlertModal(true);

    const { aws } = config;
    const { s3 } = aws;
    const {
      bucketName,
      region,
      dirName,
    } = s3.options;

    const { device_type, manufacturer, permakey } = device;
    const deviceType = device_type.name.split('/').join('-').split(' ').join('-').toLowerCase();
    const directory = (`${dirName}/${deviceType}/${manufacturer.name}/${permakey.split(`${manufacturer.name.toLowerCase()}-`).join('')}`).toLowerCase();

    const s3config = {
      bucketName,
      dirName: directory,
      region,
      accessKeyId: ASSET_ACCESS_KEY_ID,
      secretAccessKey: ASSET_SECRET_ACCESS_KEY,
    };

    const ReactS3Client = new S3(s3config);

    return ReactS3Client
      .uploadFile(file, 'default')
      .then(async () => {
        setUploadingSteps({
          uploadDams: 'complete',
          uploadMondo: 'in progress',
        });
        // update device information (images field)
        const images = device.images || [];
        images.push(directory);
        await DevicesProvider.update(id, { images });

        // upload same file to Mondo S3 bucket
        return handleUploadImageToMondo(file, deviceType);
      })
      .catch(err => {
        setShowUploadingAlertModal(false);
        setAlertModalContent({
          title: 'Upload Error!',
          body: <p>{err.message}</p>,
          type: 'error',
        });
        setShowAlertModal(true);
      });
  }

  async function handleUploadImageToMondo(file, deviceType) {
    const { aws } = config;
    const { s3 } = aws;
    const { mondo } = s3;
    const {
      bucketName,
      dirName,
      region,
    } = mondo.options;
    const { manufacturer, permakey } = device;
    const directory = (`${dirName}/${deviceType}/${manufacturer.name}/${permakey.split(`${manufacturer.name.toLowerCase()}-`).join('')}`).toLowerCase();

    const s3config = {
      bucketName,
      dirName: directory,
      region,
      accessKeyId: MONDO_ASSET_ACCESS_KEY_ID,
      secretAccessKey: MONDO_ASSET_SECRET_ACCESS_KEY,
    };

    const ReactS3Client = new S3(s3config);

    return ReactS3Client
      .uploadFile(file, 'default')
      .then(() => {
        setUploadingSteps({
          uploadDams: 'complete',
          uploadMondo: 'complete',
        });
        setShowUploadingAlertModal(false);
        setAlertModalContent({
          title: 'Image Uploaded!',
          body: <p><strong>Device Image</strong> successfully uploaded!.</p>,
          callback: () => fetchData(),
          type: 'success',
        });
        setShowAlertModal(true);
      })
      .catch(err => {
        setShowUploadingAlertModal(false);
        setAlertModalContent({
          title: 'Upload Error!',
          body: <p>{err.message}</p>,
          type: 'error',
        });
        setShowAlertModal(true);
      });
  }

  const toggleTab = (tab) => {
    window.history.pushState({}, null, `/devices/edit/${id}?tab=${tab}`);
    return setActiveTab(tab);
  };

  return (
    <Layout pageLoading={!device.id || processing}>
      <div className="content content-fixed pd-b-0">
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <Breadcrumb
            heading={`Update Device${device.title
              ? ' : ' + device.title
              : ''
              }`}
            items={breadcrumb}
          />
        </Container>
      </div>
      <Modals.Alert
        show={showAlertModal}
        setShow={setShowAlertModal}
        {...alertModalContent}
      />
      <Modals.Uploading
        steps={uploadingSteps}
        show={showUploadingAlertModal}
        setShow={setShowUploadingAlertModal}
      />
      {device.id && showUploadModal && (
        <Modals.UploadImage
          show={showUploadModal}
          setShow={setShowUploadModal}
          setProcessing={setProcessing}
          setShowAlert={setShowAlertModal}
          setSaveDisabled={setSaveDisabled}
          setUploadingSteps={setUploadingSteps}
          setAlertContent={setAlertModalContent}
          setShowUploadingAlert={setShowUploadingAlertModal}
          handleUploadImage={handleUploadImage}
          {...device}
        />
      )}
      {device.id && (
        <div className="contact-wrapper" style={{ top: 174 }}>
          <div className="contact-content fluid">
            <div className="contact-content-header r-f-0">
              <nav className="nav">
                <a
                  role="button"
                  href="/devices"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleTab('information');
                  }}
                  className={`nav-link ${activeTab === 'information' ? 'active' : ''
                    }`}
                >
                  Information
                </a>
                <a
                  role="button"
                  href="/devices"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleTab('images');
                  }}
                  className={`nav-link ${activeTab === 'images' ? 'active' : ''
                    }`}
                >
                  Images
                </a>
              </nav>
            </div>
            <div className="contact-content-body ps r-f-0 overflow-y-auto">
              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === "information" ? "show active" : ""
                    } pd-20 pd-xl-25`}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex align-items-center justify-content-between mg-b-25">
                      <h5 className="mg-b-0">&nbsp;</h5>
                      <div className="d-flex">
                        <button
                          className="btn btn-sm btn-outline-primary d-flex align-items-center mg-r-5"
                          type="submit"
                        >
                          <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                          <span className="d-none d-sm-inline mg-l-5">
                            {' '}
                            Save
                          </span>
                        </button>
                        <button
                          className="btn btn-sm btn-outline-danger d-flex align-items-center mg-r-5"
                          onClick={(e) => {
                            e.preventDefault();
                            return handleDeleteDevice();
                          }}
                        >
                          <FeatherIcon icon="trash-2" classes="wd-10 mg-r-5" />
                          <span className="d-none d-sm-inline mg-l-5">
                            {' '}
                            Delete
                          </span>
                        </button>
                        <button
                          className="btn btn-sm btn-white d-flex align-items-center"
                          onClick={(e) => {
                            e.preventDefault();
                            return (window.location = "/devices");
                          }}
                        >
                          <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                          <span className="d-none d-sm-inline mg-l-5">
                            {' '}
                            Cancel
                          </span>
                        </button>
                      </div>
                    </div>
                    <div data-label="Device Information" className="legend">
                      <Row>
                        <Col xs={12} sm={4}>
                          <div
                            className={`form-group ${!model ? "is-invalid" : ""
                              }`}
                          >
                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                              Base Model
                            </label>
                            <Form.AutocompleteInput
                              value={model}
                              setValue={setModel}
                              placeholder="Enter Device Model..."
                              classes={`form-group ${!model ? "is-invalid" : ""
                                }`}
                              handleValueChange={handleSearchModel}
                              setProcessing={setProcessing}
                              searchResults={searchResults}
                              secondaryButton={
                                <Form.Button
                                  text=""
                                  buttonLink
                                  buttonLinkHref="/devices/models/add"
                                  classes="btn btn-outline-light"
                                  icon={<FeatherIcon icon="plus" />}
                                />
                              }
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={4}>
                          <div
                            className={`form-group ${errors.title ? "is-invalid" : ""
                              }`}
                          >
                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                              Title
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errors.title ? "parsley-error" : ""
                                }`}
                              defaultValue={device.title}
                              {...register('title', { required: true })}
                              onChange={e => {
                                setTitle(e.target.value);
                                setProductCode(e.target.value.split(' ').join('-').toUpperCase());
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={4}>
                          <div
                            className={`form-group ${errors.product_code || !isValidProductCode
                              ? "is-invalid"
                              : ""
                              }`}
                          >
                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                              Product code
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errors.product_code || !isValidProductCode
                                ? "parsley-error"
                                : ""
                                }`}
                              defaultValue={productCode}
                              onBlur={(e) =>
                                handleValidateProductCode(e.target.value)
                              }
                              {...register('product_code', { required: true })}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Devices.Variants
                      {...device}
                      title={title}
                      setTitle={setTitle}
                      setProductCode={setProductCode}
                      setSelectedStorage={setStorage}
                      setSelectedColor={setColor}
                      selectedStorage={storage}
                      selectedColor={color}
                      register={register}
                      errors={errors}
                    />
                    {features && features.length > 0 &&
                      <div
                        data-label="Feature Information"
                        className="legend mg-t-20 feature-information"
                      >
                        {features.map((feature) => (
                          <li
                            key={feature.key}
                            className="list-group-item d-flex align-items-center"
                          >
                            <div>
                              <h6 className="tx-13 tx-inverse tx-semibold mg-b-0">
                                {feature.title}
                              </h6>
                              <span className="d-block tx-11 text-muted">
                                {feature.key}
                              </span>
                            </div>
                            <div>
                              <input
                                type="text"
                                className={`form-control ${errors[feature.key] ? 'parsley-error' : ''}`}
                                defaultValue={feature.value}
                                {...register(`feature.${feature.key}`, { required: false })}
                              />
                            </div>
                          </li>
                        ))}
                      </div>}
                  </form>
                </div>
                <div
                  className={`tab-pane ${activeTab === "images" ? "show active" : ""
                    } pd-20 pd-xl-25`}
                >
                  <div className="d-flex align-items-center justify-content-between mg-b-25">
                    <h6 className="mg-b-0">{device.title} File Manager</h6>
                    <div className="d-flex">
                      <button
                        className="btn btn-sm btn-outline-success d-flex align-items-center mg-r-5"
                        onClick={() => handleSetMainImage()}
                        disabled={saveDisabled}
                      >
                        <FeatherIcon icon="check" classes="wd-10 mg-r-5" />
                        <span className="d-none d-sm-inline mg-l-5">
                          {' '}
                          Set Main Image
                        </span>
                      </button>
                      <button
                        className="btn btn-sm btn-primary d-flex align-items-center mg-r-5"
                        onClick={() => setShowUploadModal(true)}
                      >
                        <FeatherIcon icon="upload" classes="wd-10 mg-r-5" />
                        <span className="d-none d-sm-inline mg-l-5">
                          {' '}
                          Upload
                        </span>
                      </button>
                      <Link to="/devices">
                        <button className="btn btn-sm btn-white d-flex align-items-center">
                          <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                          <span className="d-none d-sm-inline mg-l-5">
                            {' '}
                            Cancel
                          </span>
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="file-manager-wrapper">
                    <Row style={{ maxWidth: "100%" }}>
                      <Col xs={12}>
                        <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                          Available AWS S3 Image(s)
                        </label>
                      </Col>
                      <Col xs={12}>
                        <ImageManager
                          setShowUploadModal={setShowUploadModal}
                          setSaveDisabled={setSaveDisabled}
                          defaultImg={defaultImg}
                          setDefaultImg={setDefaultImg}
                          setMainImg={setMainImg}
                          images={images}
                          mainImg={mainImg}
                          root="devices/"
                        />
                      </Col>
                      <Col xs={12}>
                        <hr className="mg-y-40 bd-0" />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default EditDevice;
