import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { Layout } from 'containers';
import {
  Breadcrumb,
  FeatherIcon,
  TextEditor,
} from 'components';
import {
  DevicesProvider,
  SettingsProvider,
  ManufacturersProvider,
} from 'providers';
import Swal from 'sweetalert2';
import Select from 'react-select';
import queryString from 'query-string';
import wysiwyg from 'static/wysiwyg.json';

const selectStyles = {
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

const AddModel = () => {
  const breadcrumb = [
    {
      title: 'Base Device Models',
      link: '/devices/models',
      active: false,
    },
    {
      title: 'Add Base Model',
      active: true,
    },
  ];
  const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm();
  const [processing, setProcessing] = useState(true);
  const [types, setTypes] = useState([]);
  const [features, setFeatures] = useState({});
  const [manufacturers, setManufacturers] = useState([]);
  const [deviceTypeId, setDeviceTypeId] = useState(null);
  const [manufacturerId, setManufacturerId] = useState(null);
  const [manufacturerName, setManufacturerName] = useState(null);
  const [modelDescription, setModelDescription] = useState('');
  const [activeTab, setActiveTab] = useState('information');

  useEffect(() => {
    const fetchData = async () => {
      const manufacturers = await ManufacturersProvider.getAll();
      const types = await DevicesProvider.getDeviceTypes();
      const settings = await SettingsProvider.searchSettingByCodeKey(
        'device',
        'features_template',
        {
          subcategory: 'model',
        });
      if (settings && settings.value && settings.value.children) {
        setFeatures(settings.value.children);
      }

      if (manufacturers.results && manufacturers.results.length > 0) {
        const _manufacturers = [];
        manufacturers.results.map((item) =>
          _manufacturers.push({ value: item.id, label: item.name })
        );
        setManufacturers(_manufacturers);
      }
      if (types.results && types.results.length > 0) {
        const _types = [];
        types.results.map((item) =>
          _types.push({ value: item.id, label: item.name })
        );
        setTypes(_types);
      }
      setProcessing(false);
    };
    fetchData();
  }, []);

  const validateModelCode = async (val) => {
    const querystr = queryString.stringify({ code: val });
    const data = await DevicesProvider.getModels(0, 1, `&${querystr}`);
    if (data.results && data.results.length > 0) {
      setError('code', 'code', 'Specified mode code already exists!');
      return false;
    }
    return true;
  };

  const onSubmit = async (data) => {
    setProcessing(true);
    const { code, feature, name } = data;
    const formData = {
      name,
      code,
      device_type_id: deviceTypeId,
      manufacturer_id: manufacturerId,
      manufacturer_name: manufacturerName,
      description: modelDescription,
    };

    if (feature) {
      const modelFeatures = [];
      features.map(item => {
        const { key } = item;
        modelFeatures.push({
          ...item,
          value: feature[key] || item.value,
        })
      });
      formData.features = {
        title: `${manufacturerName} ${name}`,
        key: uuidv4(),
        children: modelFeatures,
      }
    }
    const result = await DevicesProvider.createModel(formData);

    if (result.status === 'success') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Base Device successfully created!',
        showConfirmButton: false,
        timer: 5000
      });
      return setTimeout(async function () {
        window.location = '/devices/models';
      }, 5000);
    }

    // error occured
    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: result.message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 5000
    });
  };

  return (
    <Layout pageLoading={processing}>
      <div className="content content-fixed pd-b-0">
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <Breadcrumb heading="Create Base Device" items={breadcrumb} />
        </Container>
      </div>
      <div className="contact-wrapper" style={{ top: 174 }}>
        <div className="contact-content fluid">
          <div className="contact-content-header r-f-0">
            <nav className="nav">
              <a
                role="button"
                href="/devices/models"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab("information");
                }}
                className={`nav-link ${activeTab === "information" ? "active" : ""
                  }`}
              >
                Information
              </a>
            </nav>
          </div>
          <div className="contact-content-body ps r-f-0 overflow-y-auto">
            <div className="tab-content">
              <div
                className={`tab-pane ${activeTab === 'information' ? 'show active' : ''
                  } pd-20 pd-xl-25`}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="d-flex align-items-center justify-content-between mg-b-25">
                    <h5 className="mg-b-0">&nbsp;</h5>
                    <div className="d-flex">
                      <button
                        className="btn btn-sm btn-outline-primary d-flex align-items-center mg-r-5"
                        type="submit"
                      >
                        <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                        <span className="d-none d-sm-inline mg-l-5"> Save</span>
                      </button>
                      <button
                        className="btn btn-sm btn-white d-flex align-items-center"
                        onClick={(e) => {
                          e.preventDefault();
                          return (window.location = "/devices/models");
                        }}
                      >
                        <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                        <span className="d-none d-sm-inline mg-l-5">
                          {" "}
                          Cancel
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    data-label="Base Device Model Information"
                    className="legend"
                  >
                    <Row>
                      <Col xs={12} sm={3}>
                        <div
                          className={`form-group ${!deviceTypeId ? "is-invalid" : ""
                            }`}
                        >
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Device Type
                          </label>
                          <Select
                            className={!deviceTypeId ? "parsley-error" : ""}
                            name="device_type"
                            options={types}
                            isLoading={types.length === 0}
                            isDisabled={types.length === 0}
                            onChange={(input) => setDeviceTypeId(input.value)}
                            styles={selectStyles}
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={3}>
                        <div
                          className={`form-group ${!manufacturerId ? "is-invalid" : ""
                            }`}
                        >
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Manufacturer
                          </label>
                          <Select
                            className={!manufacturerId ? "parsley-error" : ""}
                            name="manufacturer_id"
                            options={manufacturers}
                            isLoading={manufacturers.length === 0}
                            isDisabled={manufacturers.length === 0}
                            styles={selectStyles}
                            onChange={(input) => {
                              setManufacturerId(input.value);
                              return setManufacturerName(input.label);
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={3}>
                        <div
                          className={`form-group ${errors.name ? "is-invalid" : ""
                            }`}
                        >
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Model
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.name ? "parsley-error" : ""
                            }`}
                            {...register('name', { required: true })}
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={3}>
                        <div
                          className={`form-group ${errors.code ? "is-invalid" : ""
                            }`}
                        >
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Code
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.code ? "parsley-error" : ""
                            }`}
                            {...register('code', { required: true })}
                            onMouseLeave={(e) =>
                              validateModelCode(e.target.value)
                            }
                            onKeyUp={() => clearErrors("code")}
                          />
                          {errors.code && (
                            <ul
                              className="parsley-errors-list filled"
                              id="parsley-id-5"
                            >
                              <li className="parsley-required">
                                {errors.code.message ||
                                  "Invalid model code specified"}
                              </li>
                            </ul>
                          )}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="form-group">
                          <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                            Description (Optional)
                          </label>
                          <TextEditor.WYSIWYG
                            model={modelDescription}
                            setModel={setModelDescription}
                            placeholderText="Enter description..."
                            toolbarButtons={
                              wysiwyg.toolbarButtons.contractDetails
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {features && (
                    <div
                      data-label="Feature Information"
                      className="legend mg-t-20 feature-information"
                    >
                      {features.length > 0 && features.map((feature) => (
                        <li
                          key={feature.key}
                          className="list-group-item d-flex align-items-center"
                        >
                          <div>
                            <h6 className="tx-13 tx-inverse tx-semibold mg-b-0">
                              {feature.title}
                            </h6>
                            <span className="d-block tx-11 text-muted">
                              {feature.key}
                            </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              className={`form-control ${errors[feature.key] ? 'parsley-error' : ''}`}
                              defaultValue={feature.value}
                              {...register(`feature.${feature.key}`, { required: false })}
                            />
                          </div>
                        </li>
                      ))}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddModel;
