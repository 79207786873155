import React from 'react';

const StepActions = props => {
    const { 
        confirmImport,
        disableFinishBtn,
        disableNextBtn,
        invalidLayout,
        step,
        retry,
        setStep,
        setDisableNextBtn,
        setPageLoading,
    } = props;

    return (
        <div className="actions clearfix">
            <ul role="menu" aria-label="Pagination">
                <li className={(step === 1 || step > 4) ? 'disabled' : ''}>
                    <a
                        href="/"
                        role="menuitem"
                        disabled={step > 4}
                        className="btn btn-sm pd-x-15 btn-uppercase"
                        onClick={e => {
                            e.preventDefault();
                            if (step > 4) return false;
                            if (step === 2) setDisableNextBtn(false);
                            if (step > 1) setStep(step - 1);
                        }}
                    >
                        Previous
                    </a>
                </li>

                {step <= 4 &&
                <li className={`${(disableNextBtn) ? 'disabled' : ''} ${invalidLayout ? 'is-invalid' : ''}`}>
                    <a
                        href="/"
                        role="menuitem"
                        disabled={disableNextBtn && !retry}
                        className="btn btn-sm pd-x-15 btn-uppercase"
                        onClick={e => {
                            e.preventDefault();
                            if (retry) return window.location = '/dealsheets/import';
                            if (step === 3 && !disableNextBtn) return confirmImport();
                            if (step < 5 && !disableNextBtn) {
                                if (step === 2) setDisableNextBtn(false);
                                else setDisableNextBtn(true);
                                setStep(step + 1);
                            }
                        }}
                    >
                        {retry ? 'Try again' : 'Next'}
                    </a>
                </li>}

                {step === 5 &&
                <li className={disableFinishBtn ? 'disabled' : ''}>
                    <a
                        href="/"
                        role="menuitem"
                        disabled={disableFinishBtn}
                        onClick={e => {
                            e.preventDefault();
                            if (disableFinishBtn) return false;
                            setPageLoading(true);
                            setStep(step + 1);
                        }}
                    >View Post Upload Report</a>
                </li>}
            </ul>
        </div>
    );
};

export default StepActions;
