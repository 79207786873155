import React, { useEffect, useState } from 'react';
import { Card, Container } from 'reactstrap';
import { Layout } from 'containers';
import { Breadcrumb } from 'components';
import { SettingsProvider } from 'providers';
// import SqlEditor from 'react-sql-editor';
// import JSONInput from 'react-json-editor-ajrm';
// import locale from 'react-json-editor-ajrm/locale/en';

const Field = props => {
    const {
        name,
        value,
        data_type,
    } = props;

    switch (data_type) {
        case 'float':
        case 'number':
            return (
                <div className="mb-3">
                    <div className="mt-4" />
                    <div data-label={name} className="legend">
                        <input
                            id={name}
                            name={name}
                            type="number"
                            aria-label={name}
                            defaultValue={value}
                            className="form-control"
                        />
                    </div>
                </div>
            );

        case 'array':
            return (
                <div className="mb-3">
                    <div className="mt-4" />
                    <div data-label={name} className="legend">
                        {/* <JSONInput
                            id={name}
                            placeholder={value}
                            locale={locale}
                            height='150px'
                            width='100%'
                        /> */}
                    </div>
                </div>
            );

        case 'json':
        case 'jsonb':
            return (
                <div className="mb-3">
                    <div className="mt-4" />
                    <div data-label={name} className="legend">
                        {/* <JSONInput
                            id={name}
                            placeholder={value}
                            locale={locale}
                            height='300px'
                            width='100%'
                        /> */}
                    </div>
                </div>
            );

        case 'sql':
            return (
                <div className="mb-3">
                    <div className="mt-4" />
                    <div data-label={name} className="legend">
                        {/* <SqlEditor
                            title="Sql Editor"
                            width="auto"
                            height="300px"
                            isShowHeader={true}
                            onChange={data => {
                                console.log(data);
                            }}
                            validatorConfig={{
                                maxSqlNum: 1,
                                // validators: READ_VALIDATORS,
                            }}
                        /> */}
                    </div>
                </div>
            );

        default: break;
    }

    return (
        <div className="mb-3">

        </div>
    );
};

const TabContent = props => {
    const { data, parent } = props;
    const [active, setActive] = useState(null);
    const [settings, setSettings] = useState(null);
    const [componentLoading, setComponentLoading] = useState(true);

    async function fetchData() {
        if (!componentLoading) setComponentLoading(true);
        if (data && data.length > 0) {
            const settings = {};
            data.map(item => {
                if (Object.keys(settings).includes(item.subcategory)) {
                    return settings[item.subcategory].push(item);
                }
                settings[item.subcategory] = [];
                return settings[item.subcategory].push(item);
            });
            setActive(Object.keys(settings)[0]);
            setSettings(settings);
            console.log(settings);
        }
        setComponentLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const toggleActive = active => setActive(active);

    return (
        <div>
            {componentLoading ? (
                <div>component loading...</div>
            ) : (
                <div>
                    {settings && Object.keys(settings).map((item, index) => (
                        <React.Fragment key={index.toString()}>
                            {active === item ? (
                                <>
                                    <h6
                                        className="accordion-title ui-accordion-header ui-corner-top ui-state-default ui-accordion-icons ui-accordion-header-active ui-state-active"
                                        onClick={() => toggleActive(item)}
                                    >
                                        <span className="ui-accordion-header-icon ui-icon ui-icon-triangle-1-e" />
                                        <span style={{ textTransform: 'capitalize' }}>{item}</span>
                                    </h6>
                                    <div className="accordion-body ui-accordion-content ui-corner-bottom ui-helper-reset ui-widget-content ui-accordion-content-active">
                                        <p>
                                            Below is a list of settings and configurations of&nbsp;
                                            <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                                                {parent} {item}
                                            </span>
                                            {/* <div className="custom-control custom-checkbox pd-t-10">
                                                <input type="checkbox" className="custom-control-input" id="c1" />
                                                <label className="custom-control-label" htmlFor="c1">id</label>
                                            </div> */}
                                        </p>
                                        {settings[item].map(subitem => (
                                            <Field key={subitem.id} {...subitem} name={subitem.key} />
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h6
                                        className="accordion-title ui-accordion-header ui-corner-top ui-accordion-header-collapsed ui-corner-all ui-state-default ui-accordion-icons"
                                        onClick={() => toggleActive(item)}
                                    >
                                        <span className="ui-accordion-header-icon ui-icon ui-icon-triangle-1-e" />
                                        <span style={{ textTransform: 'capitalize' }}>{item}</span>
                                    </h6>
                                    <div className="accordion-body ui-accordion-content ui-corner-bottom ui-helper-reset ui-widget-content">
                                        <p>
                                            Below is a list of settings and configurations of&nbsp;
                                            <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                                                {parent} {item}
                                            </span>
                                        </p>
                                        {settings[item].map(subitem => (
                                            <Field key={subitem.id} {...subitem} name={subitem.key} />
                                        ))}
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    );
}

const Settings = () => {
    const [pageLoading, setPageLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(null);
    const [settings, setSettings] = useState(null);
    const breadcrumb = [
        { title: 'Settings', link: '/settings', active: false },
        { title: 'Configurations', active: true }
    ];

    const toggleTab = tab => setActiveTab(tab);

    async function fetchData() {
        if (!pageLoading) setPageLoading(true);
        const data = await SettingsProvider.all();
        if (data && data.length > 0) {
            const settings = {};
            data.map(item => {
                if (Object.keys(settings).includes(item.category)) {
                    return settings[item.category].push(item);
                }
                settings[item.category] = [];
                return settings[item.category].push(item);
            });
            setActiveTab(Object.keys(settings)[0]);
            setSettings(settings);
        }
        setPageLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Layout pageLoading={pageLoading}>
            <div className="content content-fixed pd-b-0">
                <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <Breadcrumb
                        heading="System Configurations"
                        items={breadcrumb}
                    />
                    {settings && (
                        <ul className="nav nav-tabs border-bottom-0" role="tablist">
                            {Object.keys(settings).map(item => (
                                <li className="nav-item" key={`head__${item}`}>
                                    <a
                                        className={`nav-link ${activeTab === item ? 'active' : ''}`}
                                        data-toggle="tab"
                                        href={`#${item}`}
                                        role="tab"
                                        aria-controls={item}
                                        aria-selected={activeTab === item}
                                        style={{ textTransform: 'capitalize' }}
                                        onClick={e => {
                                            e.preventDefault();
                                            return toggleTab(item);
                                        }}
                                    >
                                        {item}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}
                </Container>
            </div>
            <Card className="card-body">
                {settings && (
                    <div className="tab-content bd bd-gray-300 bd-t-0 border-0 pd-t-10">
                        {Object.keys(settings).map(item => (
                            <div
                                id={item}
                                key={`content__${item}`}
                                className={`tab-pane fade ${activeTab === item ? 'show active' : ''}`}
                                role="tabpanel"
                            >
                                <TabContent data={settings[item]} parent={item} />
                            </div>
                        ))}
                    </div>
                )}
            </Card>
        </Layout>
    );
};

export default Settings;
