import React, { useEffect, useState } from 'react';
import { Dealsheets } from 'components';
import {
    BulkActionsProvider,
    DataImportsProvider,
    DevicesProvider,
    PackagesProvider,
} from 'providers';

export default function PostUploadReport(props) {
    const { id, setPageLoading } = props;
    const [activeTab, setActiveTab] = useState('devices');
    const [processing, setProcessing] = useState(false);
    const [upload, setUpload] = useState({});
    const [devicesReport, setDevicesReport] = useState([]);
    const [packagesReport, setPackagesReport] = useState([]);
    const [updatedPackages, setUpdatedPackages] = useState([]);
    const [activePackage, setActivePackage] = useState(null);
    const [updatedDevices, setUpdatedDevices] = useState([]);
    const [activeDevice, setActiveDevice] = useState(null);
    const [packages, setPackages] = useState([]);
    const [models, setModels] = useState([]);

    async function fetchData() {
        const importDetails = await DataImportsProvider.get(id);
        getDevices();
        getPacakges();
        await toggleTabs('devices');
        setUpload(importDetails);
        setPageLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [ ]);

    async function getDevices() {
        const models = await DevicesProvider.getModels(0, 1000);
        if (models.results) {
            const baseDevices = models.results.map(item => ({
                value: item.id,
                label: `${item.manufacturer.name} ${item.name}`
            }));
            setModels(baseDevices);
        }
    }

    async function getPacakges() {
        const packages = await PackagesProvider.getAll(0, 1000);
        if (packages.results) {
            const packageList = packages.results.map(item => ({
                value: item.id,
                label: item.name
            }));
            setPackages(packageList);
        }
    }

    async function toggleTabs(tab, onLoad = false) {
        if (!onLoad) setPageLoading(true);
        const report = await DataImportsProvider.report(id, tab);
        if (tab === 'devices') setDevicesReport(report);
        if (tab === 'packages') setPackagesReport(report);
        setActiveTab(tab);
        setPageLoading(false);
    }

    async function updateReport() {
        const { reference } = upload;
        const result = await BulkActionsProvider.update('upload_report', { reference });
        if (result.data) setUpload(result.data);
        return true;
    }

    return (
        <div>
            <Dealsheets.UploadDetails
                {...upload}
                packages={packages}
                baseDevices={models}
                activeTab={activeTab}
                getDevices={getDevices}
                toggleTabs={toggleTabs}
                getPacakges={getPacakges}
                setActiveTab={setActiveTab}
                updateReport={updateReport}
                devicesReport={devicesReport}
                packagesReport={packagesReport}
                setProcessing={setProcessing}
                setPageLoading={setPageLoading}
                setUpdatedPackages={setUpdatedPackages}
                setUpdatedDevices={setUpdatedDevices}
                setActivePackage={setActivePackage}
                setActiveDevice={setActiveDevice}
                updatedPackages={updatedPackages}
                updatedDevices={updatedDevices}
                activePackage={activePackage}
                activeDevice={activeDevice}
                processing={processing}
            />
        </div>
    );
}