import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Container } from 'reactstrap';
import { Layout } from 'containers';
import { Breadcrumb, Devices, FeatherIcon, Loading, Modals } from 'components';
import { BulkActionsProvider, DevicesProvider } from 'providers';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import config from '../../config';

const Models = () => {
  const breadcrumb = [{ title: 'Base Models', active: true }];
  const [models, setModels] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [resultsLoading, setResultsLoading] = useState(true);
  const [showImportModal, setShowImportModal] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [truncate, setTruncate] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(config.results.pagination.perpage);
  const [sorting, setSorting] = useState(config.results.sorting.models);
  const [totalRecords, setTotalRecords] = useState(0);

  const actionButtons = [
    {
      type: 'button',
      title: 'Import Base Models',
      classes: 'btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5',
      icon: <FeatherIcon icon="download" classes="wd-10 mg-r-5" />,
      action: e => {
        e.preventDefault();
        setShowImportModal(true);
      }
    },
    {
      type: 'button-link',
      title: 'Add Base Model',
      classes: 'btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5',
      icon: <FeatherIcon icon="plus" classes="wd-10 mg-r-5" />,
      link: '/devices/models/add',
    },
  ];

  async function fetchData() {
    if (!resultsLoading) setResultsLoading(true);
    const models = await DevicesProvider.getModels(0, 1000, '&has_image=No');
    setModels(models.results || []);
    setTotalRecords(models.count || 0);
    setResultsLoading(false);
  };

  // load data on page load
  useEffect(() => {
    fetchData();
  }, []);

  const handleImportModels = async (object) => {
    setLoadingText('Importing base models');
    setProcessing(true);

    console.log(object)

    const { data, headers } = object;
    const fields = [
      'model',
      'base_model',
      'device_type_id',
      'manufacturer_name',
      'manufacturer_id',
      'product_code',
      'permakey',
    ];
    const postData = [];
    data.map(item => {
      const obj = {};
      const features = {};
      const children = [];

      Object.keys(item).map(key => {
        if (fields.includes(key)) {
          if (key === 'base_model') {
            features.title = item[key];
            features.key = uuidv4();
          } else if (key === 'model') {
            obj.name = item[key];
          } else {
            obj[key] = item[key];
          }
        } else {
          children.push({
            title: headers[key] || key,
            value: item[key],
            key: uuidv4(),
          });
        }
      });
      obj.features = {
        ...features,
        children,
      };
      postData.push(obj);
    });

    var chunks = postData.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 50);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);

    let importedCount = 0;
    const failedChunk = [];
    const importError = [];

    await Promise.all(chunks.map(async (chunk, index) => {
      const query = (index === 0 && truncate) ? '?truncate=true' : '';
      const response = await BulkActionsProvider.create('device_base_models', chunk, query);
      const { status, message } = response;
      if (status === 'success') {
        const { data } = response;
        importedCount += data.length;
        return true;
      } else {
        failedChunk.push(chunk);
        if (importError.indexOf(message) === -1) {
          importError.push({ save_dealsheet: message });
        }
        return false;
      }
    }));

    setProcessing(false);
    setShowImportModal(false);

    // change step
    if (failedChunk.length === 0) {
      setLoadingText('Fetching base models');
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: `${importedCount} base device models imported successfully!`,
        showConfirmButton: false,
        timer: 4000
      });
      return setTimeout(async function () {
        fetchData();
      }, 4000);
    }
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 4000
    });
    return setTimeout(async function () {
      fetchData();
    }, 4000);
  };

  const handleDeleteModel = async (id, name) => {
    return confirmAlert({
      title: 'Confirm Delete',
      message: `Are you sure you want to delete ${name}?`,
      buttons: [
        {
          label: 'Yes, Continue',
          onClick: async () => {
            setLoadingText('Deleting');
            setProcessing(true);
            const result = await DevicesProvider.deleteModel(id);

            if (result.status === 'success') {
              setProcessing(false);
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: `${name} successfully deleted!`,
                showConfirmButton: false,
                timer: 5000
              });
              return setTimeout(async function () {
                fetchData();
              }, 5000);
            }

            // error occured
            setProcessing(false);
            return Swal.fire({
              position: 'center',
              icon: 'error',
              title: result.message || 'Failed to process request, please try again!',
              showConfirmButton: false,
              timer: 5000
            });
          },
        },
        {
          label: 'Cancel',
        },
      ],
    });
  };

  return (
    <Layout pageLoading={resultsLoading}>
      {processing && <Loading text={loadingText} />}
      <div className="content content-fixed pd-b-0">
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <Breadcrumb
            heading="Base Model Management"
            items={breadcrumb}
            actions={actionButtons}
            setShowImportModal={setShowImportModal}
          />
        </Container>
      </div>
      {showImportModal &&
        <Modals.ImportDevices
          truncate={truncate}
          show={showImportModal}
          processing={processing}
          setShow={setShowImportModal}
          callback={handleImportModels}
          setTruncate={setTruncate}
          title="Import Device Base Models"
          subheading="Device Importer allows you to easily import device base models and variants in CSV datafile."
          type="Device Base Models"
        />
      }
      <Devices.ModelFilter
        setData={setModels}
        selectedRows={selectedRows}
        resultsLoading={resultsLoading}
        setResultsLoading={setResultsLoading}
        setSelectedRows={setSelectedRows}
        setTotalRecords={setTotalRecords}
        setFilterOptions={setFilterOptions}
      />
      <Devices.ModelList
        setSelectedRows={setSelectedRows}
        resultsLoading={resultsLoading}
        setResultsLoading={setResultsLoading}
        filterOptions={filterOptions}
        setResults={setModels}
        results={models}
        sorting={sorting}
        setSorting={setSorting}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        totalRecords={totalRecords}
        setTotalRecords={setTotalRecords}
        handleDeleteRecord={handleDeleteModel}
      />
    </Layout>
  );
};

export default Models;
