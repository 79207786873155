import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const ResultsModal = props => {
    const {
        title,
        titleClasses,
        body,
        show,
        setShow,
        buttonText,
        buttonClass,
        buttonOnClick,
    } = props;

    const handleClose = () => {
        window.location = "/";
        return setShow(false);
    }

    return (
        <Modal show={show} onHide={handleClose} centered className="special-text-modal">
            <Modal.Header closeButton>
                <h5 className={`modal-title ${titleClasses}`}>
                    {title}
                </h5>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'flex-start' }}>
                <a href="/" className={buttonClass} onClick={buttonOnClick}>
                    {buttonText}
                </a>
            </Modal.Footer>
        </Modal>
    );
};

ResultsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    title: PropTypes.string,
    titleClasses: PropTypes.string,
    body: PropTypes.any,
    buttonText: PropTypes.string,
    buttonClass: PropTypes.string,
    buttonOnClick: PropTypes.func,
};

ResultsModal.defaultProps = {
    title: '',
    titleClasses: '',
    body: <p />,
    buttonText: '',
    buttonClass: 'btn btn-primary',
    buttonOnClick: () => {},
};

export default ResultsModal;
