import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import {
    SettingsProvider,
    NetworksProvider,
    DealsheetsProvider,
    SaleIndicatorsProvider,
    DevicesProvider,
    PackagesProvider,
} from 'providers';
import {
    Form,
    Breadcrumb,
    DeviceAutoCompleteInput,
    FeatherIcon,
    Packages,
    TextEditor,
} from 'components';
import wysiwyg from 'static/wysiwyg.json';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import validator from 'validator';
import Swal from 'sweetalert2';
import moment from 'moment';

const dealTypes = [
    { value: 'Generic', label: 'Generic' },
    { value: 'Exclusive', label: 'Exclusive' },
    { value: 'Hot Deals', label: 'Hot Deals' },
    { value: 'Promotion', label: 'Promotion' },
    { value: 'Recommended', label: 'Recommended' }
];

const actionButtons = [{
	type: 'button-link',
	link: '/dealsheets/import',
	title: 'Import Dealsheet',
	classes: 'btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5',
	icon: <FeatherIcon icon="download" classes="wd-10 mg-r-5" />,
}];

export default function AddDeal() {
    const {
        trigger,
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [specialText, setSpecialText] = useState('');
    const [loaderText, setLoaderText] = useState("Loading");
    const [processing, setProcessing] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [saleIndicators, setSaleIndicators] = useState([]);
    const [expiryDate, setExpiryDate] = useState(null);
    const [contractTerms, setContractTerms] = useState([]);
    const [networkProviders, setNetworkProviders] = useState([]);
    const breadcrumb = [
        { title: "Deals", link: "/deals", active: false },
        { title: "Add Deal", active: true },
    ];

    const formInit = () => {
        register('valid_to', { required: true });
        register('deal_type', { required: true });
        register('device_id', { required: true });
        register('package_id', { required: true });
        register('provider_id', { required: true });
        register('contract_term', { required: true });
        register('special_text', { required: false });
        register('sale_indicator_id', { required: true });
    };

    async function fetchData() {
        const networks = await NetworksProvider.getAll();
        const settings = await SettingsProvider.searchSettingByCodeKey('dealsheet', 'contract_terms');
        const indicators = await SaleIndicatorsProvider.getAll();

        if (networks && networks.results) {
            const networkProviders = networks.results.map(item => ({
                value: item.id,
                label: item.name,
            }));
            setNetworkProviders(networkProviders);
        }

        if (settings && settings.value) {
            const contractTerms = settings.value.map(term => ({
                value: term,
                label: term === 1 ? `${term} month` : `${term} months`,
            }));
            setContractTerms(contractTerms);
        }

        if (indicators && indicators.results) {
            const saleIndicators = indicators.results.map(item => ({
                value: item.id,
                label: item.description,
            }));
            setSaleIndicators(saleIndicators);
        }

        setPageLoading(false);
        formInit();
    }

    useEffect(() => {
        fetchData();
    }, []);

    const onSubmit = async (data) => {
        setLoaderText('Saving deal');
        setProcessing(true);
        const {
            device_id,
            package_id,
            device_cost,
            reduced_subscription,
        } = data;
        const postData = {
            ...data,
            online: true,
            status: 'ACTIVE',
            dealsheet_type: 'A',
            valid_from: moment().format('YYYY-MM-DD'),
        };

        // first retrieve selected device to save device_description
        const device = await DevicesProvider.get(device_id);

        // first retrieve selected pacakge to save package_code
        const pack = await PackagesProvider.get(package_id);
        
        // pass device_description and package code
        postData.device_description = device.title;
        postData.package_code = pack.code;

        postData.device_cost = (device_cost.length > 0) ? device_cost : null;
        postData.reduced_subscription = (reduced_subscription.length > 0) ? reduced_subscription : null;

        const result = await DealsheetsProvider.add(postData);
        const { status, message } = result;

        setProcessing(false);

        if (status === 'success') {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'New deal successfully added!',
                showConfirmButton: false,
                timer: 5000
            });
            return setTimeout(async function () {
                window.location = '/deals';
            }, 5000);
        }
        return Swal.fire({
            position: 'center',
            icon: 'error',
            title: message || 'Failed to process request, please try again!',
            showConfirmButton: false,
            timer: 5000
        });
    };

    return (
        <Layout pageLoading={pageLoading || processing} loaderText={loaderText}>
            <div className="content content-fixed pd-b-0">
                <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <Breadcrumb
                        heading="Add a new Deal"
                        items={breadcrumb}
                        actions={actionButtons}
                    />
                </Container>
            </div>
            <div className="contact-wrapper" style={{ top: 174 }}>
                <div className="contact-content fluid">
                    {/* <div className="contact-content-header r-f-0">
                        <nav className="nav">
                            <a
                                role="button"
                                href="/deals/add"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                                className="nav-link active"
                            >
                                Information
                            </a>
                        </nav>
                    </div> */}
                    <div className="contact-content-body ps top-0 r-f-0 overflow-y-auto">
                        <div className="tab-content">
                            <div className="tab-pane show active pd-20 pd-xl-25">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="d-flex align-items-center justify-content-between mg-b-25">
                                        <h5 className="mg-b-0">Deal Information</h5>
                                        <div className="d-flex">
                                            <button
                                                className="btn btn-sm btn-outline-primary d-flex align-items-center mg-r-5"
                                                type="submit"
                                            >
                                                <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                                                <span className="d-none d-sm-inline mg-l-5">
                                                    {" "}
                                                    Save
                                                </span>
                                            </button>
                                            <button
                                                className="btn btn-sm btn-white d-flex align-items-center"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    return (window.location = "/deals");
                                                }}
                                            >
                                                <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                                                <span className="d-none d-sm-inline mg-l-5">
                                                    {" "}
                                                    Cancel
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <p>
                                        Please complete the form below to add a new deal. Make sure that all
                                        required (<span className="tx-danger">*</span>) are completed before
                                        pressing "Save".
                                    </p>
                                    <Row>
                                        <Col xs={12} sm={3}>
                                            <div className="form-group">
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Dealsheet Number&nbsp;
                                                    <span className="tx-danger">*</span>
                                                </label>
                                                <Form.Input
                                                    id="dealsheet_number"
                                                    name="dealsheet_number"
                                                    className="form-control"
                                                    regRef={{ required: true }}
                                                    onChange={() => trigger('dealsheet_number')}
                                                    register={register}
                                                    errors={errors}
                                                    validate
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <div className={`form-group ${errors.provider_id ? 'is-invalid' : ''}`}>
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Network Provider&nbsp;
                                                    <span className="tx-danger">*</span>
                                                </label>
                                                <Select
                                                    options={networkProviders}
                                                    onChange={item => {
                                                        setValue('provider_id', item.value);
                                                        return trigger('provider_id');
                                                    }}
                                                />
                                                {errors.provider_id &&
                                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                        <li className="parsley-required">
                                                            Please select a valid network provider.
                                                        </li>
                                                    </ul>}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <div className={`form-group ${errors.device_id ? 'is-invalid' : ''}`}>
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Device&nbsp;
                                                    <span className="tx-danger">*</span>
                                                </label>
                                                <DeviceAutoCompleteInput
                                                    setDevice={value => {
                                                        setValue('device_id', value);
                                                        return trigger('device_id');
                                                    }}
                                                />
                                                {errors.device_id &&
                                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                        <li className="parsley-required">
                                                            Please select a valid device.
                                                        </li>
                                                    </ul>}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <div className={`form-group ${errors.package_id ? 'is-invalid' : ''}`}>
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Package&nbsp;
                                                    <span className="tx-danger">*</span>
                                                </label>
                                                <Packages.AutoCompleteInput
                                                    setPackage={value => {
                                                        setValue('package_id', value);
                                                        return trigger('package_id');
                                                    }}
                                                />
                                                {errors.package_id &&
                                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                        <li className="parsley-required">
                                                            Please select a valid package.
                                                        </li>
                                                    </ul>}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <div className={`form-group ${errors.total_subscription ? 'is-invalid' : ''}`}>
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Total Subscription&nbsp;
                                                    <span className="tx-danger">*</span>
                                                </label>
                                                <div className="input-group mg-b-10">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text"
                                                            style={{ minWidth: "auto" }}
                                                        >
                                                            R
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className={`form-control ${errors.total_subscription ? 'parsley-error' : ''}`}
                                                        onChange={() => trigger('total_subscription')}
                                                        {...register('total_subscription', {
                                                            required: true,
                                                            validate: value => validator.isNumeric(value),
                                                        })}
                                                    />
                                                </div>
                                                {errors.total_subscription &&
                                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                        <li className="parsley-required">
                                                            Please input a valid total subscription/deal price.
                                                        </li>
                                                    </ul>}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <div className={`form-group ${errors.contract_term ? 'is-invalid' : ''}`}>
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Contract Term&nbsp;
                                                    <span className="tx-danger">*</span>
                                                </label>
                                                <Select
                                                    options={contractTerms}
                                                    onChange={item => {
                                                        setValue('contract_term', item.value);
                                                        return trigger('contract_term');
                                                    }}
                                                />
                                                {errors.contract_term &&
                                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                        <li className="parsley-required">
                                                            Please select a valid contract term.
                                                        </li>
                                                    </ul>}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <div className={`form-group ${errors.valid_to ? 'is-invalid' : ''}`}>
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Expiry Date&nbsp;
                                                    <span className="tx-danger">*</span>
                                                </label>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    selected={expiryDate}
                                                    onChange={(date) => {
                                                        setExpiryDate(date);
                                                        setValue('valid_to', moment(date).format('YYYY-MM-DD'));
                                                        return trigger('valid_to');
                                                    }}
                                                />
                                                {errors.valid_to &&
                                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                        <li className="parsley-required">
                                                            Please select a valid sale indicator.
                                                        </li>
                                                    </ul>}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <div className={`form-group ${errors.sale_indicator_id ? 'is-invalid' : ''}`}>
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Sale Indicator&nbsp;
                                                    <span className="tx-danger">*</span>
                                                </label>
                                                <Select
                                                    options={saleIndicators}
                                                    onChange={item => {
                                                        setValue('sale_indicator_id', item.value);
                                                        return trigger('sale_indicator_id');
                                                    }}
                                                />
                                                {errors.sale_indicator_id &&
                                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                        <li className="parsley-required">
                                                            Please select a valid sale indicator.
                                                        </li>
                                                    </ul>}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <div className={`form-group ${errors.provider_id ? 'is-invalid' : ''}`}>
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Deal Type&nbsp;
                                                    <span className="tx-danger">*</span>
                                                </label>
                                                <Select
                                                    options={dealTypes}
                                                    onChange={item => {
                                                        setValue('deal_type', item.value);
                                                        return trigger('deal_type');
                                                    }}
                                                />
                                                {errors.deal_type &&
                                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                        <li className="parsley-required">
                                                            Please select a valid deal type.
                                                        </li>
                                                    </ul>}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <div className={`form-group ${errors.provider_id ? 'is-invalid' : ''}`}>
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Package Title
                                                </label>
                                                <Form.Input
                                                    id="package_title"
                                                    name="package_title"
                                                    onChange={() => trigger('deal_type')}
                                                    regRef={{ required: false }}
                                                    register={register}
                                                    errors={errors}
                                                    validate
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <div className={`form-group ${errors.reduced_subscription ? 'is-invalid' : ''}`}>
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Reduced Subscription
                                                </label>
                                                <div className="input-group mg-b-10">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text"
                                                            style={{ minWidth: "auto" }}
                                                        >
                                                            R
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className={`form-control ${errors.reduced_subscription ? 'parsley-error' : ''}`}
                                                        onChange={() => trigger('reduced_subscription')}
                                                        {...register('reduced_subscription', {
                                                            required: false,
                                                            validate: value => value.length > 0 ? validator.isNumeric(value) : true,
                                                        })}
                                                    />
                                                </div>
                                                {errors.reduced_subscription &&
                                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                        <li className="parsley-required">
                                                            Please input a valid reduced subscription.
                                                        </li>
                                                    </ul>}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <div className={`form-group ${errors.device_cost ? 'is-invalid' : ''}`}>
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Device Cost
                                                </label>
                                                <div className="input-group mg-b-10">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text"
                                                            style={{ minWidth: "auto" }}
                                                        >
                                                            R
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className={`form-control ${errors.device_cost ? 'parsley-error' : ''}`}
                                                        onChange={() => trigger('device_cost')}
                                                        {...register('device_cost', {
                                                            required: false,
                                                            validate: value => value.length > 0 ? validator.isNumeric(value) : true,
                                                        })}
                                                    />
                                                </div>
                                                {errors.device_cost &&
                                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                        <li className="parsley-required">
                                                            Please input a valid device cost.
                                                        </li>
                                                    </ul>}
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <div className="form-group">
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Description&nbsp;
                                                    <span className="tx-danger">*</span>
                                                </label>
                                                <Form.Input
                                                    id="description"
                                                    name="description"
                                                    className="form-control"
                                                    onChange={() => trigger('description')}
                                                    regRef={{ required: true }}
                                                    register={register}
                                                    errors={errors}
                                                    validate
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Free Accessory
                                                </label>
                                                <Form.Input
                                                    id="free_accessory"
                                                    name="free_accessory"
                                                    className="form-control"
                                                    onChange={() => trigger('free_accessory')}
                                                    regRef={{ required: false }}
                                                    register={register}
                                                    errors={errors}
                                                    validate
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Extra Data
                                                </label>
                                                <Form.Input
                                                    id="extra_data"
                                                    name="extra_data"
                                                    className="form-control"
                                                    regRef={{ required: false }}
                                                    onChange={() => trigger('extra_data')}
                                                    register={register}
                                                    errors={errors}
                                                    validate
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                    Special Text
                                                </label>
                                                <TextEditor.WYSIWYG
                                                    model={specialText}
                                                    placeholderText="Enter special text..."
                                                    toolbarButtons={wysiwyg.toolbarButtons.specialText}
                                                    setModel={value => {
                                                        setSpecialText(value);
                                                        setValue('special_text', value);
                                                        return trigger('special_text');
                                                    }}
                                                />
                                                {errors.special_text &&
                                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                                        <li className="parsley-required">
                                                            Please input valid special text.
                                                        </li>
                                                    </ul>}
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
