import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import { Container, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Layout } from 'containers';
import { Breadcrumb, FeatherIcon, Loading, ResultsModal } from 'components';
import { DevicesProvider, ManufacturersProvider } from 'providers';
import 'react-confirm-alert/src/react-confirm-alert.css';

const DuplicateDevice = () => {
    const { id } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ processing, setProcessing ] = useState(false);
    const [ showModal, setShowModal ] = useState(false);
    const [ modalData, setModalData ] = useState({})
    const [ model, setModel ] = useState('');
    const [ builtIn, setBuiltIn ] = useState(null);
    const [ storageType, setStorageType ] = useState(null);
    const [ notExpandableStorage, setNotExpandableStorage ] = useState(null);
    const [ features, setFeatures ] = useState({});
    const [ device, setDevice ] = useState({});
    const [ activeTab, setActiveTab ] = useState('information');
    const [ manufacturers, setManufacturers ] = useState([]);
    const [ types, setTypes ] = useState([]);
    const breadcrumb = [{ title: 'Devices', link: '/devices', active: false }, { title: 'Duplicate Device', active: true }];
    
    useEffect(() => {
        const fetchData = async () => {
            const device = await DevicesProvider.get(id);
            const mdata  = await ManufacturersProvider.getAll();
            const tdata  = await DevicesProvider.getDeviceTypes();
            if (mdata.results) {
                if (mdata.results.length > 0) {
                    const manufacturers = [];
                    mdata.results.map(item => manufacturers.push({ value: item.id, label: item.name }));
                    setManufacturers(manufacturers);
                }
            }
            if (tdata.results) {
                if (tdata.results.length > 0) {
                    const types = [];
                    tdata.results.map(item => types.push({ value: item.id, label: item.name }));
                    setTypes(types);
                }
            }
            setLoading(false);
            setModel(`${device.model} (Copy)`);
            setFeatures(device.features);
            setDevice(device);
        };
        fetchData();
    }, []);

    function handleCancel() {
        confirmAlert({
            title: 'Discard Changes',
            message: 'You are closing a record that has not been saved.',
            buttons: [
                {
                    label: 'Discard Changes',
                    onClick: () => window.location = '/devices',
                },
                {
                    label: 'Cancel',
                }
            ]
        });
    }

    function handleSave() {
        if (device.id) {
            if (device.model === model) {
                setModalData({
                    title: 'Error',
                    titleClasses: 'tx-danger',
                    buttonText: 'Close',
                    body: <p>Duplicate cannot be the same as the origin.</p>,
                    buttonClass: 'btn btn-white',
                    buttonOnClick: (e) => {
                        e.preventDefault();
                        setShowModal(false);
                        return false;
                    }
                });
                setShowModal(true);
                return false;
            }
            confirmAlert({
                title: 'Save',
                message: 'You are about to capture a new device record.',
                buttons: [
                    {
                        label: 'Proceed',
                        onClick: () => saveDevice(),
                    },
                    {
                        label: 'Cancel',
                    }
                ]
            });
        }
    }

    const generatePermakey = (manufacturer, model) => {
        var permakey = (manufacturer + '-' + model).split(' ').join('-').toLowerCase();
        permakey = permakey.replace('(', '');
        permakey = permakey.replace(')', '');
        return permakey;
    };

    async function saveDevice() {
        setProcessing(true);
        var newDevice = {
            product_code      : `${device.product_code}_${builtIn}`,
            device_type_id    : device.device_type_id,
            manufacturer_name : device.manufacturer_name,
            manufacturer_id   : device.manufacturer_id,
            images            : device.images,
            features          : device.features,
            archived          : false,
            status            : 'ACTIVE',
            created           : new Date().toISOString(),
            updated           : new Date().toISOString(),
        };

        newDevice.model    = model;
        newDevice.permakey = generatePermakey(device.manufacturer.name, model);
        newDevice.features.hardware_performance.built_in_storage.built_in               = builtIn;
        newDevice.features.hardware_performance.built_in_storage.storage_type           = storageType;
        newDevice.features.hardware_performance.built_in_storage.not_expandable_storage = notExpandableStorage;

        const results = await DevicesProvider.create(newDevice);
        setProcessing(false);

        if (results.status === 'success') {
            setModalData({
                title: 'Success',
                titleClasses: 'tx-success',
                buttonText: 'Close',
                body: <p>New device has been successfully captured.</p>,
                buttonOnClick: (e) => {
                    e.preventDefault()
                    window.location = '/devices';
                    return false;
                }
            });
            setShowModal(true);
        } else {
            setModalData({
                title: 'Error',
                titleClasses: 'tx-danger',
                buttonText: 'Close',
                buttonClass: 'btn btn-white',
                body: <p>{results.message}</p>,
                buttonOnClick: (e) => {
                    e.preventDefault();
                    setShowModal(false);
                    return false;
                }
            });
            setShowModal(true);
        }
    }

    return (
        <Layout>
            {(loading || processing) && <Loading text={processing ? 'Processing' : 'Loading'} />}
            <ResultsModal show={showModal} setShow={setShowModal} {...modalData} />
            <div className="content content-fixed pd-b-0">
                <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <Breadcrumb
                        heading={`Duplicate Device${device.id ? ' : ' + device.manufacturer.name + ' ' + model : ''}`}
                        items={breadcrumb}
                    />
                </Container>
            </div>
            {device.id &&
            <div className="contact-wrapper" style={{ top: 174 }}>
                <div className="contact-content fluid">
                    <div className="contact-content-header r-f-0">
                        <nav className="nav">
                            <a role="button" href="/devices/add" onClick={e => { e.preventDefault(); setActiveTab('information'); }} className={`nav-link ${activeTab === 'information' ? 'active' : ''}`}>Information</a>
                            <a role="button" href="/devices/add" onClick={e => { e.preventDefault(); }} className={`nav-link ${activeTab === 'images' ? 'active' : ''}`}>Images</a>
                        </nav>
                    </div>
                    <div className="contact-content-body ps r-f-0 overflow-y-auto">
                        <div className="tab-content">
                            <div className={`tab-pane ${activeTab === 'information' ? 'show active' : ''} pd-20 pd-xl-25`}>
                                <div className="d-flex align-items-center justify-content-between mg-b-25">
                                    <h5 className="mg-b-0">Device Information</h5>
                                    <div className="d-flex">
                                        <button
                                            className="btn btn-sm btn-white d-flex align-items-center mg-r-5"
                                            onClick={handleSave}
                                        >
                                            <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                                            <span className="d-none d-sm-inline mg-l-5"> Save</span>
                                        </button>
                                        <button
                                            className="btn btn-sm btn-white d-flex align-items-center"
                                            onClick={handleCancel}
                                        >
                                            <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                                            <span className="d-none d-sm-inline mg-l-5"> Discard</span>
                                        </button>
                                    </div>
                                </div>
                                <h6 className="mg-t-40 mg-b-20">Device name</h6>
                                <Row style={{ maxWidth: '100%' }}>
                                    <Col xs={12} sm={4}>
                                        <div className="form-group">
                                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                Device Type
                                            </label>
                                            <Select
                                                name="device_type_id"
                                                options={types}
                                                isLoading={types.length === 0}
                                                value={device.device_type.id}
                                                inputValue={device.device_type.name}
                                                isDisabled
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <div className="form-group">
                                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                Manufacturer
                                            </label>
                                            <Select
                                                name="manufacturer_id"
                                                options={manufacturers}
                                                isLoading={manufacturers.length === 0}
                                                value={device.manufacturer.id}
                                                inputValue={device.manufacturer.name}
                                                isDisabled
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <div className="form-group">
                                            <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                Model
                                            </label>
                                            <input
                                                name="model"
                                                type="text"
                                                className="form-control"
                                                defaultValue={model}
                                                onChange={e => setModel(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                    {features.hardware_performance.built_in_storage &&
                                    <Col xs={12} sm={12}>
                                        <h6 className="mg-t-40 mg-b-20">Built in storage</h6>
                                        <Row>
                                            {'built_in' in features.hardware_performance.built_in_storage &&
                                            <Col xs={4}>
                                                <div className="form-group">
                                                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                        Built in
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="built_in"
                                                        className="form-control"
                                                        defaultValue={features.hardware_performance.built_in_storage.built_in}
                                                        onChange={e => setBuiltIn(e.target.value)}
                                                    />
                                                </div>
                                            </Col>}
                                            {'storage_type' in features.hardware_performance.built_in_storage &&
                                            <Col xs={4}>
                                                <div className="form-group">
                                                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                        Storage type
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="built_in"
                                                        className="form-control"
                                                        defaultValue={features.hardware_performance.built_in_storage.storage_type}
                                                        onChange={e => setStorageType(e.target.value)}
                                                    />
                                                </div>
                                            </Col>}
                                            {'not_expandable_storage' in features.hardware_performance.built_in_storage &&
                                            <Col xs={4}>
                                                <div className="form-group">
                                                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                                        Not expandable storage
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="built_in"
                                                        className="form-control"
                                                        defaultValue={features.hardware_performance.built_in_storage.not_expandable_storage}
                                                        onChange={e => setNotExpandableStorage(e.target.value)}
                                                    />
                                                </div>
                                            </Col>}
                                        </Row>
                                    </Col>}
                                </Row>
                            </div>
                            <div className={`tab-pane ${activeTab === 'images' ? 'show active' : ''} pd-20 pd-xl-25`}>
                                <div className="d-flex align-items-center justify-content-between mg-b-25">
                                    <h6 className="mg-b-0">Device Images</h6>
                                    <div className="d-flex">
                                        <button className="btn btn-sm btn-white d-flex align-items-center mg-r-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save wd-10 mg-r-5"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                                            <span className="d-none d-sm-inline mg-l-5"> Save</span>
                                        </button>
                                        <button className="btn btn-sm btn-outline-danger d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash">
                                            <polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                            <span className="d-none d-sm-inline mg-l-5"> Delete</span>
                                        </button>
                                    </div>
                                </div>
                                <Row style={{ maxWidth: '100%' }}>
                                    <Col xs={12} sm={4}>
                                        <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                            Files
                                        </label>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </Layout>
    );
};

export default DuplicateDevice;
