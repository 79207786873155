import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { SessionProvider } from 'providers';
import smoothscroll from 'smoothscroll-polyfill';

import './index.scss';
import App from './App';
import config from './config';

const { api, stage } = config;

const httpLink = createHttpLink({
    uri: api[stage]['graphql'],
    // uri: 'http://localhost:4000/',
});

const authLink = setContext((_, { headers }) => {

    // Get the authentication token from local storage if it exists
    const token = SessionProvider.getToken();

    // Return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

// IMPORTANT: use correct ID of your root element
// this is the ID of the div in your index,html
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Element.scrollIntoView polyfill for Safari
smoothscroll.polyfill();

root.render(
    <ApolloProvider client={client}>
        <StrictMode>
            <App />
        </StrictMode>
    </ApolloProvider>
);
