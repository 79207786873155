import React from 'react';
import PropTypes from 'prop-types';

const PreLoader = props => {
    const { text } = props;
    return (
        <div className="preloader-wrapper">
            <div className="preloader">
                <span className="line line-1"></span>
                <span className="line line-2"></span>
                <span className="line line-3"></span>
                <span className="line line-4"></span>
                <span className="line line-5"></span>
                <span className="line line-6"></span>
                <span className="line line-7"></span>
                <span className="line line-8"></span>
                <span className="line line-9"></span>
                <div>{text}</div>
            </div>
        </div>
    );
};

PreLoader.propTypes = {
    text: PropTypes.string,
};

PreLoader.defaultProps = {
    text: 'Loading',
};

export default PreLoader;
