import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FeatherIcon } from 'components';

const Uploading = props => {
    const {
        show,
        setShow,
        steps,
        title,
        text,
    } = props;
    const handleClose = () => setShow(false);
    return (
        <Modal show={show} onHide={handleClose} centered className="confirm-modal" backdrop="static">
            <Modal.Body>
                <div>
                    <div>
                        <div className="content clearfix pd-0">
                            <h3 className="title">{title}...</h3>
                            <p className="mg-b-20">{text}</p>
                            <hr />
                        </div>
                        {steps &&
                        <ul className="steps">
                            {steps.uploadDams &&
                            <li className={`step-item ${steps.uploadDams === 'in progress' ? 'active' : ''} ${steps.uploadDams === 'complete' ? 'complete' : ''}`}>
                                <a href="/" className="step-link" onClick={e => e.preventDefault()}>
                                    <span className="step-number">
                                        {steps.uploadDams === 'pending' &&
                                        <FeatherIcon icon="more-horizontal" width="16" height="16" />}
                                        {steps.uploadDams === 'in progress' &&
                                        <FeatherIcon icon="refresh-cw" width="16" height="16" classes="fa-spin" />}
                                        {steps.uploadDams === 'complete' &&
                                        <FeatherIcon icon="check" width="16" height="16" />}
                                    </span>
                                    <span className="step-title">Uploading to DAMS</span>
                                </a>
                            </li>}
                            {steps.uploadMondo &&
                            <li className={`step-item ${steps.uploadMondo === 'in progress' ? 'active' : ''} ${steps.uploadMondo === 'complete' ? 'complete' : ''}`}>
                                <a href="/" className="step-link" onClick={e => e.preventDefault()}>
                                    <span className="step-number">
                                        {steps.uploadMondo === 'pending' &&
                                        <FeatherIcon icon="more-horizontal" width="16" height="16" />}
                                        {steps.uploadMondo === 'in progress' &&
                                        <FeatherIcon icon="refresh-cw" width="16" height="16" classes="fa-spin" />}
                                        {steps.uploadMondo === 'complete' &&
                                        <FeatherIcon icon="check" width="16" height="16" />}
                                    </span>
                                    <span className="step-title">Uploading to Mondo</span>
                                </a>
                            </li>}
                        </ul>}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

Uploading.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    steps: PropTypes.shape({}),
    title: PropTypes.string,
    text: PropTypes.string
};

Uploading.defaultProps = {
    steps: null,
    title: 'File Upload in Progress',
    text: 'This may take a few minutes to complete. Please do not close the window whilst upload is in progress.'
};

export default Uploading;