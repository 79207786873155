import axios from 'axios';
import config from '../config';
import Session from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
let headers   = {};

if (Session.getToken()) {
  headers = {
    'Authorization': `Bearer ${Session.getToken()}`,
  }
}

class UsersProvider {
  static async getAll(offset=config.results.offset, limit=config.results.limit) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}users?offset=${offset}&limit=${limit}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async getRoles(offset=config.results.offset, limit=config.results.limit) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}roles?offset=${offset}&limit=${limit}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async getRole(id) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}roles/${id}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async create(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}users`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async update(id, data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}users/${id}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async resetPassword(id) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}users/${id}/password/reset`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async delete(id) {
    return await axios({
      mode: 'no-cors',
      method: 'DELETE',
      url: `${baseurl}users/${id}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async addRole(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}roles`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async updateRole(id, data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}roles/${id}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async deleteRole(id) {
    return await axios({
      mode: 'no-cors',
      method: 'DELETE',
      url: `${baseurl}roles/${id}`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }
}

export default UsersProvider;
