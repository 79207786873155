var stage = process.env.REACT_APP_ENV || 'dev';

export default {
    stage,
    auth: {
        cookie: {
            name: '__damsUserSession',
            expires: 1, // days
        }
    },
    results: {
        offset: 0,
        limit: 100,
        pagination: {
            perpage: 100,
            perpageOptions: [50, 100, 250, 500, 1000]
        },
        sorting: {
            dealsheets: {
                column: 'dealsheet_number',
                direction: 'asc',
            },
            devices: {
                column: 'created',
                direction: 'desc',
            },
            models: [
                ['updated', 'desc'],
                ['created', 'desc'],
            ]
        }
    },
    aws: {
        s3: {
            url: `https://dams-media-${stage}.s3.eu-west-2.amazonaws.com/`,
            options : {
                dirName: 'devices',
                bucketName: `dams-media-${stage}`,
                region: 'eu-west-2',
            },
            mondo: {
                options : {
                    bucketName: `buymondo-media-${stage}`,
                    dirName: 'assets/devices',
                    region: 'eu-west-2',
                }
            }
        }
    },
    api: {
        dev: {
            graphql: process.env.GRAPHQL ? process.env.GRAPHQL : 'https://yveet6094b.execute-api.eu-west-2.amazonaws.com/dev/graphql',
            baseurl: process.env.API_URL ? process.env.API_URL : 'https://5pdhn04bz2.execute-api.eu-west-2.amazonaws.com/dev/v1/',
            token: process.env.API_TOKEN ? process.env.API_TOKEN : null,
        },
        qa: {
            graphql: process.env.GRAPHQL ? process.env.GRAPHQL : 'https://yveet6094b.execute-api.eu-west-2.amazonaws.com/dev/graphql',
            baseurl: process.env.API_URL ? process.env.API_URL : 'https://xyoecgbpic.execute-api.eu-west-2.amazonaws.com/qa/v1/',
            token: process.env.API_TOKEN ? process.env.API_TOKEN : null,
        },
        uat: {
            graphql: process.env.GRAPHQL ? process.env.GRAPHQL : 'https://yveet6094b.execute-api.eu-west-2.amazonaws.com/dev/graphql',
            baseurl: process.env.API_URL ? process.env.API_URL : 'https://cpqp8zv4mf.execute-api.eu-west-2.amazonaws.com/uat/v1/',
            token: process.env.API_TOKEN ? process.env.API_TOKEN : null,
        },
        prod: {
            graphql: process.env.GRAPHQL ? process.env.GRAPHQL : 'https://yveet6094b.execute-api.eu-west-2.amazonaws.com/dev/graphql',
            baseurl: process.env.API_URL ? process.env.API_URL : 'https://rne8f485t9.execute-api.eu-west-2.amazonaws.com/prod/v1/',
            token: process.env.API_TOKEN ? process.env.API_TOKEN : null,
        }
    }
};
