import AddBaseDevice from './AddBaseDevice';
import AddManufacturer from './AddManufacturer';
import AddType from './AddType';
import AddVariant from './AddVariant';
import Filter from './Filter';
import ManufacturerList from './ManufacturerList';
import TypeList from './TypeList';
import ModelFilter from './ModelFilter';
import ModelList from './ModelList';
import List from './List';
import UpdateManufacturer from './UpdateManufacturer';
import UpdateType from './UpdateType';
import Variants from './Variants';

export default {
    AddBaseDevice,
    AddManufacturer,
    AddType,
    AddVariant,
    Filter,
    ManufacturerList,
    TypeList,
    ModelFilter,
    ModelList,
    List,
    UpdateManufacturer,
    UpdateType,
    Variants,
};
