import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FeatherIcon } from 'components';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';

const UploadImage = props => {
    const {
        show,
        text,
        title,
        setShow,
        handleUploadImage,
    } = props;
    const handleClose = () => setShow(false);
    const fileInput = useRef();

    async function onSubmit(event) {
        event.preventDefault();
        handleUploadImage(fileInput);
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <h6 className="modal-title">{title}</h6>
            </Modal.Header>
            <Modal.Body>
                <p className="mg-b-20">{text}</p>
                <div className="legend" data-label="Select File to Upload">
                    <form id="image-uploader-form" onSubmit={onSubmit}>
                        <input name="file" type="file" ref={fileInput} />
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-sm btn-primary d-flex align-items-center"
                    type="submit"
                    form="image-uploader-form"
                >
                    <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                    <span className="d-none d-sm-inline mg-l-5">
                        Upload
                    </span>
                </button>
            </Modal.Footer>
        </Modal>
    );
};

UploadImage.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleUploadImage: PropTypes.func.isRequired,
    title: PropTypes.string,
    text: PropTypes.string
};

UploadImage.defaultProps = {
    title: 'Upload File',
    text: 'This action will upload the image to the relevant location on S3 bucket.'
};

export default UploadImage;
