import React from 'react';
import { Nav, NavLink } from 'reactstrap';
import { FeatherIcon } from 'components';

export default function NavLeft(props) {
    const { activeTab, setActiveTab } = props;
    return (
        <Nav className="flex-column">
            <NavLink
                className={activeTab === 'users' ? 'active' : ''}
                onClick={() => setActiveTab('users')}
            >
                <span>
                    <FeatherIcon icon="users" classes="wd-10" />
                </span>
            </NavLink>
            <NavLink
                className={activeTab === 'recently-added' ? 'active' : ''}
                onClick={() => setActiveTab('recently-added')}
            >
                <span>
                    <FeatherIcon icon="clock" classes="wd-10" />
                </span>
            </NavLink>
            <NavLink
                className={activeTab === 'roles' ? 'active' : ''}
                onClick={() => setActiveTab('roles')}
            >
                <span>
                    <FeatherIcon icon="tag" classes="wd-10" />
                </span>
            </NavLink>
            <NavLink
                className={activeTab === 'export' ? 'active' : ''}
                onClick={() => setActiveTab('export')}
            >
                <span>
                    <FeatherIcon icon="upload" classes="wd-10" />
                </span>
            </NavLink>
        </Nav>
    );
}