import { gql } from '@apollo/client';

export default {
    list: gql`
        query ListDeals($filters: DealFilters, $offset: Int, $limit: Int, $orderby: [[String]]) {
            deals(filters: $filters, offset: $offset, limit: $limit, orderby: $orderby) {
                contract_term
                dealsheet_number
                dealsheet_type
                total_subscription
                reduced_subscription
                device_description
                valid_from
                valid_to
                sale_indicator {
                    name
                    description
                }
                network_provider {
                    name
                    logo
                    primary_color
                }
                package {
                    name
                    code
                }
                device {
                    title
                }
            }

            saleIndicators {
                id
                name
                description
            }
        }
    `,

    get: gql`
        query GetDeal($id: ID!) {
            deal(id: $id) {
                contract_term
                dealsheet_number
                dealsheet_type
                total_subscription
                reduced_subscription
                device_description
                valid_from
                valid_to
                sale_indicator {
                    name
                    description
                }
                network_provider {
                    name
                    logo
                    primary_color
                }
                package {
                    name
                    code
                }
                device {
                    title
                }
            }
        }
    `,

    details: gql`
        query GetDeal($dealsheet_number: String!) {
            dealByDealsheetNumber(dealsheet_number: $dealsheet_number) {
                contract_term
                dealsheet_number
                dealsheet_type
                total_subscription
                reduced_subscription
                device_description
                valid_from
                valid_to
                sale_indicator {
                    name
                    description
                }
                network_provider {
                    name
                    logo
                    primary_color
                }
                package {
                    name
                    code
                }
                device {
                    title
                }
            }

            saleIndicators {
                id
                name
                description
            }
        }
    `,
};
