import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { NetworksProvider, UsersProvider } from 'providers';
import { Modals } from 'components';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';

export default function UploadsFilter(props) {
    const { data, setFiltered, setResultsLoading } = props;
    const { register, handleSubmit, setValue } = useForm();
    const [users, setUsers] = useState([]);
    const [networks, setNetworks] = useState([]);
    const [expiryDate, setExpiryDate] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [resettable, setResettable] = useState(false);
    const [alertContent, setAlertContent] = useState({});
    const [componentLoading, setComponentLoading] = useState(true);
    const toggleCollapsed = () => setCollapsed(!collapsed);

    async function fetchData() {
        const data = await NetworksProvider.getAll();
        const users = await UsersProvider.getAll();
        if (data.results) {
            if (data.results.length > 0) {
                const networks = [];
                data.results.map(item => networks.push({ value: item.id, label: item.name }));
                setNetworks(networks);
            }
        }
        setUsers(users.results || []);
        setComponentLoading(false);
    };

    useEffect(() => {
        fetchData();
        register('valid_to', { required: false });
        register('reference', { required: false });
        register('provider_id', { required: false });
        register('uploaded_by', { required: false });
    }, []);

    const onSubmit = formData => {
        const {
            valid_to,
            reference,
            provider_id,
            uploaded_by,
        } = formData;

        if (
            !valid_to &&
            !reference &&
            !provider_id &&
            !uploaded_by
        ) {
            setAlertContent({
                title: 'Validation Error',
                type: 'error',
                body: <p>No filter option selected! Please select at least one filter option.</p>,
                closeButtonText: 'Close'
            });
            return setShowAlert(true);
        }

        setResultsLoading(true);
        setProcessing(true);
        setResettable(true);

        const keys = Object.keys(formData);

        const filtered = data.filter(item => {
            let found = false;
            keys.map(key => {
                if (formData[key]) {
                    if (key === 'valid_to') {
                        return found = moment(formData[key]).isSameOrBefore(moment(item[key]));
                    }
                    return found = item[key] === formData[key];
                }
            });
            return found;
        });
        setFiltered(filtered);
        setResultsLoading(false);
        setProcessing(false);
    };

    return (
        <div className="filter-wrapper">
            <Modals.Alert
                show={showAlert}
                setShow={setShowAlert}
                {...alertContent}
            />
            <button
                className={`btn btn-primary btn-block rounded-0 btn-filter-heading ${collapsed ? 'collapsed' : ''}`}
                data-toggle="collapse"
                type="button"
                aria-expanded="false"
                aria-controls="collapseFilters"
                onClick={toggleCollapsed}
            >
                Filter Options
            </button>
            <div className={`collapse mg-t-0 ${collapsed ? '' : 'show'}`} id="collapseFilters">
                <Card>
                    <CardBody>
                        <form
                            method="post"
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Row>
                                <Col xs={12} className="mg-b-15">
                                    <Row>
                                        <Col xs={12} md={3}>
                                            <div className="form-group mg-b-0">
                                                <label>Reference #</label>
                                                <Select
                                                    options={data.map(item => ({ value: item.reference, label: item.reference }))}
                                                    isDisabled={processing || componentLoading}
                                                    onChange={item => {
                                                        setValue('reference', item.value);
                                                        setResettable(true);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <div className="form-group mg-b-0">
                                                <label>Network provider</label>
                                                <Select
                                                    options={networks}
                                                    isDisabled={processing || componentLoading}
                                                    onChange={item => {
                                                        setValue('provider_id', item.value);
                                                        setResettable(true);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <div className="form-group mg-b-0">
                                                <label>Expiry Date</label>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    selected={expiryDate}
                                                    onChange={date => {
                                                        setExpiryDate(date);
                                                        setValue('valid_to', moment(date).format('YYYY-MM-DD'));
                                                        setResettable(true);
                                                    }}
                                                    disabled={processing || componentLoading}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <div className="form-group mg-b-0">
                                                <label>Uploaded By</label>
                                                <Select
                                                    options={users.map(item => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }))}
                                                    isDisabled={processing || componentLoading}
                                                    onChange={item => {
                                                        setValue('uploaded_by', item.value);
                                                        setResettable(true);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} md={2}>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                        disabled={processing || componentLoading}
                                    >
                                        {processing ? 'Processing...' : 'Apply Filters'}
                                    </button>
                                </Col>
                                <Col xs={6} md={2}>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block"
                                        disabled={processing || componentLoading || !resettable}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location = '/dealsheets/uploads';
                                        }}
                                    >
                                        Reset Filters
                                    </button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}
