import React, { useState } from 'react';
import {
    Col,
    Nav,
    NavLink,
    TabContent,
    TabPane,
    Row,
} from 'reactstrap';
import { FeatherIcon } from 'components';
import moment from 'moment';

export default function ContentUser(props) {
    const {
        id,
        role,
        status,
        created,
        updated,
        archived,
        username,
        last_name,
        first_name,
        last_login,
        handleUpdateUser,
    } = props;
    const [activeTab, setActiveTab] = useState('information');

    return (
        <>
            <div className="contact-content-header">
                <Nav className="nav">
                    <NavLink
                        className={activeTab === 'information' ? 'active' : ''}
                        onClick={() => setActiveTab('information')}
                    >
                        Information
                    </NavLink>
                    <NavLink
                        className={activeTab === 'permissions' ? 'active' : ''}
                        onClick={() => setActiveTab('permissions')}
                    >
                        Permissions
                    </NavLink>
                </Nav>
                <a href="" id="contactOptions" className="text-secondary mg-l-auto d-xl-none">
                    <FeatherIcon icon="more-horizontal" classes="wd-10" />
                </a>
            </div>
            <div className="contact-content-body ps">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="information" className="pd-20 pd-xl-25">
                        <div className="d-flex align-items-center justify-content-between mg-b-25">
                            <h6 className="mg-b-0">Personal Details</h6>
                            <div className="d-flex">
                                <a href=" " className="btn btn-sm btn-white tx-primary bd-primary d-flex align-items-center mg-r-5">
                                    <FeatherIcon icon="edit-2" classes="wd-10" />
                                    <span className="d-none d-sm-inline mg-l-5"> Edit</span>
                                </a>
                                <a href=" " className="btn btn-sm btn-white tx-danger bd-danger d-flex align-items-center">
                                    <FeatherIcon icon="trash" classes="wd-10" />
                                    <span className="d-none d-sm-inline mg-l-5"> Delete</span>
                                </a>
                            </div>
                        </div>
                        <Row>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    First Name
                                </label>
                                <p className="mg-b-0">{first_name}</p>
                            </Col>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Last Name
                                </label>
                                <p className="mg-b-0">{last_name}</p>
                            </Col>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Role
                                </label>
                                <p className="mg-b-0">{role.description}</p>
                            </Col>
                        </Row>
                        <h6 className="mg-b-20">&nbsp;</h6>
                        <Row>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Created
                                </label>
                                <p className="mg-b-0">{moment(created).format('DD MMMM YYYY hh:mma')}</p>
                            </Col>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Last Modified
                                </label>
                                <p className="mg-b-0">{updated ? moment(updated).format('DD MMMM YYYY hh:mma') : '-'}</p>
                            </Col>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Last Login
                                </label>
                                <p className="mg-b-0">{last_login ? moment(last_login).fromNow() : '-'}</p>
                            </Col>
                        </Row>
                        <h6 className="mg-t-40 mg-b-20">Account Details</h6>
                        <Row>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Email
                                </label>
                                <p className="tx-primary mg-b-0">test@rewardsco.co.za</p>
                            </Col>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Mobile
                                </label>
                                <p className="tx-primary mg-b-0">+27 31 123 1231</p>
                            </Col>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Username
                                </label>
                                <p className="mg-b-0">{username}</p>
                            </Col>
                        </Row>
                        <h6 className="mg-t-40 mg-b-20">Other Details</h6>
                        <Row>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Archived
                                </label>
                                <div className="custom-control custom-switch mr-4">
                                    <input
                                        id="archived"
                                        type="checkbox"
                                        className="custom-control-input"
                                        checked={archived}
                                        onChange={(e) => handleUpdateUser(id, {
                                            archived: e.target.checked,
                                            status: e.target.checked ? 'ARCHIVED' : status,
                                        })}
                                    />
                                    <label className="custom-control-label" htmlFor="archived">
                                        {archived ? 'Yes' : 'No'}
                                    </label>
                                </div>
                            </Col>
                            <Col xs={6} sm>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Status
                                </label>
                                <div className="custom-control custom-switch mr-4">
                                    <input
                                        id="status"
                                        type="checkbox"
                                        className="custom-control-input"
                                        checked={status.toLowerCase() === 'active'}
                                        onChange={(e) => handleUpdateUser(id, {
                                            status: e.target.checked ? 'ACTIVE' : 'INACTIVE',
                                        })}
                                    />
                                    <label className="custom-control-label" htmlFor="archived">
                                        {status.toLowerCase() === 'active' ? 'Active' : 'Inactive'}
                                    </label>
                                </div>
                            </Col>
                            <Col xs={6} sm />
                        </Row>
                    </TabPane>
                    <TabPane tabId="permissions" className="pd-20 pd-xl-25">
                        <div className="d-flex align-items-center justify-content-between mg-b-25">
                            <h6 className="mg-b-0">User Permissions</h6>
                            <div className="d-flex">
                                <a href=" " className="btn btn-sm btn-white tx-primary bd-primary d-flex align-items-center mg-r-5">
                                    <FeatherIcon icon="edit-2" classes="wd-10" />
                                    <span className="d-none d-sm-inline mg-l-5"> Edit</span>
                                </a>
                                <a href=" " className="btn btn-sm btn-white tx-danger bd-danger d-flex align-items-center">
                                    <FeatherIcon icon="trash" classes="wd-10" />
                                    <span className="d-none d-sm-inline mg-l-5"> Delete</span>
                                </a>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </>
    );
}
