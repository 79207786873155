import React from 'react';

const Steps = props => {
    const { step } = props;
    return (
        <div className="steps-wrapper clearfix">
            <ul className="steps steps-lg steps-justified steps-tab" role="tablist">
                <li className={`step-item ${step > 1 ? 'complete' : 'active'}`}>
                    <a href="/" className="step-link" onClick={e => e.preventDefault()}>
                        <span className="step-number">1</span>
                        <span className="step-title">Upload File</span>
                    </a>
                </li>
                <li className={`step-item ${step > 2 ? 'complete' : ''} ${step === 2 ? 'active' : ''} ${step < 2 ? 'disabled' : ''}`}>
                    <a href="/" className="step-link" onClick={e => e.preventDefault()}>
                        <span className="step-number">2</span>
                        <span className="step-title">Import Options</span>
                    </a>
                </li>
                <li className={`step-item ${step > 3 ? 'complete' : ''} ${step === 3 ? 'active' : ''} ${step < 3 ? 'disabled' : ''}`}>
                    <a href="/" className="step-link" onClick={e => e.preventDefault()}>
                        <span className="step-number">3</span>
                        <span className="step-title">Field Mapping</span>
                    </a>
                </li>
                <li className={`step-item ${step > 4 ? 'complete' : ''} ${step === 4 ? 'active' : ''} ${step < 4 ? 'disabled' : ''}`}>
                    <a href="/" className="step-link" onClick={e => e.preventDefault()}>
                        <span className="step-number">4</span>
                        <span className="step-title">Process File</span>
                    </a>
                </li>
                <li className={`step-item ${step > 5 ? 'complete' : ''} ${step === 5 ? 'active' : ''} ${step < 5 ? 'disabled' : ''}`}>
                    <a href="/" className="step-link" onClick={e => e.preventDefault()}>
                        <span className="step-number">5</span>
                        <span className="step-title">Complete</span>
                    </a>
                </li>
                {/* <li className={`step-item ${step > 6 ? 'complete' : ''} ${step === 6 ? 'active' : ''} ${step < 6 ? 'disabled' : ''}`}>
                    <a href="/" className="step-link" onClick={e => e.preventDefault()}>
                        <span className="step-number">6</span>
                        <span className="step-title">Post Upload Report</span>
                    </a>
                </li> */}
            </ul>
        </div>
    );
};

export default Steps;
