import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
    FeatherIcon,
    Loading,
} from 'components';
import {
    BulkActionsProvider,
    DevicesProvider,
    ManufacturersProvider,
} from 'providers';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
import devicesJSON from 'static/devices.json';

const selectStyles = {
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 })
};

const formatStr = (string) => {
    return string
        .split('_')
        .join(' ');
};

export default function AddBaseDevice(props) {
    const { isModal, showModal, title, saveCallback } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loadingText, setLoadingText] = useState('Loading');
    const [processing, setProcessing] = useState(true);
    const [model, setModel] = useState([]);
    const [types, setTypes] = useState([]);
    const [features, setFeatures] = useState({});
    const [manufacturers, setManufacturers] = useState([]);
    const [deviceTypeId, setDeviceTypeId] = useState(null);
    const [manufacturerId, setManufacturerId] = useState(null);
    const [manufacturerName, setManufacturerName] = useState(null);
    const [featureHeaders, setFeatureHeaders] = useState([]);
    const [activeFeature, setActiveFeature] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const manufacturers = await ManufacturersProvider.getAll();
            const types = await DevicesProvider.getDeviceTypes();
            setModel(model);
            if (manufacturers.results && manufacturers.results.length > 0) {
                const _manufacturers = [];
                manufacturers.results.map(item => _manufacturers.push({ value: item.id, label: item.name }));
                setManufacturers(_manufacturers);
            }
            if (types.results && types.results.length > 0) {
                const _types = [];
                types.results.map(item => _types.push({ value: item.id, label: item.name }));
                setTypes(_types);
            }
            const headers = Object.keys(devicesJSON.features);
            setActiveFeature(headers.length > 0 ? headers[0] : null);
            setFeatureHeaders(headers);
            setFeatures(devicesJSON.features);
            setProcessing(false);
        };
        fetchData();
    }, [ ]);

    const validateModelCode = async val => {
        const querystr = queryString.stringify({ code: val });
        const data = await DevicesProvider.getModels(0, 1, `&${querystr}`);
        if (data.results && data.results.length > 0) {
            return false;
        }
        return true;
    };

    const getModelCode = async data => {
        const { name } = data;
        const code = `${manufacturerName}-${name}`.split(' ').join('-');
        const valid = await validateModelCode(code);
        return (valid) ? code : `${code}-2`;
    };

    const getVariantCode = data => {
        const { colour, storage, name } = data;
        let code = `${manufacturerName}-${name}`;
        if (storage) code += `-${storage}`;
        if (colour)  code += `-${colour}`;
        return code.split(' ').join('-').trim();
    };

    const getVariantTitle = data => {
        const { colour, storage, name } = data;
        let code = `${manufacturerName} ${name}`;
        if (storage) code += ` ${storage}`;
        if (colour)  code += ` ${colour}`;
        return code.trim();
    };

    const permakey = val => {
        return val.split(' ').join('-').toLowerCase();
    }

    const onSubmit = async (data) => {
        if (!deviceTypeId) {
            return alert('Please select device type');
        }
        if (!manufacturerId) {
            return alert('Please select manufacturer');
        }

        setLoadingText(`Creating base model (${manufacturerName} ${data.name})`);
        setProcessing(true);
        const colors   = data.colors.split(' ').join('').split(',');
        const storage  = data.storage.split(' ').join('').split(',');
        const formData = {
            ...data,
            device_type_id: deviceTypeId,
            manufacturer_id: manufacturerId,
            manufacturer_name: manufacturerName,
            code: getModelCode(data),
        };

        const baseModel = {
            name: data.name,
            device_type_id: deviceTypeId,
            manufacturer_id: manufacturerId,
            manufacturer_name: manufacturerName,
            code: permakey(`${manufacturerName} ${data.name}`)
        };

        const createModelRes = await DevicesProvider.createModel(baseModel);
        if (createModelRes.status === 'success') {
        
            // create base model variants
            setLoadingText('Creating base model variants');
            const devices = [];
            colors.map(colour => {
                devices.push({
                    model_id: createModelRes.data.id,
                    device_type_id: deviceTypeId,
                    manufacturer_id: manufacturerId,
                    manufacturer_name: manufacturerName,
                    product_code: getVariantCode({ name: data.name, colour }),
                    title: getVariantTitle({ name: data.name, colour }),
                    variants: { color: colour },
                    features: data.features,
                    model: data.name,
                    storage: null,
                    permakey: permakey(getVariantCode({ name: data.name, colour })),
                    colour,
                });
                storage.map(item => {
                    devices.push({
                        model_id: createModelRes.data.id,
                        device_type_id: deviceTypeId,
                        manufacturer_id: manufacturerId,
                        manufacturer_name: manufacturerName,
                        product_code: getVariantCode({ name: data.name, colour, storage: item }),
                        title: getVariantTitle({ name: data.name, colour, storage: item }),
                        variants: { color: colour, storage: parseInt(item.replace('TB', '000GB')) },
                        features: data.features,
                        model: data.name,
                        storage: item,
                        permakey: permakey(getVariantCode({ name: data.name, colour, storage: item })),
                        colour,
                    });
                });
            });
            storage.map(item => {
                devices.push({
                    model_id: createModelRes.data.id,
                    device_type_id: deviceTypeId,
                    manufacturer_id: manufacturerId,
                    manufacturer_name: manufacturerName,
                    product_code: getVariantCode({ name: data.name, storage: item }),
                    title: getVariantTitle({ name: data.name, storage: item }),
                    variants: { storage: parseInt(item.replace('TB', '000GB')) },
                    features: data.features,
                    model: data.name,
                    storage: item,
                    permakey: permakey(getVariantCode({ name: data.name, storage: item })),
                });
            });

            const createVariantsRes = await BulkActionsProvider.create('devices', devices);
            if (createVariantsRes.status === 'success') {
                alert('Base Device successfully added!');
                if (saveCallback && typeof saveCallback === 'function') {
                    setProcessing(false);
                    setLoadingText(null);
                    return saveCallback();
                } else {
                    return window.location = '/devices/models';
                }
            } else {
                alert('Base Device successfully added. Error adding variants!');
            }
        }
        setProcessing(false);
        alert(`Error: ${createModelRes.message}`);
        setLoadingText(null);
        return true;
    };

    return (
        <div style={{ position: 'relative' }}>
            {processing && <Loading text={loadingText} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex align-items-center justify-content-between mg-b-25">
                    <h4 className="mg-b-0 text-navy font-weight-600">{title || ''}</h4>
                    <div className="d-flex">
                        <button
                            className="btn btn-sm btn-outline-primary d-flex align-items-center mg-r-5"
                            type="submit"
                        >
                            <FeatherIcon icon="save" classes="wd-10 mg-r-5" />
                            <span className="d-none d-sm-inline mg-l-5"> Save</span>
                        </button>
                        <button
                            className="btn btn-sm btn-white d-flex align-items-center"
                            onClick={e => {
                                e.preventDefault();
                                if (isModal && typeof showModal === 'function') {
                                    showModal(false);
                                    return false;
                                }
                                return window.location = '/devices/models';
                            }}
                        >
                            <FeatherIcon icon="x" classes="wd-10 mg-r-5" />
                            <span className="d-none d-sm-inline mg-l-5"> Cancel</span>
                        </button>
                    </div>
                </div>
                <div data-label="Base Device Model Information" className="legend">
                    <Row>
                        <Col xs={12} md={4}>
                            <div className={`form-group ${!deviceTypeId ? 'is-invalid' : ''}`}>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Device Type
                                </label>
                                <Select
                                    className={!deviceTypeId ? 'parsley-error' : ''}
                                    name="device_type"
                                    options={types}
                                    isLoading={types.length === 0}
                                    isDisabled={types.length === 0}
                                    onChange={input => setDeviceTypeId(input.value)}
                                    styles={selectStyles}
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className={`form-group ${!manufacturerId ? 'is-invalid' : ''}`}>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Manufacturer
                                </label>
                                <Select
                                    className={!manufacturerId ? 'parsley-error' : ''}
                                    name="manufacturer_id"
                                    options={manufacturers}
                                    isLoading={manufacturers.length === 0}
                                    isDisabled={manufacturers.length === 0}
                                    styles={selectStyles}
                                    onChange={input => {
                                        setManufacturerId(input.value);
                                        return setManufacturerName(input.label);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className={`form-group ${errors.name ? 'is-invalid' : ''}`}>
                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                                    Model
                                </label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.name ? 'parsley-error' : ''}`}
                                    {...register('name', { required: true })}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                {features &&
                    <div data-label="Feature Information" className="legend mg-t-20">
                        <div className="media align-items-stretch device-features-media">
                            <ul className="nav nav-tabs flex-column wd-200">
                                {featureHeaders.map(item => (
                                    <li key={item} className="nav-item">
                                        <a
                                            className={`nav-link ${(activeFeature === item) ? 'active' : ''}`}
                                            data-toggle="tab"
                                            href="/"
                                            role="tab"
                                            onClick={e => {
                                                e.preventDefault();
                                                return setActiveFeature(item);
                                            }}
                                        >
                                            {formatStr(item)}
                                        </a>
                                    </li>))}
                            </ul>
                            <div className="media-body">
                                <div className="tab-content bd bd-gray-300 bd-l-0 pd-20">
                                    {featureHeaders.map(header => {
                                        let subheaders = [];
                                        if (features[header]) {
                                            subheaders = Object.keys(features[header]);
                                        }
                                        return (
                                            <div
                                                key={header}
                                                className={`tab-pane fade ${(activeFeature === header) ? 'active show' : ''}`}
                                                role="tabpanel"
                                            >
                                                <h4>{formatStr(header)}</h4>
                                                {subheaders.length > 0 && subheaders.map(subheader => {
                                                    let rows = [];
                                                    let value = null;
                                                    if (features[header][subheader] && typeof features[header][subheader] === 'object') {
                                                        if (features[header][subheader] && Object.keys(features[header][subheader]).length > 0) {
                                                            rows = Object.keys(features[header][subheader]);
                                                        }
                                                    } else {
                                                        value = features[header][subheader];
                                                    }
                                                    return (
                                                        <div key={subheader}>
                                                            {(rows.length > 0 || value) &&
                                                            <div className="form-group">
                                                                <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{formatStr(subheader)} {formatStr(header)}</label>
                                                                {rows.length > 0 && rows.map(item => (
                                                                <div key={item}>
                                                                    {typeof features[header][subheader][item] !== 'object' &&
                                                                        <div className="form-group">
                                                                            <div className="input-group mg-b-10">
                                                                                <div className="input-group-prepend">
                                                                                    <span className="input-group-text">
                                                                                        {formatStr(item)}
                                                                                    </span>
                                                                                </div>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={features[header][subheader][item]}
                                                                                    {...register(`features[${header}][${subheader}][${item}]`, {
                                                                                        required: false,
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                        </div>}
                                                                </div>))}
                                                                {value && (
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={value}
                                                                    {...register(features[header][subheader], {
                                                                        required: false,
                                                                    })}
                                                                />)}
                                                            </div>}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div data-label="Variations" className="legend mg-t-20">
                        <div className="form-group">
                            <div className="input-group mg-b-10">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Colours
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className={`form-control ${errors.colors ? 'parsley-error' : ''}`}
                                    placeholder="Enter colour names separated by a comma. e.g. Phantom Silver, Apple Green, Rossey Red, etc"
                                    {...register('colors', {
                                        required: true,
                                    })}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group mg-b-10">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Storage
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    name="storage"
                                    className={`form-control ${errors.storage ? 'parsley-error' : ''}`}
                                    placeholder="Enter storage capacity separated by a comma. e.g. 64GB, 128GB, 256GB, etc"
                                    {...register('storage', {
                                        required: true,
                                    })}
                                />
                            </div>
                        </div>
                    </div>
            </form>
        </div>
    );
};
