import React from 'react';
import { Card } from 'reactstrap';
import { useQuery } from '@apollo/client';
import UsersGQL from 'queries/users';
import Table from './RolesTable';

const RoleListTable = props => {
    const { data, loading } = useQuery(UsersGQL.listRoles);

    return (
        <div className="dealsheet-result-table-wrapper">
            <Card>
                <Table
                    roles={data && data.userRoles || []}
                    resultsLoading={loading}
                    {...props}
                />
            </Card>
        </div>
    );
};

export default RoleListTable;
