import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import { TextEditor } from 'components';
import { BulkActionsProvider, DealsheetsProvider } from 'providers';
import wysiwyg from 'static/wysiwyg.json';
import Swal from 'sweetalert2';

const EditSpecialTextModal = props => {
    const {
        show,
        data,
        setShow,
        bulkEdit,
    } = props;
    const [specialText, setSpecialText] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [loadComponent, setLoadComponent] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            var specialText = '';
            if (bulkEdit) {
                specialText = '';
            } else {
                specialText = (data.special_text) ? data.special_text : '';
                setSpecialText(specialText);
            }
            setLoadComponent(true);
        };
        fetchData();
    }, [data]);

    const handleClose = () => setShow(false);

    async function handleUpdate() {
        if (specialText.length > 0) {
            setSubmitting(true);
            if (bulkEdit) {
                const updateData = [];
                data.map(item => updateData.push({
                    item_id: item.id,
                    special_text: specialText,
                    dealsheet_no: item.dealsheet_number,
                }));
                const result = await BulkActionsProvider.update('dealsheet', updateData);
                setSubmitting(false);
                if (result.status === 'success') {
                    handleClose();
                    Swal.fire({
                        icon: 'success',
                        position: 'center',
                        title: 'Successfully saved special text!',
                        showConfirmButton: false,
                        timer: 6000
                    });
                    return setTimeout(async function() {
                        if (props.fetchData && typeof props.fetchData === 'function') {
                            return props.fetchData();
                        }
                        return window.location.href = '/deals';
                    }, 6000);
                } else {
                    return Swal.fire({
                        icon: 'error',
                        position: 'center',
                        title: result.message || 'Failed to process request, please try again!',
                        showConfirmButton: false,
                        timer: 6000
                    });
                }
            } else {
                const result = await DealsheetsProvider.update(data.id, {
                    special_text: specialText,
                    dealsheet_number: data.dealsheet_number,
                });

                setSubmitting(false);

                if (result.status === 'success') {
                    handleClose();
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Successfully saved special text!',
                        showConfirmButton: false,
                        timer: 6000
                    });
                    return setTimeout(async function() {
                        if (props.fetchData && typeof props.fetchData === 'function') {
                            return props.fetchData();
                        }
                        return window.location.href = '/deals';
                    }, 6000);
                } else {
                    return Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: result.message || 'Failed to process request, please try again!',
                        showConfirmButton: false,
                        timer: 6000
                    });
                }
            }
        }
    };

    return (
        <Modal
            centered
            size="xl"
            show={show}
            onHide={handleClose}
            className="special-text-modal"
        >
            <Modal.Header closeButton>
                <h6 className="modal-title">
                    Please enter Special Text&nbsp;
                    {!bulkEdit && <span>({data.dealsheet_number})</span>}
                    <br />
                    {bulkEdit &&
                        <span>
                            <strong>IMPORTANT:</strong>
                            &nbsp;You have selected multiple deals. The text entered below will be applied to all selected deals.
                        </span>}
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row className="margin-bottom-15">
                    <Col xs={12}>
                        {loadComponent &&
                            <TextEditor.WYSIWYG
                                model={specialText}
                                setModel={setSpecialText}
                                placeholderText="Enter special text..."
                                toolbarButtons={wysiwyg.toolbarButtons.specialText}
                            />}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-secondary tx-13"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary tx-13"
                    disabled={submitting}
                    onClick={() => {
                        handleUpdate();
                    }}
                >
                    {submitting ? 'Processing...' : ' Save '}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

EditSpecialTextModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    bulkEdit: PropTypes.bool,
    data: PropTypes.any.isRequired,
    setShowResultsModal: PropTypes.func,
    fetchData: PropTypes.any,
};

EditSpecialTextModal.defaultProps = {
    bulkEdit: false,
    fetchData: null,
    setShowResultsModal: () => { },
};

export default EditSpecialTextModal;
