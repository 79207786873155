import React from 'react';
import { Modal } from 'react-bootstrap';

export default function DefaultModal(props) {
    const {
        show,
        size,
        setShow,
        callback,
        modalTitle,
    } = props;

    const handleClose = () => {
        setShow(false);
        if (callback) callback();
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className="confirm-modal"
            size={size || 'md'}
        >
            {modalTitle && (
                <Modal.Header closeButton>
                    <h6 className="modal-title">
                        {modalTitle}
                    </h6>
                </Modal.Header>
            )}
            <Modal.Body>
                {props.children}
            </Modal.Body>
        </Modal>
    );
}
