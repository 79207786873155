import React, { useState } from 'react';
import { Users } from 'components';
import { Layout } from 'containers';
import { TabContent, TabPane } from 'reactstrap';

export default function UserManagement() {
    const [selected, setSelected] = useState(null);
    const [activeTab, setActiveTab] = useState('users');
    const [sideBar, setSideBar] = useState(null);

    const handleSelected = data => {
        const { key } = data;
        const sidebar = { type: key };

        switch (key) {
            case 'role':
                const {
                    label,
                    description,
                } = data;
                sidebar.heading = label;
                sidebar.subheading = description;
                break;

            case 'user':
                const {
                    first_name,
                    last_name,
                    role,
                } = data;
                sidebar.heading = `${first_name} ${last_name}`;
                sidebar.subheading = role.description;
                break;
        }
        setSideBar(sidebar);
        setSelected({ ...data, type: key });
    };

    async function handleUpdateRole(id, data) {

    }

    async function handleUpdateUser(id, data) {

    }

    return (
        <Layout>
            <div className="contact-wrapper">
                <div className="contact-navleft">
                    <Users.NavLeft
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                </div>
                <div className="contact-sidebar">
                    <Users.Search />
                    <div className="contact-sidebar-body ps ps--active-y">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="users">
                                <Users.List handleSelected={handleSelected} />
                            </TabPane>
                            <TabPane tabId="recently-added">
                                <Users.RecentlyAdded handleSelected={handleSelected} />
                            </TabPane>
                            <TabPane tabId="roles">
                                <Users.RoleList handleSelected={handleSelected} />
                            </TabPane>
                            <TabPane tabId="export">
                                <Users.Export
                                    setSideBar={setSideBar}
                                    setSelected={setSelected}
                                />
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
                <div className="contact-content">
                    <Users.Content
                        {...selected}
                        handleUpdateRole={handleUpdateRole}
                        handleUpdateUser={handleUpdateUser}
                    />
                    <div className="contact-content-sidebar ps">
                        {sideBar && (
                            <Users.ContentSideBar
                                {...sideBar}
                                selected={selected}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
}