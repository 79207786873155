import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Layout } from 'containers';
import { confirmAlert } from 'react-confirm-alert';
import {
  BulkActionsProvider,
  PackagesProvider,
} from 'providers';
import {
  Breadcrumb,
  FeatherIcon,
  ImportPackagesModal,
  Packages,
} from 'components';
import moment from 'moment';
import Swal from 'sweetalert2';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function PackagesPage() {
  const [pageLoading, setPageLoading] = useState(true);
  const [resultsLoading, setResultsLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [truncate, setTruncate] = useState(false);
  const [processing, setProcessing] = useState(false);
  const breadcrumb = [{ title: 'Packages', active: true }];

  async function fetchData() {
    if (!pageLoading) setPageLoading(true);
    const packages = await PackagesProvider.getAll(0, 800, 'has_contract_details=Yes');
    setPackages(packages.results);
    setResultsLoading(false);
    setPageLoading(false);
  };

  // load data on page load
  useEffect(() => {
    fetchData();
  }, []);

  const actionButtons = [{
    type: 'button',
    title: 'Import Packages',
    classes: 'btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5',
    icon: <FeatherIcon icon="download" classes="wd-10 mg-r-5" />,
    action: (e) => {
      e.preventDefault();
      setShowImportModal(true);
    },
  },
  {
    type: 'button-link',
    title: 'Add Package',
    classes: 'btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5',
    icon: <FeatherIcon icon="plus" classes="wd-10 mg-r-5" />,
    link: '/packages/add',
  }];

  const handleImportPackages = async (object) => {
    const { data } = object;
    setProcessing(true);

    // break data array into chunks for bulk processing
    // reduce the array/object into chunks of 100 records each
    const chunks = data.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 100);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);

    let noOfImportedPackages = 0;
    const failedChunk = [];
    const importError = [];
    const reference = `PU${moment().format('YYMMDDHHmmss')}`;

    await Promise.all(chunks.map(async (chunk, index) => {
      const query = (index === 0 && truncate) ? `?truncate=true&reference=${reference}` : `?reference=${reference}`;
      const response = await BulkActionsProvider.create('packages', chunk, query);
      const { status, message, results } = response;
      if (status === 'success') {
        noOfImportedPackages += (results && results.length) || 0;
        return true;
      } else {
        failedChunk.push(chunk);
        if (importError.indexOf(message) === -1) {
          importError.push({ save_dealsheet: message });
        }
        return false;
      }
    }));
    
    setShowImportModal(false);
    setProcessing(false);
    setTruncate(false);

    // change step
    if (failedChunk.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: `${noOfImportedPackages} Packages successfully imported!`,
        showConfirmButton: false,
        timer: 5000
      });
      return setTimeout(async function () {
        fetchData();
      }, 5000);
    }
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 5000
    });
  };

  async function handleDelete(data) {
    const { name, id } = data;
    confirmAlert({
      title: 'Confirm delete',
      message: `Are you are sure you want to delete ${name}?`,
      buttons: [{
        label: 'Yes, continue',
        onClick: async () => {
          setPageLoading(true);
          const result = await PackagesProvider.delete(id);
          const { status, message } = result;
          
          if (status && status === 'success') {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Request processed successfully!',
              showConfirmButton: false,
              timer: 4000
            });
            return setTimeout(async function () {
              setProcessing(false);
              fetchData();
            }, 4000);
          }

          setPageLoading(false);
          setProcessing(false);

          Swal.fire({
            position: 'center',
            icon: 'error',
            title: message || 'Failed to process request, please try again!',
            showConfirmButton: false,
            timer: 4000
          });
        }
      },
      {
        label: 'Cancel',
      }]
    });
  };

  return (
    <Layout pageLoading={pageLoading}>
      <ImportPackagesModal
        types={types}
        truncate={truncate}
        networks={networks}
        show={showImportModal}
        processing={processing}
        setTruncate={setTruncate}
        setShow={setShowImportModal}
        callback={handleImportPackages}
      />
      <div className="content content-fixed pd-b-0">
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <Breadcrumb
            heading="Package Management"
            items={breadcrumb}
            actions={actionButtons}
          />
        </Container>
      </div>
      <Packages.Filter
        types={types}
        resultsLoading={resultsLoading}
        setResultsLoading={setResultsLoading}
        packages={packages}
        networks={networks}
        selectedRows={selectedRows}
        setTypes={setTypes}
        setNetworks={setNetworks}
        setPackages={setPackages}
        setSelectedRows={setSelectedRows}
      />
      <Packages.ListTable
        setSelectedRows={setSelectedRows}
        resultsLoading={resultsLoading}
        handleDelete={handleDelete}
        packages={packages}
      />
    </Layout>
  );
}
