import React from 'react';
import { PreLoader } from 'components';
import { Header, Footer } from '../containers';

const Layout = props => {
    const { header, footer, pageLoading, loaderText } = props;
    return (
        <React.Fragment>
            {pageLoading && <PreLoader text={loaderText || 'Loading'} />}
            <Header {...header} />
            <div className="page-body-container" style={{ marginTop:60, minHeight: 'calc(100vh - 106px)' }}>
                {props.children}
            </div>
            <Footer {...footer} />
        </React.Fragment>
    );
};

export default Layout;