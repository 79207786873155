import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import DataTable from 'react-data-table-component';
import { FeatherIcon } from 'components';
import { DealsheetsProvider } from 'providers';
import { Link } from 'react-router-dom';
import config from '../../config';

const MatchedDevice = props => {
    const { device } = props;
    return (
        <div>
            {device ?
                <span className="tx-color-03">{device.title}</span> :
                <span className="tx-color-04">No match</span>
            }
        </div>
    );
};

const SpecialText = props => {
    const {
        id,
        dealsheet_number,
        setShowEditSpecialTextModal,
        special_text,
        setEditRowData,
    } = props;
    return (
        <>
            {special_text &&
                <a
                    role="button"
                    href="/"
                    onClick={(e) => {
                        e.preventDefault();
                        setEditRowData({ id, dealsheet_number, special_text });
                        setShowEditSpecialTextModal(true);
                    }}
                >
                    Edit
                </a>}
            {!special_text &&
                <a
                    role="button"
                    href="/"
                    onClick={(e) => {
                        e.preventDefault();
                        setEditRowData({ id, dealsheet_number, special_text });
                        setShowEditSpecialTextModal(true);
                    }}
                >
                    Add
                </a>}
        </>
    );
};

const Package = props => {
    return (
        <div>
            {props.package && <span>{props.package.name}<br /></span>}
            {props.package && <span className="tx-color-03">{props.package.description}</span>}
        </div>);
};

const SaleIndicator = props => {
    const { sale_indicator_id, sale_indicator } = props;
    return <div>{sale_indicator_id && sale_indicator.description}</div>;
};

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const RowActions = props => {
    const {
        id,
        dealsheet_number,
        handleDeleteDeal,
    } = props;
    return (
        <div>
            <Link to={`/deals/edit/${encodeURI(dealsheet_number)}`} className="mg-r-10">
                <FeatherIcon icon="edit" width="16" height="16" />
            </Link>
            &nbsp;&nbsp;
            <a
                href={`/deals/delete/${encodeURI(id)}`}
                className="tx-danger"
                onClick={e => {
                    e.preventDefault();
                    return handleDeleteDeal(props);
                }}
            >
                <FeatherIcon icon="trash-2" width="16" height="16" />
            </a>
        </div>
    );
};

const Table = props => {
    const {
        devices,
        setShowEditSpecialTextModal,
        handleRowSelected,
        dealsheets,
        setDealsheets,
        resultsLoading,
        setResultsLoading,
        setEditRowData,
        page,
        setPage,
        perPage,
        setPerPage,
        totalRecords,
        setTotalRecords,
        filterOptions,
        sorting,
        setSorting,
    } = props;

    const [componentLoaded, setComponentLoaded] = useState(false);

    useEffect(() => {
        if (!componentLoaded) {
            setComponentLoaded(true);
        }
        return () => { };
    }, []);

    const fetchData = async (perPage, page, sorting) => {
        setResultsLoading(true);
        const offset = (page - 1) * perPage;
        const limit = perPage;
        const filters = [];
        let dealsheets = [];

        filterOptions.map(item => {
            if (item.field_name.length > 0 && item.field_operator.length > 0) {
                return filters.push([
                    `${item.field_name}`,
                    `${(item.field_operator === 'ex') ? 'ne' : item.field_operator}`,
                    `${(item.field_operator === 'ne' || item.field_operator === 'ex') ? null : item.field_value}`,
                ]);
            }
            return item;
        });
        const orderby = `&orderby=[["${sorting.column}", "${sorting.direction}"]]`;
        if (filters.length > 0) {
            const querystr = JSON.stringify(filters);
            dealsheets = await DealsheetsProvider.filter(querystr + orderby, offset, limit);
        } else {
            dealsheets = await DealsheetsProvider.getAll(offset, limit, orderby);
        }
        setDealsheets(dealsheets.results);
        setTotalRecords(dealsheets.count);
        setResultsLoading(false);
    };

    async function handlePerRowsChange(perPage, page) {
        // only executable after page has fully loaded
        if (componentLoaded) {
            fetchData(perPage, page, sorting);
            setPerPage(perPage);
        }
    };

    const handlePageChange = async (page) => {
        // only executable after page has fully loaded
        if (componentLoaded) {
            fetchData(perPage, page, sorting);
            setPage(page);
        }
    };

    const handleSort = (column, sortDirection) => {
        // only executable after page has fully loaded
        if (componentLoaded) {
            const sortingOrder = {
                column: column.sortField,
                direction: sortDirection,
            };
            setSorting(sortingOrder);
            fetchData(perPage, page, sortingOrder);
        }
    };

    // table headings definition
    const columns = [{
        name: 'Dealsheet No',
        sortField: 'dealsheet_number',
        selector: row => row['dealsheet_number'],
        sortable: true,
        wrap: true,
        width: '150px',
    },
    {
        name: 'Package',
        sortField: 'package_description',
        selector: row => row['package_description'],
        sortable: true,
        wrap: true,
        cell: row => <Package {...row} />
    },
    {
        name: 'Dealsheet Device',
        sortField: 'device_description',
        selector: row => row['device_description'],
        sortable: true,
        wrap: true,
        cell: row => <span className="text-capitalize">{row.device_description.toLowerCase().replace('_', ' ')}</span>
    },
    {
        name: 'Matched Device',
        sortField: 'matched_device',
        selector: row => row['matched_device'],
        sortable: true,
        wrap: true,
        cell: row => <MatchedDevice {...row} devices={devices} />
    },
    {
        name: 'Device Image',
        selector: row => row['image'],
        sortable: false,
        cell: row => <span>{(row.device && row.device.image_default) ? 'Y' : 'N'}</span>
    },
    {
        name: 'Total Subscription',
        sortField: 'total_subscription',
        selector: row => row['total_subscription'],
        sortable: true,
        cell: row => (
            <>
                {row.total_subscription ?
                    <CurrencyFormat
                        value={row.total_subscription}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'R'}
                    /> : '-'}
            </>)
    },
    {
        name: 'Term',
        sortField: 'contract_term',
        selector: row => row['contract_term'],
        sortable: true,
    },
    {
        name: 'Valid From',
        sortField: 'valid_from',
        selector: row => row['valid_from'],
        sortable: true,
    },
    {
        name: 'Valid To',
        sortField: 'valid_to',
        selector: row => row['valid_to'],
        sortable: true,
    },
    {
        name: 'Sale Indicator',
        sortField: 'sale_indicator',
        selector: row => row['sale_indicator'],
        sortable: true,
        cell: row => <SaleIndicator {...row} />
    },
    {
        name: 'Special Text',
        selector: row => row['special_text'],
        sortable: false,
        cell: row => <SpecialText {...row} setShowEditSpecialTextModal={setShowEditSpecialTextModal} setEditRowData={setEditRowData} />
    },
    {
        name: 'Actions',
        sortable: false,
        cell: row => <RowActions {...row} {...props} />
    }];

    // render datatable component
    return (
        <DataTable
            data={dealsheets}
            columns={columns}
            customStyles={customStyles}
            onSelectedRowsChange={handleRowSelected}
            noHeader
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRecords}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressPending={resultsLoading}
            paginationRowsPerPageOptions={config.results.pagination.perpageOptions}
            paginationPerPage={perPage}
            onSort={handleSort}
        />
    );
};

export default Table;
