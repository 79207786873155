import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DealsheetImportSelectNetwork = props => {
    const {
        selected,
        networks,
        show,
        setStep,
        setShow,
        validToDate,
        setDealType,
        validFromDate,
        setValidToDate,
        setValidFromDate,
        handleSelectNetwork,
        showDealTypeInput,
        setDisableNextBtn,
    } = props;
    const [ disableContinue, setDisableContinue ] = useState(true);
    const handleClose = () => setShow(false);
    return (
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Modal.Header>
                <h4 className="modal-title">
                    Select network and additional options.
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <div className="form-group">
                            <label>Network Provider</label>
                            <select defaultValue={selected} className="form-control select2" onChange={e => handleSelectNetwork(e.target.value)}>
                                <option value="">Select</option>
                                {networks.length > 0 && networks.map(network => (
                                    <option key={network.id} value={network.id}>{network.name}</option>
                                ))}
                            </select>
                        </div>
                    </Col>
                    {showDealTypeInput &&
                    <Col xs={12}>
                        <div className="form-group">
                            <label>Deal Type</label>
                            <select
                                className="form-control select2"
                                onChange={e => {
                                    setDealType(e.target.value);
                                    setDisableContinue(e.target.value.length === 0);
                                }}
                            >
                                <option value="">Select</option>
                                <option value="Generic">Generic</option>
                                <option value="Exclusive">Exclusive</option>
                                <option value="Hot Deals">Hot Deals</option>
                                <option value="Promotion">Promotion</option>
                                <option value="Recommended">Recommended</option>
                            </select>
                        </div>
                        <Row>
                            <Col xs={6}>
                                <div className="form-group">
                                    <label>Valid From</label>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        selected={validFromDate}
                                        onChange={date => setValidFromDate(date)}
                                    />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="form-group">
                                    <label>Valid To</label>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        selected={validToDate}
                                        onChange={date => setValidToDate(date)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>}
                    <Col xs={6} className="mg-b-15">
                        <button
                            type="button"
                            className="btn btn-secondary tx-13"
                            onClick={() => window.location = '/dealsheets/import'}
                        >
                            Cancel
                        </button>
                    </Col>
                    {showDealTypeInput &&
                    <Col xs={6} className="text-right mg-b-15">
                        <button
                            type="button"
                            className="btn btn-primary tx-13"
                            disabled={disableContinue}
                            onClick={() => {
                                setStep(2);
                                handleClose();
                                setDisableNextBtn(false);
                                return setDisableContinue(true);
                            }}
                        >
                            Continue
                        </button>
                    </Col>}
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default DealsheetImportSelectNetwork;
