import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import queryString from 'query-string';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FeatherIcon, Form } from 'components';
import { DevicesProvider, ManufacturersProvider } from 'providers';

const ModelItem = props => {
    const { manufacturer, name, handleSelect } = props;
    return (
        <a
            role="button"
            href="/"
            className="option-link"
            onClick={e => {
                e.preventDefault();
                return handleSelect(props);
            }}
        >
            {manufacturer.name} {name}
        </a>
    );
};

const ModelFilter = (props) => {
    const { resultsLoading, setData, setTotalRecords, setFilterOptions, setResultsLoading } = props;
    const [ model, setModel ] = useState('');
    const [ deviceTypeId, setDeviceTypeId ] = useState(null);
    const [ manufacturers, setManufacturers ] = useState([]);
    const [ manufacturer, setManufacturer ] = useState(null);
    const [ collapsed, setCollapsed ] = useState(false);
    const [ processing, setProcessing ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ deviceTypes, setDeviceTypes ] = useState([]);
    const [ searchResults, setSearchResults ] = useState([]);
    const [ selectedModel, setSelectedModel ] = useState(null);
    const [ hasImage, setHasImage ] = useState(false);
    const toggleCollapsed = () => setCollapsed(!collapsed);

    useEffect(() => {
        const fetchData = async () => {
            const types = await DevicesProvider.getDeviceTypes();
            const brands = await ManufacturersProvider.getAll();
            if (types.results) {
                if (types.results.length > 0) {
                    const deviceTypes = [];
                    types.results.map(item => deviceTypes.push({ value: item.id, label: item.name }));
                    setDeviceTypes(deviceTypes);
                }
            }
            if (brands.results) {
                if (brands.results.length > 0) {
                    const manufacturers = [];
                    brands.results.map(item => manufacturers.push({ value: item.id, label: item.name }));
                    setManufacturers(manufacturers);
                }
            }
            setIsLoading(false);
        };
        fetchData();
    }, [ ]);

    async function handleSubmit(e) {
        e.preventDefault();
        if (selectedModel || deviceTypeId || manufacturer) {
            setResultsLoading(true);
            setProcessing(true);
            const filters = {};
            if (selectedModel) filters.id              = selectedModel.id;
            if (deviceTypeId)  filters.device_type_id  = deviceTypeId;
            if (manufacturer)  filters.manufacturer_id = manufacturer;

            filters.has_image = hasImage ? 'Yes' : 'No';
            
            if (Object.keys(filters).length > 0) {
                setFilterOptions(filters);
                const querystr = queryString.stringify(filters);
                const devices = await DevicesProvider.getModels(0, 200, `&${querystr}`);
                setResultsLoading(false);
                setData(devices.results);
				setTotalRecords(devices.count);
                setProcessing(false);
                return true;
            }
            setProcessing(false);
            setResultsLoading(false);

        } else alert('Please select filter options!');
    };

    const handleSelect = item => {
        setModel(`${item.manufacturer_name} ${item.name}`);
        setSelectedModel(item);
        return setSearchResults([]);
    };

    const handleSearchModel = async e => {
        setSearchResults([]);
        setModel(e.target.value);
        if (e.target.value.length > 2) {
            const data = await DevicesProvider.searchModels('model_name', e.target.value, 0, 50);
            const { results } = data;
            const object = [];
            results.map(item => object.push(<ModelItem {...item} handleSelect={handleSelect} />));
            setSearchResults(object);
        }
    };

    async function handleHasImageChange(checked) {
        setHasImage(checked);
        const filters = {};

        setProcessing(true);
        setResultsLoading(true);

        if (selectedModel || deviceTypeId || manufacturer) {
            if (selectedModel) filters.id              = selectedModel.id;
            if (deviceTypeId)  filters.device_type_id  = deviceTypeId;
            if (manufacturer)  filters.manufacturer_id = manufacturer;
        }

        filters.has_image = checked ? 'Yes' : 'No';
        
        if (Object.keys(filters).length > 0) {
            setFilterOptions(filters);
            const querystr = queryString.stringify(filters);
            const devices = await DevicesProvider.getModels(0, 200, `&${querystr}`);
            setResultsLoading(false);
            setData(devices.results);
            setTotalRecords(devices.count);
            setProcessing(false);
            return true;
        }
        
        setProcessing(false);
        setResultsLoading(false);
    };

    return (
        <div className="filter-wrapper">
            <button
                className={`btn btn-primary btn-block rounded-0 btn-filter-heading ${collapsed ? 'collapsed' : ''}`}
                data-toggle="collapse"
                type="button"
                aria-expanded="false"
                aria-controls="collapseFilters"
                onClick={toggleCollapsed}
            >
                Filter Options
            </button>
            <div className={`collapse mg-t-0 ${collapsed ? '' : 'show'}`} id="collapseFilters">
                <Card>
                    <CardBody>
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col xs={12} className="mg-b-15">
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <div className="form-group mg-b-0">
                                                <label>Device type</label>
                                                <Select
                                                    options={deviceTypes}
                                                    name="device_type_id"
                                                    isLoading={isLoading}
                                                    isDisabled={resultsLoading}
                                                    onChange={(item) => {
                                                        if (item.value.length > 0) {
                                                            setDeviceTypeId(item.value);
                                                        } else {
                                                            setDeviceTypeId(null);
                                                        }
                                                        return true;
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <div className="form-group mg-b-0">
                                                <label>Manufacturer</label>
                                                <Select
                                                    options={manufacturers}
                                                    isDisabled={resultsLoading}
                                                    onChange={(item) => {
                                                        if (item.value.length > 0) {
                                                            setManufacturer(item.value);
                                                        } else {
                                                            setManufacturer(null);
                                                        }
                                                        return true;
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <div className="form-group mg-b-0">
                                                <label>Name</label>
                                                <Form.AutocompleteInput
                                                    value={model}
                                                    setValue={setModel}
                                                    placeholder="Find and select device base model..."
                                                    classes="form-group"
                                                    handleValueChange={handleSearchModel}
                                                    setProcessing={setProcessing}
                                                    searchResults={searchResults}
                                                    secondaryButton={
                                                        <Form.Button
                                                            text=""
                                                            buttonLink
                                                            buttonLinkHref="/devices/models/add"
                                                            classes="btn btn-outline-light"
                                                            icon={<FeatherIcon icon="plus" />}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} md={2}>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                        disabled={processing || resultsLoading}
                                    >
                                        {processing ? 'Processing...' : 'Apply Filters'}
                                    </button>
                                </Col>
                                <Col xs={6} md={2}>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block"
                                        disabled={processing || resultsLoading}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location  = '/devices/models';
                                        }}
                                    >
                                        Reset Filters
                                    </button>
                                </Col>
                                <Col xs={6} md={2} style={{ paddingTop: "10px" }} >
                                    <div className="custom-control custom-switch">
                                        <input 
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            id="hasImage"
                                            value={hasImage ? 'Yes' : 'No'}
                                            checked={hasImage} 
                                            onChange={(e) => handleHasImageChange(e.target.checked)}
                                            disabled={processing || resultsLoading}
                                        />
                                        <label className="custom-control-label" htmlFor="hasImage">Device has image?</label>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default ModelFilter;
