import axios from 'axios';
import config from '../config';
import { browserName, osName, osVersion } from 'react-device-detect';
import Session from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
let headers   = {};

if (Session.getToken()) {
  headers = {
    'Authorization': `Bearer ${Session.getToken()}`,
  }
}

class AuthProvider {
  static async login(user, password, geoLocation = {}) {
    const device = {
      browser: browserName,
      os_name: osName,
      os_version: osVersion,
    };
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}auth/login`,
      data: { user, password, device, geoinfo: geoLocation },
      crossdomain: true,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async register(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}auth/register`,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async changePassword(old_password, new_password1, new_password2) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}auth/password/change`,
      data: { old_password, new_password1, new_password2 },
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async resetPassword(email) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}auth/password/reset`,
      data: { email },
      crossdomain: true,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async resetPasswordConfirm(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}auth/password/reset/confirm`,
      crossdomain: true,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }
}

export default AuthProvider;
