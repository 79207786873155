import axios from 'axios';
import config from '../config';
import Session from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
let headers   = {};

if (Session.getToken()) {
  headers = {
    'Authorization': `Bearer ${Session.getToken()}`,
  }
}

class DevicesProvider {
  static async create(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}devices`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async createModel(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}devices/models`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async createDeviceType(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}device_types`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async attributes(offset=config.results.offset, limit=config.results.limit) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}attributes?offset=${offset}&limit=${limit}&category=devices&orderby=[["sort_order", "ASC"]]`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async getAll(offset=config.results.offset, limit=config.results.limit, queryString) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}devices?offset=${offset}&limit=${limit}${queryString}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async getModels(offset=config.results.offset, limit=config.results.limit, queryString=`&orderby=${JSON.stringify(config.results.sorting.models)}`) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}devices/models?offset=${offset}&limit=${limit}${queryString}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async getModel(id) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}devices/models/${id}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async get(id) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}devices/${id}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async addImages(id, data) {
    let requestHeaders = headers;
    requestHeaders['Content-Type'] = 'multipart/form-data';
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}devices/${id}/images`,
      crossdomain: true,
      headers: requestHeaders,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async getImages(id, uri = '') {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}devices/${uri + id}/images`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async search(prop, value, offset=config.results.offset, limit=config.results.limit) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}devices/search/${prop}/${value}?offset=${offset}&limit=${limit}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async searchModels(prop, value, offset=config.results.offset, limit=config.results.limit, orderby='[["name", "ASC"]]') {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}devices/models/search/${prop}/${value}?offset=${offset}&limit=${limit}&orderby=${orderby}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async getVariants(id, key='color', qstr=null) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}devices/models/${id}/variants?key=${key}${qstr ? `&${qstr}` : ''}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async getDeviceTypes(offset=config.results.offset, limit=config.results.limit) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}device_types?offset=${offset}&limit=${limit}`,
      crossdomain: true,
      headers
    })
    .then((json) => json.data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async update(id, data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}devices/${id}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async updateModel(id, data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}devices/models/${id}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async updateDeviceType(id, data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}device_types/${id}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async delete(id) {
    return await axios({
      mode: 'no-cors',
      method: 'DELETE',
      url: `${baseurl}devices/${id}`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async deleteModel(id) {
    return await axios({
      mode: 'no-cors',
      method: 'DELETE',
      url: `${baseurl}devices/models/${id}`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async deleteDeviceType(id) {
    return await axios({
      mode: 'no-cors',
      method: 'DELETE',
      url: `${baseurl}device_types/${id}`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }
}

export default DevicesProvider;
