import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ heading, items, actions }) => {
    return (
        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                        {/* <li className="breadcrumb-item"><Link to="/">Dealsheets</Link></li> */}
                        {items.length > 0 && items.map((item, index) => (
                            <li key={index.toString()} className={`breadcrumb-item ${item.active ? 'active' : ''}`}>
                                {item.active && <span>{item.title}</span>}
                                {!item.active && <Link to={item.link}>{item.title}</Link>}
                            </li>
                        ))}
                    </ol>
                </nav>
                <h4 className="mg-b-0 tx-spacing--1">{heading}</h4>
            </div>
            {actions.length > 0 &&
            <div className="d-none d-md-block">
                {actions.map((item) => {
                    if (item.type === 'button') {
                        return (
                            <button
                                key={item.title}
                                className={item.classes}
                                onClick={item.action || function() {}}
                            >
                                {item.icon} {item.title}
                            </button>
                        );
                    }
                    if (item.type === 'button-link') {
                        return (
                            <Link to={item.link} key={item.title}>
                                <button
                                    className={item.classes}
                                >
                                    {item.icon} {item.title}
                                </button>
                            </Link>
                        );
                    }
                    return false;
                })}
            </div>}
        </div>
    );
};

Breadcrumb.propTypes = {
    actions: PropTypes.instanceOf(Array),
    heading: PropTypes.string,
    items: PropTypes.instanceOf(Array),
};

Breadcrumb.defaultProps = {
    actions: [],
    heading: 'Manage Deals',
    items: [],
};

export default Breadcrumb;
